import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../redux/reducers/spinner";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function VerifyAction({
  order_id,
  start_date,
  end_date,
  params,
}) {
  const dispatch = useDispatch();

  let remitId = order_id;

  const [visible, setvisible] = useState(false);
  const creating = useSelector((state) => state.admin.creating);

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    courier_order_id: "",
    verifier_comment: "",
  });
  const [payload, setPayload] = useState({
    start_date: start_date,
    end_date: end_date,
  });
  const { verifier_comment } = input;

  useEffect(() => {
    setPayload({
      ...payload,
      start_date: start_date,
      end_date: end_date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    dispatch(
      adminActions.verifyRemittance(
        {
          ...input,
          courier_order_id: remitId,
          verifier_comment: verifier_comment ? verifier_comment : "No comments",
        },
        payload,
        params,
        setvisible
      )
    );
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setvisible(true)}>
          Verify
        </MDBBtn>

        <MDBModal isOpen={visible}>
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>Verify this remittance!</MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group" +
                  (submitted && !verifier_comment ? " is-invalid" : "")
                }
              >
                <label htmlFor="verifier_comment">
                  Add a comment(optional)
                </label>
                <input
                  type="text"
                  name="verifier_comment"
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !verifier_comment ? " is-invalid" : "")
                  }
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Verify Remittance"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
