import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable } from "mdbreact";
import CreateEmployee from "./CreateEmployee";
import EditEmployee from "./EditEmployee";
import DeleteEmployee from "./DeleteEmployee";
import ApproveEmployee from "./ApproveEmployee";
import ViewEmployee from "./ViewEmployee";
import InviteEmployee from "./InviteEmployee";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import TableLoader from "../../includes/Loaders/TableLoader";
import ReuseableSearchBar from "../../search/search";
import PageNumber from "../../includes/PageNumber";

export default function Employees() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  const allEmployees = useSelector((state) => state.admin.allEmployees);
  const loading = useSelector((state) => state.admin.getEmployeesloader);
  const searching = useSelector((state) => state.admin.searching);
  const dispatch = useDispatch();

  let params = useParams();
  useEffect(() => {
    dispatch(adminActions.getEmployees(company_id, params.page));
  }, [dispatch, company_id, params]);

  let employeeData = allEmployees.data;

  function createTableData() {
    let dataToMap = employeeData && employeeData.data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((employee, i) => {
        let singleRowArray = {
          name: employee.employee_name,
          contact: <span>{employee.employee_phone}</span>,
          address: employee.employee_address,
          role: employee.employee_job_title,
          company_status:
            employee.company_approved === true ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Approved
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                False
              </span>
            ),

          status:
            employee.termination_date === null &&
            employee.status === "active" ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Active
              </span>
            ) : employee.termination_date === null &&
              employee.status === "inactive" ? (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Inactive
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Terminated
              </span>
            ),

          actions: (
            <div className="d-flex">
              <EditEmployee employeeData={employee} company={company_id} />
              <DeleteEmployee employeeData={employee} company={company_id} />
              {employee.company_approved === false &&
                employee.termination_date === null && (
                  <ApproveEmployee
                    employeeData={employee}
                    company={company_id}
                  />
                )}
              <ViewEmployee employeeData={employee} />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact no.",
        field: "contact",
        sort: "asc",
        width: 100,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: 'Company',
      //     field: 'company',
      //     sort: 'asc',
      //     width: 100
      // },
      {
        label: "Job Title",
        field: "role",
        sort: "asc",
        width: 100,
      },
      {
        label: "Company Approved",
        field: "company_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };
  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          <div className="main-content">
            <div className=" d-flex justify-content-between  align-items-center flex-wrap mb-3">
              <h1 className="mr-2">Employees</h1>
              <div className="d-flex justify-content-end align-items-center ">
                <InviteEmployee />
                <CreateEmployee />
              </div>
            </div>
            <div className="separator-breadcrumb border-top" />
            <div className="search-page">
              <PageNumber data={employeeData} route="employees" />
              <ReuseableSearchBar
                companyId={company_id}
                page={params.page}
                searchAction={adminActions.searchEmployee}
                clearSearchResultsAction={adminActions.getEmployees}
              />
            </div>

            <div>
              {loading || searching ? (
                <TableLoader />
              ) : (
                <MDBDataTable
                  responsive
                  entries={8}
                  entriesOptions={[8, 10, 15]}
                  bordered
                  maxHeight="400px"
                  theadColor="MDBTableHeader"
                  hover
                  noBottomColumns
                  data={data}
                  paging={false}
                  searching={false}
                />
              )}
            </div>
            <Pagination data={employeeData} route="employees" />
          </div>
        </div>
      )}
    </MainApp>
  );
}
