import React, { useEffect, useState } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function ChangePhone({ employeeId }) {
  const employee_id = employeeId;
  const allEmployees = useSelector((state) => state.admin.allEmployees);
  let employeeData = allEmployees.data;

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      employee_phone: employeeData?.employee_phone,
    };
    setData({ ...dataObject });
  }, [employeeData]);

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({ id: "", employee_phone: "" });
  let [employee_phone, setEmployeePhone] = useState("");
  employee_phone = employee_phone?.substring(1);
  const updating = useSelector((state) => state.users.updating);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // dispatch action to change phone
    dispatch(userActions.changePhone({ ...data, id: employee_id }));
    handleClose();
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setOpen(true)}>
          Change Phone Number
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Change your Phone Number
            </MDBModalHeader>
            <MDBModalBody>
              <label htmlFor="phone">New Phone Number</label>
              <PhoneInput
                defaultCountry="NG"
                value={data.employee_phone}
                onChange={setEmployeePhone}
                className={
                  "form-control form-control-rounded" +
                  (submitted && !employee_phone ? " is-invalid" : "")
                }
              />
              {submitted && !employee_phone && (
                <div className="invalid-feedback">
                  New Phone Number is required
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {updating === true ? <Beat loading={updating} /> : "Save"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default ChangePhone;
