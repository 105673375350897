import { MDBContainer, MDBBtn } from "mdbreact";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";

export default function AcceptAnOrder({ id }) {
  // let user = JSON.parse(localStorage.getItem("user"));
  // let teamId = JSON.parse(localStorage.getItem("teamId"));
  // const company_id = user.data.groups[teamId].company_id;

  const dispatch = useDispatch();

  const accepting = useSelector((state) => state.admin.accepting);

  function accept() {
    dispatch(adminActions.acceptOrder({ courier_order_id: id }));
  }

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => accept()}>
          {accepting === true ? <Beat loading={true} /> : "Accept Order"}
        </MDBBtn>
      </MDBContainer>
    </div>
  );
}
