import React from "react";
import { css } from "@emotion/core";
import RotateLoader from "react-spinners/RotateLoader";
import BeatLoader from "react-spinners/BeatLoader";
import PulseLoader from "react-spinners/PulseLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Rotate({ color }) {
  return (
    <RotateLoader css={override} size={80} color={color ? color : "#AA880E"} />
  );
}
function Pulse() {
  return <PulseLoader css={override} size={5} color={"#B2BEB5"} />;
}
function Beat() {
  return <BeatLoader css={override} size={10} color={"#ffffff"} />;
}

export { Rotate, Beat, Pulse };
