import React from "react";
import { useDispatch } from "react-redux";
import { LOGOWHITE, PImage } from "../images/images";
import { FOODBG } from "../images/images";
import { userActions } from "../../redux/actions";

export default function TermsConditions() {
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem("user"));
  function logout() {
    dispatch(userActions.logout());
  }
  return (
    <>
      <div
        className="main-header px-4"
        style={{
          backgroundImage: `url(${FOODBG})`,
          backgroundColor: "#ba2312",
        }}
      >
        <div className="logo">
          <a href="/">
            <img src={LOGOWHITE} alt="Logo" />
          </a>
        </div>

        <div style={{ margin: "auto" }} />
        <div className="header-part-right">
          {/* User avatar dropdown */}
          {user?.data ? (
            <div className="dropdown">
              <div className="user col align-self-end">
                <div className="d-flex">
                  <p className="text-white mb-1 mr-3 text-capitalize">
                    Hello {user?.data?.name}
                  </p>
                  <div className="availability-status">
                    <img
                      src={PImage}
                      alt="avatar"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="userDropdown"
                    >
                      <div className="dropdown-header">
                        <i className="i-Lock-User mr-1" /> {user?.data?.name}
                      </div>
                      <a className="dropdown-item" href="/user/profile">
                        My Profile
                      </a>

                      <button
                        className="dropdown-item"
                        onClick={() => logout()}
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <a
              href="/auth/login"
              className="btn btn-rounded btn-secondary btn-block"
            >
              Login
            </a>
          )}
        </div>
      </div>
      <div className="main-content-padding">
        <div className="main-content">
          {/* ============ Body content start ============= */}
          <div className="breadcrumb">
            <div>
              <h1 className="mr-2 mb-2">Terms of Service</h1>
              <p className="mb-0">Last Updated on July, 2017</p>
            </div>
          </div>
          <div className="separator-breadcrumb border-top" />

          <div className="writeup-list-item">
            <h3>1. Introduction</h3>
            <p>
              These Terms contained herein on this webpage, shall govern your
              use of this website, including all pages within this website
              (collectively referred to herein below as this “Website”). These
              Terms apply in full force and effect to your use of this Website
              and by using this Website, you expressly accept all terms and
              conditions contained herein in full. You must not use this
              Website, if you have any objection to any of these Website
              Standard Terms and Conditions.
            </p>
            <p>
              This Website is not for use by any minors (defined as those who
              are not at least 18 years of age), and you must not use this
              Website if you are a minor.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>2. Intellectual Property Rights.</h3>
            <p>
              OGWUGO and its licensors own all rights to the intellectual
              property and material contained in this Website, and all such
              rights are reserved.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>3. Restrictions.</h3>
            <p>
              You are expressly and emphatically restricted from all of the
              following:
            </p>
            <ol type="a">
              <li>
                Publishing any Website material in any media EXCEPT for the
                purpose of disseminating valuable information as a way of
                creating awareness;
              </li>
              <li>
                Selling, sublicensing and/or otherwise commercializing any
                Website material;
              </li>
              <li>Publicly performing and/or showing any Website material;</li>
              <li>Using this Website in any way that</li>
              <li>
                Using this Website in any way that impacts user access to this
                Website;
              </li>
              <li>
                Using this Website contrary to applicable laws and regulations,
                or in a way that causes, or may cause, harm to the Website, or
                to any person or business entity;
              </li>
              <li>
                Engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this Website, or while
                using this Website;
              </li>
              <li>
                Using this Website to engage in any advertising or marketing
                without proper authorization;
              </li>
            </ol>
            <p>
              Certain areas of this Website are restricted from access by you
              and OGWUGO may further restrict access by you to any areas of this
              Website, at any time, in its sole and absolute discretion. Any
              user ID and password you may have for this Website are
              confidential and you must maintain confidentiality of such
              information.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>4. Your Content.</h3>
            <p>
              In these Terms and Conditions, “Your Content” shall mean any
              audio, video, text, images or other material you choose to display
              on this Website. With respect to Your Content, by displaying it,
              you grant OGWUGO a non-exclusive, worldwide, irrevocable,
              royalty-free, sub licensable license to use, reproduce, adapt,
              publish, translate and distribute it in any and all media.
            </p>
            <p>
              Your Content must be your own and must not be infringing on any
              third party’s rights. OGWUGO reserves the right to remove any of
              Your Content from this Website at any time, and for any reason,
              without notice.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>5. Limitation of liability.</h3>
            <p>
              In no event shall OGWUGO, nor any of its officers, directors and
              employees, be liable to you for anything arising out of or in any
              way connected with your use of this Website, whether such
              liability is under contract, tort or otherwise, and OGWUGO,
              including its officers, directors and employees shall not be
              liable for any indirect, consequential or special liability
              arising out of or in any way related to your use of this Website.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>6. Refunds on the cancellation of confirmed orders.</h3>
            <p>
              Please note that if your order has been confirmed and is under
              process, a full refund of the amount paid will not be refunded if
              you wish to cancel the order. A fine of 10% will be charged and
              you will thereby be issued with 90% of the value of your order.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>7. Indemnification.</h3>
            <p>
              You hereby indemnify to the fullest extent, OGWUGO from and
              against any and all liabilities, costs, demands, causes of action,
              damages and expenses (including reasonable attorney’s fees)
              arising out of or in any way related to your breach of any of the
              provisions of these Terms.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>8. Severability.</h3>
            <p>
              If any provision of these Terms is found to be unenforceable or
              invalid under any applicable law, such unenforceability or
              invalidity shall not render these Terms unenforceable or invalid
              as a whole, and such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>9. Variation of Terms.</h3>
            <p>
              OGWUGO is permitted to revise these Terms at any time as it sees
              fit, and by using this Website you are expected to review such
              Terms on a regular basis to ensure you understand all terms and
              conditions governing use of this Website.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>10. Assignment.</h3>
            <p>
              OGWUGO shall be permitted to assign, transfer, and subcontract its
              rights and/or obligations under these Terms without any
              notification or consent required. However, .you shall not be
              permitted to assign, transfer, or subcontract any of your rights
              and/or obligations under these Terms.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>11. Entire Agreement</h3>
            <p>
              These Terms, including any legal notices and disclaimers contained
              on this Website, constitute the entire agreement between OGWUGO
              and you in relation to your use of this Website, and supersede all
              prior agreements and understandings with respect to the same.
            </p>
          </div>

          <div className="writeup-list-item">
            <h3>12. Governing Law & Jurisdiction.</h3>
            <p>
              These Terms will be governed by and construed in accordance with
              the laws of Federal Republic of Nigeria and you submit to the
              non-exclusive jurisdiction of the state and federal courts located
              in NIGERIA for the resolution of any disputes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
