// import config from 'config';
import { authHeader, history } from "../helpers";
import base_url from "./base_url";
import app_url from "./app_url";

export const userService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  createPushNotification,
  registerCompany,
  getRider,
  createMyReferral,
  approveRider,
  getSingleRider,
  getActiveRider,
  getInactiveRider,
  ImageUpload,
  createRider,
  editRider,
  deleteRider,
  getSingleUser,
  getMyCompanies,
  changePhone,
  addDescription,
  inviteUser,
  registerInvite,
  registerUser,
  getMyInvites,
  rejectInvite,
  getMyReferral,
  getUserOrders,
  filterUserOrders,
  trackOrder,
  getSecondPartyOrder,
  userImageUpload,
  changeUserPhone,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };
  return fetch(`${base_url}api/auth/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem(
        "accessToken",
        JSON.stringify(user.data.access_token)
      );
      return user;
    });
}

function logout(callBack = () => {}) {
  // remove user from local storage to log user out
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  fetch(`${base_url}api/auth/logout`, requestOptions)
    .then((response) => response.json())
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      history("/auth/login");
      callBack();
      // return user;
    });
}

function forgotPassword(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...input,
      reset_form_link: `${app_url}/auth/resetPassword`,
    }),
  };

  return fetch(`${base_url}api/auth/forgot`, requestOptions).then(
    handleResponse
  );
}

function resetPassword(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/auth/reset`, requestOptions).then(
    handleResponse
  );
}

function changePassword(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/auth/change`, requestOptions).then(
    handleResponse
  );
}
function changePhone(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/employee/update`, requestOptions).then(
    handleResponse
  );
}
function changeUserPhone(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/user/update`, requestOptions).then(
    handleResponse
  );
}
function ImageUpload(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/employee/update`, requestOptions).then(
    handleResponse
  );
}
function userImageUpload(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/user/update`, requestOptions).then(
    handleResponse
  );
}
function addDescription(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/user/update`, requestOptions).then(
    handleResponse
  );
}
function inviteUser(input, company_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...input,
      company_id,
      invite_form_link: `${app_url}/auth/inviteRegisteration`,
    }),
  };

  return fetch(`${base_url}api/invite/store`, requestOptions).then(
    handleResponse
  );
}

function createPushNotification(id, firebaseDetails) {
  // console.log("called this guy service",id, firebaseDetails)
  // console.log("firebase stuff",JSON.stringify({
  //     "user_id": id,
  //     "url": process.env.REACT_APP_FIREBASE_URL,
  //     "server_key": process.env.REACT_APP_FIREBASE_SERVER_KEY,
  //     "device_token": firebaseDetails.device_token,
  //     "device_id": firebaseDetails.device_id,
  //     "device_type": firebaseDetails.device_type,
  //     "notification_type": firebaseDetails.notification_type,
  //     "notification_agent": firebaseDetails.notification_agent,
  // }))
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      user_id: id,
      url: process.env.REACT_APP_FIREBASE_URL,
      server_key: process.env.REACT_APP_FIREBASE_SERVER_KEY,
      device_token: firebaseDetails.device_token,
      device_id: firebaseDetails.device_id,
      device_type: firebaseDetails.device_type,
      notification_type: firebaseDetails.notification_type,
      notification_agent: firebaseDetails.notification_agent,
    }),
  };

  return fetch(`${base_url}api/push-notification/store`, requestOptions).then(
    handleResponse
  );
}

function getRider(company_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id: company_id }),
  };

  return fetch(`${base_url}api/courier/filter/index`, requestOptions).then(
    handleResponse
  );
}
function approveRider(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };
  return fetch(`${base_url}api/courier/approve/company`, requestOptions).then(
    handleResponse
  );
}

function getSingleRider(rider_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${base_url}api/courier/show?id=${rider_id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function getActiveRider(company_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      company_id,
      status: "active",
      company_approved: "1",
      pagination: "1",
    }),
  };

  return fetch(`${base_url}api/courier/filter/index`, requestOptions).then(
    handleResponse
  );
}

function getInactiveRider(company_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "inactive" }),
  };

  return fetch(`${base_url}api/courier/filter/index`, requestOptions).then(
    handleResponse
  );
}

function createRider(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/onboard/courier`, requestOptions).then(
    handleResponse
  );
}

function editRider(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/courier/update`, requestOptions).then(
    handleResponse
  );
}
// function uploadImage() {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//      ...authHeader(), "Content-Type": "multipart/form-data",
//     },
//     body: formData,
//   };
//   return fetch(`${base_url}api/user/update`, requestOptions).then(
//     handleResponse
//   );
// }

function deleteRider(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };

  return fetch(`${base_url}api/courier/terminate`, requestOptions).then(
    handleResponse
  );
}

function getSingleUser(user_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${base_url}api/user/show?user_id=${user_id}`,
    requestOptions
  ).then(handleResponse);
}

function getMyCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${base_url}api/company/me`, requestOptions).then(
    handleResponse
  );
}

function registerCompany(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/onboard/company`, requestOptions).then(
    handleResponse
  );
}
function registerUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/auth/register`, requestOptions).then(
    handleResponse
  );
}

function getMyInvites() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${base_url}api/invite/me?properties=1`, requestOptions).then(
    handleResponse
  );
}
function rejectInvite(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: id }),
  };

  return fetch(`${base_url}api/invite/refuse`, requestOptions).then(
    handleResponse
  );
}

function registerInvite(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/onboard/invite`, requestOptions).then(
    handleResponse
  );
}
function getMyReferral() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${base_url}api/referral/me?properties=1`, requestOptions).then(
    handleResponse
  );
}
function getUserOrders() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(`${base_url}api/second-party/order/me`, requestOptions).then(
    handleResponse
  );
}
function createMyReferral(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log(data, "data");
  return fetch(`${base_url}api/referral/store`, requestOptions).then(
    handleResponse
  );
}
function filterUserOrders(payload, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${base_url}api/second-party/order/filter/me`,
    requestOptions
  ).then(handleResponse);
}
function trackOrder() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(`${base_url}api/second-party/order/track`, requestOptions).then(
    handleResponse
  );
}
function getSecondPartyOrder(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${base_url}api/second-party/order/show?id=${id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (response.status === 204) {
      return (response.data = { data: { data: [] } });
    }
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
