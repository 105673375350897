import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../redux/reducers/spinner";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import OrderItems from "../../includes/OrderItems";

export default function RemitAction({ order_id, id }) {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.admin.singleOrder);
  useEffect(() => {
    setInputArray(data.order_items);
  }, [data.order_items]);

  let remitId = order_id;
  const [inputArray, setInputArray] = useState([]);

  const [visible, setvisible] = useState(false);
  const handleClose = () => {
    setvisible(false);
  };
  const creating = useSelector((state) => state.admin.creating);

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    id: "",
    remitted_amount: "",
    comment: "",
    remittance_point: 1,
    delivered_items: [],
  });

  const { remitted_amount, comment, delivered_items, remittance_point } = input;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };
  const getOrder = () => {
    dispatch(adminActions.getAnOrder(id));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      remitted_amount &&
      remittance_point &&
      comment &&
      inputArray.length !== 0
    ) {
      dispatch(
        adminActions.remitOrder(
          { ...input, courier_order_id: remitId, delivered_items: inputArray },
          setvisible
        )
      );
    } else if (remitted_amount && comment && remittance_point) {
      dispatch(
        adminActions.remitOrder(
          { ...input, courier_order_id: remitId },
          setvisible
        )
      );
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn
          color="primary"
          onClick={() => {
            setvisible(true);
            getOrder();
          }}
        >
          Remit
        </MDBBtn>

        <MDBModal isOpen={visible} toggle={handleClose} size="lg">
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>Remit this order!</MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group" +
                  (submitted && !remitted_amount ? " is-invalid" : "")
                }
              >
                <label htmlFor="remitted_amount">Remitted Amount*</label>
                <input
                  type="number"
                  name="remitted_amount"
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !remitted_amount ? " is-invalid" : "")
                  }
                />
                {submitted && !remitted_amount && (
                  <div className="invalid-feedback">
                    You must input the remitted amount
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="remittance_point">Remittance point</label>

                <input
                  type="text"
                  name="remittance_point"
                  value={input.remittance_point}
                  onChange={handleChange}
                  className={"form-control form-control-rounded"}
                />
              </div>

              <div
                className={
                  "form-group" + (submitted && !comment ? " is-invalid" : "")
                }
              >
                <label htmlFor="comment">
                  Employee Comment* (Please add a comment)
                </label>

                <input
                  type="text"
                  name="comment"
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !comment ? " is-invalid" : "")
                  }
                />
                {submitted && !comment && (
                  <div className="invalid-feedback">You must add a comment</div>
                )}
              </div>

              <div
                className={
                  "form-group" +
                  (submitted && !delivered_items ? " is-invalid" : "")
                }
              >
                <label htmlFor="delivered_items">
                  Delivered Items - (Optional, Use this to add changes in the
                  initial order)
                </label>
                <OrderItems
                  data={data}
                  inputArray={inputArray}
                  setInputArray={setInputArray}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Remit Order"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
