import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../redux/actions";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function ViewUserOrders(userOrderList) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const secondPartyOrder = useSelector(
    (state) => state.users.secondPartyOrder.data
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <MDBContainer>
      <MDBBtn
        onClick={() => {
          setOpen(true);
          dispatch(
            userActions.getSecondPartyOrder(userOrderList.userOrderList.id)
          );
        }}
      >
        View Order
      </MDBBtn>
      <MDBModal isOpen={open} toggle={handleClose} size="lg">
        <MDBModalHeader toggle={handleClose}>View Order details</MDBModalHeader>
        <MDBModalBody>
          {secondPartyOrder && (
            <div className="text-left">
              <div className="mb-4 d-flex  flex-wrap justify-content-between">
                <h3 className="text-primary">Order #{secondPartyOrder?.id}</h3>
                {secondPartyOrder?.status === "assigned" && (
                  <div>
                    {" "}
                    <span className="badge badge-pill badge-outline-success p-2 m-1">
                      Assigned
                    </span>
                  </div>
                )}
                {secondPartyOrder?.status === "delivered" && (
                  <div>
                    {" "}
                    <span className="badge badge-pill badge-outline-success p-2 m-1 ">
                      Delivered
                    </span>{" "}
                  </div>
                )}
              </div>

              <div className="accordion mb-3" id="accordionContact">
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                      <a
                        className="text-default collapsed"
                        data-toggle="collapse"
                        href="#accordion-item-icon-right-1"
                        aria-expanded="false"
                      >
                        Customer Information
                      </a>
                    </h3>
                  </div>
                  <div
                    className="collapse"
                    id="accordion-item-icon-right-1"
                    data-parent="#accordionContact"
                    style={{}}
                  >
                    <div className="order_contact">
                      <div className="d-flex">
                        <div className="mr-4">
                          <div className="alternative-image">
                            {secondPartyOrder &&
                              secondPartyOrder?.customer_name &&
                              secondPartyOrder?.customer_name.charAt(0)}
                          </div>
                        </div>
                        <div className="px-2 w-100">
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Name:</h6>
                                <h5 className="text-capitalize">
                                  {secondPartyOrder?.customer_name}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Phone:</h6>
                                <h5>{secondPartyOrder?.customer_phone}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Email:</h6>
                                <h5>{secondPartyOrder?.customer_email}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Address:</h6>
                                <h5>{secondPartyOrder?.customer_address}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>City:</h6>
                                <h5>{secondPartyOrder?.customer_city}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Gender:</h6>
                                <h5>{secondPartyOrder?.customer_gender}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion mb-3" id="accordionStore">
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                      <a
                        className="text-default collapsed"
                        data-toggle="collapse"
                        href="#accordion-item-icon-right-2"
                      >
                        Store Information
                      </a>
                    </h3>
                  </div>
                  <div
                    className="collapse"
                    id="accordion-item-icon-right-2"
                    data-parent="#accordionStore"
                  >
                    <div className="order_contact">
                      <div className="d-flex">
                        <div className="mr-4">
                          <div className="alternative-image">
                            {secondPartyOrder &&
                              secondPartyOrder?.outlet_name &&
                              secondPartyOrder?.outlet_name.charAt(0)}
                          </div>
                        </div>
                        <div className="px-2 w-100">
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Store name:</h6>
                                <h5 className="text-capitalize">
                                  {secondPartyOrder?.outlet_name}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Store phone:</h6>
                                <h5>{secondPartyOrder?.outlet_phone}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              {/* <div className="customer-details">
                                                    <h6>Email:</h6>
                                                    <h5>{secondPartyOrder.customer_email}</h5>
                                                </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {secondPartyOrder &&
                (secondPartyOrder.status === "assigned" ||
                  secondPartyOrder.status === "delivered") && (
                  <div className="accordion mb-3" id="accordionRider">
                    <div className="card">
                      <div className="card-header header-elements-inline">
                        <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                          <a
                            className="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-3"
                            aria-expanded="false"
                          >
                            Riders Information
                          </a>
                        </h3>
                      </div>
                      <div
                        className="collapse"
                        id="accordion-item-icon-right-3"
                        data-parent="#accordionRider"
                        style={{}}
                      >
                        <div className="Rider_contact">
                          <div className="d-flex">
                            <div className="mr-4">
                              <div className="alternative-image">
                                {secondPartyOrder &&
                                  secondPartyOrder?.courier_orders?.courier
                                    ?.courier_name &&
                                  secondPartyOrder?.courier_orders?.courier?.courier_name.charAt(
                                    0
                                  )}
                              </div>
                            </div>
                            <div className="px-2 w-100">
                              <div className="row">
                                <div className="col-md-4 mb-2">
                                  <div className="rider-details">
                                    <h6>Name:</h6>
                                    <h5 className="text-capitalize">
                                      {
                                        secondPartyOrder?.courier_orders
                                          ?.courier?.courier_name
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <div className="rider-details">
                                    <h6>Phone:</h6>
                                    <h5>
                                      {
                                        secondPartyOrder?.courier_orders
                                          ?.courier?.courier_phone
                                      }
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              <div className="order_contact mb-4">
                <div className="pt-4 mb-4">
                  <div className="d-flex justify-content-between">
                    <h3 className="mb-2">Order Details</h3>
                  </div>
                </div>

                <div className="d-flex mb-4">
                  <div className="mr-4">
                    <div className="customer-details">
                      <h6>Payment Status:</h6>
                      <h5 className="text-capitalize">
                        {secondPartyOrder?.order_paid === true ? (
                          <span className="badge badge-pill badge-outline-success p-2">
                            Paid
                          </span>
                        ) : (
                          <span className="badge badge-pill badge-outline-danger p-2">
                            Not Paid
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="customer-details">
                      <h6>Payment Method:</h6>
                      <h5 className="text-capitalize">
                        {secondPartyOrder?.order_payment_method &&
                          secondPartyOrder?.order_payment_method.name}
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="float-right text-right mt-3">
                  <div className="d-flex">
                    <h5 className="mr-2">Order Amount:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {secondPartyOrder?.order_amount &&
                        numberWithCommas(secondPartyOrder.order_amount)}
                    </h5>
                  </div>
                  <div className="d-flex">
                    <h5 className="mr-2">Delivery fee:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {secondPartyOrder?.order_delivery_fee &&
                        numberWithCommas(secondPartyOrder.order_delivery_fee)}
                    </h5>
                  </div>

                  <div className="d-flex">
                    <h5 className="mr-2">Service fee:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {secondPartyOrder?.order_service_fee &&
                        numberWithCommas(secondPartyOrder?.order_service_fee)}
                    </h5>
                  </div>
                  <hr className="my-2" />
                  <h5>Total amount:</h5>
                  <h3>
                    ₦
                    {secondPartyOrder &&
                      secondPartyOrder?.order_amount &&
                      numberWithCommas(secondPartyOrder?.order_amount)}
                  </h3>
                </div>
                <div className="row mt-4">
                  <div className="col-md-7">
                    <div className="mb-3">
                      <label className="mb-0">Customer Comment:</label>
                      {secondPartyOrder?.customer_comment ? (
                        <h4 className="mb-4">
                          {secondPartyOrder?.customer_comment}
                        </h4>
                      ) : (
                        <h4 className="mb-4">No comments</h4>
                      )}
                    </div>
                    <div className="o-desc">
                      <label className="mb-0 ">Order Description:</label>
                      {secondPartyOrder?.order_description ? (
                        <h4 className="mb-4 ">
                          {secondPartyOrder.order_description}
                        </h4>
                      ) : (
                        <h4 className="mb-4">No description</h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
}
