import React, { useState, useEffect } from "react";
//import base_url from "../../../redux/services/base_url";
import { PImage } from "./../../images/images";
import { MDBBtn } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";

const FileUploader = ({ employeeId }) => {
  const employee_id = employeeId;
  const singleEmployee = useSelector((state) => state.admin.singleEmployee);

  const [image, setImage] = useState({ preview: PImage, base64: "" });

  const dispatch = useDispatch();
  const updating = useSelector((state) => state.users.updating);

  useEffect(() => {
    if (singleEmployee?.employee_picture) {
      let dataObject = {
        preview: singleEmployee?.employee_picture,
      };
      setImage({ ...dataObject });
    }

    // Update the document title using the browser API
  }, [singleEmployee]);

  const handleChange = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => {
        setImage({
          preview: reader.result,
          base64: reader.result,
        });
      };
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    dispatch(
      userActions.ImageUpload({
        id: employee_id,
        base64_photos: [image.base64],
      })
    );
  };

  return (
    <div>
      <label htmlFor="upload-button" className="cursor-pointer">
        {image.preview ? (
          <img
            src={image.preview}
            alt="profile"
            style={{
              width: 200,
              height: 200,
              borderRadius: "4%",
            }}
          />
        ) : (
          <>
            <h5 className="text-center">Upload your photo</h5>
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <br />
      <MDBBtn color="primary" onClick={handleUpload}>
        {updating === true ? <Beat loading={updating} /> : "Upload"}
      </MDBBtn>
    </div>
  );
};
export default FileUploader;
