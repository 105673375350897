import React from "react";

export default function SideNavOrder() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  let user_rank = (user) => {
    if (user.data.team_roles[teamId].roles.includes("company")) {
      return "company_owner";
    }

    if (user.data.team_roles[teamId].roles.includes("employee")) {
      return "company_employee";
    }

    if (user.data.team_roles[teamId].roles.includes("courier")) {
      return "company_courier";
    }
  };
  let isOwner = user.data.team_roles[teamId].roles.includes("owner");
  let isCourier = user.data.team_roles[teamId].roles.includes("courier");
  const currentPage = window.location.href.split("/")[3];
  let currentCourierPage = window.location.href.split("/")[4];

  return (
    <div className="side-content-wrap">
      <div
        className="sidebar-left rtl-ps-none"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul className="navigation-left">
          {!isCourier ? (
            <li className={currentPage === "" ? "nav-item active" : "nav-item"}>
              <a className="nav-item-hold" href="/">
                <i className="nav-icon i-Dashboard" />
                <span className="nav-text">Dashboard</span>
              </a>
            </li>
          ) : (
            <li
              className={
                currentCourierPage === "dashboard"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/courier/dashboard">
                <i className="nav-icon i-Dashboard" />
                <span className="nav-text">Dashboard</span>
              </a>
            </li>
          )}
          {!isCourier ? (
            <li className="nav-item active">
              <a className="nav-item-hold" href="/orders">
                <i className="nav-icon i-Library" />
                <span className="nav-text">Orders</span>
              </a>
            </li>
          ) : (
            <li className="nav-item">
              <a className="nav-item-hold" href="/courier/orders">
                <i className="nav-icon i-Library" />
                <span className="nav-text">Orders</span>
              </a>
            </li>
          )}
          {!isCourier && (
            <li
              className={
                currentPage === "orders-table" ? "nav-item active" : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/orders-table">
                <i className="nav-icon i-Library" />
                <span className="nav-text">Orders Review</span>
              </a>
            </li>
          )}
          {!isCourier && (
            <>
              <li className="nav-item">
                <a className="nav-item-hold" href="/riders">
                  <i className="nav-icon i-Motorcycle" />
                  <span className="nav-text">Riders</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-item-hold" href="/customers">
                  <i className="nav-icon i-Checked-User" />
                  <span className="nav-text">Customers</span>
                </a>
              </li>
            </>
          )}

          {user_rank(user) === "company_owner" && (
            <li className="nav-item">
              <a className="nav-item-hold" href="/employees">
                <i className="nav-icon i-Business-ManWoman" />
                <span className="nav-text">Employees</span>
              </a>
            </li>
          )}
          {isOwner && (
            <li className="nav-item">
              <a className="nav-item-hold" href="/companies">
                <i className="nav-icon i-Management" />
                <span className="nav-text">Companies</span>
              </a>
            </li>
          )}
          {!isCourier && (
            <li
              className={
                currentPage === "outlets" ? "nav-item active" : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/outlets/1">
                <i className="nav-icon i-Shop" />
                <span className="nav-text">Outlets</span>
              </a>
            </li>
          )}

          {!isCourier ? (
            <li
              className={
                currentPage === "remittance" ? "nav-item active" : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/remittance/1">
                <i className="nav-icon i-Financial" />
                <span className="nav-text">Remittance</span>
              </a>
            </li>
          ) : (
            <li
              className={
                currentCourierPage === "courier/remittance"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/courier/remittance/1">
                <i className="nav-icon i-Financial" />
                <span className="nav-text">Remittance</span>
              </a>
            </li>
          )}
          {user_rank(user) === "company_owner" && (
            <li
              className={
                currentPage === "verifyRemittance"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/verifyRemittance/1">
                <i className="nav-icon i-Financial" />
                <span className="nav-text">Verify Remittance</span>
              </a>
            </li>
          )}
          {user_rank(user) === "company_owner" && (
            <li className="nav-item">
              <a className="nav-item-hold" href="/ordersReport/1">
                <i className="nav-icon i-File-Clipboard-File--Text" />
                <span className="nav-text">Orders Report</span>
              </a>
            </li>
          )}
          {user_rank(user) === "company_owner" && (
            <li className="nav-item">
              <a className="nav-item-hold" href="/deliveryReport">
                <i className="nav-icon i-Receipt-3" />
                <span className="nav-text">Delivery Report</span>
              </a>
            </li>
          )}
          {user_rank(user) === "company_owner" && (
            <li
              className={
                currentPage === "settings" ? "nav-item active" : "nav-item"
              }
            >
              <a className="nav-item-hold" href="/settings">
                <i className="nav-icon i-Double-Tap" />
                <span className="nav-text">Settings</span>
              </a>
            </li>
          )}
        </ul>
      </div>

      <div className="sidebar-overlay" />
    </div>
  );
}
