import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function EditCustomer({ customerData }) {
  const customer_id = customerData.id;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      customer_email: customerData.customer_email,
      customer_name: customerData.customer_name,
      customer_phone: customerData.customer_phone,
      customer_gender: customerData.customer_gender,
      customer_address: customerData.customer_address,
      customer_city: customerData.customer_city,
      customer_state: customerData.customer_state,
      customer_country: customerData.customer_country,
    };

    setData({ ...dataObject });
  }, [customerData]);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [submitted, setSubmitted] = useState(false);
  let [customer_phone, setCustomerPhone] = useState("");
  const [data, setData] = useState({
    id: "",
    customer_email: "",
    customer_name: "",
    customer_phone: "",
    customer_gender: "",
    customer_address: "",
    customer_city: "",
    customer_state: "",
    customer_country: "",
  });
  const {
    customer_email,
    customer_name,
    customer_gender,
    customer_address,
    customer_city,
    customer_state,
    customer_country,
  } = data;
  const updating = useSelector((state) => state.admin.updating);
  customer_phone = customer_phone?.substring(1);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // dispatch action to create data
    dispatch(
      adminActions.editCustomer({ ...data, customer_phone, id: customer_id })
    );
    handleClose();
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn onClick={() => setOpen(true)} title="Edit Customer">
          <i className="nav-icon i-Pen-2" />
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>Edit Customer</MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_name ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_name">Customer name*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.customer_name}
                    name="customer_name"
                    onChange={handleChange}
                  />
                  {submitted && customer_name && (
                    <div className="invalid-feedback">
                      Customer name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_email ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_email">Customer email*</label>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue={data.customer_email}
                    name="customer_email"
                    onChange={handleChange}
                  />
                  {submitted && customer_email && (
                    <div className="invalid-feedback">
                      Customer email is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_phone">Customer phone*</label>
                  <PhoneInput
                    className="form-control"
                    value={data.customer_phone}
                    onChange={setCustomerPhone}
                  />
                  {submitted && customer_phone && (
                    <div className="invalid-feedback">
                      Customer phone is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_gender ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_gender">Customer gender*</label>
                  <select
                    className="form-control"
                    defaultValue={data.customer_gender}
                    name="customer_gender"
                    onChange={handleChange}
                  >
                    <option>Select option</option>
                    <option value="female">female</option>
                    <option value="male">male</option>
                  </select>
                  {submitted && customer_gender && (
                    <div className="invalid-feedback">
                      Customer gender is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_address">Customer address*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.customer_address}
                    name="customer_address"
                    onChange={handleChange}
                  />
                  {submitted && customer_address && (
                    <div className="invalid-feedback">
                      Customer address is required.
                    </div>
                  )}
                </div>

                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={data.customer_country}
                  state_name={data.customer_state}
                  city_name={data.customer_city}
                />
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_country">Customer country*</label>
                  <select
                    name="customer_country"
                    selected={data.customer_country}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.customer_country && (
                      <option value={data.customer_country}>
                        {data.customer_country}
                      </option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && customer_country && (
                    <div className="invalid-feedback">
                      Customer country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_state">Customer state</label>
                  <select
                    name="customer_state"
                    selected={data.customer_state}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.customer_state && (
                      <option value={data.customer_state}>
                        {data.customer_state}
                      </option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && customer_state && (
                    <div className="invalid-feedback">
                      Customer state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_city">Customer city*</label>
                  <select
                    name="customer_city"
                    selected={data.customer_city}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.customer_city && (
                      <option value={data.customer_city}>
                        {data.customer_city}
                      </option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && customer_city && (
                    <div className="invalid-feedback">
                      Customer city is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {updating === true ? (
                  <Beat loading={updating} />
                ) : (
                  "Edit Customer"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default EditCustomer;
