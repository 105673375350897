import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cleanInput } from "../../redux/helpers";

export const FilterRidersRemitted = ({
  date,
  remitted_start_date,
  setStartDate = () => {},
  remitted_end_date,
  setEndDate = () => {},
}) => {
  let params = useParams();

  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    remitted_start_date: remitted_start_date,
    remitted_end_date: remitted_end_date,
    // status: "completed",
  });
  useEffect(() => {
    setPayload({
      ...payload,
      remitted_start_date: remitted_start_date,
      remitted_end_date: remitted_end_date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remitted_start_date, remitted_end_date]);

  useEffect(() => {
    cleanInput(payload);
    dispatch(adminActions.filterCourierRemittedOrders(payload, params.page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <div>
      <div className='separator-breadcrumb border-top' />
      <div className='date-picker-wrapper'>
        <form>
          <div className='row' style={{ marginLeft: "10px" }}>
            <i
              className=' 
            i-Calendar-4'
              style={{ marginRight: "10px", marginTop: "10px" }}
            />
            <div className='allign col-md-6'>
              <div
                className={
                  "div-box" + (!remitted_start_date ? " is-invalid" : "")
                }>
                <DatePicker
                  className='form-control'
                  selected={remitted_start_date}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <span style={{ margin: "2px 2px" }}>-</span>
              <div
                className={
                  "div-box " + (!remitted_end_date ? " is-invalid" : "")
                }>
                <DatePicker
                  className='form-control'
                  selected={remitted_end_date}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
