import React from "react";
import { createBrowserHistory } from "history";

export default function (Route) {
  return class PrivateRoute extends React.Component {
    render() {
      if (localStorage.getItem("user") === null) {
        createBrowserHistory().push("/auth/login");
        createBrowserHistory().go();
      } else {
        return <Route {...this.props} />;
      }
    }
  };
}
