import React, { useEffect, useState } from "react";
import UserMainApp from "../../layouts/UserMainApp";
import { MDBDataTable } from "mdbreact";

import { useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import TableLoader from "../../includes/Loaders/TableLoader";

import moment from "moment";

import { FilterUserOrders } from "../../filter/filterUserOrders";
import ViewUserOrders from "./viewUserOrder";

export default function UserOrders() {
  //Filtering by Date
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  const [start_date, setStartDate] = useState(lastWeek);
  const [end_date, setEndDate] = useState(new Date());

  const [dateInput, setDateInput] = useState({});
  const userOrders = useSelector((state) => state.users.userOrders);

  const loading = useSelector((state) => state.users.userOrderLoading);

  useEffect(() => {
    setDateInput({ start_date: start_date, end_date: end_date });
  }, [start_date, end_date]);

  // Copies text to device clipboard

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let userOrderList = userOrders?.data;

  const [dataToMap, setDataToMap] = useState([]);

  useEffect(() => {
    setDataToMap(userOrderList && userOrderList.data);
  }, [userOrderList]);

  const filteruserOrders = () => {
    let completed = [];
    dataToMap.length > 0 &&
      dataToMap.forEach((item) => {
        if (item.status === "completed") {
          completed.push(item);
        }
      });

    return setDataToMap(completed);
  };

  function createTableData() {
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, i) => {
        let singleRowArray = {
          order_id: (
            <span>
              {order.id}
              <i
                className="i-Files ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyTextToClipboard(order.id);
                }}
              ></i>
            </span>
          ),
          created_at: moment(order?.created_at).format("l"),
          order_amount: <span>₦{numberWithCommas(order?.order_amount)}</span>,
          status:
            order.order_paid === "true" ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Paid
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Not paid
              </span>
            ),
          payment_method: (
            <span className="text-capitalize">
              {order?.order_payment_method?.name}
            </span>
          ),
          customer_name: (
            <span className="text-capitalize">
              {order.customer_name ? order.customer_name : null}
            </span>
          ),
          customer_phone: (
            <span className="text-capitalize">
              {order.customer_phone ? order.customer_phone : null}
            </span>
          ),
          actions: (
            <div className="">
              <ViewUserOrders userOrderList={order} />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "ORDER ID",
        field: "order_id",
        sort: "asc",
        width: 250,
      },
      {
        label: "CREATED AT",
        field: "created_at",
        sort: "asc",
        width: 200,
      },
      {
        label: "ORDER AMOUNT",
        field: "order_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "STATUS",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "PAYMENT METHOD",
        field: "payment_method",
        sort: "asc",
        width: 200,
      },
      {
        label: "DROP OFF NAME",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "DROP OFF PHONE",
        field: "customer_phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: dataSet,
  };
  return (
    <UserMainApp>
      {loading & dataSet ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          {/* ============ Body content start ============= */}
          <div className="main-content">
            <div className="breadcrumb">
              <h1>Orders</h1>
            </div>
            <FilterUserOrders
              date={dateInput}
              filter={filteruserOrders}
              start_date={start_date}
              setStartDate={setStartDate}
              end_date={end_date}
              setEndDate={setEndDate}
            />
            <h6>*Displays from the Most recent to the oldest</h6>

            {loading ? (
              <TableLoader />
            ) : (
              <MDBDataTable
                responsive
                // scrollY
                theadColor="MDBTableHeader"
                hover
                bordered
                maxHeight="400px"
                noBottomColumns
                data={data}
                paging={false}
                sortable={false}
                searching={false}
              />
            )}

            <Pagination data={userOrderList} route="userOrder" />
            {/* end of main-content */}
          </div>
        </div>
      )}
    </UserMainApp>
  );
}
