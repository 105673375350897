import React from 'react';
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../redux/actions';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

function DeleteRider({ riderData }) {
    const courier_id = riderData.id;
    const dispatch = useDispatch();

    let dataObject = {
        courier_email: riderData.courier_email,
        courier_name: riderData.courier_name,
        courier_phone: riderData.courier_phone,
        courier_address: riderData.courier_address,
        courier_city: riderData.courier_city,
        courier_state: riderData.courier_state,
        courier_country: riderData.courier_country,
    }


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const deleting = useSelector(state => state.users.deleting);


    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(userActions.deleteRider({id:courier_id },setOpen ));

    }


    return (
        <div>
            <MDBContainer>
                <MDBBtn onClick={() => setOpen(true)}  title="Delete Rider"><i className="nav-icon i-Close-Window" /></MDBBtn>
                <MDBModal isOpen={open} toggle={handleClose}>
                    <form name="form" onSubmit={handleSubmit}>
                        <MDBModalHeader toggle={handleClose}>Delete Rider</MDBModalHeader>
                        <MDBModalBody>
                            <div className="text-left row pt-4 px-3">
                                <h5>Would you like to delete the rider named: {dataObject.courier_name} </h5>,
                                <h5>Located at: {dataObject.courier_address}, {dataObject.courier_city}, {dataObject.courier_state}, {dataObject.courier_country}?</h5>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => setOpen(false)}>Close</MDBBtn>
                            <MDBBtn color="primary" type="submit">
                                {deleting === true ? <Beat loading={deleting} /> : "Delete rider"}
                            </MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
        </div>


    )
}


export default DeleteRider;