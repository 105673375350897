import React from "react";
import { useHistory } from "react-router";
import { history } from "../../redux/helpers";
import { PImage } from "../images/images";

export default function SwitchAccount() {
  let user = JSON.parse(localStorage.getItem("user"));

  const historyy = useHistory();
  const setTeam = (id) => {
    localStorage.setItem("teamId", JSON.stringify(id));
    if (id === "administrator") {
      history("/auth/userRedirect");
    }
    let isCourier = user.data.team_roles[id].roles.includes("courier");
    if (isCourier) {
      historyy.push("/courier/dashboard");
    } else {
      historyy.push("/");
    }
  };

  return (
    <div>
      <h2 className=" h-align">Choose an account</h2>
      <div className="card mx-auto " style={{ width: "25rem" }}>
        <ul className="list-group list-group-flush">
          {Object.keys(user.data.team_roles) &&
            Object.keys(user.data.team_roles).map((role) => (
              <li
                className="list-group-item cursor-pointer"
                key={role}
                onClick={() => setTeam(role)}
              >
                <div className="d-flex flex-row img-txt">
                  <img
                    className="p-img"
                    src={user?.data?.picture || PImage}
                    alt=""
                  />
                  <div className=" d-flex flex-column ml-2">
                    <p className="text-capitalize m-0"> {user.data.name} </p>
                    <p className="text-capitalize">
                      {" "}
                      {user.data.team_roles[role].display_name}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          <div className="card mx-auto " style={{ width: "25rem" }}>
            <li
              className="list-group-item cursor-pointer"
              onClick={() => historyy.push("/userDashboard")}
            >
              <div className="d-flex flex-row img-txt">
                <img
                  className="p-img"
                  src={user?.data?.picture || PImage}
                  alt=""
                />
                <div className=" d-flex flex-column ml-2">
                  <p className="text-capitalize m-0"> {user.data.name} </p>
                  <p className="text-capitalize">{"Default"}</p>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}
