import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import CanceledOrders from "../RidersOrders/CanceledOrders";
import RejectedOrders from "../RidersOrders/RejectedOrders";
import CompletedOrders from "../RidersOrders/CompletedOrders";
import PendingOrders from "../RidersOrders/PendingOrders";
import moment from "moment";

export default function OrdersList() {
  const singleCourierOrder = useSelector(
    (state) => state.admin.singleCourierOrder
  );
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  const pendingOrders = useSelector((state) => state.admin.myPending);
  const acceptedOrders = useSelector((state) => state.admin.myAccepted);
  const completedOrders = useSelector((state) => state.admin.myCompleted);
  const canceledOrders = useSelector((state) => state.admin.myCancelled);
  const rejectedOrders = useSelector((state) => state.admin.myRejected);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getMyPendingCourierOrders());
    dispatch(adminActions.getMyAcceptedCourierOrders());
    dispatch(adminActions.getMyCompletedCourierOrders());
    dispatch(adminActions.getMyCancelledCourierOrders());
    dispatch(adminActions.getMyRejectedCourierOrders());
  }, [dispatch, company_id]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      <div className="text-left order-wrapper">
        <div className="d-flex justify-content-between mb-3">
          <h3 className="mt-2">Order List</h3>
        </div>

        <ul className="nav nav-pills mb-2" id="myPillTab" role="tablist">
          {acceptedOrders && (
            <li className="nav-item">
              <a
                className="nav-link active"
                id="accepted-pill"
                data-toggle="pill"
                href="#acceptedPIll"
                role="tab"
                aria-controls="acceptedPIll"
                aria-selected="false"
              >
                <span>({acceptedOrders.length})</span> Accepted
              </a>
            </li>
          )}
          {pendingOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="pending-pill"
                data-toggle="pill"
                href="#pendingPIll"
                role="tab"
                aria-controls="pendingPIll"
                aria-selected="false"
              >
                <span>({pendingOrders.length})</span> Pending
              </a>
            </li>
          )}
          {completedOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="contact-icon-pill"
                data-toggle="pill"
                href="#contactPIll"
                role="tab"
                aria-controls="contactPIll"
                aria-selected="false"
              >
                <span>({completedOrders.length})</span> Completed
              </a>
            </li>
          )}
          {canceledOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="canceled-pill"
                data-toggle="pill"
                href="#canceledPILL"
                role="tab"
                aria-controls="canceledPILL"
                aria-selected="false"
              >
                <span>({canceledOrders.length})</span> Canceled
              </a>
            </li>
          )}
          {rejectedOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="rejected-pill"
                data-toggle="pill"
                href="#rejectedPIll"
                role="tab"
                aria-controls="rejectedPIll"
                aria-selected="false"
              >
                <span>({rejectedOrders.length})</span> Rejected
              </a>
            </li>
          )}
        </ul>

        <div className="" id="myPillTabContent">
          <div
            className="tab-pane fade show active"
            id="homePIll"
            role="tabpanel"
            aria-labelledby="home-icon-pill"
          >
            <div>
              <div
                className="scroll-list"
                data-perfect-scrollbar
                data-suppress-scroll-x="true"
              >
                {acceptedOrders && acceptedOrders.length === 0
                  ? "No content found"
                  : acceptedOrders?.map((courierOrder, i) => {
                      return (
                        <div
                          key={i}
                          className="card text-left mb-2"
                          onClick={() =>
                            dispatch(
                              adminActions.getACourierOrder(courierOrder?.id)
                            )
                          }
                        >
                          <div
                            className={
                              courierOrder?.id === singleCourierOrder.id
                                ? "active-order card-body d-flex justify-content-between "
                                : "card-body d-flex justify-content-between "
                            }
                          >
                            <div className="single-order">
                              <h5>
                                Order <span>#{courierOrder?.order?.id}</span>
                              </h5>
                              <p className="mb-0">
                                <i className="nav-icon i-Clock" />
                                <span>
                                  {moment(
                                    courierOrder?.order?.created_at
                                  ).format("lll")}
                                </span>
                              </p>
                            </div>
                            <div>
                              <h4 className="font-weight-bold">
                                ₦
                                {numberWithCommas(
                                  courierOrder?.order?.order_amount
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pendingPIll"
            role="tabpanel"
            aria-labelledby="pending-pill"
          >
            <PendingOrders />
          </div>
          <div
            className="tab-pane fade"
            id="contactPIll"
            role="tabpanel"
            aria-labelledby="contact-icon-pill"
          >
            <CompletedOrders />
          </div>
          <div
            className="tab-pane fade"
            id="canceledPILL"
            role="tabpanel"
            aria-labelledby="canceled-pill"
          >
            <CanceledOrders />
          </div>
          <div
            className="tab-pane fade"
            id="rejectedPIll"
            role="tabpanel"
            aria-labelledby="rejected-pill"
          >
            <RejectedOrders />
          </div>
        </div>
      </div>
    </div>
  );
}
