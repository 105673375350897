import React from "react";
import { useSelector } from "react-redux";
import AssignAnOrder from "./AssignAnOrder";
import EditOrder from "./EditOrder";
import { MDBDataTable } from "mdbreact";
import { DEFAULTORDER } from "../../images/images";
import CloseOrder from "./CloseOrder";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";

export default function ViewOrders() {
  const singleOrder = useSelector((state) => state.admin.singleOrder);
  const loading = useSelector((state) => state.admin.loading);

  function orderPrice(unit, price) {
    return unit * price;
  }

  const singleOrderItem = singleOrder.order_items;
  const labelText = singleOrder?.second_party_order
    ? "Order Value"
    : "Order Price";
  const UnitText = singleOrder?.second_party_order
    ? "Unit Value "
    : "Unit Price";
  // const courier_id = singleOrder?.courier_orders?.[0]?.courier_id;
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //to detect Url
  function detectURLs(message) {
    var urlRegex = /(((https?:\/\/)|(support\.)| (www\.))[^\s]+)/g;
    return message.match(urlRegex);
  }
  //Format a json string to defined sentence
  const formatJsonToString = (json) => {
    try {
      let parsedJson = JSON.parse(json);
      let string = "";

      parsedJson &&
        Object.keys(parsedJson).map((value) => {
          return (string = string + value + ": " + parsedJson[value] + "\n");
        });

      return string;
    } catch (error) {
      return json;
    }
  };

  function createTableData() {
    let dataToMap = singleOrderItem;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((item, i) => {
        let singleRowArray = {
          photo: (
            <img className="order_img" src={DEFAULTORDER} alt="order_image" />
          ),
          name: (
            <span>
              <span> {item.name} </span>
              <br></br>
              <span>
                {" "}
                {item.options &&
                  item.options.map((option, key) => {
                    return option.name ? (
                      <span key={key}>
                        {option.name} <br></br>{" "}
                      </span>
                    ) : null;
                  })}{" "}
              </span>
            </span>
          ),
          quantity: (
            <span>
              <span> {item.quantity} </span>
              <br></br>
              <span>
                {" "}
                {item.options &&
                  item.options.map((option, key) => {
                    return option.quantity ? (
                      <span key={key}>
                        {option.quantity} <br></br>{" "}
                      </span>
                    ) : null;
                  })}{" "}
              </span>
            </span>
          ),
          unit_price: (
            <span>
              <span>
                <span> ₦{numberWithCommas(item.price)} </span>
                <br></br>
                <span>
                  {" "}
                  {item.options &&
                    item.options.map((option, key) => {
                      return option.price ? (
                        <span key={key}>
                          ₦{numberWithCommas(option.price)} <br></br>{" "}
                        </span>
                      ) : null;
                    })}{" "}
                </span>
              </span>
            </span>
          ),

          order_price: (
            <span>
              <span>
                <span>
                  {" "}
                  ₦{numberWithCommas(
                    orderPrice(item.quantity, item.price)
                  )}{" "}
                </span>
                <br></br>
                <span>
                  {" "}
                  {item.options &&
                    item.options.map((option, key) => {
                      return option.price ? (
                        <span key={key}>
                          ₦
                          {numberWithCommas(
                            orderPrice(option.price, option.quantity)
                          )}{" "}
                          <br></br>{" "}
                        </span>
                      ) : null;
                    })}{" "}
                </span>
              </span>
            </span>
          ),
        };
        outputArray.push(singleRowArray);

        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Image",
        field: "photo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Quantity",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: `${UnitText}`,
        field: "unit_price",
        sort: "asc",
        width: 150,
      },
      {
        label: `${labelText}`,
        field: "order_price",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };

  return (
    <div className="main-content-padding">
      {loading ? (
        <PrimaryLoader />
      ) : (
        <>
          {singleOrder && (
            <div className="text-left">
              <div className="mb-4 d-flex  flex-wrap justify-content-between">
                <h3 className="text-primary">Order #{singleOrder.id}</h3>
                {singleOrder.status === "assigned" && (
                  <div>
                    {" "}
                    <span className="badge badge-pill badge-outline-success p-2 m-1">
                      Assigned
                    </span>
                  </div>
                )}
                {singleOrder.status === "unassigned" && (
                  <AssignAnOrder id={singleOrder.id} />
                )}
                {singleOrder.status === "delivered" && (
                  <div>
                    <span className="badge badge-pill badge-outline-success p-2 m-1">
                      Delivered
                    </span>
                  </div>
                )}
              </div>
              <div>
                <h5 className="text-capitalize">
                  {singleOrder.third_party_order === false &&
                    singleOrder.second_party_order === false && (
                      <span className="badge badge-pill badge-outline-success p-2 mb-2">
                        DashBoard Side Order
                      </span>
                    )}
                  {singleOrder.third_party_order === true && (
                    <span className="badge badge-pill badge-outline-success p-2 mb-2">
                      Food App Order
                    </span>
                  )}
                  {singleOrder.second_party_order === true && (
                    <span className="badge badge-pill badge-outline-success p-2 mb-2">
                      User Side Order
                    </span>
                  )}
                </h5>
              </div>

              <div className="accordion mb-3" id="accordionContact">
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                      <a
                        className="text-default collapsed"
                        data-toggle="collapse"
                        href="#accordion-item-icon-right-1"
                        aria-expanded="false"
                      >
                        Customer Information
                      </a>
                    </h3>
                  </div>
                  <div
                    className="collapse"
                    id="accordion-item-icon-right-1"
                    data-parent="#accordionContact"
                  >
                    <div className="order_contact">
                      <div className="d-flex">
                        <div className="mr-4">
                          <div className="alternative-image">
                            {singleOrder &&
                              singleOrder.customer_name &&
                              singleOrder.customer_name.charAt(0)}
                          </div>
                        </div>
                        <div className="px-2 w-100">
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Name:</h6>
                                <h5 className="text-capitalize">
                                  {singleOrder.customer_name}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Phone:</h6>
                                <h5>{singleOrder.customer_phone}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Email:</h6>
                                <h5>{singleOrder.customer_email}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Address:</h6>
                                <h5>{singleOrder.customer_address}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>City:</h6>
                                <h5>{singleOrder.customer_city}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Gender:</h6>
                                <h5>{singleOrder.customer_gender}</h5>
                              </div>
                            </div>
                          </div>

                          {/* For orders that have service areas */}
                          {singleOrder?.customer_service_area && (
                            <div>
                              <h5 className="font-weight-bold mb-4 mt-3">
                                Customer Service Area:
                              </h5>
                              <div className="row">
                                <div className="col-md-4 mb-2">
                                  <div className="customer-details">
                                    <h6>Location:</h6>
                                    <h5 className="text-capitalize">
                                      {singleOrder?.customer_service_area?.area}
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <div className="customer-details">
                                    <h6>State:</h6>
                                    <h5 className="text-capitalize">
                                      {
                                        singleOrder?.customer_service_area
                                          ?.state
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <div className="customer-details">
                                    <h6>City:</h6>
                                    <h5 className="text-capitalize">
                                      {singleOrder?.customer_service_area?.city}
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <div className="customer-details">
                                    <h6>Zip Code:</h6>
                                    <h5 className="text-capitalize">
                                      {singleOrder?.customer_service_area?.zip}
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <div className="customer-details">
                                    <h6>Longitude:</h6>
                                    <h5 className="text-capitalize">
                                      {
                                        singleOrder?.customer_service_area
                                          ?.longitude
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                  <div className="customer-details">
                                    <h6>Latitude:</h6>
                                    <h5 className="text-capitalize">
                                      {
                                        singleOrder?.customer_service_area
                                          ?.latitude
                                      }
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion mb-3" id="accordionStore">
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                      <a
                        className="text-default collapsed"
                        data-toggle="collapse"
                        href="#accordion-item-icon-right-2"
                      >
                        Store Information
                      </a>
                    </h3>
                  </div>
                  <div
                    className="collapse"
                    id="accordion-item-icon-right-2"
                    data-parent="#accordionStore"
                  >
                    <div className="order_contact">
                      <div className="d-flex">
                        <div className="mr-4">
                          <div className="alternative-image">
                            {singleOrder &&
                              singleOrder.outlet_name &&
                              singleOrder.outlet_name.charAt(0)}
                          </div>
                        </div>
                        <div className="px-2 w-100">
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Store name:</h6>
                                <h5 className="text-capitalize">
                                  {singleOrder.outlet_name}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Store phone:</h6>
                                <h5>{singleOrder.outlet_phone}</h5>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="customer-details">
                                <h6>Store Address:</h6>
                                <h5>{singleOrder.outlet_address}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {singleOrder &&
                (singleOrder.status === "assigned" ||
                  singleOrder.status === "delivered") && (
                  <div className="accordion mb-3" id="accordionRider">
                    <div className="card">
                      <div className="card-header header-elements-inline">
                        <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                          <a
                            className="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-3"
                            aria-expanded="false"
                          >
                            Riders Information
                          </a>
                        </h3>
                      </div>

                      <div
                        className="collapse"
                        id="accordion-item-icon-right-3"
                        data-parent="#accordionRider"
                      >
                        <div className="Rider_contact">
                          <div className="d-flex">
                            <div className="mr-4">
                              <div className="alternative-image">
                                {singleOrder?.courier_orders?.[0]?.courier?.courier_name.charAt(
                                  0
                                )}
                              </div>
                            </div>
                            <div className="px-2 w-100">
                              <div className="row">
                                <div className="col-md-4 mb-2 mt-3">
                                  <div className="rider-details">
                                    <h6>Name:</h6>
                                    <h5 className="text-capitalize">
                                      {
                                        singleOrder?.courier_orders?.[0]
                                          ?.courier?.courier_name
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-2 mt-3">
                                  <div className="rider-details">
                                    <h6>Phone:</h6>
                                    <h5>
                                      {
                                        singleOrder?.courier_orders?.[0]
                                          ?.courier?.courier_phone
                                      }
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              <div className="order_contact mb-4">
                <div className="pt-3 mb-4">
                  <div className="d-flex justify-content-between">
                    <h3 className="mb-2">Order Details</h3>
                    <div>
                      <div className="d-flex">
                        {singleOrder && singleOrder.status === "unassigned" && (
                          <EditOrder
                            order_data={singleOrder}
                            order_status="unassigned"
                          />
                        )}
                        {singleOrder && singleOrder.status === "assigned" && (
                          <EditOrder
                            order_data={singleOrder}
                            order_status="assigned"
                          />
                        )}
                        {singleOrder && singleOrder.status === "unassigned" && (
                          <CloseOrder
                            order_data={singleOrder}
                            order_status="unassigned"
                          />
                        )}
                        {singleOrder && singleOrder.status === "assigned" && (
                          <CloseOrder
                            order_data={singleOrder}
                            order_status="assigned"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div className="mr-4">
                    <div className="customer-details">
                      <h6>Payment Status:</h6>
                      <h5 className="text-capitalize">
                        {singleOrder.order_paid === true ? (
                          <span className="badge badge-pill badge-outline-success p-2">
                            Paid
                          </span>
                        ) : (
                          <span className="badge badge-pill badge-outline-danger p-2">
                            Not Paid
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="customer-details">
                      <h6>Payment Method:</h6>
                      <h5 className="text-capitalize">
                        {singleOrder.order_payment_method &&
                          singleOrder.order_payment_method.name}
                      </h5>
                    </div>
                  </div>
                  <div>
                    <div className="customer-details">
                      <h6>Payment Details:</h6>
                      {singleOrder.order_payment_method &&
                      singleOrder.order_payment_method.details ? (
                        <h5>
                          {singleOrder.order_payment_method &&
                            formatJsonToString(
                              singleOrder.order_payment_method.details
                                .split(",")
                                .map(function (item, idx) {
                                  return detectURLs(item) ? (
                                    <a
                                      href={item.trim()}
                                      key={idx}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item}
                                      <br />
                                    </a>
                                  ) : (
                                    <span key={idx}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })
                            )}
                        </h5>
                      ) : (
                        <h5>No Details</h5>
                      )}
                    </div>
                  </div>
                </div>

                {singleOrder && (
                  <MDBDataTable
                    responsive
                    scrollY
                    entriesOptions={[5, 10, 15]}
                    striped
                    borderless
                    hover
                    noBottomColumns
                    displayEntries={false}
                    searching={false}
                    paging={false}
                    data={data}
                  />
                )}

                <div className="float-right text-right mt-4">
                  <div className="d-flex">
                    <h5 className="mr-2">Delivery fee:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {singleOrder?.order_delivery_fee &&
                        numberWithCommas(singleOrder.order_delivery_fee)}
                    </h5>
                  </div>
                  <div className="d-flex mt-1">
                    <h5 className="mr-2">Discount:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {singleOrder?.order_discount &&
                        numberWithCommas(singleOrder.order_discount)}
                    </h5>
                  </div>
                  <div className="d-flex mt-1">
                    <h5 className="mr-2">Insurance fee:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {singleOrder?.order_insurance_fee &&
                        numberWithCommas(singleOrder.order_insurance_fee)}
                    </h5>
                  </div>
                  <div className="d-flex mt-1">
                    <h5 className="mr-2">Service fee:</h5>
                    <h5 className="font-weight-bold">
                      ₦
                      {singleOrder?.order_service_fee &&
                        numberWithCommas(singleOrder.order_service_fee)}
                    </h5>
                  </div>
                  {singleOrder?.order_coupons?.length > 0 && (
                    <div className="d-flex mt-1">
                      <h5 className="mr-2">Coupon:</h5>
                      <h5 className="font-weight-bold">
                        ₦
                        {singleOrder?.order_coupons?.[0]?.amount &&
                          numberWithCommas(
                            singleOrder?.order_coupons?.[0]?.amount
                          )}
                      </h5>
                    </div>
                  )}
                  <hr className="my-2" />
                  <h5>Total amount:</h5>
                  {singleOrder && singleOrder.second_party_order ? (
                    <h3>
                      ₦
                      {singleOrder &&
                        singleOrder.order_amount &&
                        numberWithCommas(singleOrder.order_amount)}
                      <br></br>
                      <small className="is-invalid small">
                        Order value not summed
                      </small>
                    </h3>
                  ) : (
                    <h3>
                      ₦
                      {singleOrder &&
                        singleOrder.order_amount &&
                        numberWithCommas(singleOrder.order_amount)}
                    </h3>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-md-7">
                    <div className="mb-3">
                      <label className="mb-0">Order Category:</label>
                      {singleOrder?.order_items &&
                        singleOrder?.order_items.map((item, key) => {
                          return item.category ? (
                            <div key={key}>
                              <h5 className="mb-4"> {item.category}</h5>
                            </div>
                          ) : (
                            <h5 key={key} className="mb-4">
                              Not Available
                            </h5>
                          );
                        })}
                    </div>
                    <div className="mb-3">
                      <label className="mb-0">Customer Comment:</label>
                      {singleOrder.customer_comment ? (
                        <h5 className="mb-4">{singleOrder.customer_comment}</h5>
                      ) : (
                        <h5 className="mb-4">No comments</h5>
                      )}
                    </div>
                    <div className="o-desc">
                      <label className="mb-0 ">Order Description:</label>
                      {singleOrder.order_description ? (
                        <h5 className="mb-4 ">
                          {singleOrder.order_description}
                        </h5>
                      ) : (
                        <h5 className="mb-4">No description</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
