import React from "react";
import MainApp from "../layouts/MainApp";

//import { PImage } from '../images/images';
import ChangePhone from "./profile/changePhone";
import ChangePassword from "../auth/ChangePassword";
import { userActions } from "../../redux/actions";
import FileUploader from "./profile/ImageUpload";
import { useSelector, useDispatch } from "react-redux";

export default function Profile() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  let employee_id = user.data.groups[teamId].employee_id;
  const company_id = user.data.groups[teamId].company_id;
  const dispatch = useDispatch();

  let singleEmployee = useSelector((state) => state.admin.singleEmployee);
  React.useEffect(() => {
    dispatch(userActions.getMyReferralCode());
  }, [dispatch]);
  const myReferral = useSelector((state) => state.users.myReferral);
  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  let user_rank = (user) => {
    if (user?.data?.team_roles[teamId]?.roles?.includes("company")) {
      return "company_owner";
    }

    if (user?.data?.team_roles[teamId]?.roles?.includes("employee")) {
      return "company_employee";
    }

    if (user?.data?.team_roles[teamId]?.roles?.includes("courier")) {
      return "company_courier";
    }
  };

  return (
    <MainApp>
      <div className="main-content-padding">
        <div className="main-content">
          {/* ============ Body content start ============= */}
          <div className="breadcrumb">
            <h1 className="mr-2">My Profile</h1>
          </div>
          <div className="separator-breadcrumb border-top" />

          <div className="row">
            <div className="col-md-6">
              <FileUploader employeeId={employee_id} company={company_id} />
              <div className="mb-4 pb-4 mt-4">
                <h4 className="text-primary mb-2">
                  {" "}
                  {singleEmployee.employee_name}
                </h4>
                <p className="text-primary mb-1"> {user_rank(user)}</p>
              </div>

              <div className="row">
                <div className="col-md-6 col-6">
                  <div className="mb-4">
                    <p className="text-primary mb-1">
                      <i className="i-Envelope text-16 mr-1" /> Email
                    </p>
                    <span>{user.data.email}</span>
                  </div>
                </div>
                <div className="col-md-6  col-6">
                  <div className="mb-4">
                    <p className="text-primary mb-1">
                      <i className="i-Telephone text-16 mr-1" /> Telephone
                    </p>
                    <span className="mb-1">
                      {singleEmployee.employee_phone}
                    </span>
                    <br />
                  </div>
                </div>

                <div className="col-md-6  col-6">
                  <div className="mb-4">
                    <p className="text-primary mb-1">
                      <i className="i-Paper-Plane text-16 mr-1" /> Referral Code
                    </p>
                    <span className="mb-1">
                      {myReferral?.data?.referral_code}
                      <i
                        className="i-Files ml-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          copyTextToClipboard(myReferral?.data?.referral_code);
                        }}
                      />
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <ChangePassword />
            {user_rank(user) === "company_owner" && (
              <ChangePhone employeeId={employee_id} company={company_id} />
            )}
          </div>
        </div>
      </div>
    </MainApp>
  );
}
