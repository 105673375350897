import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RidersMap from "./RidersMap";
import { adminActions } from "../../../redux/actions";
import AllRidersMap from "./AllRidersMap";
import { fireApp } from "../../firebase/firebaseInit";
import { getDatabase, ref, onValue } from "firebase/database";

export default function RiderMapWrapper({ view, rider_id }) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  const dispatch = useDispatch();
  const [locationList, setLocationList] = useState({});
  const database = getDatabase(fireApp);

  useEffect(() => {
    if (view === "singleRider") {
      const locationRef = ref(database, company_id + "/" + rider_id);
      onValue(locationRef, (snapshot) => {
        const location = snapshot.val();
        setLocationList(location);

        dispatch(adminActions.getLocationList({ location }));
      });
    } else {
      const locationRef = ref(database, company_id + "/");
      onValue(locationRef, (snapshot) => {
        const location = snapshot.val();
        setLocationList(location);

        dispatch(adminActions.getLocationList({ location }));
      });
    }
  }, [dispatch, database, company_id, rider_id, view]);

  return (
    <div>
      {view === "singleRider" && <RidersMap locationList={locationList} />}
      {view === "allRider" && <AllRidersMap locationList={locationList} />}
    </div>
  );
}
