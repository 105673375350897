import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../redux/reducers/spinner";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function RidersRemitAction({ courier_order_id }) {
  const dispatch = useDispatch();

  let remitId = courier_order_id;

  const [visible, setvisible] = useState(false);
  const handleClose = () => {
    setvisible(false);
  };
  const creating = useSelector((state) => state.admin.creating);

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    courier_order_id: "",
    comment: "",
  });

  const { comment } = input;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    dispatch(
      adminActions.commentToRemit(
        {
          ...input,
          courier_order_id: remitId,
          comment: comment ? comment : "No comments",
        },
        setvisible
      )
    );
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => setvisible(true)}>
          Remit
        </MDBBtn>

        <MDBModal isOpen={visible} toggle={handleClose} size='lg'>
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>Remit this order!</MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group" + (submitted && !comment ? " is-invalid" : "")
                }>
                <label htmlFor='comment'>Comment* (Please add a comment)</label>

                <input
                  type='text'
                  name='comment'
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !comment ? " is-invalid" : "")
                  }
                />
                {submitted && !comment && (
                  <div className='invalid-feedback'>You must add a comment</div>
                )}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit'>
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Remit Order"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
