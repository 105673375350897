import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminActions, userActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cleanInput } from "../../redux/helpers";

export const FilterAll = ({
  date,
  start_date,
  setStartDate = () => {},
  end_date,
  setEndDate = () => {},
}) => {
  let params = useParams();

  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  const dispatch = useDispatch();
  // const remittedOrders = useSelector((state) => state.admin.remittedOrders);

  const [payload, setPayload] = useState({
    company_id: company_id,
    start_date: start_date,
    end_date: end_date,
    employee_id: "",
    courier_id: "",
    canceled_by_employee: false,
    canceled_by_courier: false,
    // remitted: 1,
  });
  useEffect(() => {
    setPayload({
      ...payload,
      start_date: start_date,
      end_date: end_date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date]);

  //filtering the Staff by Date
  useEffect(() => {
    dispatch(userActions.getRider(company_id));
    dispatch(adminActions.getEmployees(company_id));
  }, [dispatch, company_id]);

  const allRiders = useSelector((state) => state.users.items);
  let myRiders = allRiders.data && allRiders.data.data;
  const allEmployees = useSelector((state) => state.admin.allEmployees);
  let myEmployees = allEmployees.data && allEmployees.data.data;

  const handlefilterValue = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setPayload((payload) => ({ ...payload, [name]: value }));
  };

  //filtering remittances by date
  const [filterValue, setFilterValue] = useState("");
  const handleClick = (filterValue) => {
    setFilterValue(filterValue);

    switch (filterValue) {
      case "canceled_by_courier":
        setPayload({
          ...payload,
          filterType: "",
          canceled_by_courier: true,
          canceled_by_employee: "",
        });
        break;

      case "canceled_by_employee":
        setPayload({
          ...payload,
          filterType: "",
          canceled_by_employee: true,
          canceled_by_courier: "",
        });
        break;

      case "completed":
        setPayload({
          ...payload,
          filterType: "completed",
          canceled_by_employee: "",
          canceled_by_courier: "",
        });
        break;

      default:
        setPayload({
          ...payload,
          filterType: "",
          canceled_by_employee: "",
          canceled_by_courier: "",
        });
        break;
    }
  };
  // function all(){
  //   dispatch(adminActions.filterRemittedOrders(payload, params.page));
  // }

  useEffect(() => {
    cleanInput(payload);
    dispatch(adminActions.filterRemittedOrders(payload, params.page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, params.page, filterValue]);

  useEffect(() => {
    cleanInput(payload);
    dispatch(adminActions.filterRemittedOrdersNoPage(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, filterValue]);

  return (
    <div>
      <div className="d-flex fil-all">
        <button
          type="Link"
          className="btn btn-link"
          style={{ color: filterValue === "All" ? "#D14522" : "black" }}
          onClick={() => handleClick("All")}
        >
          All
        </button>
        <button
          type="Link"
          className="btn btn-link"
          style={{ color: filterValue === "completed" ? "#D14522" : "black" }}
          onClick={() => {
            handleClick("completed");
          }}
        >
          Completed
        </button>
        <button
          type="Link"
          className="btn btn-link"
          style={{
            color: filterValue === "canceled_by_employee" ? "#D14522" : "black",
          }}
          onClick={() => handleClick("canceled_by_employee")}
        >
          Cancelled by Employee
        </button>
        <button
          type="Link"
          className="btn btn-link"
          style={{
            color: filterValue === "canceled_by_courier" ? "#D14522" : "black",
          }}
          onClick={() => handleClick("canceled_by_courier")}
        >
          Cancelled by Courier
        </button>
      </div>
      <div className="separator-breadcrumb border-top" />
      <div className="date-picker-wrapper">
        <form>
          <div
            className="row d-flex justify-content-between"
            style={{ marginLeft: "10px" }}
          >
            <div className="allign col-md-6">
              <i
                className=" 
            i-Calendar-4"
                style={{ marginRight: "10px", marginTop: "10px" }}
              />
              <div className={"div-box" + (!start_date ? " is-invalid" : "")}>
                <DatePicker
                  className="form-control"
                  selected={start_date}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <span style={{ margin: "2px 2px" }}>-</span>
              <div className={"div-box " + (!end_date ? " is-invalid" : "")}>
                <DatePicker
                  className="form-control"
                  selected={end_date}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
            <div className=" allign ml-2 emp-f">
              <select
                className="dropdown-btn  text-capitalize"
                name="employee_id"
                onChange={handlefilterValue}
              >
                <option value="">Employee: All</option>
                {myEmployees &&
                  myEmployees.map((employee, i) => (
                    <option value={employee.id} key={employee.id}>
                      {employee.employee_name}
                    </option>
                  ))}
              </select>
              <select
                className="dropdown-btn2 text-capitalize"
                name="courier_id"
                onChange={handlefilterValue}
              >
                <option>Rider: All</option>
                {myRiders &&
                  myRiders.map((rider, i) => (
                    <option value={rider.id} key={rider.id}>
                      {rider.courier_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
