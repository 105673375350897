import React from "react";
import UserHeader from "../navigation/UserHeader";
import SideNavUser from "../navigation/SideNavUser";

export default function UserMainApp(props) {
  const viewClone = localStorage.originalUserDetail;

  return (
    <div className="app-admin-wrap layout-sidebar-large">
      <UserHeader />
      <SideNavUser />

      <div className="main-content-wrap sidenav-open d-flex flex-column">
        {viewClone && (
          <div>
            <h3>You are currently viewing the clone of some company</h3>
          </div>
        )}

        {props.children}
      </div>
    </div>
  );
}
