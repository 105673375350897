import { adminService } from "../services";
import { alertActions } from "../actions";
import { userActions } from "../actions";
// import { history } from '../helpers';

export const adminActions = {
  getAllOrders,
  getAllOrdersByDate,
  getAllOrdersByDateNoPagination,
  searchOrders,
  getCourierOrders,
  getACourierOrder,
  getUnassignedOrders,
  getUnassignedOrdersLoadMore,
  getPaidUnassignedOrders,
  getPaidUnassignedOrdersLoadMore,
  getUnpaidUnassignedOrders,
  getUnpaidUnassignedOrdersLoadMore,
  getAssignedOrders,
  getAssignedOrdersLoadMore,
  getCompletedOrders,
  getCompletedOrderList,
  getCompletedOrderListLoadMore,
  getClosedOrders,
  getClosedOrdersLoadMore,
  markAsClosed,
  markAsdelivered,
  createOrder,
  CloseOrder,
  createRecordOrder,
  completeOrder,
  cancelOrder,
  editOrder,
  assignOrder,
  unAssignOrder,
  acceptOrder,
  rejectOrder,
  getSingleOrder,
  getSingleCourierOrder,
  getAnOrder,
  remitOrder,
  getRemittedOrders,
  filterRemittedOrders,
  filterRemittedOrdersNoPage,
  filterDeliveryReport,
  filterCourierRemittedOrders,
  verifyRemittance,
  getAllCompanies,
  approveCompany,
  getMyCourier,
  getEmployees,
  createEmployees,
  editEmployee,
  deleteEmployee,
  approveEmployee,
  setRiderActive,
  setRiderInactive,
  setEmployeeActive,
  setEmployeeInactive,
  getSingleEmployee,
  getSingleRider,
  getCustomers,
  commentToRemit,
  createCustomers,
  searchCompany,
  searchEmployee,
  searchAllCustomers,
  searchAllOutlet,
  editCustomer,
  deleteCustomer,
  getRoles,
  assignRole,
  getAllAbility,
  assignAbility,
  getCompanyStats,
  getCourierStats,
  getLocationList,
  getEmployeeStats,
  getGeneralStats,
  getCountries,
  getAllStates,
  getStates,
  getCities,
  getAllOutlet,
  createOutlet,
  searchOutlet,
  editOutlet,
  deleteOutlet,
  getMyCompany,
  getMyPendingCourierOrders,
  getMyAcceptedCourierOrders,
  getMyCompletedCourierOrders,
  getMyCancelledCourierOrders,
  getMyRejectedCourierOrders,
  getMyPendingRemittance,
  getMyCompletedRemittance,
  createThirdPartyToken,
  getThirdPartyToken,
};

function getAllOrders() {
  return (dispatch) => {
    dispatch(request());
    adminService.getAllOrders().then(
      (allOrders) => {
        dispatch(success(allOrders.data.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETALLORDERS_REQUEST" };
  }
  function success(allOrders) {
    return { type: "GETALLORDERS_SUCCESS", allOrders };
  }
  function failure(error) {
    return { type: "GETALLORDERS_FAILURE", error };
  }
}

function getAllOrdersByDate(company_id, start_date, end_date, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService
      .getAllOrdersByDate(company_id, start_date, end_date, page)
      .then(
        (allOrdersByDate) => {
          dispatch(request(false));
          dispatch(success(allOrdersByDate));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(request(false));
        }
      );
  };

  function request(payload) {
    return { type: "GETALLORDERSBYDATE_REQUEST", payload };
  }
  function success(allOrdersByDate) {
    return { type: "GETALLORDERSBYDATE_SUCCESS", allOrdersByDate };
  }
  function failure(error) {
    return { type: "GETALLORDERSBYDATE_FAILURE", error };
  }
}
function getAllOrdersByDateNoPagination(company_id, start_date, end_date) {
  return (dispatch) => {
    dispatch(request(true));
    adminService
      .getAllOrdersByDateNoPagination(company_id, start_date, end_date)
      .then(
        (allOrdersByDatePagination) => {
          dispatch(request(false));
          dispatch(success(allOrdersByDatePagination));
          return true;
        },

        (error) => {
          dispatch(failure(error.toString()));
          dispatch(request(false));
        }
      );
  };

  function request(payload) {
    return { type: "GETALLORDERSBYDATENOPAGINATION_REQUEST", payload };
  }
  function success(allOrdersByDatePagination) {
    return {
      type: "GETALLORDERSBYDATENOPAGINATION_SUCCESS",
      allOrdersByDatePagination,
    };
  }
  function failure(error) {
    return { type: "GETALLORDERSBYDATENOPAGINATION_FAILURE", error };
  }
}



function searchOrders(payload) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.searchOrders(payload).then(
      (searchOrders) => {
        dispatch(request(false));
        dispatch(success(searchOrders));
        return true;
      },

      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "SEARCHORDERS_REQUEST", payload };
  }
  function success(searchOrders) {
    return { type: "SEARCHORDERS_SUCCESS", searchOrders};
  }
  function failure(error) {
    return { type: "SEARCHORDERS_FAILURE", error };
  }
}

// function commentOnRemittance(id, comment) {
//   return (dispatch) => {
//     dispatch(request());
//     adminService.commentOnRemittance(id, comment).then(
//       (remittedOrders) => {
//         dispatch(success(remittedOrders));
//       },
//       (error) => dispatch(failure(error.toString()))
//     );
//   };

//   function request() {
//     return { type: "GETFILTEREDORDERS_REQUEST" };
//   }
//   function success(allOrdersByDate) {
//     return { type: "GETFILTEREDORDERS_SUCCESS", allOrdersByDate };
//   }
//   function failure(error) {
//     return { type: "GETFILTEREDORDERS_FAILURE", error };
//   }
// }

function getCourierOrders(ordersData, page) {
  return (dispatch) => {
    dispatch(request());
    adminService.getCourierOrders(ordersData, page).then(
      (allOrdersByDate) => {
        dispatch(success(allOrdersByDate));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETALLORDERSBYDATE_REQUEST" };
  }
  function success(allOrdersByDate) {
    return { type: "GETALLORDERSBYDATE_SUCCESS", allOrdersByDate };
  }
  function failure(error) {
    return { type: "GETALLORDERSBYDATE_FAILURE", error };
  }
}

function getUnassignedOrders(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getUnassignedOrders(company_id, page).then(
      (unassigned) => {
        dispatch(request(false));
        dispatch(success(unassigned.data));
        let data_result = unassigned.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUNASSIGNEDORDERS_REQUEST", payload };
  }
  function success(unassigned) {
    return { type: "GETUNASSIGNEDORDERS_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "GETUNASSIGNEDORDERS_FAILURE", error };
  }
}

function getUnassignedOrdersLoadMore(company_id, page, setPage) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getUnassignedOrders(company_id, page).then(
      (unassigned) => {
        setPage(page);
        dispatch(request(false));
        dispatch(success(unassigned.data.data));
        let data_result = unassigned.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUNASSIGNEDORDERS_REQUEST", payload };
  }
  function success(unassigned) {
    return { type: "GETUNASSIGNEDORDERSLOADMORE_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "GETUNASSIGNEDORDERS_FAILURE", error };
  }
}

function getAssignedOrders(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getAssignedOrders(company_id, page).then(
      (assigned) => {
        dispatch(request(false));
        dispatch(success(assigned.data));
      },

      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETASSIGNEDORDERS_REQUEST", payload };
  }
  function success(assigned) {
    return { type: "GETASSIGNEDORDERS_SUCCESS", assigned };
  }
  function failure(error) {
    return { type: "GETASSIGNEDORDERS_FAILURE", error };
  }
}

function getAssignedOrdersLoadMore(company_id, setPage, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getAssignedOrders(company_id, page).then(
      (assigned) => {
        setPage(page);
        dispatch(request(false));
        dispatch(success(assigned.data.data));
      },

      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETASSIGNEDORDERS_REQUEST", payload };
  }
  function success(assigned) {
    return { type: "GETASSIGNEDORDERSLOADMORE_SUCCESS", assigned };
  }
  function failure(error) {
    return { type: "GETASSIGNEDORDERS_FAILURE", error };
  }
}

function getPaidUnassignedOrders(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getPaidUnassignedOrders(company_id, page).then(
      (unassigned) => {
        dispatch(request(false));
        dispatch(success(unassigned.data));
        let data_result = unassigned.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUNASSIGNED_PAID_ORDERS_REQUEST", payload };
  }
  function success(unassigned) {
    return { type: "GETUNASSIGNED_PAID_ORDERS_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "GETUNASSIGNED_PAID_ORDERS_FAILURE", error };
  }
}

function getPaidUnassignedOrdersLoadMore(company_id, page, setPage) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getPaidUnassignedOrders(company_id, page).then(
      (unassigned) => {
        setPage(page);
        dispatch(request(false));
        dispatch(success(unassigned.data.data));
        let data_result = unassigned.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUNASSIGNED_PAID_ORDERS_REQUEST", payload };
  }
  function success(unassigned) {
    return { type: "GETUNASSIGNED_PAID_LOADMORE_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "GETUNASSIGNED_PAID_ORDERS_FAILURE", error };
  }
}

function getUnpaidUnassignedOrders(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getUnpaidUnassignedOrders(company_id, page).then(
      (unassigned) => {
        dispatch(request(false));
        dispatch(success(unassigned.data));
        let data_result = unassigned.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUNASSIGNED_UNPAID_ORDERS_REQUEST", payload };
  }
  function success(unassigned) {
    return { type: "GETUNASSIGNED_UNPAID_ORDERS_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "GETUNASSIGNED_UNPAID_ORDERS_FAILURE", error };
  }
}

function getUnpaidUnassignedOrdersLoadMore(company_id, page, setPage) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getUnpaidUnassignedOrders(company_id, page).then(
      (unassigned) => {
        setPage(page);
        dispatch(request(false));
        dispatch(success(unassigned.data.data));
        let data_result = unassigned.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUNASSIGNED_UNPAID_ORDERS_REQUEST", payload };
  }
  function success(unassigned) {
    return { type: "GETUNASSIGNED_UNPAID_LOADMORE_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "GETUNASSIGNEDORDERS_FAILURE", error };
  }
}

function getCompletedOrders(company_id, start_date, end_date) {
  return (dispatch) => {
    dispatch(request());
    adminService.getCompletedOrders(company_id, start_date, end_date).then(
      (completed) => {
        dispatch(success(completed.data.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETCOMPLETEDORDERS_REQUEST" };
  }
  function success(completed) {
    return { type: "GETCOMPLETEDORDERS_SUCCESS", completed };
  }
  function failure(error) {
    return { type: "GETCOMPLETEDORDERS_FAILURE", error };
  }
}

function getCompletedOrderList(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getCompletedOrderList(company_id, page).then(
      (completed) => {
        dispatch(request(false));
        dispatch(success(completed.data));

        let data_result = completed.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETCOMPLETEDORDERS_REQUEST", payload };
  }
  function success(completed) {
    return { type: "GETCOMPLETEDORDERS_SUCCESS", completed };
  }
  function failure(error) {
    return { type: "GETCOMPLETEDORDERS_FAILURE", error };
  }
}

function getCompletedOrderListLoadMore(company_id, page, setPage) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getCompletedOrderList(company_id, page).then(
      (completed) => {
        dispatch(request(false));
        setPage(page);
        dispatch(success(completed.data.data));

        let data_result = completed.data.data;
        if (data_result.length > 0) {
          dispatch(getAnOrder(data_result[0].id));
        }
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETCOMPLETEDORDERS_REQUEST", payload };
  }
  function success(completed) {
    return { type: "GETCOMPLETEDORDERSLOADMORE_SUCCESS", completed };
  }
  function failure(error) {
    return { type: "GETCOMPLETEDORDERS_FAILURE", error };
  }
}
function getMyCompletedCourierOrders() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyCompletedCourierOrders().then(
      (myCompleted) => {
        dispatch(success(myCompleted.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_COMPLETED_COURIER_ORDERS_REQUEST" };
  }
  function success(myCompleted) {
    return { type: "GET_MY_COMPLETED_COURIER_ORDERS_SUCCESS", myCompleted };
  }
  function failure(error) {
    return { type: "GET_MY_COMPLETED_COURIER_ORDERS_FAILURE", error };
  }
}

function getMyPendingRemittance() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyPendingRemittance().then(
      (pendingRemittance) => {
        dispatch(success(pendingRemittance.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_PENDING_REMITTANCE_REQUEST" };
  }
  function success(pendingRemittance) {
    return { type: "GET_MY_PENDING_REMITTANCE_SUCCESS", pendingRemittance };
  }
  function failure(error) {
    return { type: "GET_MY_PENDING_REMITTANCE_FAILURE", error };
  }
}
function getMyCompletedRemittance() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyCompletedRemittance().then(
      (completedRemittance) => {
        dispatch(success(completedRemittance.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_COMPLETED_REMITTANCE_REQUEST" };
  }
  function success(completedRemittance) {
    return { type: "GET_MY_COMPLETED_REMITTANCE_SUCCESS", completedRemittance };
  }
  function failure(error) {
    return { type: "GET_MY_COMPLETED_REMITTANCE_FAILURE", error };
  }
}
function getMyCancelledCourierOrders() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyCancelledCourierOrders().then(
      (myCancelled) => {
        dispatch(success(myCancelled.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_CANCELLED_COURIER_ORDERS_REQUEST" };
  }
  function success(myCancelled) {
    return { type: "GET_MY_CANCELLED_COURIER_ORDERS_SUCCESS", myCancelled };
  }
  function failure(error) {
    return { type: "GET_MY_CANCELLED_COURIER_ORDERS_FAILURE", error };
  }
}
function getMyRejectedCourierOrders() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyRejectedCourierOrders().then(
      (myRejected) => {
        dispatch(success(myRejected.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_REJECTED_COURIER_ORDERS_REQUEST" };
  }
  function success(myRejected) {
    return { type: "GET_MY_REJECTED_COURIER_ORDERS_SUCCESS", myRejected };
  }
  function failure(error) {
    return { type: "GET_MY_REJECTED_COURIER_ORDERS_FAILURE", error };
  }
}

function getMyPendingCourierOrders() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyPendingCourierOrders().then(
      (myPending) => {
        myPending && dispatch(success(myPending.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_PENDING_COURIER_ORDERS_REQUEST" };
  }
  function success(myPending) {
    return { type: "GET_MY_PENDING_COURIER_ORDERS_SUCCESS", myPending };
  }
  function failure(error) {
    return { type: "GET_MY_PENDING_COURIER_ORDERS_FAILURE", error };
  }
}
function getMyAcceptedCourierOrders() {
  return (dispatch) => {
    dispatch(request());
    adminService.getMyAcceptedCourierOrders().then(
      (myAccepted) => {
        dispatch(success(myAccepted.data.data));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GET_MY_ACCEPTED_COURIER_ORDERS_REQUEST" };
  }
  function success(myAccepted) {
    return { type: "GET_MY_ACCEPTED_COURIER_ORDERS_SUCCESS", myAccepted };
  }
  function failure(error) {
    return { type: "GET_MY_ACCEPTED_COURIER_ORDERS_FAILURE", error };
  }
}
function createOrder(input, setvisible) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request(input));
    adminService.createOrder(input).then(
      (unassigned) => {
        dispatch(success(unassigned.data));
        dispatch(getUnassignedOrders(company_id));
        dispatch(getCustomers(company_id));
        setvisible(false);
        dispatch(alertActions.success(unassigned));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATEORDER_REQUEST" };
  }
  function success(unassigned) {
    return { type: "CREATEORDER_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "CREATEORDER_FAILURE", error };
  }
}

function editOrder(data, setOpen) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request());
    adminService.editOrder(data).then(
      (editedOrder) => {
        dispatch(success(editedOrder.data));
        dispatch(alertActions.success(editedOrder));
        dispatch(getAssignedOrders(company_id));
        dispatch(getUnassignedOrders(company_id));
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "EDITORDER_REQUEST" };
  }
  function success(editedOrder) {
    return { type: "EDITORDER_SUCCESS", editedOrder };
  }
  function failure(error) {
    return { type: "EDITORDER_FAILURE", error };
  }
}

function CloseOrder(id, setOpen) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  return (dispatch) => {
    dispatch(request());
    adminService.CloseOrder(id).then(
      (closed) => {
        dispatch(alertActions.success(closed));
        dispatch(getUnassignedOrders(company_id));
        dispatch(getAssignedOrders(company_id));
        dispatch(getClosedOrders(company_id));
        dispatch(getAnOrder(id.id));
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CLOSEORDER_REQUEST" };
  }

  function failure(error) {
    return { type: "CLOSEORDER_FAILURE", error };
  }
}

function assignOrder(input, company_id, setvisible) {
  return (dispatch) => {
    dispatch(request(input));
    adminService.assignOrder(input).then(
      (assignOrder) => {
        dispatch(success(assignOrder.data));
        dispatch(getAssignedOrders(company_id));
        dispatch(getUnassignedOrders(company_id));
        setvisible(false);
        dispatch(alertActions.success(assignOrder));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "ASSIGNORDER_REQUEST" };
  }
  function success(assignOrder) {
    return { type: "ASSIGNORDER_SUCCESS", assignOrder };
  }
  function failure(error) {
    return { type: "ASSIGNORDER_FAILURE", error };
  }
}
function acceptOrder(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.acceptOrder(id).then(
      (acceptedOrder) => {
        dispatch(success(acceptedOrder.data));
        dispatch(getMyAcceptedCourierOrders());
        dispatch(getMyPendingCourierOrders());

        dispatch(alertActions.success(acceptedOrder));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "ACCEPTEDORDER_REQUEST" };
  }
  function success(acceptedOrder) {
    return { type: "ACCEPTEDORDER_SUCCESS", acceptedOrder };
  }
  function failure(error) {
    return { type: "ACCEPTEDORDER_FAILURE", error };
  }
}

function completeOrder(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.completeOrder(id).then(
      (completedOrder) => {
        dispatch(success(completedOrder.data));
        dispatch(getMyAcceptedCourierOrders());
        dispatch(getMyCompletedCourierOrders());
        dispatch(getCompletedOrders());
        dispatch(alertActions.success(completedOrder));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "COMPLETEDORDER_REQUEST" };
  }
  function success(completedOrder) {
    return { type: "COMPLETEDORDER_SUCCESS", completedOrder };
  }
  function failure(error) {
    return { type: "COMPLETEDORDER_FAILURE", error };
  }
}

function rejectOrder(id) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request(id));
    adminService.rejectOrder(id).then(
      (rejectedOrder) => {
        dispatch(success(rejectedOrder.data));
        dispatch(getMyRejectedCourierOrders());
        dispatch(getUnassignedOrders(company_id));

        dispatch(alertActions.success(rejectedOrder));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "REJECTEDORDER_REQUEST" };
  }
  function success(rejectedOrder) {
    return { type: "REJECTEDORDER_SUCCESS", rejectedOrder };
  }
  function failure(error) {
    return { type: "REJECTEDORDER_FAILURE", error };
  }
}

function cancelOrder(id) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request());
    adminService.cancelOrder(id).then(
      (canceledOrder) => {
        dispatch(success(canceledOrder.data));
        dispatch(getMyCancelledCourierOrders());
        dispatch(getUnassignedOrders(company_id));
        dispatch(alertActions.success(canceledOrder));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CANCELEDORDER_REQUEST" };
  }
  function success(canceledOrder) {
    return { type: "CANCELEDORDER_SUCCESS", canceledOrder };
  }
  function failure(error) {
    return { type: "CANCELEDORDER_FAILURE", error };
  }
}

function unAssignOrder(id, setvisible, openModal) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request(id));
    adminService.unAssignOrder(id).then(
      (assignOrder) => {
        dispatch(success(assignOrder.data));
        dispatch(success(getAssignedOrders(company_id)));
        setvisible(false);
        openModal(true);
        dispatch(alertActions.success(assignOrder));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "UNASSIGNORDER_REQUEST" };
  }
  function success() {
    return { type: "UNASSIGNORDER_SUCCESS" };
  }
  function failure(error) {
    return { type: "UNASSIGNORDER_FAILURE", error };
  }
}

function getSingleOrder(order) {
  return (dispatch) => {
    dispatch({ type: "GETSINGLEORDER_REQUEST", order });
  };
}

function getSingleCourierOrder(courierOrder) {
  return (dispatch) => {
    dispatch({ type: "GETSINGLECOURIERORDER_REQUEST", courierOrder });
  };
}

function getAnOrder(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.getAnOrder(id).then(
      (order) => {
        dispatch(success(order.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETANORDER_REQUEST" };
  }
  function success(order) {
    return { type: "GETANORDER_SUCCESS", order };
  }
  function failure(error) {
    return { type: "GETANORDER_FAILURE", error };
  }
}

function getACourierOrder(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.getACourierOrder(id).then(
      (courierOrder) => {
        dispatch(success(courierOrder.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETACOURIERORDER_REQUEST" };
  }
  function success(courierOrder) {
    return { type: "GETACOURIERORDER_SUCCESS", courierOrder };
  }
  function failure(error) {
    return { type: "GETACOURIERORDER_FAILURE", error };
  }
}

function remitOrder(input, setvisible) {
  return (dispatch) => {
    dispatch(request(input));
    adminService.remitOrder(input).then(
      (remitted) => {
        dispatch(success(remitted));
        dispatch(getRemittedOrders());
        setvisible(false);
        dispatch(alertActions.success(remitted));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "REMITORDER_REQUEST" };
  }
  function success(remitted) {
    return { type: "REMITORDER_SUCCESS", remitted };
  }
  function failure(error) {
    return { type: "REMITORDER_FAILURE", error };
  }
}
function commentToRemit(input, setvisible) {
  return (dispatch) => {
    dispatch(request(input));
    adminService.commentToRemit(input).then(
      (courierRemitted) => {
        dispatch(success(courierRemitted.data));
        dispatch(getMyCompletedRemittance());
        dispatch(getRemittedOrders());
        setvisible(false);
        dispatch(alertActions.success(courierRemitted));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "COMMENT_TO_REMIT_REQUEST" };
  }
  function success(courierRemitted) {
    return { type: "COMMENT_TO_REMIT_SUCCESS", courierRemitted };
  }
  function failure(error) {
    return { type: "COMMENT_TO_REMIT_FAILURE", error };
  }
}

function getRemittedOrders(company_id, start_date, end_date, page) {
  return (dispatch) => {
    dispatch(request(true));

    adminService.getRemittedOrders(company_id, start_date, end_date, page).then(
      (remittedOrders) => {
        dispatch(success(remittedOrders));
        dispatch(request(false));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETREMITTEDORDERS_REQUEST", payload };
  }
  function success(remittedOrders) {
    return { type: "GETREMITTEDORDERS_SUCCESS", remittedOrders };
  }
  function failure(error) {
    return { type: "GETREMITTEDORDERS_FAILURE", error };
  }
}

function filterRemittedOrders(payload, page) {
  return (dispatch) => {
    dispatch(request(true));

    adminService.filterRemittedOrders(payload, page).then(
      (remittedOrders) => {
        if (payload.filterType === "completed") {
          let results = remittedOrders.data.data.filter((item) => {
            return item.status === "completed";
          });
          remittedOrders = {
            ...remittedOrders,
            data: { ...remittedOrders.data, data: results },
          };
        }
        dispatch(request(false));

        dispatch(success(remittedOrders));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETREMITTEDORDERS_REQUEST", payload };
  }
  function success(remittedOrders) {
    return { type: "GETREMITTEDORDERS_SUCCESS", remittedOrders };
  }
  function failure(error) {
    return { type: "GETREMITTEDORDERS_FAILURE", error };
  }
}

function filterRemittedOrdersNoPage(payload) {
  return (dispatch) => {
    dispatch(request(true));

    adminService.filterRemittedOrdersNoPage(payload).then(
      (remittedNoPageOrders) => {
        if (payload.filterType === "completed") {
          let results = remittedNoPageOrders.data.data.filter((item) => {
            return item.status === "completed";
          });
          remittedNoPageOrders = {
            ...remittedNoPageOrders,
            data: { ...remittedNoPageOrders.data, data: results },
          };
        }
        dispatch(request(false));

        dispatch(success(remittedNoPageOrders));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETREMITTEDORDERSNOPAGE_REQUEST", payload };
  }
  function success(remittedNoPageOrders) {
    return { type: "GETREMITTEDORDERSNOPAGE_SUCCESS", remittedNoPageOrders };
  }
  function failure(error) {
    return { type: "GETREMITTEDORDERSNOPAGE_FAILURE", error };
  }
}

function filterCourierRemittedOrders(payload, page) {
  return (dispatch) => {
    dispatch(request(true));

    adminService.filterCourierRemittedOrders(payload, page).then(
      (myCompleted) => {
        dispatch(request(false));

        dispatch(success(myCompleted));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GET_MY_COMPLETED_COURIER_ORDERS_REQUEST", payload };
  }
  function success(myCompleted) {
    return { type: "GET_MY_COMPLETED_COURIER_ORDERS_SUCCESS", myCompleted };
  }
  function failure(error) {
    return { type: "GET_MY_COMPLETED_COURIER_ORDERS_FAILURE", error };
  }
}

function filterDeliveryReport(payload, page) {
  return (dispatch) => {
    adminService.filterDeliveryReport(payload, page).then(
      (filteredOrders) => {
        dispatch(success(filteredOrders));

        return;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(filteredOrders) {
    return { type: "FILTERDELIVERY_SUCCESS", filteredOrders };
  }
  function failure(error) {
    return { type: "FILTERDELIVERY_FAILURE", error };
  }
}

function getClosedOrders(id, page) {
  return (dispatch) => {
    dispatch(request(true));

    adminService.getClosedOrders(id, page).then(
      (closedOrders) => {
        dispatch(request(false));
        dispatch(success(closedOrders.data));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETCLOSEDDORDERS_REQUEST", payload };
  }
  function success(closedOrders) {
    return { type: "GETCLOSEDORDERS_SUCCESS", closedOrders };
  }
  function failure(error) {
    return { type: "GETCLOSEDORDERS_FAILURE", error };
  }
}
function getClosedOrdersLoadMore(id, page, setPage) {
  return (dispatch) => {
    dispatch(request(true));

    adminService.getClosedOrders(id, page).then(
      (closedOrders) => {
        setPage(page);
        dispatch(request(false));
        dispatch(success(closedOrders.data.data));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETCLOSEDDORDERS_REQUEST", payload };
  }
  function success(closedOrders) {
    return { type: "GETCLOSEDORDERSLOADMORE_SUCCESS", closedOrders };
  }
  function failure(error) {
    return { type: "GETCLOSEDORDERS_FAILURE", error };
  }
}

function markAsClosed(id, setvisible) {
  return (dispatch) => {
    dispatch(request());
    adminService.markAsClosed(id).then(
      (closed) => {
        dispatch(success(closed.data));
        dispatch(getClosedOrders(id));
        setvisible(false);
        dispatch(alertActions.success(closed));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "MARK_AS_CLOSED_REQUEST" };
  }
  function success(closed) {
    return { type: "MARK_AS_CLOSED_SUCCESS", closed };
  }
  function failure(error) {
    return { type: "MARK_AS_CLOSED_FAILURE", error };
  }
}

function createRecordOrder(input, setvisible) {
  return (dispatch) => {
    dispatch(request(input));
    adminService.createOrder(input).then(
      (unassigned) => {
        dispatch(success(unassigned.data));
        dispatch(markAsdelivered(unassigned.data.id));
        setvisible(false);
      },

      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATERECORD_REQUEST" };
  }
  function success(unassigned) {
    return { type: "CREATERECORD_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "CREATERECORD_FAILURE", error };
  }
}

function markAsdelivered(id) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  return (dispatch) => {
    dispatch(request());
    adminService.markAsdelivered(id).then(
      (delivered) => {
        dispatch(getCompletedOrderList(company_id));
        dispatch(alertActions.success(delivered));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "MARK_AS_DELIVERED_REQUEST" };
  }

  function failure(error) {
    return { type: "MARK_AS_DELIVERED_FAILURE", error };
  }
}

function verifyRemittance(input, payload, params, setvisible) {
  return (dispatch) => {
    dispatch(request(input));
    adminService.verifyRemittance(input).then(
      (verified) => {
        dispatch(success(input));
        setvisible(false);
        dispatch(alertActions.success(verified));
        dispatch(filterRemittedOrders(payload, params));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "VERIFYREMIT_REQUEST" };
  }
  function success(verified) {
    return { type: "VERIFYREMIT_SUCCESS", verified };
  }
  function failure(error) {
    return { type: "VERIFYREMIT_FAILURE", error };
  }
}

function getAllCompanies(page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getAllCompanies(page).then(
      (companies) => {
        dispatch(request(false));
        dispatch(success(companies));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETALLCOMPANIES_REQUEST", payload };
  }
  function success(companies) {
    return { type: "GETALLCOMPANIES_SUCCESS", companies };
  }
  function failure(error) {
    return { type: "GETALLCOMPANIES_FAILURE", error };
  }
}

function approveCompany(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.approveCompany(id).then(
      (approve) => {
        dispatch(getAllCompanies());
        dispatch(alertActions.success(approve));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "APPROVECOMPANY_REQUEST" };
  }
  function failure(error) {
    return { type: "APPROVECOMPANY_FAILURE", error };
  }
}

function getEmployees(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getEmployees(company_id, page).then(
      (allEmployees) => {
        dispatch(request(false));
        dispatch(success(allEmployees));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETEMPLOYEES_REQUEST", payload };
  }
  function success(allEmployees) {
    return { type: "GETEMPLOYEES_SUCCESS", allEmployees };
  }
  function failure(error) {
    return { type: "GETEMPLOYEES_FAILURE", error };
  }
}

function createEmployees(data, id, setOpen) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.createEmployees(data).then(
      (unassigned) => {
        dispatch(success(unassigned.data.data));
        dispatch(getEmployees(id));
        setOpen(false);
        dispatch(alertActions.success(unassigned));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATEEMPLOYEES_REQUEST" };
  }
  function success(unassigned) {
    return { type: "CREATEEMPLOYEES_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "CREATEEMPLOYEES_FAILURE", error };
  }
}

function editEmployee(data, setOpen) {
  return (dispatch) => {
    dispatch(request());
    adminService.editEmployee(data).then(
      (editedEmployee) => {
        dispatch(success(editedEmployee.data));
        dispatch(alertActions.success(editedEmployee));
        dispatch(getEmployees());
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "EDITEMPLOYEE_REQUEST" };
  }
  function success(editedEmployee) {
    return { type: "EDITEMPLOYEE_SUCCESS", editedEmployee };
  }
  function failure(error) {
    return { type: "EDITEMPLOYEE_FAILURE", error };
  }
}

function deleteEmployee(id, setOpen) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request());
    adminService.deleteEmployee(id).then(
      (deletedEmployee) => {
        dispatch(success(deletedEmployee.data));
        dispatch(alertActions.success(deletedEmployee));
        dispatch(getEmployees(company_id));
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "DELETEEMPLOYEE_REQUEST" };
  }
  function success(deletedEmployee) {
    return { type: "DELETEEMPLOYEE_SUCCESS", deletedEmployee };
  }
  function failure(error) {
    return { type: "DELETEEMPLOYEE_FAILURE", error };
  }
}

function approveEmployee(id, company, setOpen) {
  return (dispatch) => {
    dispatch(request());
    adminService.approveEmployee(id).then(
      (approvedEmployee) => {
        dispatch(success(approvedEmployee.data));
        dispatch(alertActions.success(approvedEmployee));
        dispatch(getEmployees(company));
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "APPROVEEMPLOYEE_REQUEST" };
  }
  function success(approvedEmployee) {
    return { type: "APPROVEEMPLOYEE_SUCCESS", approvedEmployee };
  }
  function failure(error) {
    return { type: "APPROVEEMPLOYEE_FAILURE", error };
  }
}

function getSingleEmployee(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.getSingleEmployee(id).then(
      (singleEmployee) => {
        dispatch(success(singleEmployee.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: "SINGLEEMPLOYEE_REQUEST" };
  }
  function success(singleEmployee) {
    return { type: "SINGLEEMPLOYEE_SUCCESS", singleEmployee };
  }
  function failure(error) {
    return { type: "SINGLEEMPLOYEE_FAILURE", error };
  }
}

function setEmployeeActive(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.setEmployeeActive().then(
      (employeeActive) => {
        dispatch(alertActions.success(employeeActive));
        dispatch(getSingleEmployee(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "SET_EMPLOYEE_ACTIVE_REQUEST" };
  }
  function failure(error) {
    return { type: "SET_EMPLOYEE_ACTIVE_FAILURE", error };
  }
}

function setEmployeeInactive(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.setEmployeeInactive().then(
      (employeeInactive) => {
        dispatch(alertActions.success(employeeInactive));
        dispatch(getSingleEmployee(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "SET_EMPLOYEE_ACTIVE_REQUEST" };
  }
  function failure(error) {
    return { type: "SET_EMPLOYEE_ACTIVE_FAILURE", error };
  }
}

function getSingleRider(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.getSingleRider(id).then(
      (singleRider) => {
        dispatch(success(singleRider.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: "SINGLERIDER_REQUEST" };
  }
  function success(singleRider) {
    return { type: "SINGLERIDER_SUCCESS", singleRider };
  }
  function failure(error) {
    return { type: "SINGLERIDER_FAILURE", error };
  }
}

function setRiderActive(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.setRiderActive().then(
      (riderActive) => {
        dispatch(alertActions.success(riderActive));
        dispatch(getSingleRider(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "SET_RIDER_ACTIVE_REQUEST" };
  }
  function failure(error) {
    return { type: "SET_RIDER_ACTIVE_FAILURE", error };
  }
}

function setRiderInactive(id) {
  return (dispatch) => {
    dispatch(request());
    adminService.setRiderInactive().then(
      (riderInactive) => {
        dispatch(alertActions.success(riderInactive));
        dispatch(getSingleRider(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "SET_RIDER_ACTIVE_REQUEST" };
  }
  function failure(error) {
    return { type: "SET_RIDER_ACTIVE_FAILURE", error };
  }
}

function getCustomers(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getCustomers(company_id, page).then(
      (allCustomers) => {
        dispatch(request(false));
        dispatch(success(allCustomers));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETCUSTOMERS_REQUEST", payload };
  }
  function success(allCustomers) {
    return { type: "GETCUSTOMERS_SUCCESS", allCustomers };
  }
  function failure(error) {
    return { type: "GETCUSTOMERS_FAILURE", error };
  }
}

function createCustomers(data, company_id) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.createCustomers(data).then(
      (unassigned) => {
        dispatch(success(unassigned.data.data));
        dispatch(getCustomers(company_id));
        dispatch(alertActions.success(unassigned));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATECUSTOMERS_REQUEST" };
  }
  function success(unassigned) {
    return { type: "CREATECUSTOMERS_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "CREATECUSTOMERS_FAILURE", error };
  }
}

function searchAllCustomers({ search, company_id }) {
  return (dispatch) => {
    dispatch(request());
    adminService.searchCustomers(search, company_id).then(
      (searched) => {
        dispatch(success(searched));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "SEARCH_ALL_CUSTOMERS_REQUEST" };
  }
  function success(searched) {
    return { type: "SEARCH_ALL_CUSTOMERS_SUCCESS", searched };
  }
  function failure(error) {
    return { type: "SEARCH_ALL_CUSTOMERS_FAILURE", error };
  }
}

function searchEmployee({ search }) {
  return (dispatch) => {
    dispatch(request());

    adminService.searchEmployee(search).then(
      (searchedEmployee) => {
        dispatch(success(searchedEmployee));
      },

      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "SEARCHEMPLOYEE_REQUEST" };
  }
  function success(searchedEmployee) {
    return { type: "SEARCHEMPLOYEE_SUCCESS", searchedEmployee };
  }
  function failure(error) {
    return { type: "SEARCHEMPLOYEE_FAILURE", error };
  }
}

function editCustomer(data) {
  return (dispatch) => {
    dispatch(request());
    adminService.editCustomer(data).then(
      (editedCustomer) => {
        dispatch(success(editedCustomer.data));
        dispatch(alertActions.success(editedCustomer));
        dispatch(getCustomers());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "EDITCUSTOMER_REQUEST" };
  }
  function success(editedCustomer) {
    return { type: "EDITCUSTOMER_SUCCESS", editedCustomer };
  }
  function failure(error) {
    return { type: "EDITCUSTOMER_FAILURE", error };
  }
}

function deleteCustomer(id, setOpen) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request());
    adminService.deleteCustomer(id).then(
      (deletedCustomer) => {
        dispatch(success(deletedCustomer.data));
        dispatch(alertActions.success(deletedCustomer));
        setOpen(false);
        dispatch(getCustomers(company_id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "DELETECUSTOMER_REQUEST" };
  }
  function success(deletedCustomer) {
    return { type: "DELETECUSTOMER_SUCCESS", deletedCustomer };
  }
  function failure(error) {
    return { type: "DELETECUSTOMER_FAILURE", error };
  }
}

function getRoles() {
  return (dispatch) => {
    dispatch(request());
    adminService.getRoles().then(
      (allRoles) => {
        dispatch(success(allRoles.data.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETROLES_REQUEST" };
  }
  function success(allRoles) {
    return { type: "GETROLES_SUCCESS", allRoles };
  }
  function failure(error) {
    return { type: "GETROLES_FAILURE", error };
  }
}

function assignRole(user_id, team_id, role_id, setvisible) {
  return (dispatch) => {
    dispatch(request());
    adminService.assignRole(user_id, team_id, role_id).then(
      (allRoles) => {
        dispatch(success(allRoles));
        dispatch(alertActions.success(allRoles));
        setvisible(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "ASSIGNROLES_REQUEST" };
  }
  function success(allRoles) {
    return { type: "ASSIGNROLES_SUCCESS", allRoles };
  }
  function failure(error) {
    return { type: "ASSIGNROLES_FAILURE", error };
  }
}

function getAllAbility(page) {
  return (dispatch) => {
    dispatch(request());
    adminService.getAllAbility(page).then(
      (allAbility) => {
        dispatch(success(allAbility));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETALLABILITY_REQUEST" };
  }
  function success(allAbility) {
    return { type: "GETALLABILITY_SUCCESS", allAbility };
  }
  function failure(error) {
    return { type: "GETALLABILITY_FAILURE", error };
  }
}

function assignAbility(input, setvisible) {
  return (dispatch) => {
    dispatch(request());
    adminService.assignAbility(input).then(
      (allAbility) => {
        dispatch(success(allAbility));
        dispatch(alertActions.success(allAbility));
        setvisible(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "ASSIGNABILITY_REQUEST" };
  }
  function success(allAbility) {
    return { type: "ASSIGNABILITY_SUCCESS", allAbility };
  }
  function failure(error) {
    return { type: "ASSIGNABILITY_FAILURE", error };
  }
}

function getCompanyStats(company_id, start_date, end_date) {
  return (dispatch) => {
    dispatch(request());
    adminService.getCompanyStats(company_id, start_date, end_date).then(
      (companyStats) => {
        dispatch(success(companyStats.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETCOMPANYSTATS_REQUEST" };
  }
  function success(companyStats) {
    return { type: "GETCOMPANYSTATS_SUCCESS", companyStats };
  }
  function failure(error) {
    return { type: "GETCOMPANYSTATS_FAILURE", error };
  }
}

function getCourierStats(company_id, start_date, end_date) {
  return (dispatch) => {
    dispatch(request());
    adminService.getCourierStats(company_id, start_date, end_date).then(
      (courierStats) => {
        dispatch(success(courierStats.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETCOURIERSTATS_REQUEST" };
  }
  function success(courierStats) {
    return { type: "GETCOURIERSTATS_SUCCESS", courierStats };
  }
  function failure(error) {
    return { type: "GETCOURIERSTATS_FAILURE", error };
  }
}

function getEmployeeStats(company_id, start_date, end_date) {
  return (dispatch) => {
    dispatch(request());
    adminService.getEmployeeStats(company_id, start_date, end_date).then(
      (employeeStats) => {
        dispatch(success(employeeStats.data.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETEMPLOYEESTATS_REQUEST" };
  }
  function success(employeeStats) {
    return { type: "GETEMPLOYEESTATS_SUCCESS", employeeStats };
  }
  function failure(error) {
    return { type: "GETEMPLOYEESTATS_FAILURE", error };
  }
}

function getGeneralStats(company_id, date) {
  return (dispatch) => {
    dispatch(request());
    adminService.getGeneralStats(company_id, date).then(
      (generalStats) => {
        dispatch(success(generalStats.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETGENERALSTATS_REQUEST" };
  }
  function success(generalStats) {
    return { type: "GETGENERALSTATS_SUCCESS", generalStats };
  }
  function failure(error) {
    return { type: "GETGENERALSTATS_FAILURE", error };
  }
}

function getCountries() {
  return (dispatch) => {
    dispatch(request());
    adminService.getCountries().then(
      (countries) => {
        dispatch(success(countries.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETCOUNTRIES_REQUEST" };
  }
  function success(countries) {
    return { type: "GETCOUNTRIES_SUCCESS", countries };
  }
  function failure(error) {
    return { type: "GETCOUNTRIES_FAILURE", error };
  }
}

function getAllStates() {
  return (dispatch) => {
    dispatch(request());
    adminService.getAllStates().then(
      (states) => {
        dispatch(success(states.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETALLSTATES_REQUEST" };
  }
  function success(states) {
    return { type: "GETALLSTATES_SUCCESS", states };
  }
  function failure(error) {
    return { type: "GETALLSTATES_FAILURE", error };
  }
}

function getStates(country_id) {
  return (dispatch) => {
    dispatch(request());
    adminService.getStates(country_id).then(
      (allStates) => {
        dispatch(success(allStates.data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETSTATES_REQUEST" };
  }
  function success(allStates) {
    return { type: "GETSTATES_SUCCESS", allStates };
  }
  function failure(error) {
    return { type: "GETSTATES_FAILURE", error };
  }
}

function getCities(city) {
  return { type: "GETCITIES_SUCCESS", city };
}

function getAllOutlet(company_id, page) {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getAllOutlet(company_id, page).then(
      (allOutlet) => {
        dispatch(request(false));
        dispatch(success(allOutlet));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETALLOUTLET_REQUEST", payload };
  }
  function success(allOutlet) {
    return { type: "GETALLOUTLET_SUCCESS", allOutlet };
  }
  function failure(error) {
    return { type: "GETALLOUTLET_FAILURE", error };
  }
}

function createOutlet(data, setOpen) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request(data));
    adminService.createOutlet(data).then(
      (createOutlet) => {
        dispatch(success(createOutlet.data.data));
        dispatch(getAllOutlet(company_id));
        setOpen(false);

        dispatch(alertActions.success(createOutlet));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATEOUTLET_REQUEST" };
  }
  function success(createOutlet) {
    return { type: "CREATEOUTLET_SUCCESS", createOutlet };
  }
  function failure(error) {
    return { type: "CREATEOUTLET_FAILURE", error };
  }
}

function searchOutlet(search) {
  return (dispatch) => {
    dispatch(request());
    adminService.searchOutlet(search).then(
      (searched) => {
        dispatch(success(searched));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "SEARCHOUTLET_REQUEST" };
  }
  function success(searched) {
    return { type: "SEARCHOUTLET_SUCCESS", searched };
  }
  function failure(error) {
    return { type: "SEARCHOUTLET_FAILURE", error };
  }
}
function searchAllOutlet(search) {
  return (dispatch) => {
    dispatch(request());
    adminService.searchOutlet(search).then(
      (searchedOutlet) => {
        dispatch(success(searchedOutlet));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "SEARCH_ALL_OUTLET_REQUEST" };
  }
  function success(searchedOutlet) {
    return { type: "SEARCH_ALL_OUTLET_SUCCESS", searchedOutlet };
  }
  function failure(error) {
    return { type: "SEARCH_ALL_OUTLET_FAILURE", error };
  }
}

function searchCompany(search) {
  return (dispatch) => {
    dispatch(request());
    adminService.searchCompany(search).then(
      (searchedCompany) => {
        dispatch(success(searchedCompany));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "SEARCHCOMPANIES_REQUEST" };
  }
  function success(searchedCompany) {
    return { type: "SEARCHCOMPANIES_SUCCESS", searchedCompany };
  }
  function failure(error) {
    return { type: "SEARCHCOMPANIES_FAILURE", error };
  }
}

function editOutlet(data, setOpen) {
  return (dispatch) => {
    dispatch(request());
    adminService.editOutlet(data).then(
      (editedOutlet) => {
        dispatch(success(editedOutlet.data));
        dispatch(alertActions.success(editedOutlet));
        dispatch(getAllOutlet());
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "EDITOUTLET_REQUEST" };
  }
  function success(editedOutlet) {
    return { type: "EDITOUTLET_SUCCESS", editedOutlet };
  }
  function failure(error) {
    return { type: "EDITOUTLET_FAILURE", error };
  }
}

function deleteOutlet(id, setOpen) {
  return (dispatch) => {
    dispatch(request());
    adminService.deleteOutlet(id).then(
      (deletedOutlet) => {
        dispatch(success(deletedOutlet.data));
        dispatch(alertActions.success(deletedOutlet));
        dispatch(getAllOutlet());
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "DELETEOUTLET_REQUEST" };
  }
  function success(deletedOutlet) {
    return { type: "DELETEOUTLET_SUCCESS", deletedOutlet };
  }
  function failure(error) {
    return { type: "DELETEOUTLET_FAILURE", error };
  }
}

function getMyCompany() {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getMyCompany().then(
      (myCompany) => {
        dispatch(request(false));
        dispatch(success(myCompany.data));
        dispatch(userActions.createPushNotification());
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETMYCOMPANY_REQUEST", payload };
  }
  function success(myCompany) {
    return { type: "GETMYCOMPANY_SUCCESS", myCompany };
  }
  function failure(error) {
    return { type: "GETMYCOMPANY_FAILURE", error };
  }
}

function getMyCourier() {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getMyCourier().then(
      (myCourier) => {
        dispatch(request(false));
        dispatch(success(myCourier.data));
        dispatch(userActions.createPushNotification());
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETMYCOURIER_REQUEST", payload };
  }
  function success(myCourier) {
    return { type: "GETMYCOURIER_SUCCESS", myCourier };
  }
  function failure(error) {
    return { type: "GETMYCOURIER_FAILURE", error };
  }
}

function createThirdPartyToken(data, setOpen) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.createThirdPartyToken(data).then(
      (createThirdPartyToken) => {
        dispatch(success(createThirdPartyToken.data.data));
        dispatch(getThirdPartyToken());
        setOpen(false);
        dispatch(alertActions.success(createThirdPartyToken));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATETHIRDPARTYTOKEN_REQUEST" };
  }
  function success(createThirdPartyToken) {
    return { type: "CREATETHIRDPARTYTOKEN_SUCCESS", createThirdPartyToken };
  }
  function failure(error) {
    return { type: "CREATETHIRDPARTYTOKEN_FAILURE", error };
  }
}

function getThirdPartyToken() {
  return (dispatch) => {
    dispatch(request(true));
    adminService.getThirdPartyToken().then(
      (thirdPartyToken) => {
        dispatch(request(false));
        dispatch(success(thirdPartyToken.data.data));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETTHIRDPARTYTOKEN_REQUEST", payload };
  }
  function success(thirdPartyToken) {
    return { type: "GETTHIRDPARTYTOKEN_SUCCESS", thirdPartyToken };
  }
  function failure(error) {
    return { type: "GETTHIRDPARTYTOKEN_FAILURE", error };
  }
}
function getLocationList(locationList) {
  return (dispatch) => {
    dispatch(success(locationList));
  };

  function success(locationList) {
    return { type: "GETLOCATION_LIST", locationList };
  }
}
