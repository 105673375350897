import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import PrivateRoute from "./components/privateRoute";
import Login from "./components/auth/Login";
import RegisterCompany from "./components/auth/RegisterCompany";
import RegisterUser from "./components/auth/RegisterUser";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import UserClearance from "./components/auth/UserClearance";
import UserRedirect from "./components/auth/UserRedirect";
import UserDashboard from "./components/pages/UserDashboard";
import MyInvites from "./components/pages/Invites/MyInvites";
import Profile from "./components/pages/Profile";
import UserProfile from "./components/pages/UserProfile";
import SwitchAccount from "./components/auth/SwitchAccount";
import TermsConditions from "./components/pages/TermsConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import RidersOrders from "./components/pages/RidersOrders/RidersOrders";
import RidersRemittance from "./components/pages/RidersRemittance/RidersRemittance";
import TrackOrders from "./components/pages/Orders/TrackOrders";
import TrackView from "./components/pages/Orders/TrackView";
import UserOrders from "./components/pages/Orders/UserOrders";
import DashboardRider from "./components/pages/DashboardRider";
import DashboardN from "./components/pages/DashboardN";
import Customers from "./components/pages/Customers/Customers";
import Orders from "./components/pages/Orders/Orders";
import OrdersReport from "./components/pages/reports/OrdersReport";
import DeliveryReport from "./components/pages/reports/DeliveryReport";
import MileageReport from "./components/pages/reports/MileageReport";
import OrdersTable from "./components/pages/Orders/OrdersTable";

import Riders from "./components/pages/Riders/Riders";
import AllRiders from "./components/pages/Riders/AllRiders";
import RiderView from "./components/pages/Riders/RiderView";

import Employees from "./components/pages/Employees/Employees";
import Companies from "./components/pages/Company/Companies";
import Stores from "./components/pages/Stores/Stores";
import Remittance from "./components/pages/Remittance/Remittance";
import VerifyRemittance from "./components/pages/Remittance/VerifyRemittance";
import SetRole from "./components/pages/Employees/SetRole";
import Settings from "./components/pages/Settings/Settings";
import AssignAbility from "./components/pages/Settings/AssignAbility";

import Notification from "./components/includes/Notification";
import CreateAccount from "./components/auth/CreateAccount";
import InviteLinkReg from "./components/auth/InviteLinkReg";
import FirebaseNotification from "./components/includes/FirebaseNotification";

function App() {
  return (
    <div className="App" data-test="appComponent">
      {/* remove alert after faded out */}
      <Notification />
      <FirebaseNotification />

      <Router>
        <Switch>
          <Route exact path="/" component={PrivateRoute(DashboardN)} />
          <Route
            exact
            path="/courier/dashboard"
            component={PrivateRoute(DashboardRider)}
          />
          <Route
            exact
            path="/userdashboard"
            component={PrivateRoute(UserDashboard)}
          />
          <Route
            exact
            path="/delivery"
            component={PrivateRoute(DeliveryReport)}
          />
          <Route exact path="/riders" component={PrivateRoute(Riders)} />
          <Route exact path="/allriders" component={PrivateRoute(AllRiders)} />
          <Route path="/allriders/:page" component={PrivateRoute(AllRiders)} />
          <Route
            path="/user/trackOrder"
            component={PrivateRoute(TrackOrders)}
          />
          <Route path="/user/trackView" component={PrivateRoute(TrackView)} />
          <Route path="/user/userOrders" component={PrivateRoute(UserOrders)} />
          <Route
            exact
            path="/riderView/:id"
            component={PrivateRoute(RiderView)}
          />
          <Route exact path="/orders" component={PrivateRoute(Orders)} />
          <Route
            exact
            path="/orders-table"
            component={PrivateRoute(OrdersTable)}
          />

          <Route
            exact
            path="/courier/orders"
            component={PrivateRoute(RidersOrders)}
          />
          <Route
            exact
            path="/ordersReport"
            component={PrivateRoute(OrdersReport)}
          />
          <Route
            path="/ordersReport/:page"
            component={PrivateRoute(OrdersReport)}
          />
          <Route exact path="/customers" component={PrivateRoute(Customers)} />
          <Route path="/customers/:page" component={PrivateRoute(Customers)} />
          <Route exact path="/employees" component={PrivateRoute(Employees)} />
          <Route path="/employees/:page" component={PrivateRoute(Employees)} />
          <Route exact path="/companies" component={PrivateRoute(Companies)} />
          <Route path="/companies/:page" component={PrivateRoute(Companies)} />
          <Route exact path="/outlets" component={PrivateRoute(Stores)} />
          <Route path="/outlets/:page" component={PrivateRoute(Stores)} />
          <Route
            exact
            path="/remittance"
            component={PrivateRoute(Remittance)}
          />
          <Route
            path="/remittance/:page"
            component={PrivateRoute(Remittance)}
          />
          <Route
            path="/courier/remittance"
            component={PrivateRoute(RidersRemittance)}
          />
          <Route
            path="/courier/remittance/:page"
            component={PrivateRoute(RidersRemittance)}
          />
          <Route
            exact
            path="/verifyRemittance"
            component={PrivateRoute(VerifyRemittance)}
          />
          <Route
            path="/verifyRemittance/:page"
            component={PrivateRoute(VerifyRemittance)}
          />
          <Route
            exact
            path="/deliveryReport"
            component={PrivateRoute(DeliveryReport)}
          />
          <Route
            exact
            path="/deliveryReport/:page"
            component={PrivateRoute(DeliveryReport)}
          />
          <Route
            exact
            path="/mileageReport"
            component={PrivateRoute(MileageReport)}
          />
          <Route exact path="/setRole" component={PrivateRoute(SetRole)} />
          <Route exact path="/settings" component={PrivateRoute(Settings)} />
          <Route
            exact
            path="/settings/ability"
            component={PrivateRoute(AssignAbility)}
          />
          <Route
            path="/settings/ability/:page"
            component={PrivateRoute(AssignAbility)}
          />
          <Route exact path="/user/profile" component={PrivateRoute(Profile)} />
          <Route
            exact
            path="/user/userProfile"
            component={PrivateRoute(UserProfile)}
          />
          <Route exact path="/auth/login" component={Login} />
          <Route
            exact
            path="/auth/registerCompany"
            component={RegisterCompany}
          />
          <Route exact path="/auth/registerUser" component={RegisterUser} />
          <Route exact path="/auth/switchAccount" component={SwitchAccount} />
          <Route exact path="/auth/password" component={ForgotPassword} />
          <Route exact path="/auth/resetPassword" component={ResetPassword} />
          <Route path="/auth/resetPassword?:token" component={ResetPassword} />
          <Route exact path="/auth/userClearance" component={UserClearance} />
          <Route exact path="/auth/createAccount" component={CreateAccount} />
          <Route exact path="/auth/userRedirect" component={UserRedirect} />
          <Route
            exact
            path="/auth/inviteRegisteration"
            component={InviteLinkReg}
          />
          <Route exact path="/invite/me" component={MyInvites} />
          <Route exact path="/terms-of-service" component={TermsConditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
