import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
// import {Rotate, Beat} from "../../../redux/reducers/spinner";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function CreateCustomers() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const id = user.data.groups[teamId].company_id;
  const dispatch = useDispatch();

  // Open and close the modals
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  //This is to get company details
  const [myCompany, setMyCompany] = React.useState({});
  useEffect(() => {
    let isUnmount = false;
    const getMyCompany = async () => {
      let companyData = JSON.parse(await localStorage.getItem("myCompany"));
      if (!isUnmount) {
        companyData && setMyCompany(companyData);
        companyData &&
          setData((data) => ({
            ...data,
            customer_city: companyData.company_city,
            customer_state: companyData.company_state,
            customer_country: companyData.company_country,
          }));
      }
    };
    getMyCompany();
    return () => {
      isUnmount = true;
    };
  }, []);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  // store the input data in state
  const [submitted, setSubmitted] = useState(false);
  let [customer_phone, setCustomerPhone] = useState("");
  const [data, setData] = useState([
    {
      company_id: "",
      customer_email: "",
      customer_name: "",
      customer_gender: "",
      customer_address: "",
      customer_city: "",
      customer_state: "",
      customer_country: "",
    },
  ]);
  const {
    customer_email,
    customer_name,
    customer_gender,
    customer_address,
    customer_city,
    customer_state,
    customer_country,
  } = data;
  const creating = useSelector((state) => state.admin.creating);

  // Function that sets input data to state
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  customer_phone = customer_phone?.substring(1);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      customer_name &&
      customer_address &&
      customer_city &&
      customer_state &&
      customer_country &&
      customer_phone
    ) {
      // dispatch action to create data
      dispatch(
        adminActions.createCustomers(
          { ...data, customer_phone, company_id: id },
          id
        )
      );
      handleClose();
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setOpen(true)}>
          Add a customer
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Add a new customer to your company
            </MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_name ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_name">Customer name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="customer_name"
                    onChange={handleChange}
                  />
                  {submitted && customer_name && (
                    <div className="invalid-feedback">
                      Customer name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_email ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_email">Customer email*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="customer_email"
                    onChange={handleChange}
                  />
                  {submitted && customer_email && (
                    <div className="invalid-feedback">
                      Customer email is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_phone">Customer phone number*</label>
                  <PhoneInput
                    defaultCountry="NG"
                    className="form-control"
                    value={customer_phone}
                    onChange={setCustomerPhone}
                  />
                  {submitted && customer_phone && (
                    <div className="invalid-feedback">
                      Customer phone is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_gender ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_gender">Customer gender*</label>
                  <select
                    className="form-control"
                    name="customer_gender"
                    onChange={handleChange}
                  >
                    <option>Select option</option>
                    <option value="female">female</option>
                    <option value="male">male</option>
                  </select>
                  {submitted && customer_gender && (
                    <div className="invalid-feedback">
                      Customer gender is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_address">Customer address*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="customer_address"
                    onChange={handleChange}
                  />
                  {submitted && customer_address && (
                    <div className="invalid-feedback">
                      Customer address is required.
                    </div>
                  )}
                </div>

                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={myCompany.company_country}
                  state_name={myCompany.company_state}
                  city_name={myCompany.company_city}
                />

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_country">Customer country*</label>
                  <select
                    name="customer_country"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_country && (
                      <option>{myCompany.company_country}</option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>

                  {submitted && customer_country && (
                    <div className="invalid-feedback">
                      Customer country is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_state">Customer state </label>
                  <select
                    name="customer_state"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_state && (
                      <option>{myCompany.company_state}</option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && customer_state && (
                    <div className="invalid-feedback">
                      Customer state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && customer_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="customer_city">Customer city* </label>
                  <select
                    name="customer_city"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_city && (
                      <option>{myCompany.company_city}</option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && customer_city && (
                    <div className="invalid-feedback">
                      Customer city is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Create Customer"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default CreateCustomers;
