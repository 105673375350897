import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function EditRider({ riderData }) {
  const courier_id = riderData.id;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      courier_email: riderData.courier_email,
      courier_name: riderData.courier_name,
      courier_phone: riderData.courier_phone,
      courier_address: riderData.courier_address,
      courier_city: riderData.courier_city,
      courier_state: riderData.courier_state,
      courier_country: riderData.courier_country,
    };

    setData({ ...dataObject });
  }, [riderData]);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [submitted, setSubmitted] = useState(false);
  let [courier_phone, setCourierPhone] = useState("");
  const [data, setData] = useState({
    id: "",
    courier_name: "",
    courier_phone: "",
    courier_address: "",
    courier_city: "",
    courier_state: "",
    courier_country: "",
  });
  const {
    courier_name,
    courier_address,
    courier_city,
    courier_state,
    courier_country,
  } = data;
  const updating = useSelector((state) => state.users.updating);
  courier_phone = courier_phone?.substring(1);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // dispatch action to create data
    dispatch(
      userActions.editRider({ ...data, courier_phone, id: courier_id }, setOpen)
    );
    handleClose();
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn onClick={() => setOpen(true)} title="Edit Rider">
          <i className="nav-icon i-Pen-2" />
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>Edit Rider</MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && courier_name ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_name">Rider name*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.courier_name}
                    name="courier_name"
                    onChange={handleChange}
                  />
                  {submitted && courier_name && (
                    <div className="help-block">Rider name is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && courier_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_phone">Rider phone*</label>
                  <PhoneInput
                    defaultCountry="NG"
                    className="form-control"
                    value={data.courier_phone}
                    onChange={setCourierPhone}
                  />
                  {submitted && courier_phone && (
                    <div className="help-block">Rider phone is required</div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && courier_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_address">Rider address*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.courier_address}
                    name="courier_address"
                    onChange={handleChange}
                  />
                  {submitted && courier_address && (
                    <div className="help-block">Rider address is required.</div>
                  )}
                </div>

                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={data.courier_country}
                  state_name={data.courier_state}
                  city_name={data.courier_city}
                />

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && courier_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_country">Rider country*</label>
                  <select
                    name="courier_country"
                    selected={data.courier_country}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.courier_country && (
                      <option value={courier_country}>
                        {data.courier_country}
                      </option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && courier_country && (
                    <div className="help-block">Rider country is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && courier_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_state">Rider state </label>
                  <select
                    name="courier_state"
                    selected={data.courier_state}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.courier_state && (
                      <option value={data.courier_state}>
                        {data.courier_state}
                      </option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && courier_state && (
                    <div className="help-block">Rider state is required.</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && courier_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_city">Rider city*</label>
                  <select
                    className="form-control"
                    selected={data.courier_city}
                    name="courier_city"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.courier_city && (
                      <option value={data.courier_city}>
                        {data.courier_city}
                      </option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && courier_city && (
                    <div className="help-block">Rider city is required</div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {updating === true ? <Beat loading={updating} /> : "Edit Rider"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default EditRider;
