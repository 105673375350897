import React from 'react';
import {LOGOWHITE} from '../images/images';
import {FOODBG} from '../images/images';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/actions';
// import { Beat } from "../../redux/reducers/spinner";


export default function UserClearance() {
    
    const dispatch = useDispatch();


    function logout() {
        dispatch(userActions.logout());
    }

    return (
        
        <div className="">
            <div className="row">
                <div className="col-md-4">
                    <div style={{background: `url(${FOODBG})`, backgroundColor: '#BA2312', backgroundSize: 'cover', height: '100vh'}}>
                        <div className="auth-logo text-center mb-4"><img src={LOGOWHITE} alt="Logo" /></div>
                    </div>
                </div>
                <div className="col-md-7 offset-md-1">
                    <div className="p-4 auth-box">
                    <div className="auth-title">
                        <h1 className="mb-4 text-primary font-weight-bold">User Clearance</h1>
                        <h4 className="mb-4">Oops, it looks like your account has been deleted </h4>
                        </div>
                        
                        <button className="btn btn-rounded btn-primary btn-block mt-4" onClick={() => logout()}>Sign out</button>
                    </div>
                </div>
            </div>
        </div>
        

    );
}



