import React, { useState } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
// import {Rotate, Beat} from "../../../redux/reducers/spinner";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

function CreateThirdPartyToken() {
  let user = JSON.parse(localStorage.getItem("user"));
  const id = user.data.company_id;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([{ company_id: "", label: "" }]);
  const { label } = data;
  const creating = useSelector((state) => state.admin.creating);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (label) {
      // dispatch action to create data
      dispatch(
        adminActions.createThirdPartyToken({ ...data, company_id: id }, setOpen)
      );
      handleClose();
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setOpen(true)}>Create Third Party Token</MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name='form' onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Create Third Party Token
            </MDBModalHeader>
            <MDBModalBody>
              <div className='text-left row pt-4'>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && label ? " has-error" : "")
                  }>
                  <label htmlFor='label'>Label*</label>
                  <input
                    type='text'
                    className='form-control'
                    name='label'
                    onChange={handleChange}
                  />
                  {submitted && !label && (
                    <div className='help-block '>Label is required</div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit'>
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Create Third Party Token"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default CreateThirdPartyToken;
