import React, { useState } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import UnassignAnOrder from "./UnassignAnOrder";

function CloseOrder({ order_data, order_status }) {
  const order_id = order_data.id;
  const dispatch = useDispatch();

  let dataObject = {
    customer_name: order_data.customer_name,
    order_items: order_data.order_items,
    outlet_name: order_data.outlet_name,
  };

  const [open, setOpen] = useState(false);
  const [visible, setvisible] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const closing = useSelector((state) => state.admin.closing);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(adminActions.CloseOrder({ id: order_id }, setOpen)); //closeOrder
  };

  return (
    <div data-test="closeOrder">
      <MDBContainer>
        {order_status === "assigned" ? (
          <div>
            <MDBBtn onClick={() => setvisible(true)} title="Close Order">
              <i className="nav-icon i-Close-Window" />
            </MDBBtn>
            <p className="mt-1 ml-1">Close</p>
            <UnassignAnOrder
              id_holder={order_data?.courier_orders}
              actionType="Close"
              visible={visible}
              setvisible={setvisible}
              openModal={setOpen}
            />
          </div>
        ) : (
          <div>
            <MDBBtn onClick={() => setOpen(true)} title="Close Order">
              <i className="nav-icon i-Close-Window" />
            </MDBBtn>
            <p className="mt-1 ml-1">Close</p>
          </div>
        )}

        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>Close Order</MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4 px-3">
                <h4>This order would be unassigned and Closed.</h4>
                <br />
                <h5>
                  Would you like to delete the order made by{" "}
                  <b>{dataObject.customer_name}</b>{" "}
                </h5>
                ,
                <h5>
                  for{" "}
                  <b>
                    {dataObject.order_items && dataObject.order_items.length}
                  </b>{" "}
                  items from <b>{dataObject.outlet_name}</b>?
                </h5>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {closing === true ? <Beat loading={closing} /> : "Close order"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default CloseOrder;
