import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import AssignToRole from "./AssignToRole";
import MainApp from "../../layouts/MainApp";
import Pagination from "../../includes/Pagination";

export default function AssignAbility() {
  const allAbility = useSelector((state) => state.admin.allAbility);

  const dispatch = useDispatch();

  let params = useParams();

  useEffect(() => {
    dispatch(adminActions.getAllAbility(params.page));
  }, [dispatch, params]);

  let abilityData = allAbility.data;

  function createTableData() {
    let dataToMap = abilityData && abilityData.data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((ability, i) => {
        let singleRowArray = {
          ability: ability.name,
          actions: <AssignToRole id={ability.id} />,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Ablity",
        field: "ability",
        sort: "asc",
        width: 200,
      },

      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };

  return (
    <MainApp>
      <div className='main-content-padding'>
        <div className='main-content'>
          <div className='breadcrumb'>
            <h1 className='mr-2'>Assign Permission to roles</h1>
          </div>
          <div className='separator-breadcrumb border-top' />
          <div>
            <MDBDataTable
              responsive
              searching={false}
              striped
              bordered
              hover
              noBottomColumns
              data={data}
              paging={false}
            />
            <Pagination data={abilityData} route='settings/ability' />
          </div>

          {/* end of row*/}
          {/* end of main-content */}
        </div>
      </div>
    </MainApp>
  );
}
