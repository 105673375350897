import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, userActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function AssignAnOrder({ id }) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  const dispatch = useDispatch();
  const activeRiders = useSelector((state) => state.users.rider_active);

  const assigning = useSelector((state) => state.admin.assigning);

  useEffect(() => {
    dispatch(userActions.getActiveRider(company_id));
  }, [dispatch, company_id]);

  const [visible, setvisible] = useState(false);
  // const updating = useSelector(state => state.assets.updatingAsset);

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({ order_id: "", courier_id: "" });

  const { courier_id } = input;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };
  function assign() {
    let dataToMap = activeRiders;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((rider, i) => {
        let singleRowArray = [
          <option value={rider.id}>{rider.courier_name}</option>,
        ];
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let my_order_id = id;
    setSubmitted(true);
    if (courier_id) {
      dispatch(
        adminActions.assignOrder(
          { ...input, order_id: my_order_id },
          company_id,
          setvisible
        )
      );
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => setvisible(true)}>
          Assign Order
        </MDBBtn>

        <MDBModal isOpen={visible}>
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>
              Assign this order to an active rider
            </MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group" + (submitted && !courier_id ? " is-invalid" : "")
                }>
                <label htmlFor='courier_id'>Select Active Rider</label>
                <select
                  name='courier_id'
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !courier_id ? " is-invalid" : "")
                  }>
                  <option>Select active rider</option>
                  {assign()}
                </select>
                {submitted && !courier_id && (
                  <div className='invalid-feedback'>
                    You must select a rider
                  </div>
                )}
              </div>
              {/* <button className="btn btn-rounded btn-primary btn-block mt-4">Assign Order</button> */}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn
                color='primary'
                type='submit'
                disabled={assigning}
                className='assignBtn'>
                {assigning === true ? <Beat loading={true} /> : "Assign Order"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
