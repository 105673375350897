import React, { useEffect } from "react";
import MainApp from "./../layouts/MainApp";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../redux/actions";
import { history } from "../../redux/helpers";
import moment from "moment";
import PrimaryLoader from "../includes/Loaders/PrimaryLoader";
import { LocationTracker } from "./Riders/RidersFirebase";

export default function DashboardRider() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  if (company_id === "undefined" || "") {
    history("/auth/userClearance");
  }

  let last4months = new Date();
  last4months.setDate(last4months.getDate() - 100);

  const dispatch = useDispatch();

  //fetch the company data, retrieve it from the reducer and store in local storage
  useEffect(() => {
    dispatch(adminActions.getMyCourier());
  }, [dispatch]);
  const myCourier = useSelector((state) => state.admin.myCourier);

  const loading = useSelector((state) => state.admin.getCourierLoader);
  localStorage.setItem("myCourier", JSON.stringify(myCourier));

  var today = new Date();

  return (
    <MainApp>
      <LocationTracker />
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          <div className="main-content">
            <div className="breadcrumb">
              <h1 className="mr-2 text-capitalize">
                Welcome {user.data.name},
              </h1>
            </div>
            <div className="separator-breadcrumb border-top" />

            <div className="row">
              <div className="col-md-7">
                {myCourier && (
                  <div
                    className={
                      myCourier && myCourier.orders_pending > 0
                        ? "card mb-4 mt-4 order-gradient"
                        : "card mb-4 mt-4"
                    }
                  >
                    <div className="d-flex p-2 px-3 bg-white justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div
                          className="card p-1"
                          style={{
                            background: "#D14522",
                            width: "42px",
                            height: "42px",
                          }}
                        >
                          {myCourier && (
                            <h2 className="t-font-boldest m-0 text-center text-white">
                              {myCourier && myCourier.orders_pending}
                            </h2>
                          )}
                        </div>
                        <h5 className="heading ml-2">New Orders</h5>
                      </div>
                      <div>
                        <a href="courier/orders">
                          Manage Orders{" "}
                          <i className="i-Arrow-Right-in-Circle ml-1"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                <hr />

                <div className="mb-4 mt-4 d-flex justify-content-between">
                  <div>
                    <h3>Your Today's report</h3>
                    <p>(i.e: the last 24 hours)</p>
                  </div>
                  <p>{moment(today).format("ll")}</p>
                </div>

                <div className="row mb-4">
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-4 o-hidden">
                      <div className="ul-card__widget-chart">
                        <div className="">
                          {myCourier && (
                            <div className="ul-widget__chart-number">
                              <h2 className="t-font-boldest">
                                {myCourier && myCourier.orders_pending}
                              </h2>
                            </div>
                          )}
                          <h5 className="heading">Pending Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-4 o-hidden">
                      <div className="ul-card__widget-chart">
                        <div className="">
                          {myCourier && (
                            <div className="ul-widget__chart-number">
                              <h2 className="t-font-boldest">
                                {myCourier && myCourier.orders_canceled}
                              </h2>
                            </div>
                          )}
                          <h5 className="heading">Cancelled Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-4 o-hidden">
                      <div className="ul-card__widget-chart">
                        <div className="">
                          {myCourier && (
                            <div className="ul-widget__chart-number">
                              <h2 className="t-font-boldest">
                                {myCourier && myCourier.orders_completed}
                              </h2>
                            </div>
                          )}
                          <h5 className="heading">Completed Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-4 o-hidden">
                      <div className="ul-card__widget-chart">
                        <div className="ml-2">
                          {myCourier && (
                            <div className="ul-widget__chart-number">
                              <h2 className="t-font-boldest">
                                {myCourier && myCourier.orders_accepted}
                              </h2>
                            </div>
                          )}
                          <h5 className="heading">Accepted Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-4 o-hidden">
                      <div className="ul-card__widget-chart">
                        <div className="ml-2">
                          {myCourier && (
                            <div className="ul-widget__chart-number">
                              <h2 className="t-font-boldest">
                                {myCourier && myCourier.orders_rejected}
                              </h2>
                            </div>
                          )}
                          <h5 className="heading">Rejected Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="mb-4 o-hidden">
                      <div className="ul-card__widget-chart">
                        <div className="ml-2">
                          {myCourier && (
                            <div className="ul-widget__chart-number">
                              <h2 className="t-font-boldest">
                                {myCourier && myCourier.average_delivery_time}
                              </h2>
                            </div>
                          )}
                          <h5 className="heading">Average Delivery Time</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* end of row */}
            </div>
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
