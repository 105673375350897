import React, { useState, useEffect } from "react";
import AuthLayout from "../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { userActions, adminActions } from "../../redux/actions";
import { Beat } from "../../redux/reducers/spinner";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";

export default function RegisterUser() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminActions.getCountries());
  }, [dispatch]);

  const registering = useSelector((state) => state.registration.registering);

  // Used to toggle the password visibility
  function toggleFunction(e) {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }
  function toggleFunction2(e) {
    var x = document.getElementById("password2");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }

  // Form actions
  const [submitted] = useState(false);
  let [phone, setPhone] = useState("");
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    birth_date: "",
    birth_year: "",
    referral_code: "",
  });
  const {
    name,
    email,
    password,
    password_confirmation,
    birth_date,
    birth_year,
    referral_code,
  } = data;
  phone = phone?.substring(1);
  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (name && email && password && password_confirmation) {
      // get return url from location state or default to home page
      dispatch(userActions.registerUser(data, phone));
    }
  }
  return (
    <AuthLayout>
      <div className="auth-box">
        <div className="auth-title">
          <h1 className="mb-4 text-primary font-weight-bold">
            Get started with Ogwugo Delivery
          </h1>
          <h4 className="mb-4">Lets connect your business to the world </h4>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h3>Enter Personal details</h3>
          <div className="text-left row pt-4">
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && name ? " has-error" : "")
              }
            >
              <label htmlFor="name">Full name*</label>
              <input
                placeholder="Enter name"
                type="text"
                className="form-control"
                name="name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !name && (
                <div className="help-block">Name is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && phone ? " has-error" : "")
              }
            >
              <label htmlFor="phone">Phone*</label>
              <PhoneInput
                defaultCountry="NG"
                placeholder="Enter Phone number"
                className="form-control"
                value={phone}
                onChange={setPhone}
              />
              {submitted && !phone && (
                <div className="help-block">Phone is required</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && email ? " has-error" : "")
              }
            >
              <label htmlFor="email">Email*</label>
              <input
                placeholder="Enter Email"
                type="email"
                className="form-control"
                name="email"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !email && (
                <div className="help-block">Email is required</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && password ? " has-error" : "")
              }
            >
              <label htmlFor="password">Password*</label>
              <input
                placeholder="Minimum of six(6) characters"
                type="password"
                id="password"
                className="form-control"
                name="password"
                onChange={handleChange}
              />
              {submitted && !password && (
                <div className="help-block"> Password is required</div>
              )}
              <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction}
              ></i>
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && password_confirmation ? " has-error" : "")
              }
            >
              <label htmlFor="password_confirmation">Confirm Password*</label>
              <input
                placeholder="Minimum of six(6) characters"
                type="password"
                id="password2"
                className="form-control"
                name="password_confirmation"
                onChange={handleChange}
              />
              {submitted && !password_confirmation && (
                <div className="help-block">
                  Password confirmation is required
                </div>
              )}
              <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction2}
              ></i>
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                ((submitted && birth_date) || (submitted && birth_year)
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor="birth_date ">Date of birth:</label>

              <input
                type="date"
                placeholder="dd-mm-yyyy"
                className="form-control"
                selected={birth_date}
                onChange={(event) => {
                  setData({
                    ...data,
                    birth_date: moment(event.target.value).format("YYYY-MM-DD"),
                    birth_year: moment(event.target.value).format("YYYY"),
                    // birth_date &&
                    // new Date(birth_date).getFullYear().toString(),
                  });
                }}
              />
              {console.log("birthYear", birth_year)}
              {(submitted && !birth_date) ||
                (submitted && !birth_year && (
                  <div className="help-block">Date of birth is required</div>
                ))}
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && referral_code ? " has-error" : "")
              }
            >
              <label htmlFor="referral_code">Referral code</label>
              <input
                placeholder="Minimum of six(6) characters"
                type="referral_code"
                id="referral_code"
                className="form-control"
                name="referral_code"
                onChange={handleChange}
              />
            </div>
          </div>

          <button className="btn btn-rounded btn-primary btn-block mt-4">
            {registering === true ? (
              <Beat loading={registering} />
            ) : (
              "Register "
            )}
          </button>
        </form>
        <div className="mt-3 mb-4 text-center">
          <a className="text-muted" href="/auth/login">
            {" "}
            <u>Sign In</u>
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}
