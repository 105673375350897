import React from "react";
import { useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { DEFAULTORDER } from "../../images/images";
import AcceptAnOrder from "./AcceptAnOrder";
import RejectOrder from "./RejectOrder";
import CompleteOrder from "./CompleteOrder";

export default function ViewCourierOrders() {
  const singleCourierOrder = useSelector(
    (state) => state.admin.singleCourierOrder
  );

  function orderPrice(unit, price) {
    return unit * price;
  }

  const singleCourierOrderItem = singleCourierOrder?.order?.order_items;
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function createTableData() {
    let dataToMap = singleCourierOrderItem;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((item, i) => {
        let singleRowArray = {
          photo: (
            <img className="order_img" src={DEFAULTORDER} alt="order_image" />
          ),
          name: item.name,
          quantity: item.quantity,
          unit_price: <span>₦{numberWithCommas(item.price)}</span>,
          order_price: (
            <span>
              ₦{numberWithCommas(orderPrice(item.quantity, item.price))}
            </span>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Image",
        field: "photo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Quantity",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Unit Price",
        field: "unit_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Price",
        field: "order_price",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };

  return (
    <div className="main-content-padding">
      {singleCourierOrder && (
        <div className="text-left">
          <div className="mb-4 d-flex justify-content-between">
            <h3 className="text-primary">
              Order #{singleCourierOrder?.order?.id}
            </h3>

            {singleCourierOrder?.status === "pending" && (
              <div>
                {" "}
                <span className="badge  p-2 m-1">
                  <AcceptAnOrder id={singleCourierOrder?.id} />
                </span>
                <span className="badge  p-2 m-1">
                  <RejectOrder id={singleCourierOrder?.id} />
                </span>
              </div>
            )}
            {singleCourierOrder?.status === "completed" && (
              <div>
                {" "}
                <span className="badge badge-pill badge-outline-success p-2 m-1">
                  Delivered
                </span>{" "}
              </div>
            )}
            {singleCourierOrder?.status === "canceled" && (
              <div>
                {" "}
                <span className="badge badge-pill badge-outline-success p-2 m-1">
                  Canceled
                </span>{" "}
              </div>
            )}
            {singleCourierOrder?.status === "accepted" && (
              <div>
                {" "}
                <span className="badge  p-2 m-1">
                  <CompleteOrder id={singleCourierOrder?.id} />
                </span>
              </div>
            )}
          </div>

          <div className="accordion mb-3" id="accordionContact">
            <div className="card">
              <div className="card-header header-elements-inline">
                <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                  <a
                    className="text-default collapsed"
                    data-toggle="collapse"
                    href="#accordion-item-icon-right-1"
                    aria-expanded="false"
                  >
                    Customer Information
                  </a>
                </h3>
              </div>
              <div
                className="collapse"
                id="accordion-item-icon-right-1"
                data-parent="#accordionContact"
                style={{}}
              >
                <div className="order_contact">
                  <div className="d-flex">
                    <div className="mr-4">
                      <div className="alternative-image">
                        {singleCourierOrder &&
                          singleCourierOrder?.order?.customer_name &&
                          singleCourierOrder?.order?.customer_name.charAt(0)}
                      </div>
                    </div>
                    <div className="px-2 w-100">
                      <div className="row">
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Name:</h6>
                            <h5 className="text-capitalize">
                              {singleCourierOrder?.order?.customer_name}
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Phone:</h6>
                            <h5>{singleCourierOrder?.order?.customer_phone}</h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Email:</h6>
                            <h5>{singleCourierOrder?.order?.customer_email}</h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Address:</h6>
                            <h5>
                              {singleCourierOrder?.order?.customer_address}
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>City:</h6>
                            <h5>{singleCourierOrder?.order?.customer_city}</h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Gender:</h6>
                            <h5>
                              {singleCourierOrder?.order?.customer_gender}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mb-3" id="accordionStore">
            <div className="card">
              <div className="card-header header-elements-inline">
                <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                  <a
                    className="text-default collapsed"
                    data-toggle="collapse"
                    href="#accordion-item-icon-right-2"
                  >
                    Store Information
                  </a>
                </h3>
              </div>
              <div
                className="collapse"
                id="accordion-item-icon-right-2"
                data-parent="#accordionStore"
              >
                <div className="order_contact">
                  <div className="d-flex">
                    <div className="mr-4">
                      <div className="alternative-image">
                        {singleCourierOrder &&
                          singleCourierOrder?.order?.outlet_name &&
                          singleCourierOrder?.order?.outlet_name.charAt(0)}
                      </div>
                    </div>
                    <div className="px-2 w-100">
                      <div className="row">
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Store name:</h6>
                            <h5 className="text-capitalize">
                              {singleCourierOrder?.order?.outlet_name}
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <div className="customer-details">
                            <h6>Store phone:</h6>
                            <h5>{singleCourierOrder?.order?.outlet_phone}</h5>
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          {/* <div className="customer-details">
                                                    <h6>Email:</h6>
                                                    <h5>{singleCourierOrder.customer_email}</h5>
                                                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="order_contact mb-4">
            <div className="pt-4 mb-4">
              <div className="d-flex justify-content-between">
                <h3 className="mb-2">Order Details</h3>
                <div></div>
              </div>
            </div>

            <div className="d-flex mb-4">
              <div className="mr-4">
                <div className="customer-details">
                  <h6>Payment Status:</h6>
                  <h5 className="text-capitalize">
                    {singleCourierOrder?.order?.order_paid === true ? (
                      <span className="badge badge-pill badge-outline-success p-2">
                        Paid
                      </span>
                    ) : (
                      <span className="badge badge-pill badge-outline-danger p-2">
                        Not Paid
                      </span>
                    )}
                  </h5>
                </div>
              </div>
              <div className="">
                <div className="customer-details">
                  <h6>Payment Method:</h6>
                  <h5 className="text-capitalize">
                    {singleCourierOrder?.order?.order_payment_method &&
                      singleCourierOrder?.order?.order_payment_method.name}
                  </h5>
                </div>
              </div>
            </div>

            {singleCourierOrder && (
              <MDBDataTable
                responsive
                entriesOptions={[5, 10, 15]}
                striped
                borderless
                hover
                noBottomColumns
                displayEntries={false}
                searching={false}
                paging={false}
                data={data}
              />
            )}
            <div className="float-right text-right mt-3">
              <div className="d-flex">
                <h5 className="mr-2">Delivery fee:</h5>
                <h5 className="font-weight-bold">
                  ₦
                  {singleCourierOrder?.order?.order_delivery_fee &&
                    numberWithCommas(
                      singleCourierOrder.order.order_delivery_fee
                    )}
                </h5>
              </div>
              <div className="d-flex">
                <h5 className="mr-2">Discount:</h5>
                <h5 className="font-weight-bold">
                  ₦
                  {singleCourierOrder?.order?.order_discount &&
                    numberWithCommas(singleCourierOrder.order.order_discount)}
                </h5>
              </div>
              <hr className="my-2" />
              <h5>Total amount:</h5>
              <h3>
                ₦
                {singleCourierOrder &&
                  singleCourierOrder?.order?.order_amount &&
                  numberWithCommas(singleCourierOrder.order.order_amount)}
              </h3>
            </div>
            <div className="row mt-4">
              <div className="col-md-7">
                <div className="mb-3">
                  <label className="mb-0">Customer Comment:</label>
                  {singleCourierOrder?.order?.customer_comment ? (
                    <h4 className="mb-4">
                      {singleCourierOrder?.order?.customer_comment}
                    </h4>
                  ) : (
                    <h4 className="mb-4">No comments</h4>
                  )}
                </div>
                <div>
                  <label className="mb-0">Order Description:</label>
                  {singleCourierOrder?.order?.order_description ? (
                    <h4 className="mb-4">
                      {singleCourierOrder?.order?.order_description}
                    </h4>
                  ) : (
                    <h4 className="mb-4">No description</h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
