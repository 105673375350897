import React from "react";
//import { useSelector } from "react-redux";
import OrderLayout from "../../layouts/OrderLayout";
import OrdersList from "./OrdersList";
import ViewOrders from "./ViewOrders";
//import Primaryloader from "../../includes/Loaders/PrimaryLoader";

export default function Orders() {
  //const loading = useSelector((state) => state.admin.getOrdersLoader);

  return (
    <OrderLayout>
      <div className='main-content o-hidden' data-test='ordersComponent'>
        {/* ============ Body content start ============= */}
        <div className='row' style={{ background: "#f8f8f8" }}>
          <div className='col-md-4'>
            <OrdersList />
          </div>
          <div className='col-md-8'>
            <ViewOrders />
          </div>
        </div>
      </div>
    </OrderLayout>
  );
}
