import React from "react";
import { useDispatch } from "react-redux";
import { LOGOWHITE, PImage } from "../images/images";
import { FOODBG } from "../images/images";
import { userActions } from "../../redux/actions";

export default function PrivacyPolicy() {
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem("user"));
  function logout() {
    dispatch(userActions.logout());
  }
  return (
    <>
      <div
        className="main-header px-4"
        style={{
          backgroundImage: `url(${FOODBG})`,
          backgroundColor: "#ba2312",
        }}
      >
        <div className="logo">
          <a href="/">
            <img src={LOGOWHITE} alt="Logo" />
          </a>
        </div>

        <div style={{ margin: "auto" }} />
        <div className="header-part-right">
          {/* User avatar dropdown */}
          {user?.data ? (
            <div className="dropdown">
              <div className="user col align-self-end">
                <div className="d-flex">
                  <p className="text-white mb-1 mr-3 text-capitalize">
                    Hello {user?.data?.name}
                  </p>
                  <div className="availability-status">
                    <img
                      src={PImage}
                      alt="avatar"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="userDropdown"
                    >
                      <div className="dropdown-header">
                        <i className="i-Lock-User mr-1" /> {user?.data?.name}
                      </div>
                      <a className="dropdown-item" href="/user/profile">
                        My Profile
                      </a>

                      <button
                        className="dropdown-item"
                        onClick={() => logout()}
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <a
              href="/auth/login"
              className="btn btn-rounded btn-secondary btn-block"
            >
              Login
            </a>
          )}
        </div>
      </div>
      <div className="main-content-padding">
        <div className="main-content">
          {/* ============ Body content start ============= */}
          <div className="breadcrumb">
            <div>
              <h1 className="mr-2 mb-2">Privacy Policy</h1>
              <p className="mb-0">Last Updated on July, 2017</p>
            </div>
          </div>
          <div className="separator-breadcrumb border-top" />

          <div className="writeup-list-item">
            <p>
              Ogwugo.com is an electronic commerce platforms predominantly used
              by business entities to facilitate electronic commerce and such
              business use does not generally involve the collection of personal
              information of individuals. Ogwugo recognizes the importance of
              privacy as well as the importance of maintaining the
              confidentiality of personal information. This Privacy Policy
              applies to all products and services provided by us and sets out
              how we may collect, use and disclose information in relation to
              users of the Sites.
            </p>
            <p>
              The information you share with Ogwugo allows us to provide the
              products and services you need and want while giving you the very
              best shopping experience. Our founder reminded us that "a promise
              we make is a promise we keep," and it's our promise to customers
              that we respect the trust you place in us and the privacy of the
              information you share. Part of providing superior customer service
              includes making sure that we're building a relationship of trust
              with customers. Our way of doing that is to let you know in a
              clear, prominent, and easily accessible way how we collect, use,
              share, and above all protect your personal information. Our Policy
              outlines:
            </p>
            <ul>
              <li>How and why we collect your personal information.</li>
              <li>How your personal information is used and protected.</li>
              <li>
                When and with whom we share your personal information. and
              </li>
              <li>
                What choices you can make about how we collect, use, and share
                your personal information.{" "}
              </li>
            </ul>
            <p>
              You may use our services and products via a mobile device either
              through mobile applications or mobile optimized websites.
            </p>
            <p>
              This Privacy Policy also applies to such use of our services and
              products. All capitalized terms not defined in this document shall
              have the meanings ascribed to them in the Terms of Use of the
              Site, which can be found here.{" "}
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Collection of Information</h3>
            <ol>
              <li>
                Your privacy is important to us and we have taken steps to
                ensure that we do not collect more information from you than is
                necessary for us to provide you with our services and to protect
                your account.
              </li>
              <li>
                {" "}
                Information including, but not limited to, user name, address,
                phone number, fax number, email address, gender, date and/or
                year of birth and user preferences ("Registration Information")
                may be collected at the time of user registration on the Sites.
              </li>
              <li>
                {" "}
                In connection with any transaction and payment services or
                services under our buyer protection schemes we provide on the
                Sites, information, including but not limited to, bank account
                numbers, billing and delivery information, credit/debit card
                numbers and expiration dates and tracking information from
                cheques or money orders ("Account Information") may be collected
                to, among other things, facilitate the sale and purchase as well
                as the settlement of purchase price of the products or services
                transacted on or procured through the Sites.
              </li>
              <li>
                We record and retain details of users’ activities on the Sites.
                Information relating to such transactions including, but not
                limited to, the types and specifications of the goods, pricing
                and delivery information and any trade dispute records
                (“Activities Information”) may be collected when sale and
                purchase transactions are conducted on or facilitated through
                the Sites
              </li>
              <li>
                From time to time, we collect information about our users and
                prospective users during trade shows, industry events and other
                functions. The information we may collect at these locations may
                include, but is not limited to, user name, address, phone
                number, fax number and email address ("Event Information").
              </li>
              <li>
                We record and retain records of users' buying and browsing
                activities on our platform including but not limited to IP
                addresses, browsing patterns and buyer behavioral patterns. In
                addition, we gather statistical information about the Sites and
                visitors to the Sites including, but not limited to, IP
                addresses, browser software, operating system, software and
                hardware attributes, pages viewed, number of sessions and unique
                visitors (together "Browsing Information").
              </li>
              <li>
                Registration Information, Account Information, Activities
                Information, Event Information and Browsing Information
                generally relate to business entities and are together referred
                to as business data (“Business Data”).
              </li>
              <li>
                It is mandatory for users of the Sites to provide certain
                categories of Business Data and Personal Data (as specified at
                the time of collection). In the event that users do not provide
                any or sufficient Business Data and/or Personal Data marked as
                mandatory, we may not be able to complete the registration
                process or provide such users with our products or services.
              </li>
            </ol>
          </div>
          <div className="writeup-list-item">
            <h3>Use of Personal Data</h3>
            <p>
              If you provide any Personal Data to us, you are deemed to have
              authorized us to collect, retain and use that Personal Data for
              the following purposes:
            </p>
            <ol>
              <li>Verifying your identity.</li>
              <li>
                Verifying your eligibility to register as a user of the Sites.
              </li>
              <li>
                Processing your registration as a user, providing you with a
                log-in ID for the Sites and maintaining and managing your
                registration.
              </li>
              <li>
                Providing you with customer service and responding to your
                queries, feedback, claims or disputes.
              </li>
              <li>
                To facilitate communication between buyers and sellers on the
                Sites and processing your transactions on the Sites.
              </li>
              <li>
                {" "}
                Performing research or statistical analysis in order to improve
                the content and layout of the Sites, to improve our product
                offerings and services and for marketing and promotional
                purposes.
              </li>
              <li>
                Subject to obtaining your consent in such form as may be
                required under the applicable law, we (including our affiliated
                companies and their designated Service Providers (as defined in
                C.1 below)) may use your name, phone number, residential
                address, email address, fax number and other data ("Marketing
                Data") to provide notices, surveys, product alerts,
                communications and other marketing materials to you relating to
                goods and services offered by us on the Sites including but not
                limited to Gold Suppliers membership, Verified Members
                membership, Free Members membership (each of Gold Suppliers
                membership, Verified Members membership, and Free Members
                membership, a “Membership” and collectively, the “Memberships”)
                the value added services ancillary to the Memberships, and other
                products and services offered by us and/or our affiliated
                companies and their designated Service Providers from time to
                time to members of the Sites.
              </li>
              <li>
                If you voluntarily submit any information to the Sites for
                publication on the Sites through the publishing tools, including
                but not limited to, Company Profile, Product Catalog, Trade
                Leads, TrustPass Profile and any discussion forum, then you are
                deemed to have given consent to the publication of such
                information on the Sites ("Voluntary Information").
              </li>
              <li>
                Making such disclosures as may be required for any of the above
                purposes or as required by law, regulations and guidelines or in
                respect of any investigations, claims or potential claims
                brought on or against us.
              </li>
            </ol>
          </div>
          <div className="writeup-list-item">
            <h3>Disclosure of Personal Data</h3>
            <ol>
              <li>
                You further agree that we may disclose and transfer (whether
                within or outside the jurisdiction of the Ogwugo that you are
                contracting with) your Personal Data to service providers
                engaged by us and/or other affiliated companies of Ogwugo to
                assist us with providing you with our services (including but
                not limited to data entry, database management, promotions,
                products and services alerts, delivery services, payment
                extension services, membership authentication and verification
                services and logistics services) ("Service Providers").
                <br />
                These Service Providers are under a duty of confidentiality to
                us and are only permitted to use your Personal Data in
                connection with the purposes specified at B.1 to B.9 above, and
                not for their own purposes.
              </li>
              <li>
                You agree that we may disclose and transfer (whether within or
                outside the jurisdiction of Ogwugo that you are contracting
                with), for the purposes specified at B.1 to B.9 above, your
                Personal Data to other affiliated companies and/or their
                designated Service Providers of Ogwugo services, which comprises
                a group of companies operating leading online and mobile
                marketplaces in consumer and business-to-business commerce, as
                well as cloud computing and other services.{" "}
              </li>
              <li>
                When necessary we may also disclose and transfer (whether within
                or outside the jurisdiction of Ogwguo that you are contracting
                with) your Personal Data to our professional advisers, law
                enforcement agencies, insurers, government and regulatory and
                other organizations for the purposes specified at B.9 above.
              </li>
              <li>
                Any Personal Data supplied by you will be retained by us and
                will be accessible by our employees, any Service Providers
                engaged by us and third parties referred to at C.2 and C.3
                above, for or in relation to any of the purposes stated in B.1
                to B.9 above.
              </li>
              <li>
                All Voluntary Information may be made publicly available on the
                Sites and therefore accessible by any internet user. Any
                Voluntary Information that you disclose to us becomes public
                information and you relinquish any proprietary rights (including
                but not limited to the rights of confidentiality and copyright)
                in such information. You should exercise caution when deciding
                to include personal or proprietary information in the Voluntary
                Information that you submit to us.
              </li>
              <li>
                {" "}
                We may share your Account Information with banks or vendors to
                enable your transactions on the Sites to be completed. In
                addition, we may use your Account Information to determine your
                credit-worthiness and, in the process of such determination, we
                may need to make such Account Information available to banks or
                credit agencies. While we have in place up-to-date technology
                and internal procedures to keep your Account Information and
                other Personal Data secure from intruders, there is no guarantee
                that such technology or procedures can eliminate all of the
                risks of theft, loss or misuse.
              </li>
              <li>
                We may provide statistical information to third parties, but
                when we do so, we do not provide personally-identifying
                information without your permission;
              </li>
              <li>
                We have established relationships with other parties and
                websites to offer you the benefit of products and services which
                we do not offer. We offer you access to these other parties and
                their websites either through the use of hyperlinks to these
                sites from the Sites or through offering "co-branded" sites in
                which both we and other parties share the same uniform resource
                locator, domain name or pages within a domain name on the
                Internet.
              </li>
            </ol>
            <p>
              In some cases you may be required to submit personal information
              to register or apply for products or services provided by such
              third parties or co-branded partners. This Privacy Policy does not
              apply to these third party sites or co-branded sites. The privacy
              policies of those other parties may differ from ours, and we have
              no control over the information that you submit to those third
              parties. You should read the relevant privacy policy for those
              third party sites and co-branded sites before responding to any
              offers, products or services advertised by those parties
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Google User Data</h3>

            <div>
              <h4>How we collect User Information:</h4>
              <p>
                We collect information from users when they place orders,
                subscribe to a newsletter or enter information on our app.
              </p>
            </div>
            <div>
              <h4>How we make use of Information from Users:</h4>
              <p>
                We may use the information we collect from users when they
                register, make a purchase, sign up for our newsletter, respond
                to a survey or marketing communication, surf the website, or use
                certain other site features in the following ways:
              </p>
              <ul>
                <li>
                  To personalize your experience and to allow us to deliver the
                  type of content and product offerings in which you are most
                  interested.
                </li>
                <li>To improve our website in order to better serve users.</li>
                <li>
                  To administer a contest, promotion, survey or other site
                  feature.
                </li>
                <li>To quickly process User transactions.</li>
                <li>
                  o send periodic emails regarding order or other products and
                  services.
                </li>
                <li>
                  To send periodic emails regarding order or other products and
                  services.
                </li>
                <li>
                  To follow up with them after correspondence (live chat, email
                  or phone inquiries)
                </li>
              </ul>
            </div>
            <div>
              <h4>How we store and Protect User Information:</h4>
              <p>
                Our website is scanned on a regular basis for security holes and
                known vulnerabilities in order to make visits to our site as
                safe as possible. We use regular Malware Scanning. Your personal
                information is contained behind secured networks and is only
                accessible by a limited number of persons who have special
                access rights to such systems, and are required to keep the
                information confidential. In addition, all sensitive/credit
                information supplied by users are encrypted via Secure Socket
                Layer (SSL) technology. We implement a variety of security
                measures when a user places an order enters, submits, or
                accesses their information to maintain the safety of your
                personal information. All transactions are processed through a
                gateway provider and are not stored or processed on our servers.
              </p>
            </div>
            <div>
              <h4>How we disclose User Information:</h4>
              <p>
                User information can only be disclosed at the consent of the
                user. Information disclosed, such as location, email, phone
                number, and mail address will be used to process user
                transactions on our site.
              </p>
            </div>
          </div>
          <div className="writeup-list-item">
            <h3>Right to Access</h3>
            <p>Unable to return</p>
            <p>
              Under the applicable laws, you have the right of access to
              personal information held by us and to request correction of the
              information.
            </p>
            <p>
              If you have any questions regarding this Privacy Policy or if you
              wish to access or correct your Personal Data, you may send your
              request in writing to the following address:
            </p>
            <p>Legal Counsel, The Legal Department</p>
            <p>
              Ogwugo.com, BETHEL PLAZA, 36 GARDEN AVENUE, ENUGU, ENUGU STATE,
              NIGERIA.
            </p>
            <p>
              Ogwugo Advertising Co., Ltd., No. 16B, Paradise City Estate, GRA,
              Enugu, Nigeria.
            </p>
            <p>
              In accordance with the applicable laws, we reserve the right to
              charge you a reasonable fee for the processing of any data access
              or correction request.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Cookies</h3>
            <p>
              We use "cookies" to store specific information about you and track
              your visits to the Sites. It is not uncommon for websites to use
              cookies to enhance identification of their users.
            </p>
            <p>
              A "cookie" is a small amount of data that is sent to your browser
              and stored on your computer's hard drive. A cookie can be sent to
              your computer's hard drive only if you access the Sites using a
              computer. If you do not de-activate or erase the cookie, each time
              you use the same computer to access the Sites, our web servers
              will be notified of your visit to the Sites and in turn we may
              have knowledge of your visit and the pattern of your usage.
              Generally, we use cookies to identify you and enable us to
            </p>
            <ol type="i">
              <li>
                Access your Registration Information or Account Information so
                you do not have to re-enter it;
              </li>
              <li>gather statistical information about usage by users; </li>
              <li>
                research visiting patterns and help target advertisements based
                on user interests;
              </li>
              <li>
                assist our partners to track user visits to the Sites and
                process orders; and
              </li>
              <li>track progress and participation in promotions</li>
              <p>
                You can determine if and how a cookie will be accepted by
                configuring the browser which is installed in the computer you
                are using to access the Sites. If you choose, you can change
                those configurations. By setting your preferences in the
                browser, you can accept all cookies or you can choose to be
                notified when a cookie is sent or you can choose to reject all
                cookies. If you reject all cookies by choosing the
                cookie-disabling function in your browser, you may be required
                to re-enter information on the Sites more often and certain
                features of the Sites may be unavailable.
              </p>
            </ol>
          </div>
          <div className="writeup-list-item">
            <h3>Minors</h3>
            <p>
              The Sites and their contents are not targeted to minors (those
              under the age of 18) and we do not intend to sell any of our
              products or services to minors. However, we have no way of
              distinguishing the age of individuals who access our Site. If a
              minor has provided us with personal information without parental
              or guardian consent, the parent or guardian should contact our
              Legal Department at the address set out in paragraph D above to
              remove the information.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Security Measures</h3>
            <p>
              We employ commercially reasonable security methods to prevent
              unauthorized access to the Sites, to maintain data accuracy and to
              ensure the correct use of the information we hold.
            </p>
            <p>
              For registered users of the Sites, your Registration Information
              and Account Information (if any) can be viewed and edited through
              your account, which is protected by a password. We recommend that
              you do not divulge your password to anyone. Our personnel will
              never ask you for your password in an unsolicited phone call or in
              an unsolicited email. If you share a computer with others, you
              should not choose to save your log-in information (e.g., user ID
              and password) on that shared computer. Remember to sign out of
              your account and close your browser window when you have finished
              your session.
            </p>
            <p>
              No data transmission over the internet or any wireless network can
              be guaranteed to be perfectly secure. As a result, while we try to
              protect the information we hold for you, we cannot guarantee the
              security of any information you transmit to us and you do so at
              your own risk.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Changes to Privacy Policy</h3>
            <p>
              Any changes to this Privacy Policy will be communicated by us
              posting an amended and restated Privacy Policy on the Sites. Once
              posted on the Sites the new Privacy Policy will be effective
              immediately. You agree that any information we hold about you (as
              described in this Privacy Policy and whether or not collected
              prior to or after the new Privacy Policy became effective) will be
              governed by the latest version of the Privacy Policy.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Your Feedback</h3>
            <p>
              We welcome your input regarding our Privacy Policy and any
              comments on the services we provide to you. You may send us your
              comments and responses by post to: The Legal Department,
              Ogwugo.com 16B Paradise city estate GRA , Enugu, Nigeria.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Protecting your Password</h3>
            <p>Stay safe online by protecting your account password.</p>
            <p>
              Whether you're on or offline, care for your password as you would
              care for the key to your house. If your password falls into the
              wrong hands, your identity may be stolen and someone else may
              access your account. You can take a few simple steps to help
              ensure that your password is protected:
            </p>
            <p>
              Make sure to create a different password for each online account
              you hold.
            </p>
            <p>
              Create a more secure password for your Ogwugo account by using a
              combination of letters and numbers.
            </p>
            <p>
              Change your Ogwugo password often to enhance account security.
            </p>
            <p>Don't share your password with anyone.</p>
            <p>
              If you're signing in to your Ogwugo account, only enter your
              password at a URL that begins with "https://Ogwugo.com/"
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Virus Prevention</h3>
            <p>
              Stay safe online by safeguarding your computer against viruses.
            </p>
            <p>
              There are numerous viruses in circulation that threaten to damage
              your computer or spy on your Internet activities. While there is
              no foolproof way to protect your computer, you can minimize your
              risk by installing virus protection software. Take full advantage
              of virus protection by:
            </p>
            <ul>
              <li>Keeping the virus protection software up-to-date</li>
              <li>
                Set the virus protection software to install updates on a
                regular basis.
              </li>
              <li>
                Use the virus protection software to scan your computer
                frequently.
              </li>
            </ul>
          </div>
          <div className="writeup-list-item">
            <h3>Spoof Protection</h3>
            <p>Prevent Spoof Emails and Websites.</p>
            <p>
              Claiming to be sent by a well-known company, spoof email directs
              users to Web sites asking for personal information such as a
              credit card number, Social Security number, or account password.
            </p>
            <p>
              Because it's so difficult to tell when an email or Web site is a
              spoof, Ogwugo recommends that you:
            </p>
            <p>
              Never enter sensitive personal information (such as your password
              or credit card, bank account, and Social Security numbers) in an
              email. If you've upgraded to a browser with the latest
              anti-phishing capabilities, the Web address bar may help you to
              identify secure Web pages. Look for a green Web address bar to
              confirm that you are on a secure page before entering sensitive
              information.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Before You Buy</h3>
            <p>
              Buy with confidence by reading the item description before placing
              purchasing.
            </p>
            <p>
              Before you purchase, make sure you’re familiar with the seller’s
              shipping, warranty, and return policy. Some stores will offer full
              refunds, while others charge restocking fees and give only store
              credit. On Ogwugo.com, it is important to know about the item
              you're interested in. Always read the item description carefully
              before purchasing. The item description can help you make an
              informed decision and avoid surprises. It is always a good idea to
              look at the details of a specific item, as well as the payment
              methods accepted.
            </p>
            <p>
              Visit our{" "}
              <a
                href="https://ogwugo.com/making-payments.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Making Payments
              </a>{" "}
              page to view the accepted payment methods.
            </p>

            <h4>Protect Your Account</h4>
            <p>
              Avoid logging into accounts that contain sensitive financial
              information (e.g. bank or credit card accounts or commerce
              websites) on public or shared computers. If you do access such
              information on a public or shared computer, remember to sign out
              completely and close your browser window after you're done.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Help Desk</h3>
            <p>
              Endeavour to contact the{" "}
              <a
                href="https://ogwugo.com/customer-service.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Customer service
              </a>{" "}
              when faced with confusing challenges, to avoid making unwarranted
              mistakes such as:
            </p>
            <ul>
              <li>Purchasing the wrong item,</li>
              <li>making payments to a wrong account</li>
              <li>Inputting a wrong location, etc.</li>
              <li>
                The Customer Care is always at your disposal. Feel free to
                contact.
              </li>
            </ul>
          </div>
          <div className="writeup-list-item">
            <h3>Payment Method</h3>
            <p>Use a payment method with buyer protections:</p>
            <p>
              In many cases, credit card companies limit your liability for
              online purchases in cases of fraud. Some online payment systems do
              not share your full credit card number with sellers in order to
              give you extra protections. Ogwugo's Payment Methods
            </p>
            <p>
              For Ogwugo transactions, you should use Ogwugo's only approved{" "}
              <a
                href="https://ogwugo.com/making-payments.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                payment methods
              </a>
              Always ensure your Credit/Debit card details are kept private to
              avoid being a victim of fraudulent attacks
            </p>
            <p>
              Never give out you Ogwugo account details to untrusted
              individuals. Your account was built for your privacy.
            </p>
          </div>
          <div className="writeup-list-item">
            <h3>Browser Security</h3>
            <p>Only send information over secure connections:</p>
            <p>
              Look for the "https://" connection in the address bar (and the
              padlock icon in your address bar if you’re using Google Chrome or
              Internet Explorer) when transmitting any sensitive information
              like credit card or bank numbers. When accessing financial
              accounts, check that the website has an Extended Validation
              Certificate — the URL or website name should show up as green in
              the URL bar of many modern browsers, meaning the organization that
              operates the website has been validated. Ogwugo.com is always at
              your disposal to provide a secure means of transacting online.
            </p>
            <p>
              For Ogwugo transactions, you should use Ogwugo's only approved
              payment methods Typing sensitive web addresses into your browser's
              address bar: Don’t navigate to sensitive accounts by clicking a
              link or copying and pasting the address. Instead, type out the web
              address yourself. But make sure that you’re inputting the correct
              address; some typosquatting sites look exactly like the real site,
              but are set up to phish your account information.
            </p>
            <p>
              Avoid entering personal information on suspicious sites. If a site
              is asking for personal information beyond what is required to
              purchase a product or receive a service (e.g., bank account
              information, security question answers, or passwords) be
              suspicious as these types of inquiries may be indicative of a
              phishing attempt. Some sites may be a carbon copy of the official
              site, including logos and text, but are set up by fraudsters with
              the sole purpose of obtaining your personal information.
            </p>
            <p>
              Avoid hacked sites and keep an eye on the browser’s address bar.
              If you click on a link and get instantly redirected, that site may
              have been hacked and contain malware. Malware, such as viruses,
              worms, and Trojan horses, can silently install unwanted software
              on your computer. Some hacked sites won’t automatically redirect
              you to a different page, but may contain irrelevant and spammy
              content on the page. Keeping an eye on the address bar to ensure
              the link you click on is the one you are delivered to is one way
              to be vigilant.
            </p>
            <p></p>
          </div>
          <div className="writeup-list-item">
            <h3>After Shopping</h3>
            <h4>Keep a record of the transaction:</h4>
            <p>
              Having a digital or paper copy of large transactions can help you
              if you do need to make a return or contest unauthorized charges
              made to your account.
            </p>
            <h4>Verify Your Purchase</h4>
            <p>
              Always verify every purchase you make once you have recieved the
              item. This will aid us have a proper documentation of your
              account's progress.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
