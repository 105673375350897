import React, { useState } from 'react';
import { useDispatch} from 'react-redux';
import { adminActions} from '../../../redux/actions';
// import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

export default function SetRole({ order_id }) {

    const dispatch = useDispatch();

    // const [visible, setvisible] = useState(false);
    // const updating = useSelector(state => state.assets.updatingAsset);

    const [submitted, setSubmitted] = useState(false);
    const [input, setInput] = useState({ id: '1', remitted_amount: '', employee_comment: '' });

    const { remitted_amount, employee_comment } = input;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInput(input => ({ ...input, [name]: value }));
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (remitted_amount) {
            dispatch(adminActions.remitOrder(input));
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h3>Remit this order!</h3>
                <div className={'form-group' + (submitted && !remitted_amount ? ' is-invalid' : '')}>
                    <label htmlFor="remitted_amount">Remitted Amount</label>
                    <input type="number" name="remitted_amount" onChange={handleChange} className={'form-control form-control-rounded' + (submitted && !remitted_amount ? ' is-invalid' : '')} />
                    {submitted && !remitted_amount &&
                        <div className="invalid-feedback">You must select a rider</div>
                    }
                </div>
                <div className={'form-group' + (submitted && !employee_comment ? ' is-invalid' : '')}>
                    <label htmlFor="employee_comment">Employee Comment</label>
                    <input type="text" name="employee_comment" onChange={handleChange} className={'form-control form-control-rounded' + (submitted && !employee_comment ? ' is-invalid' : '')} />
                </div>
                <button color="primary" type="submit">Remit Order</button>
            </form>
        </div>
    )
}