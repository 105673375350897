import React, { useEffect, useState } from "react";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { useSelector } from "react-redux";
import RemitAction from "./RemitAction";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import TableLoader from "../../includes/Loaders/TableLoader";
import moment from "moment";
import OrdersSummary from "../../includes/OrdersSummary";
import { FilterAll } from "../../filter/FilterAll";
import { writeFileWithXLSX } from "../../exports/xlsx";

export default function Remittance() {
  //Filtering by Date
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  const [start_date, setStartDate] = useState(lastWeek);
  const [end_date, setEndDate] = useState(new Date());

  const [dateInput, setDateInput] = useState({});
  const remittedOrders = useSelector((state) => state.admin.remittedOrders);
  const remittedNoPageOrders = useSelector(
    (state) => state.admin.remittedNoPageOrders
  );

  const loading = useSelector((state) => state.admin.getRemittanceLoader);

  useEffect(() => {
    setDateInput({ start_date: start_date, end_date: end_date });
  }, [start_date, end_date]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let remittedOrderList = remittedOrders?.data;
  const [dataToMap, setDataToMap] = useState([]);
  const [nonPaginatedDataToMap, setNonPaginatedDataToMap] = useState([]);

  useEffect(() => {
    setNonPaginatedDataToMap(remittedNoPageOrders && remittedNoPageOrders.data);
  }, [remittedNoPageOrders]);

  useEffect(() => {
    setDataToMap(remittedOrderList && remittedOrderList.data);
  }, [remittedOrderList]);

  const filterRemittedOrders = () => {
    let completed = [];
    dataToMap.length > 0 &&
      dataToMap.forEach((item) => {
        if (item.status === "completed") {
          completed.push(item);
        }
      });
    // return completed;
    return setDataToMap(completed);
  };

  function createTableData() {
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, i) => {
        let orderAmount =
          order?.order?.order_amount -
          order?.order?.order_delivery_fee -
          order?.order?.order_service_fee +
          order?.order?.order_discount;
        let totalAmount =
          orderAmount +
          order?.order?.order_insurance_fee +
          order?.order?.order_delivery_fee +
          order?.order?.order_service_fee -
          order?.order?.order_discount;
        let singleRowArray = {
          order_invoice_number: order?.order?.id,
          created_at: moment(order.created_at).format("l"),
          order_amount: <span>₦{numberWithCommas(orderAmount)}</span>,
          discount: (
            <span>₦{numberWithCommas(order?.order?.order_discount)}</span>
          ),
          insurance: (
            <span>₦{numberWithCommas(order?.order?.order_insurance_fee)}</span>
          ),
          deliveryFee: (
            <span>₦{numberWithCommas(order?.order?.order_delivery_fee)}</span>
          ),
          service: (
            <span>₦{numberWithCommas(order?.order?.order_service_fee)}</span>
          ),
          total_amount: <span>₦{numberWithCommas(totalAmount)}</span>,
          amount: <span>₦{numberWithCommas(order.remitted_amount)}</span>,
          rider: (
            <span className="text-capitalize">
              {order?.courier?.courier_name}
            </span>
          ),
          status:
            order.status === "completed" ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Completed
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Canceled
              </span>
            ),
          remitted:
            order.remitted === true ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                True
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                False
              </span>
            ),

          employee: (
            <span className="text-capitalize">
              {order.employee ? order.employee.employee_name : null}
            </span>
          ),
          actions: (
            <div className="d-flex">
              {order.remitted === false ? (
                <RemitAction order_id={order.id} id={order.order_id} />
              ) : (
                <span className="text-success">Remitted</span>
              )}
              <OrdersSummary
                employee_comment={order.employee_comment}
                courier_comment={order.courier_comment}
                order_data={order}
              />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  function createNoPaginatedData() {
    if (Array.isArray(nonPaginatedDataToMap)) {
      let outputArray = [];
      nonPaginatedDataToMap.map((order, i) => {
        let orderAmount =
          order?.order?.order_amount -
          order?.order?.order_delivery_fee -
          order?.order?.order_service_fee +
          order?.order?.order_discount;
        let totalAmount =
          orderAmount +
          order?.order?.order_insurance_fee +
          order?.order?.order_delivery_fee +
          order?.order?.order_service_fee -
          order?.order?.order_discount;
        let singleRowArray = {
          order_invoice_number: order?.order?.id,
          created_at: moment(order.created_at).format("l"),
          order_amount: orderAmount,
          discount: order?.order?.order_discount,
          insurance: order?.order?.order_insurance_fee,
          deliveryFee: order?.order?.order_delivery_fee,

          service: order?.order?.order_service_fee,
          total_amount: totalAmount,
          amount: order.remitted_amount,
          rider: order?.courier?.courier_name,

          status: order.status === "completed" ? "Completed" : "Canceled",
          remitted: order.remitted === true ? "True" : "False",

          employee: order.employee ? order.employee.employee_name : null,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "INVOICE",
        field: "order_invoice_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "CREATED",
        field: "created_at",
        sort: "asc",
        width: 150,
      },

      {
        label: "STATUS",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "RIDER",
        field: "rider",
        sort: "asc",
        width: 200,
      },
      {
        label: "IS REMITTED",
        field: "remitted",
        sort: "asc",
        width: 100,
      },
      {
        label: "ORDER AMOUNT",
        field: "order_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "DELIVERY FEE",
        field: "deliveryFee",
        sort: "asc",
        width: 100,
      },
      {
        label: "INSURANCE FEE",
        field: "insurance",
        sort: "asc",
        width: 100,
      },
      {
        label: "SERVICE FEE",
        field: "service",
        sort: "asc",
        width: 100,
      },
      {
        label: "DISCOUNT",
        field: "discount",
        sort: "asc",
        width: 100,
      },
      {
        label: "TOTAL AMOUNT",
        field: "total_amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "REMIT AMOUNT",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "EMPLOYEE",
        field: "employee",
        sort: "asc",
        width: 150,
      },
      {
        label: "ACTIONS",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: dataSet,
  };
  const downloadCSV = () => {
    if (remittedNoPageOrders) {
      const newData = createNoPaginatedData();
      writeFileWithXLSX(newData, {
        filename: "remittance.xlsx",
        download: true,
      });
    }
  };
  return (
    <MainApp>
      {loading & dataSet ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          {/* ============ Body content start ============= */}
          <div className="main-content">
            <div className="row align-items-center mb-4">
              <div className="col-md-6">
                <div className="breadcrumb">
                  <h1> Remittance</h1>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end ">
                <div className="ver-btn">
                  <MDBBtn
                    color="primary"
                    className="btn mr-2 mt-2"
                    onClick={() => downloadCSV()}
                  >
                    Download CSV
                  </MDBBtn>
                </div>
              </div>
            </div>

            <FilterAll
              date={dateInput}
              filter={filterRemittedOrders}
              start_date={start_date}
              setStartDate={setStartDate}
              end_date={end_date}
              setEndDate={setEndDate}
            />
            <h6>*Displays from the Most recent to the oldest</h6>

            {loading ? (
              <TableLoader />
            ) : (
              <MDBDataTable
                id="page"
                responsive
                // scrollY
                theadColor="MDBTableHeader"
                hover
                bordered
                maxHeight="400px"
                noBottomColumns
                data={data}
                paging={false}
                sortable={false}
                searching={false}
              />
            )}

            <Pagination data={remittedOrderList} route="remittance" />
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
