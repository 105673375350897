import React from "react";
import UserMainApp from "../../layouts/UserMainApp";
import { AVATAR } from "../../images/images";
import { useSelector } from "react-redux";

import CourierMapWrapper from "../Riders/CourierMapWrapper";

export default function TrackView() {
  const secondPartyOrder = useSelector(
    (state) => state.users.secondPartyOrder.data
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const SecondPartyRider =
    secondPartyOrder?.courier_orders[
      secondPartyOrder.courier_orders?.length - 1
    ]?.courier;

  return (
    <UserMainApp>
      <div className="main-content">
        {/* ============ Body content start ============= */}

        <div className="row">
          <div className="col-md-8 p-0">
            <CourierMapWrapper rider={SecondPartyRider} />
          </div>
          <div className="col-md-4 p-0">
            <div className="user-profile p-4">
              <a href="/user/trackOrder">Back</a>
              <div className="user-info mb-4">
                <img
                  className="profile-picture avatar-lg mb-2"
                  src={AVATAR}
                  alt=""
                />
                <p className="m-0 text-24 text-capitalize">
                  {SecondPartyRider?.courier_name}
                </p>

                <p className="text-muted m-0">
                  {SecondPartyRider && (
                    <span>{secondPartyOrder?.company_name}</span>
                  )}{" "}
                  Courier Details
                </p>
              </div>
              {SecondPartyRider && (
                <div className="user-info-card">
                  <ul>
                    <li>
                      <span>Name</span>{" "}
                      <span>{SecondPartyRider?.courier_name}</span>
                    </li>
                    <li>
                      <span>Phone</span>{" "}
                      <span>{SecondPartyRider?.courier_phone}</span>
                    </li>
                  </ul>
                </div>
              )}
              <p
                className="  text-muted mt-5 mb-4"
                style={{ marginLeft: "145px" }}
              >
                {SecondPartyRider && (
                  <span>{secondPartyOrder?.company_name}</span>
                )}{" "}
                Order Details
              </p>
              {SecondPartyRider && (
                <div className="user-info-card">
                  <ul>
                    <li>
                      <span>Order Id: </span>{" "}
                      <span> {secondPartyOrder.id} </span>
                    </li>
                    <li>
                      <span>Status</span> <span>{secondPartyOrder.status}</span>
                    </li>
                    <li>
                      <span>Order Amount</span>{" "}
                      <span>
                        {" "}
                        ₦
                        {secondPartyOrder &&
                          secondPartyOrder?.order_amount &&
                          numberWithCommas(secondPartyOrder?.order_amount)}
                      </span>
                    </li>
                    <li>
                      <span>Payment Status</span>{" "}
                      {secondPartyOrder.order_paid === "true" ? (
                        <span className="badge badge-pill badge-outline-success p-2 m-1">
                          Paid
                        </span>
                      ) : (
                        <span className="badge badge-pill badge-outline-danger p-2 m-1">
                          Not paid
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              )}
              <p
                className="  text-muted mt-5 mb-4"
                style={{ marginLeft: "145px" }}
              >
                {secondPartyOrder && (
                  <span>{secondPartyOrder?.company_name}</span>
                )}{" "}
                Customer Details
              </p>
              {secondPartyOrder && (
                <div className="user-info-card">
                  <ul>
                    <li>
                      <span>Customer Phone: </span>{" "}
                      <span> {secondPartyOrder.customer_phone}</span>
                    </li>
                    <li>
                      <span>Customer City</span>{" "}
                      <span>{secondPartyOrder.customer_city}</span>
                    </li>

                    <li>
                      <span>Customer Address</span>
                      <span>{secondPartyOrder.customer_address}</span>
                    </li>
                  </ul>
                </div>
              )}
              {secondPartyOrder?.status === "unassigned" && (
                <h5 className="text-primary mt-5">
                  *Order has not been assigned to a courier yet*
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </UserMainApp>
  );
}
