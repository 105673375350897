import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OrdersSummary from "../../includes/OrdersSummary";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { adminActions, userActions } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../includes/Pagination";
import moment from "moment";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import TableLoader from "../../includes/Loaders/TableLoader";
import { Pulse } from "../../../redux/reducers/spinner";
import { writeFileWithXLSX } from "../../exports/xlsx";

export default function OrdersReport() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  let lastmonths = new Date();
  lastmonths.setDate(lastmonths.getDate() - 20);
  const [start_date, setStartDate] = useState(lastmonths);
  const [end_date, setEndDate] = useState(new Date());
  let params = useParams();
  const dispatch = useDispatch();

  //fetch the company data, retrieve it from the reducer and store in local storage
  useEffect(() => {
    dispatch(adminActions.getMyCompany());
  }, [dispatch]);
  const myCompany = useSelector((state) => state.admin.myCompany);
  const loading = useSelector((state) => state.admin.getCompanyLoader);
  const loadingCompStats = useSelector((state) => state.admin.loadingCompStats);
  const loadingOrder = useSelector((state) => state.admin.getOrderByDateLoader);
  localStorage.setItem("myCompany", JSON.stringify(myCompany));

  useEffect(() => {
    dispatch(adminActions.getCompanyStats(company_id, start_date, end_date));
    dispatch(adminActions.getGeneralStats(company_id, end_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company_id]);

  const generalStats = useSelector((state) => state.admin.generalStats);
  const companyStats = useSelector((state) => state.admin.companyStats);

  useEffect(() => {
    dispatch(userActions.getRider(company_id));
    dispatch(adminActions.getEmployees(company_id));
  }, [dispatch, company_id]);

  const allOrdersByDate = useSelector((state) => state.admin.allOrdersByDate);
  const allOrdersByDatePagination = useSelector(
    (state) => state.admin.allOrdersByDatePagination
  );

  useEffect(() => {
    dispatch(
      adminActions.getAllOrdersByDateNoPagination(
        company_id,
        start_date,
        end_date
      )
    );
    // eslint-disable-next-line
  }, [company_id, start_date, end_date]);

  useEffect(() => {
    dispatch(
      adminActions.getAllOrdersByDate(
        company_id,
        start_date,
        end_date,
        params.page
      )
    );
    // eslint-disable-next-line
  }, [dispatch, company_id, params]);

  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    dispatch(
      adminActions.getAllOrdersByDate(
        company_id,
        start_date,
        end_date,
        params.page
      )
    );
  };
  const handleClick = () => {
    dispatch(
      adminActions.getAllOrdersByDate(
        company_id,
        start_date,
        end_date,
        params.page
      )
    );
    dispatch(adminActions.getCompanyStats(company_id, start_date, end_date));
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let allOrdersData = allOrdersByDate.data;

  function createTableData(props) {
    let dataToMap = props;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((orders, i) => {
        let singleRowArray = {
          created_at: moment(orders.created_at).format("l"),
          invoice: orders.id,
          customer_name: (
            <span className="text-capitalize">{orders.customer_name}</span>
          ),
          outlet_name: (
            <span className="text-capitalize">{orders.outlet_name}</span>
          ),
          price: <span>₦{numberWithCommas(orders.order_amount)}</span>,

          payment:
            orders.order_paid === true ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                True
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                False
              </span>
            ),

          status:
            orders.status === "delivered" ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Delivered
              </span>
            ) : orders.status === "assigned" ? (
              <span className="badge badge-pill badge-outline-warning p-2 m-1">
                Assigned
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Unassigned
              </span>
            ),
          source:
            orders.third_party_order === false &&
            orders.second_party_order === false ? (
              <span className="badge badge-pill badge-outline-success p-2 mb-2">
                DashBoard Side Order
              </span>
            ) : orders.third_party_order === true ? (
              <span className="badge badge-pill badge-outline-success p-2 mb-2">
                Food App Order
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-success p-2 mb-2">
                User Side Order
              </span>
            ),

          actions: <OrdersSummary order_data={orders} fromOrders />,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  function createNoPaginatedData(props) {
    let dataToMap = props;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((orders, i) => {
        let singleRowArray = {
          created_at: moment(orders.created_at).format("l"),
          invoice: orders.id,
          customer_name: orders.customer_name,
          outlet_name: orders.outlet_name,

          price: orders.order_amount,

          payment: orders.order_paid,

          status: orders.status,
          source:
            orders.third_party_order === false &&
            orders.second_party_order === false
              ? "DashBoard Side Order"
              : orders.third_party_order === true
              ? "  Food App Order"
              : "user Side Order",
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData(allOrdersData && allOrdersData.data);

  const data = {
    columns: [
      {
        label: "Created At",
        field: "created_at",
        sort: "asc",
        width: 150,
      },
      {
        label: "Invoice",
        field: "invoice",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Outlet Name",
        field: "outlet_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 100,
      },
      {
        label: "Paid",
        field: "payment",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: dataSet,
  };

  const downloadCSV = () => {
    dispatch(
      adminActions.getAllOrdersByDateNoPagination(
        company_id,
        start_date,
        end_date
      )
    );
    if (allOrdersByDatePagination) {
      const newData = createNoPaginatedData(allOrdersByDatePagination.data);
      writeFileWithXLSX(newData, {
        filename: "orders-report.xlsx",
        download: true,
      });
    }
  };
  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          {/* ============ Body content start ============= */}
          <div className="main-content">
            <div
              className="d-flex flex-wrap  justify-content-between 
               align-items-center mb-4"
            >
              <div className="">
                <div className="breadcrumb">
                  <h1>Orders Report</h1>
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <div className="mx-2 text-start text-md-center ">
                  {generalStats && (
                    <div className="ul-widget__chart-number">
                      <h4 className="t-font-boldest mb-0">
                        {generalStats.stats &&
                          generalStats.stats.average_delivery_time}
                      </h4>
                    </div>
                  )}
                  <h5 className="mb-0" style={{ fontSize: "13px" }}>
                    Average Delivery Time
                  </h5>
                </div>
                <div className="mx-2 text-start text-md-center ">
                  {companyStats && (
                    <div className="ul-widget__chart-number">
                      <h4 className="t-font-boldest mb-0">
                        {companyStats.stats && (
                          <span>
                            ₦
                            {numberWithCommas(
                              companyStats.stats.total_cost_price
                            )}
                          </span>
                        )}
                      </h4>
                    </div>
                  )}
                  <h5 className="mb-0" style={{ fontSize: "13px" }}>
                    Total Revenue
                  </h5>
                </div>
                <div className="fil-ord">
                  <MDBBtn
                    color="primary"
                    className="btn mr-2 mt-2"
                    onClick={() => downloadCSV()}
                  >
                    Download CSV
                  </MDBBtn>
                  {/* <ExportExcel /> */}
                </div>
              </div>
            </div>

            <div className="d-flex  mb-4 stats-report ">
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.total_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Total Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.days_worked
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Total Days Worked
                </h5>
              </div>{" "}
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats &&
                        companyStats.stats.delivered_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Delivered Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.closed_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Closed Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.assigned_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Assigned Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats &&
                        companyStats.stats.unassigned_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Unnassigned Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats &&
                        companyStats.stats.third_party_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Mobile App Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.pending_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Pending Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.rejected_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Rejected Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats &&
                        companyStats.stats.completed_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Completed Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats && companyStats.stats.canceled_orders
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Canceled Orders
                </h5>
              </div>
              <div className="mx-2 text-start text-md-center ">
                {companyStats && (
                  <div className="ul-widget__chart-number">
                    <h4 className="t-font-boldest mb-0">
                      {loadingCompStats ? (
                        <Pulse />
                      ) : (
                        companyStats.stats &&
                        companyStats.stats.total_remittance_points
                      )}
                    </h4>
                  </div>
                )}
                <h5 className="mb-0" style={{ fontSize: "13px" }}>
                  Total remittance points
                </h5>
              </div>
            </div>
            <div className="date-picker-wrapper" style={{ height: "100px" }}>
              <form onSubmit={handleSubmit}>
                <div className="d-flex">
                  <div
                    className={
                      "form-group col-md-4" +
                      (submitted && !start_date ? " is-invalid" : "")
                    }
                  >
                    <label className="text-truncate" htmlFor="start_date">
                      Start date
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={start_date}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div
                    className={
                      "form-group col-md-4" +
                      (submitted && !end_date ? " is-invalid" : "")
                    }
                  >
                    <label className="text-truncate" htmlFor="date">
                      End date
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={end_date}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>

                  <div className=" col-md-4 pt-2">
                    <button
                      className="btn btn-rounded btn-primary btn-block mt-3"
                      onClick={() => handleClick()}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="separator-breadcrumb border-top" />
            <h6>*Displays from the Most recent to the oldest</h6>
            <div style={{ overflowX: "auto" }}>
              {loadingOrder ? (
                <TableLoader />
              ) : (
                <MDBDataTable
                  id="page"
                  responsive
                  // scrollY
                  theadColor="MDBTableHeader"
                  hover
                  maxHeight="400px"
                  noBottomColumns
                  bordered
                  paging={false}
                  data={data}
                  sortable={false}
                  searching={false}
                />
              )}
            </div>

            <Pagination data={allOrdersData} route="ordersReport" />
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
