import React from "react";
import Loader from "react-loader-spinner";

const PrimaryLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        marginTop: "150px",
      }}>
      <Loader type='Bars' color='#D14522' height={150} width={150} />
    </div>
  );
};

export default PrimaryLoader;
