import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function EditStore({ outletData }) {
  const outlet_id = outletData.id;
  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      outlet_email: outletData.outlet_email,
      outlet_name: outletData.outlet_name,
      outlet_phone: outletData.outlet_phone,
      outlet_address: outletData.outlet_address,
      outlet_city: outletData.outlet_city,
      outlet_state: outletData.outlet_state,
      outlet_country: outletData.outlet_country,
    };

    setData({ ...dataObject });
  }, [outletData]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [submitted, setSubmitted] = useState(false);
  let [outlet_phone, setOutletPhone] = useState("");
  const [data, setData] = useState([
    {
      id: "",
      outlet_email: "",
      outlet_name: "",
      outlet_phone: "",
      outlet_address: "",
      outlet_city: "",
      outlet_state: "",
      outlet_country: "",
    },
  ]);
  const {
    outlet_email,
    outlet_name,
    outlet_address,
    outlet_city,
    outlet_state,
    outlet_country,
  } = data;
  const creating = useSelector((state) => state.admin.creating);
  outlet_phone = outlet_phone?.substring(1);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      outlet_name &&
      outlet_address &&
      outlet_city &&
      outlet_state &&
      outlet_country
    ) {
      // dispatch action to create data
      dispatch(adminActions.editOutlet({ ...data, id: outlet_id }, setOpen));
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn onClick={() => setOpen(true)} title="Edit Outlet">
          <i className="nav-icon i-Pen-2" />
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>Edit outlet</MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_name ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_name">Outlet name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outlet_name"
                    defaultValue={data.outlet_name}
                    onChange={handleChange}
                  />
                  {submitted && !outlet_name && (
                    <div className="invalid-feedback">
                      Outlet name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_email ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_email">Outlet email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="outlet_email"
                    defaultValue={data.outlet_email}
                    onChange={handleChange}
                  />
                  {submitted && !outlet_email && (
                    <div className="invalid-feedback">
                      outlet_email is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_phone">Outlet phone</label>
                  <PhoneInput
                    className="form-control"
                    value={data.outlet_phone}
                    onChange={setOutletPhone}
                  />
                  {submitted && !outlet_phone && (
                    <div className="invalid-feedback">
                      Outlet phone is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_address">Outlet address*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outlet_address"
                    defaultValue={data.outlet_address}
                    onChange={handleChange}
                  />
                  {submitted && !outlet_address && (
                    <div className="invalid-feedback">
                      Outlet address is required.
                    </div>
                  )}
                </div>

                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={data.outlet_country}
                  state_name={data.outlet_state}
                  city_name={data.outlet_city}
                />
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_country">Outlet country*</label>
                  <select
                    name="outlet_country"
                    selected={data.outlet_country}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.outlet_country && (
                      <option value={data.outlet_country}>
                        {data.outlet_country}
                      </option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_country && (
                    <div className="invalid-feedback">
                      Outlet country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_state">Outlet state </label>
                  <select
                    name="outlet_state"
                    selected={data.outlet_state}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.outlet_state && (
                      <option value={data.outlet_state}>
                        {data.outlet_state}
                      </option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_state && (
                    <div className="invalid-feedback">
                      Outlet state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_city">Outlet city*</label>
                  <select
                    className="form-control"
                    selected={data.outlet_city}
                    name="outlet_city"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.outlet_city && (
                      <option value={data.outlet_city}>
                        {data.outlet_city}
                      </option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_city && (
                    <div className="invalid-feedback">
                      Outlet city is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Edit outlet"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default EditStore;
