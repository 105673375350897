import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";
import { MDBBtn } from "mdbreact";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function CompletedOrders() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  let params = useParams();

  const completedOrders = useSelector((state) => state.admin.completed);
  const singleOrder = useSelector((state) => state.admin.singleOrder);
  const loading = useSelector((state) => state.admin.getCompletedLoader);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(adminActions.getCompletedOrderList(company_id, params.page));
  }, [dispatch, company_id, params]);

  const loadMore = () => {
    dispatch(
      adminActions.getCompletedOrderListLoadMore(company_id, page + 1, setPage)
    );
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div
      className="scroll-list"
      data-perfect-scrollbar
      data-suppress-scroll-x="true"
    >
      {/* <Rotate loading={true}/> */}
      {completedOrders && completedOrders?.data?.length === 0
        ? "No content found"
        : completedOrders?.data?.map((order, i) => (
            <div
              key={i}
              className="card text-left mb-2"
              onClick={() => dispatch(adminActions.getAnOrder(order.id))}
            >
              <div
                className={
                  order.id === singleOrder.id
                    ? "active-order card-body d-flex justify-content-between "
                    : "card-body d-flex justify-content-between "
                }
              >
                <div className="single-order">
                  <h5>
                    Order <span>#{order.id}</span>
                  </h5>
                  <p className="mb-0">
                    <i className="nav-icon i-Clock" />{" "}
                    <span>{moment(order.created_at).format("lll")}</span>
                  </p>
                </div>
                <div className="text-right">
                  <h4 className="font-weight-bold">
                    ₦{numberWithCommas(order.order_amount)}
                  </h4>
                  <h5 className="text-capitalize">
                    {order.order_paid === true ? (
                      <span className="badge badge-pill badge-outline-success p-2">
                        Paid
                      </span>
                    ) : (
                      <span className="badge badge-pill badge-outline-danger p-2">
                        Not Paid
                      </span>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          ))}
      <div>
        {page === completedOrders?.last_page ? (
          <p className="text-center my-4">--The End--</p>
        ) : (
          <MDBBtn
            onClick={() => loadMore()}
            disabled={loading}
            className="mt-4 ml-4"
            color="primary"
          >
            {loading === true ? <Beat loading={loading} /> : "Load more..."}
          </MDBBtn>
        )}
      </div>
    </div>
  );
}
