import React, { useState, useEffect } from "react";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { useSelector } from "react-redux";
import moment from "moment";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import { FilterDelivery } from "../../filter/filterDelivery";
import { writeFileWithXLSX } from "../../exports/xlsx";
import Tooltip from "react-tooltip-lite";
import OrderSummary from "../../includes/OrdersSummary";

export default function DeliveryReport() {
  let lastmonths = new Date();
  lastmonths.setDate(lastmonths.getDate() - 7);

  const [start_date, setStartDate] = useState(lastmonths);
  const [end_date, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [dateInput, setDateInput] = useState({});
  const filteredOrders = useSelector((state) => state.admin.filteredOrders);
  const remittedNoPageOrders = useSelector(
    (state) => state.admin.remittedNoPageOrders
  );
  // const loading = useSelector((state) => state.admin.getFilteredLoader);
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
  };
  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 10, separator = "...") => {
    if (string.length <= lengthToShow) return string;

    const sepLen = separator.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string.substr(0, frontChars) +
      separator +
      string.substr(string.length - backChars)
    );
  };

  useEffect(() => {
    setDateInput({ start_date: start_date, end_date: end_date });
  }, [start_date, end_date]);
  let remittedOrderList = filteredOrders.data;

  function createTableData(props) {
    let dataToMap = props;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, i) => {
        let orderAmount =
          order?.order?.order_amount -
          order?.order?.order_delivery_fee -
          order?.order?.order_service_fee +
          order?.order?.order_discount;
        let totalAmount =
          orderAmount +
          order?.order?.order_insurance_fee +
          order?.order?.order_delivery_fee +
          order?.order?.order_service_fee -
          order?.order?.order_discount;
        let singleRowArray = {
          created_at: moment(order.created_at).format("MM|DD|YY, HH:MM"),
          rider: order.courier.courier_name,
          invoice: (
            <Tooltip
              content="Copied"
              direction="right"
              tagName="span"
              className="target"
              eventOn="onClick"
              eventOff="onMouseOut"
              useHover={false}
            >
              <span
                onClick={() => {
                  copyTextToClipboard(order.order.id);
                }}
              >
                {" "}
                {truncateText(order.order.id)}
                <i className="i-Files ml-2" style={{ cursor: "pointer" }} />
              </span>
            </Tooltip>
          ),
          remittance_point: order?.remittance_point,

          deliveryTime:
            order.delivered_at_date === null ? (
              <span>Not available</span>
            ) : (
              moment(order.delivered_at_date).format("LT")
            ),
          remitted: <span>₦{numberWithCommas(order.remitted_amount)}</span>,
          order_amount: <span>₦{numberWithCommas(orderAmount)}</span>,
          discount: (
            <span>₦{numberWithCommas(order?.order?.order_discount)}</span>
          ),
          insurance: (
            <span>₦{numberWithCommas(order?.order?.order_insurance_fee)}</span>
          ),
          service: (
            <span>₦{numberWithCommas(order?.order?.order_service_fee)}</span>
          ),

          employee: (
            <span className="text-capitalize">
              {order.employee ? order.employee.employee_name : null}
            </span>
          ),
          deliveryFee: (
            <span>₦{numberWithCommas(order?.order?.order_delivery_fee)}</span>
          ),
          total_amount: <span>₦{numberWithCommas(totalAmount)}</span>,
          status:
            order.status === "completed" ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Completed
              </span>
            ) : order.status === "canceled" ? (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Canceled
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-warning p-2 m-1">
                {order.status}
              </span>
            ),
          actions: (
            <div className="d-flex">
              <OrderSummary
                employee_comment={order.employee_comment}
                courier_comment={order.courier_comment}
                order_data={order}
              />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  function createNoPaginatedData(props) {
    let dataToMap = props;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, i) => {
        let orderAmount =
          order?.order?.order_amount -
          order?.order?.order_delivery_fee -
          order?.order?.order_service_fee +
          order?.order?.order_discount;
        let totalAmount =
          orderAmount +
          order?.order?.order_insurance_fee +
          order?.order?.order_delivery_fee +
          order?.order?.order_service_fee -
          order?.order?.order_discount;
        let singleRowArray = {
          created_at: moment(order.created_at).format("MM|DD|YY, HH:MM"),
          rider: order.courier.courier_name,
          invoice: order.order.id,
          remittance_point: order?.remittance_point,

          deliveryTime:
            order.delivered_at_date === null
              ? "Not available"
              : moment(order.delivered_at_date).format("LT"),
          remitted: order.remitted_amount,
          order_amount: orderAmount,
          discount: order?.order?.order_discount,

          insurance: order?.order?.order_insurance_fee,

          service: order?.order?.order_service_fee,

          employee: order.employee ? order.employee.employee_name : null,

          deliveryFee: order?.order?.order_delivery_fee,

          total_amount: totalAmount,
          status:
            order.status === "completed"
              ? "Completed"
              : order.status === "canceled"
              ? "Canceled"
              : order.status,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData(remittedOrderList && remittedOrderList.data);

  const data = {
    columns: [
      {
        label: "Created at:",
        field: "created_at",
        sort: "asc",
        width: 100,
      },
      {
        label: "Employee",
        field: "employee",
        sort: "asc",
        width: 100,
      },
      {
        label: "Rider",
        field: "rider",
        sort: "asc",
        width: 100,
      },
      {
        label: "Order Invoice",
        field: "invoice",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Order Amount",
        field: "order_amount",
        sort: "asc",
        width: 100,
      },

      {
        label: "Delivery Fee",
        field: "deliveryFee",
        sort: "asc",
        width: 100,
      },
      {
        label: "Insurance Fee",
        field: "insurance",
        sort: "asc",
        width: 100,
      },
      {
        label: "Service Fee",
        field: "service",
        sort: "asc",
        width: 100,
      },
      {
        label: "Discount",
        field: "discount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Amount",
        field: "total_amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Remitted Amount",
        field: "remitted",
        sort: "asc",
        width: 100,
      },
      {
        label: "Remittance Point",
        field: "remittance_point",
        sort: "asc",
        width: 100,
      },
      {
        label: "Details ",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: dataSet,
  };
  const downloadCSV = () => {
    if (remittedNoPageOrders) {
      const newData = createNoPaginatedData(remittedNoPageOrders.data);

      writeFileWithXLSX(newData, {
        filename: "delivery-report.xlsx",
        download: true,
      });
    }
  };
  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          {/* ============ Body content start ============= */}
          <div className="main-content">
            <div className="row align-items-center mb-4">
              <div className="col-md-6">
                <div className="breadcrumb">
                  <h1>Delivery Report</h1>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end ">
                <div className="ver-btn">
                  <MDBBtn
                    color="primary"
                    className="btn mr-2 mt-2"
                    onClick={() => downloadCSV()}
                  >
                    Download CSV
                  </MDBBtn>
                </div>
              </div>
            </div>

            <FilterDelivery
              date={dateInput}
              start_date={start_date}
              setStartDate={setStartDate}
              end_date={end_date}
              setEndDate={setEndDate}
              setLoading={setLoading}
            />
            <div className="separator-breadcrumb border-top" />
            <h6>*Displays from the Most recent to the oldest</h6>
            <div style={{ overflowX: "auto" }}>
              <MDBDataTable
                id="page"
                responsive
                maxHeight="400px"
                theadColor="MDBTableHeader"
                hover
                bordered
                noBottomColumns
                data={data}
                paging={false}
                searching={false}
              />
            </div>

            <Pagination data={remittedOrderList} route="deliveryReport" />

            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
