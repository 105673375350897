import React from "react";
import { LOGOWHITE, PImage } from "../images/images";
import { userActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { FOODBG } from "../images/images";
import { useHistory } from "react-router";

export default function UserHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const dispatch = useDispatch();

  function logout() {
    dispatch(userActions.logout());
  }
  function SwitchAccount() {
    localStorage.removeItem("teamId");
    history.push("/auth/switchAccount");
  }

  return (
    <div className="main-header" style={{ backgroundImage: `url(${FOODBG})` }}>
      <div className="logo">
        <a href="/userDashboard">
          <img src={LOGOWHITE} alt="Logo" />
        </a>
      </div>
      <div className="menu-toggle">
        <div />
        <div />
        <div />
      </div>

      <div style={{ margin: "auto" }} />
      <div className="header-part-right">
        {/* User avatar dropdown */}
        {user?.data ? (
          <div className="dropdown">
            <div className="user col align-self-end">
              <div className="d-flex">
                <p className="text-white mb-1 mr-3 text-capitalize">
                  {" "}
                  Hello {user?.data?.name}
                </p>
                <div className="availability-status">
                  <img
                    src={user.data.picture || PImage}
                    alt="avatar"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="userDropdown"
                  >
                    <div className="dropdown-header">
                      <i className="i-Lock-User mr-1" /> {user?.data?.name}
                    </div>
                    <a className="dropdown-item" href="/user/userProfile">
                      My Profile
                    </a>
                    <button
                      className="dropdown-item"
                      onClick={() => SwitchAccount()}
                    >
                      Switch Account
                    </button>
                    <button className="dropdown-item" onClick={() => logout()}>
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <a
            href="/auth/login"
            className="btn btn-rounded btn-secondary btn-block"
          >
            Login
          </a>
        )}
      </div>
    </div>
  );
}
