import React, { Component } from 'react';
import Header from '../navigation/Header';
import SideNavOrder from '../navigation/SideNavOrder';


export default class OrderLayout extends Component {

    render() {

        const viewClone = localStorage.originalUserDetail;

        return (
            <div className="app-admin-wrap layout-sidebar-large">
                <Header />
                <SideNavOrder />

                <div className="main-content-wrap d-flex flex-column">
                    {viewClone &&
                        <div>
                            <h3>You are currently viewing the clone of some company</h3>
                        </div>
                    }

                    {this.props.children}
                </div>
            </div>
        )
    }
}

