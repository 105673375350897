import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable } from "mdbreact";
import CompanyActionButton from "./CompanyActionButton";
import UndoCompanyClone from "./UndoCompanyClone";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import ReuseableSearchBar from "../../search/search";
import TableLoader from "../../includes/Loaders/TableLoader";
import PageNumber from "../../includes/PageNumber";

export default function Companies() {
  const allCompanies = useSelector((state) => state.admin.companies);
  const loading = useSelector((state) => state.admin.getCompanyLoader);
  const searching = useSelector((state) => state.admin.searching);
  const dispatch = useDispatch();

  let params = useParams();

  useEffect(() => {
    dispatch(adminActions.getAllCompanies(params.page));
  }, [dispatch, params]);

  let companyData = allCompanies && allCompanies.data;
  function createTableData() {
    let dataToMap = companyData && companyData.data;

    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap &&
        dataToMap.map((company, i) => {
          let singleRowArray = {
            name: company.company_name,
            email: company.company_email,
            contact: company.company_phone,
            country: company.company_country,
            state: company.company_state,
            city: company.company_city,
            status:
              company.management_approved === true ? (
                <span className='badge badge-pill badge-outline-success p-2 m-1'>
                  Approved
                </span>
              ) : (
                <span className='badge badge-pill badge-outline-danger p-2 m-1'>
                  Not approved
                </span>
              ),
            actions: <CompanyActionButton id={company.id} />,
          };
          outputArray.push(singleRowArray);
          return true;
        });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact no.",
        field: "contact",
        sort: "asc",
        width: 100,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };
  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className='main-content-padding'>
          <div className='main-content'>
            <div className='breadcrumb'>
              <h1 className='mr-2'>Companies</h1>
              <UndoCompanyClone />
            </div>
            <div className='separator-breadcrumb border-top' />

            <div className='search-page'>
              <PageNumber data={companyData} route='companies' />
              <ReuseableSearchBar
                searchAction={adminActions.searchCompany}
                clearSearchResultsAction={adminActions.getAllCompanies}
              />
            </div>
            <div>
              {searching ? (
                <TableLoader />
              ) : (
                <MDBDataTable
                  theadColor='MDBTableHeader'
                  entries={8}
                  entriesOptions={[8, 15, 20]}
                 
                  bordered
                  maxHeight='400px'
                  hover
                  noBottomColumns
                  data={data}
                  paging={false}
                  searching={false}
                />
              )}
            </div>

            <Pagination data={companyData} route='companies' />

            {/* end of row*/}
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
