import React from 'react';
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../../../redux/actions';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

function DeleteEmployee({ employeeData }) {
    const employee_id = employeeData.id;
    const dispatch = useDispatch();

    let dataObject = {
        employee_email: employeeData.employee_email,
        employee_name: employeeData.employee_name,
        employee_phone: employeeData.employee_phone,
        employee_address: employeeData.employee_address,
        employee_city: employeeData.employee_city,
        employee_state: employeeData.employee_state,
        employee_country: employeeData.employee_country,
    }


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const deleting = useSelector(state => state.admin.deleting);


    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(adminActions.deleteEmployee({id:employee_id },setOpen));

    }


    return (
        <div>
            <MDBContainer>
                <MDBBtn onClick={() => setOpen(true)} color="primary" title="Delete Employee"><i className="nav-icon i-Close-Window" /></MDBBtn>
                <MDBModal isOpen={open} toggle={handleClose}>
                    <form name="form" onSubmit={handleSubmit}>
                        <MDBModalHeader toggle={handleClose}>Terminate Employee</MDBModalHeader>
                        <MDBModalBody>
                            <div className="text-left row pt-4 px-3">
                                <h5>Would you like to terminate the employee named: {dataObject.employee_name} </h5>,
                                <h5>Located at: {dataObject.employee_address}, {dataObject.employee_city}, {dataObject.employee_state}, {dataObject.employee_country}?</h5>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => setOpen(false)}>Close</MDBBtn>
                            <MDBBtn color="primary" type="submit">
                                {deleting === true ? <Beat loading={deleting} /> : "Terminate employee"}
                            </MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
        </div>


    )
}


export default DeleteEmployee;