import React, { useState, useEffect } from "react";
import { GRNPIN, REDPIN } from "../../images/images";

import {
  Marker,
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  // DirectionsRenderer,
} from "react-google-maps";

import { useSelector } from "react-redux";

function Map() {
  const {
    InfoBox,
  } = require("react-google-maps/lib/components/addons/InfoBox");
  const location = useSelector((state) => state.admin.locationList);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [props, setProps] = useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (location?.location) {
      let key = Object.keys(location?.location)[1];
      setProps((p) => ({
        ...p,
        lat: location?.location[key].coords.latitude,
        lng: location?.location[key].coords.longitude,
      }));
    }
  }, [location]);

  return (
    <GoogleMap
      zoom={props?.lat ? 13 : 6}
      center={{
        lat: props?.lat ?? 6.465422,
        lng: props?.lng ?? 3.406448,
      }}
    >
      {Object.keys(location?.location ?? {}).map((key) => {
        return (
          <Marker
            key={key}
            position={{
              lat: location?.location[key].coords.latitude,
              lng: location?.location[key].coords.longitude,
            }}
            onClick={() => {
              setSelectedLocation(location.location[key]);
            }}
            icon={{
              url:
                (location?.location[key]?.order_status?.order_pending ?? 0) ===
                  0 &&
                (location?.location[key]?.order_status?.order_accepted ?? 0) ===
                  0
                  ? `${GRNPIN}`
                  : `${REDPIN}`,
              anchor: new window.google.maps.Point(17, 46),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        );
      })}

      {selectedLocation && (
        <InfoBox
          position={{
            lat: selectedLocation?.coords?.latitude,
            lng: selectedLocation?.coords?.longitude,
          }}
          onCloseClick={() => {
            setSelectedLocation(false);
          }}
        >
          <div className="info-box">
            <div className="info-text ">
              <div>
                <p>Courier Name: {selectedLocation?.courier_name}</p>
                <p>Status: {selectedLocation?.status}</p>
                <p>
                  {" "}
                  Pending Orders:
                  {selectedLocation?.order_status?.order_pending}
                </p>
                <p>
                  {" "}
                  Accepted Orders:
                  {selectedLocation?.order_status?.order_accepted}
                </p>
              </div>
            </div>
          </div>
        </InfoBox>
      )}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default function AllRidersMap() {
  return (
    <div style={{ height: "100vh", width: "104%" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBYilIq5BSPrwmLLBBp6rnVNsOSmzFcDS0`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    </div>
  );
}
