import React from "react";
import OrderLayout from "../../layouts/OrderLayout";
import RidersOrdersList from "../RidersOrders/RidersOrdersList";
import ViewCourierOrders from "./ViewCourierOrder";

export default function RidersOrders() {
  return (
    <OrderLayout>
      <div className='main-content o-hidden' data-test='ridersOrdersComponent'>
        {/* ============ Body content start ============= */}
        <div className='row' style={{ background: "#f8f8f8" }}>
          <div className='col-md-4'>
            <RidersOrdersList />
          </div>
          <div className='col-md-8'>
            <ViewCourierOrders />
          </div>
        </div>
      </div>
    </OrderLayout>
  );
}
