import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import moment from "moment";
import { MDBBtn } from "mdbreact";
import { useParams } from "react-router-dom";
import { Beat } from "../../../redux/reducers/spinner";

export default function UnpaidUnassignedOrders() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  const dispatch = useDispatch();
  const params = useParams();

  const singleOrder = useSelector((state) => state.admin.singleOrder);
  const unassigned_unpaid_Orders = useSelector(
    (state) => state.admin.unassigned_unpaid
  );
  const [page, setPage] = useState(1);
  const loading = useSelector((state) => state.admin.getUnassignedLoader);
  //let duration = moment.duration(45, "minutes");

  useEffect(() => {
    dispatch(adminActions.getUnpaidUnassignedOrders(company_id, params.page));
  }, [dispatch, company_id, params]);

  const loadMore = () => {
    dispatch(
      adminActions.getUnpaidUnassignedOrdersLoadMore(
        company_id,
        page + 1,
        setPage
      )
    );
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //checking if an hour has passed since order was assigned
  function checkTimePassed(time) {
    const hour = 60 * 60 * 1000;
    const hourLater = new Date() - new Date(time);
    if (hourLater >= hour) {
      return true;
    }
  }
  return (
    <div
      className="scroll-list"
      data-perfect-scrollbar
      data-suppress-scroll-x="true"
    >
      {unassigned_unpaid_Orders && unassigned_unpaid_Orders?.data?.length === 0
        ? "No content found"
        : unassigned_unpaid_Orders?.data?.map((order, i) => (
            <div
              key={i}
              className="card text-left mb-2"
              onClick={() => dispatch(adminActions.getAnOrder(order.id))}
            >
              <div
                className={
                  order.id === singleOrder.id
                    ? "active-order card-body d-flex justify-content-between "
                    : "card-body d-flex justify-content-between "
                }
              >
                <div className="single-order">
                  <div>
                    {checkTimePassed(
                      moment(order.updated_at).format("lll")
                    ) && <i className="clock-icon i-Clock" />}
                  </div>
                  <h5>
                    Order <span>#{order.id}</span>
                  </h5>
                  <p className="mb-0">
                    <i className="nav-icon i-Clock" />{" "}
                    <span>{moment(order.created_at).format("lll")}</span>
                  </p>
                </div>
                <div className="text-right">
                  <h4 className="font-weight-bold">
                    ₦{numberWithCommas(order.order_amount)}
                  </h4>
                  <h5 className="text-capitalize">
                    {order.order_paid === true ? (
                      <span className="badge badge-pill badge-outline-success p-2">
                        Paid
                      </span>
                    ) : (
                      <span className="badge badge-pill badge-outline-danger p-2">
                        Not Paid
                      </span>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          ))}
      <div>
        {page === unassigned_unpaid_Orders?.last_page ? (
          <p className="text-center my-4">--The End--</p>
        ) : (
          <MDBBtn
            onClick={() => loadMore()}
            className="pt-2 ml-4 pb-2"
            color="primary"
          >
            {loading === true ? <Beat loading={loading} /> : "Load more..."}
          </MDBBtn>
        )}
      </div>
    </div>
  );
}
