import React, { useEffect, useState } from "react";
import MainApp from "../../layouts/MainApp";
import { AVATAR } from "../../images/images";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import RiderMapWrapper from "./RiderMapWrapper";

export default function RiderView(props) {
  const rider_id = props.match.params.id;
  const [
    view,
    // eslint-disable-next-line
    SetView,
  ] = useState("singleRider");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getSingleRider(rider_id));
  }, [dispatch, rider_id]);

  const rider = useSelector((state) => state.users.singleRider);
  return (
    <MainApp>
      <div className="main-content">
        {/* ============ Body content start ============= */}

        <div className="row">
          <div className="col-md-8 p-0">
            <RiderMapWrapper view={view} rider_id={rider_id} />
          </div>
          <div className="col-md-4 p-0">
            <div className="user-profile p-4">
              <a href="/riders">Back</a>
              <div className="user-info mb-4">
                <img
                  className="profile-picture avatar-lg mb-2"
                  src={AVATAR}
                  alt=""
                />
                <p className="m-0 text-24">{rider.courier_name}</p>
                <p className="text-muted m-0">
                  {rider && <span>{rider.courier_company_name}</span>} Rider
                </p>
              </div>
              {rider && (
                <div className="user-info-card">
                  <ul>
                    <li>
                      <span>Name</span> <span>{rider.courier_name}</span>
                    </li>
                    <li>
                      <span>Phone</span> <span>{rider.courier_phone}</span>
                    </li>
                    <li>
                      <span>Email</span>{" "}
                      <span>{rider.user && rider.user.email}</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>Status</span>{" "}
                      {rider.status === "active" ? (
                        <span className="badge badge-pill badge-outline-success p-2 m-1">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-pill badge-outline-danger p-2 m-1">
                          Inactive
                        </span>
                      )}
                    </li>
                    <li>
                      <span>Accepted orders</span>{" "}
                      <span>{rider.orders_accepted}</span>
                    </li>
                    <li>
                      <span>Orders in progress</span>{" "}
                      <span>{rider.orders_pending}</span>
                    </li>
                    <li>
                      <span>Pending orders</span>{" "}
                      <span>{rider.orders_pending}</span>
                    </li>
                    <li>
                      <span>Completed orders</span>{" "}
                      <span>{rider.orders_completed}</span>
                    </li>
                    <li>
                      <span>Rejected orders</span>{" "}
                      <span>{rider.orders_rejected}</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainApp>
  );
}
