import React from 'react';
import Header from '../navigation/Header';
import SideNav from '../navigation/SideNav';


export default function MainApp(props) {

    const viewClone = localStorage.originalUserDetail;

    return (
        <div className="app-admin-wrap layout-sidebar-large">
            <Header />
            <SideNav />

            <div className="main-content-wrap sidenav-open d-flex flex-column">
                {viewClone &&
                    <div>
                        <h3>You are currently viewing the clone of some company</h3>
                    </div>
                }

                {props.children}
            </div>
        </div>
    )

}