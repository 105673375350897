import React, { useState, Fragment, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import UnassignAnOrder from "./UnassignAnOrder";
import { cleanInput } from "../../../redux/helpers";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function EditOrder({ order_data, order_status }) {
  const order_id = order_data.id;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [visible, setvisible] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosed = () => {
    setShow(false);
  };
  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      customer_email: order_data.customer_email,
      customer_name: order_data.customer_name,
      customer_phone: order_data.customer_phone,
      customer_gender: order_data.customer_gender,
      customer_address: order_data.customer_address,
      customer_city: order_data.customer_city,
      customer_state: order_data.customer_state,
      customer_country: order_data.customer_country,
      customer_comment: order_data.customer_comment,
      order_amount: order_data.order_amount,
      order_delivery_fee: order_data.order_delivery_fee,
      order_service_fee: order_data.order_service_fee,
      outlet_id: order_data.outlet_id,
      outlet_name: order_data.outlet_name,
      outlet_phone: order_data.outlet_phone,
      outlet_address: order_data.outlet_address,
      outlet_city: order_data.outlet_city,
      outlet_state: order_data.outlet_state,
      outlet_country: order_data.outlet_country,
      order_paid: order_data.order_paid,
      order_description: order_data.order_description,
      order_payment_method: order_data.order_payment_method,
    };

    setData({ ...dataObject });

    let order_items = order_data.order_items;
    order_items && setInputArray(order_items);

    let order_payment_method = order_data.order_payment_method;
    order_payment_method && setorderPayment(order_payment_method);
  }, [order_data]);

  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const [remove, setRemove] = useState(false);

  const [error, setError] = useState({ customerError: "", optionsError: "" });
  const [selectedOrder, setSelectedOrder] = useState("");

  const [submitted, setSubmitted] = useState(false);
  let [customer_phone, setCustomerPhone] = useState("");
  let [outlet_phone, setOutletPhone] = useState("");
  const [data, setData] = useState({
    company_id: "",
    customer_name: "",
    customer_phone: "",
    customer_email: "",
    customer_gender: "",
    customer_address: "",
    customer_city: "",
    customer_state: "",
    customer_country: "",
    order_amount: "",
    order_discount: "0",
    order_service_fee: "0",
    order_items: [],
    order_payment_method: [],
    order_description: "",
    order_paid: "0",
    order_delivery_fee: "",
    outlet_name: "",
    outlet_phone: "",
    outlet_address: "",
    outlet_city: "",
    outlet_state: "",
    outlet_country: "",
  });

  const {
    customer_name,
    customer_email,
    customer_gender,
    customer_address,
    customer_city,
    customer_state,
    customer_country,
    order_amount,
    order_payment_method,
    order_description,
    order_paid,
    order_discount,
    order_delivery_fee,
    order_service_fee,
    outlet_name,
    outlet_address,
    outlet_city,
    outlet_state,
    outlet_country,
  } = data;

  const updating = useSelector((state) => state.admin.updating);
  customer_phone = customer_phone?.substring(1);
  outlet_phone = outlet_phone?.substring(1);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  let searchedOutlet = useSelector((state) => state.admin.searchedOutlet);

  // This is for the order items array
  const [inputArray, setInputArray] = useState([]);
  const handleInputChange = (index, event) => {
    const values = [...inputArray];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
    }
    setInputArray(values);
  };

  //this is for the order options array
  const [optionsArray, setOptionsArray] = useState({
    name: "",
    quantity: "",
    price: "",
  });

  const addOption = () => {
    if (
      optionsArray["name"] === "" ||
      optionsArray["price"] === "" ||
      optionsArray["quantity"] === ""
    ) {
      setError({
        ...error,
        optionsError: "Option details are required.",
      });
      return;
    }
    const index = inputArray.findIndex((object) => {
      return object.id === selectedOrder;
    });

    optionsArray["id"] = Math.random();
    if (inputArray[index].options) {
      inputArray[index] = {
        ...inputArray[index],
        options: [...inputArray[index].options, optionsArray],
      };
    } else {
      inputArray[index] = {
        ...inputArray[index],
        options: [],
      };
      inputArray[index].options.push({
        ...optionsArray,
      });
    }

    setShow(false);
  };
  const handleAddFields = () => {
    const values = [...inputArray];
    values.push({ name: "", quantity: "0", price: "0" });
    setInputArray(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputArray];
    values.splice(index, 1);
    setInputArray(values);
  };
  //this removes the option that was added
  const handleRemoveOptionsFields = (id, itemId) => {
    const index = inputArray.findIndex((object) => {
      return object.id === itemId;
    });

    let newArray = inputArray[index].options.filter((item) => {
      return item.id !== id;
    });
    inputArray[index] = {
      ...inputArray[index],
      options: newArray,
    };
    setRemove(!remove);
  };

  function getPrice() {
    let order_items_amount = 0;
    let optionsPrice = 0;
    for (var i = 0; i < inputArray.length; i++) {
      order_items_amount +=
        Number(inputArray[i].quantity) * Number(inputArray[i].price);

      if (inputArray[i].options) {
        inputArray[i].options.forEach(myFunction);
      }
      // eslint-disable-next-line
      function myFunction(item, index, arr) {
        optionsPrice += Number(item.quantity) * Number(item.price);
      }
    }
    let sum = order_items_amount + optionsPrice;
    for (let i = 0; i < inputArray.length; i++) {
      inputArray[i]["id"] = Math.random();
    }

    return sum;
  }

  useEffect(() => {
    data.order_discount = data.order_discount ?? 0;
    setData((data) => ({
      ...data,
      order_amount:
        Number(data.order_delivery_fee) +
        Number(data.order_service_fee) +
        getPrice() -
        Number(data.order_discount),
    }));

    // eslint-disable-next-line
  }, [inputArray, order_delivery_fee, order_discount, order_service_fee]);
  const recheckPrice = () => {
    setData((data) => ({
      ...data,
      order_amount:
        Number(data.order_delivery_fee) +
        Number(data.order_service_fee) +
        getPrice() -
        Number(data.order_discount),
    }));
  };

  const [orderPayment, setorderPayment] = useState([]);
  const handleOrderInputChange = (event) => {
    let values = orderPayment;
    if (event.target.value === "1") {
      values = {
        id: "1",
        name: "xend",
        icon: "https://xend.africa/assets/img/xend-logo.svg",
        details: "",
      };
    } else if (event.target.value === "2") {
      values = {
        id: "2",
        name: "cash",
        icon: "https://xend.africa/assets/img/xend-logo.svg",
        details: "",
      };
    } else if (event.target.value === "3") {
      values = {
        id: "3",
        name: "paystack",
        icon: "https://paystack.com/blog/public/assets/images/logo/paystack-logo.svg",
        details: "",
      };
    } else if (event.target.value === "4") {
      values = {
        id: "4",
        name: "paypal",
        icon: "https://translatorswithoutborders.org/wp-content/uploads/2016/01/new-paypal-logo.jpg",
        details: "",
      };
    } else if (event.target.value === "5") {
      values = {
        id: "5",
        name: "transfer",
        icon: "",
        details: "",
      };
    }

    setorderPayment(values);
  };

  // Search for stores and fill their details automatically if they exist
  function fillOutlet(result) {
    setData((data) => ({
      ...data,
      outlet_name: result.outlet_name,
      outlet_phone: result.outlet_phone,
      outlet_address: result.outlet_address,
      outlet_city: result.outlet_city,
      outlet_state: result.outlet_state,
      outlet_country: result.outlet_country,
    }));
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "#D14522";
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    cleanInput(data);

    // dispatch action to create data
    dispatch(
      adminActions.editOrder(
        {
          ...data,
          id: order_id,
          order_items: inputArray,
          order_payment_method: orderPayment,
        },
        setOpen
      )
    );
  };

  return (
    <div>
      <MDBContainer>
        {order_status === "assigned" ? (
          <div>
            <MDBBtn onClick={() => setvisible(true)} title="Edit Order">
              <i className="nav-icon i-Pen-2" />
            </MDBBtn>
            <p className="mt-1 ml-1">Edit</p>
            <UnassignAnOrder
              id_holder={order_data && order_data?.courier_orders}
              actionType="edit"
              visible={visible}
              setvisible={setvisible}
              openModal={setOpen}
            />
          </div>
        ) : (
          <div>
            <MDBBtn onClick={() => setOpen(true)} title="Edit Order">
              <i className="nav-icon i-Pen-2" />
            </MDBBtn>
            <p className="mt-1 ml-1">Edit</p>
          </div>
        )}

        <MDBModal isOpen={open} toggle={handleClose} size="lg">
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              {" "}
              Edit Order details{" "}
            </MDBModalHeader>
            <MDBModalBody>
              <div className="text-left pt-4 px-3">
                <div>
                  <h4>Edit customer details</h4>
                  <div className="separator-breadcrumb border-top" />
                  <div className="row">
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_name ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_name">Customer name*</label>
                      <input
                        type="text"
                        name="customer_name"
                        defaultValue={data.customer_name}
                        value={customer_name}
                        onChange={handleChange}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_name ? " is-invalid" : "")
                        }
                      />
                    </div>
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_phone ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_phone">Customer phone*</label>
                      <PhoneInput
                        value={data.customer_phone}
                        onChange={setCustomerPhone}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_phone ? " is-invalid" : "")
                        }
                      />
                    </div>
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_email ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_email">Customer email</label>
                      <input
                        type="email"
                        name="customer_email"
                        defaultValue={data.customer_email}
                        value={customer_email}
                        onChange={handleChange}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_email ? " is-invalid" : "")
                        }
                      />
                    </div>
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_gender ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_gender">Customer gender</label>
                      <select
                        name="customer_gender"
                        defaultValue={data.customer_gender}
                        value={customer_gender}
                        onChange={handleChange}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_gender ? " is-invalid" : "")
                        }
                      >
                        <option>Select option</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_address ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_address">Customer address</label>
                      <input
                        type="text"
                        name="customer_address"
                        defaultValue={data.customer_address}
                        value={customer_address}
                        onChange={handleChange}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_address ? " is-invalid" : "")
                        }
                      />
                    </div>

                    <Location
                      country_id={locationId.country_id}
                      state_id={locationId.state_id}
                      city_id={locationId.city_id}
                      countryList={(value) => {
                        setLocationList({
                          ...locationList,
                          countryList: value,
                        });
                      }}
                      stateList={(value) => {
                        setLocationList({ ...locationList, stateList: value });
                      }}
                      cityList={(value) => {
                        setLocationList({ ...locationList, cityList: value });
                      }}
                      country_name={data.customer_country}
                      state_name={data.customer_state}
                      city_name={data.customer_city}
                    />

                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_country ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_country">Customer country</label>
                      <select
                        name="customer_country"
                        defaultValue={data.customer_country}
                        value={customer_country}
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            country_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_country ? " is-invalid" : "")
                        }
                      >
                        {data.customer_country && (
                          <option value={data.customer_country} selected={true}>
                            {data.customer_country}
                          </option>
                        )}
                        {locationList.countryList &&
                          locationList.countryList.length > 0 &&
                          locationList.countryList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                selected={data.customer_country}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_state ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_state">Customer state</label>
                      <select
                        name="customer_state"
                        defaultValue={data.customer_state}
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            state_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_state ? " is-invalid" : "")
                        }
                      >
                        {data.customer_state && (
                          <option value={data.customer_state} selected={true}>
                            {data.customer_state}
                          </option>
                        )}
                        {locationList.stateList &&
                          locationList.stateList.length > 0 &&
                          locationList.stateList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div
                      className={
                        "form-group col-md-6 mb-4" +
                        (submitted && !customer_city ? " is-invalid" : "")
                      }
                    >
                      <label htmlFor="customer_city">Customer city</label>
                      <select
                        name="customer_city"
                        defaultValue={data.customer_city}
                        value={customer_city}
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            city_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                        className={
                          "form-control form-control-rounded" +
                          (submitted && !customer_city ? " is-invalid" : "")
                        }
                      >
                        {data.customer_city && (
                          <option value={data.customer_city} selected={true}>
                            {data.customer_city}
                          </option>
                        )}
                        {locationList.cityList &&
                          locationList.cityList.length > 0 &&
                          locationList.cityList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="w-100 px-2">
                  <h4 className="mt-4">Order details</h4>
                  <div className="separator-breadcrumb border-top" />
                </div>

                <div className="row">
                  <div className="col-md-12 table-responsive">
                    <table className="table table-hover mb-4">
                      <colgroup>
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "42%" }} />
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "15%" }} />
                      </colgroup>

                      <thead className="bg-gray-300">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Item Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Unit Price(₦)</th>
                          <th scope="col">Action</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputArray.map((inputItem, index) => (
                          <Fragment key={`${inputItem}~${index}`}>
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>
                                <input
                                  type="text"
                                  name="name"
                                  value={inputItem.name}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  className="form-control form-control-rounded"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  name="quantity"
                                  value={inputItem.quantity}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  className="form-control form-control-rounded"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  name="price"
                                  value={inputItem.price}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  className="form-control form-control-rounded"
                                />
                              </td>
                              <td>
                                <MDBBtn
                                  color="primary"
                                  disabled={!inputItem.name}
                                  onClick={() => {
                                    setShow(true);
                                    setSelectedOrder(inputItem.id);
                                  }}
                                >
                                  Options
                                </MDBBtn>

                                <MDBModal isOpen={show} toggle={handleClosed}>
                                  {/* <form name="form" onSubmit={addOption}> */}
                                  <MDBModalHeader toggle={handleClosed}>
                                    Add an Option
                                  </MDBModalHeader>
                                  <MDBModalBody>
                                    <div className="text-left row pt-4">
                                      <div
                                        className={"form-group  col-md-12 mb-3"}
                                      >
                                        <label htmlFor="name">Name</label>
                                        <input
                                          id="name"
                                          type="name"
                                          name="name"
                                          placeholder="Enter name"
                                          onChange={(event) =>
                                            setOptionsArray({
                                              ...optionsArray,
                                              name: event.target.value,
                                            })
                                          }
                                          className={
                                            "form-control form-control-rounded"
                                          }
                                        />
                                      </div>
                                      <div
                                        className={"form-group col-md-12  mb-3"}
                                      >
                                        <label htmlFor="price">
                                          Unit Price
                                        </label>
                                        <input
                                          id="price"
                                          type="price"
                                          name="price"
                                          onChange={(event) =>
                                            setOptionsArray({
                                              ...optionsArray,
                                              price: event.target.value,
                                            })
                                          }
                                          className={
                                            "form-control form-control-rounded"
                                          }
                                        />
                                      </div>
                                      <div
                                        className={"form-group  col-md-12 mb-3"}
                                      >
                                        <label htmlFor="quantity">
                                          Quantity
                                        </label>
                                        <input
                                          id="quantity"
                                          type="quantity"
                                          name="quantity"
                                          onChange={(event) =>
                                            setOptionsArray({
                                              ...optionsArray,
                                              quantity: event.target.value,
                                            })
                                          }
                                          placeholder="quantity"
                                          className={
                                            "form-control form-control-rounded"
                                          }
                                        />
                                        {error && error.optionsError && (
                                          <div className="error-notification">
                                            {error && error.optionsError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </MDBModalBody>
                                  <MDBModalFooter>
                                    <MDBBtn
                                      color="secondary"
                                      onClick={() => setShow(false)}
                                    >
                                      Close
                                    </MDBBtn>
                                    <MDBBtn
                                      color="primary"
                                      type="button"
                                      onClick={() => {
                                        addOption();
                                        recheckPrice();
                                      }}
                                    >
                                      {updating === true ? (
                                        <Beat loading={updating} />
                                      ) : (
                                        "Save"
                                      )}
                                    </MDBBtn>
                                  </MDBModalFooter>
                                  {/* </form> */}
                                </MDBModal>
                              </td>
                              <td>
                                {
                                  <button
                                    className="btn "
                                    type="button"
                                    onClick={() => handleRemoveFields(index)}
                                  >
                                    <i className=" searchIcon i-Close"></i>
                                  </button>
                                }
                              </td>
                            </tr>
                            {inputItem.options &&
                              inputItem.options.length > 0 && (
                                <tr className="w-100 flex">Options: </tr>
                              )}

                            {inputItem.options &&
                              inputItem.options.map((option, key) => (
                                <tr key={key}>
                                  <th></th>
                                  <td>
                                    <input
                                      key={key}
                                      type="text"
                                      name="name"
                                      value={option.name}
                                      onChange={(event) =>
                                        setOptionsArray({
                                          ...optionsArray,
                                          name: event.target.value,
                                        })
                                      }
                                      className="form-control form-control-rounded"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      key={key}
                                      type="number"
                                      min="0"
                                      name="quantity"
                                      value={option.quantity}
                                      onChange={(event) =>
                                        setOptionsArray({
                                          ...optionsArray,
                                          quantity: event.target.value,
                                        })
                                      }
                                      className="form-control form-control-rounded"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      key={key}
                                      type="number"
                                      min="0"
                                      name="price"
                                      value={option.price}
                                      onChange={(event) =>
                                        setOptionsArray({
                                          ...optionsArray,
                                          price: event.target.value,
                                        })
                                      }
                                      className="form-control form-control-rounded"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      className="btn mr-3"
                                      style={{}}
                                      type="button"
                                      onClick={() =>
                                        handleRemoveOptionsFields(
                                          option.id,
                                          inputItem.id
                                        )
                                      }
                                    >
                                      <i className=" searchIcon i-Close"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </Fragment>
                        ))}

                        <tr>
                          <td colspan="5">
                            <div
                              className="add-field-btn"
                              onClick={() => handleAddFields()}
                            >
                              <i className="i-Add mr-2"></i> Add new item
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div
                    className={
                      "form-group col-md-12 mb-4" +
                      (submitted && !order_description ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="order_description">Order description</label>
                    <textarea
                      type="text"
                      name="order_description"
                      value={order_description}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !order_description ? " is-invalid" : "")
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div
                    className={
                      "form-group col-md-4 mb-4" +
                      (submitted && !order_delivery_fee ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="order_delivery_fee">
                      Order delivery fee(₦)
                    </label>
                    <input
                      type="number"
                      min="0"
                      name="order_delivery_fee"
                      value={order_delivery_fee}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !order_delivery_fee ? " is-invalid" : "")
                      }
                    />
                  </div>
                  <div
                    className={
                      "form-group col-md-4 mb-4" +
                      (submitted && !order_discount ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="order_discount">Order discount(₦)</label>
                    <input
                      type="number"
                      min="0"
                      name="order_discount"
                      value={data.order_discount}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !order_discount ? " is-invalid" : "")
                      }
                    />
                  </div>

                  <div
                    className={
                      "form-group col-md-4 mb-4" +
                      (submitted && !order_amount ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="order_amount">Order amount(₦)</label>
                    <input
                      type="number"
                      min="0"
                      name="order_amount"
                      defaultValue={data.order_amount}
                      value={order_amount}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !order_amount ? " is-invalid" : "")
                      }
                      readOnly
                    />
                  </div>

                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !order_paid ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="order_paid">Order paid</label>
                    <select
                      name="order_paid"
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !order_paid ? " is-invalid" : "")
                      }
                    >
                      <option>Select option</option>
                      <option selected={data.order_paid === false} value="0">
                        Not Paid
                      </option>
                      <option selected={data.order_paid === true} value="1">
                        Paid
                      </option>
                    </select>
                  </div>

                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !order_payment_method ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="payment_method">Payment method</label>
                    <select
                      name="payment_method"
                      onChange={(event) => handleOrderInputChange(event)}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !order_paid ? " is-invalid" : "")
                      }
                    >
                      <option>Select option</option>
                      <option
                        selected={
                          data.order_payment_method &&
                          data.order_payment_method.id === "1"
                        }
                        value="1"
                      >
                        xend
                      </option>
                      <option
                        selected={
                          data.order_payment_method &&
                          data.order_payment_method.id === "2"
                        }
                        value="2"
                      >
                        cash
                      </option>
                      <option
                        selected={
                          data.order_payment_method &&
                          data.order_payment_method.id === "3"
                        }
                        value="3"
                      >
                        paystack
                      </option>
                      <option
                        selected={
                          data.order_payment_method &&
                          data.order_payment_method.id === "4"
                        }
                        value="4"
                      >
                        paypal
                      </option>
                      <option
                        selected={
                          data.order_payment_method &&
                          data.order_payment_method.id === "5"
                        }
                        value="5"
                      >
                        transfer
                      </option>
                    </select>
                  </div>

                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !outlet_name ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="outlet_name">Order outlet name</label>
                    <input
                      type="search"
                      name="outlet_name"
                      placeholder="Search Outlets by name, phone number etc."
                      value={outlet_name}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !outlet_name ? " is-invalid" : "")
                      }
                    />
                    {searchedOutlet &&
                      Array.isArray(searchedOutlet) &&
                      searchedOutlet.map((result, index) => (
                        <div
                          className="p-3 border mb-2"
                          id={result.id}
                          onClick={() => fillOutlet(result)}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="customer-details">
                                <h5>{result.outlet_name}</h5>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <h5>{result.outlet_phone}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {/* </input> */}
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !outlet_phone ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="outlet_phone">Order outlet phone</label>
                    <PhoneInput
                      name="outlet_phone"
                      value={data.outlet_phone}
                      onChange={setOutletPhone}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !outlet_phone ? " is-invalid" : "")
                      }
                    />
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !outlet_address ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="outlet_address">Order outlet address</label>
                    <input
                      type="text"
                      name="outlet_address"
                      value={outlet_address}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !outlet_address ? " is-invalid" : "")
                      }
                    />
                  </div>

                  <Location
                    country_id={locationId.country_id}
                    state_id={locationId.state_id}
                    city_id={locationId.city_id}
                    countryList={(value) => {
                      setLocationList({ ...locationList, countryList: value });
                    }}
                    stateList={(value) => {
                      setLocationList({ ...locationList, stateList: value });
                    }}
                    cityList={(value) => {
                      setLocationList({ ...locationList, cityList: value });
                    }}
                    country_name={data.company_country}
                    state_name={data.company_state}
                    city_name={data.company_city}
                  />
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !outlet_country ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="outlet_country">Order outlet country</label>
                    <select
                      name="outlet_country"
                      defaultValue={data.outlet_country}
                      value={outlet_country}
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          country_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !outlet_country ? " is-invalid" : "")
                      }
                    >
                      {data.outlet_country && (
                        <option value={data.outlet_country} selected={true}>
                          {data.outlet_country}
                        </option>
                      )}
                      {locationList.countryList &&
                        locationList.countryList.length > 0 &&
                        locationList.countryList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({ name: item.name, id: item.id })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !outlet_state ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="outlet_state">Order outlet state</label>
                    <select
                      name="outlet_state"
                      defaultValue={data.outlet_state}
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          state_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !outlet_state ? " is-invalid" : "")
                      }
                    >
                      {data.outlet_state && (
                        <option value={data.outlet_state} selected={true}>
                          {data.outlet_state}
                        </option>
                      )}
                      {locationList.stateList &&
                        locationList.stateList.length > 0 &&
                        locationList.stateList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({ name: item.name, id: item.id })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    {submitted && !outlet_state && (
                      <div className="invalid-feedback">
                        Order outlet state is required
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !outlet_city ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="outlet_city">Order outlet city</label>
                    <select
                      name="outlet_city"
                      defaultValue={data.outlet_city}
                      value={outlet_city}
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          city_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !outlet_city ? " is-invalid" : "")
                      }
                    >
                      {data.outlet_city && (
                        <option value={data.outlet_city} selected={true}>
                          {data.outlet_city}
                        </option>
                      )}
                      {locationList.cityList &&
                        locationList.cityList.length > 0 &&
                        locationList.cityList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({ name: item.name, id: item.id })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {updating === true ? <Beat loading={updating} /> : "Edit Order"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default EditOrder;
