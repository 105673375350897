import React, { Fragment, useState } from "react";

export default function OrderItems({
  inputArray,
  setInputArray = () => {},
  data,
}) {
  // This is for the order items array
  const handleInputChange = (index, event) => {
    const values = [...inputArray];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
    }
    setInputArray(values);
  };

  //this removes the option that was added
  const [remove, setRemove] = useState(false);
  const handleRemoveOptionsFields = (id, itemId) => {
    const index = inputArray.findIndex((object) => {
      return object.id === itemId;
    });

    let newArray = inputArray[index].options.filter((item) => {
      return item.id !== id;
    });
    inputArray[index] = {
      ...inputArray[index],
      options: newArray,
    };
    setRemove(!remove);
  };
  function orderPrice(unit, price) {
    return unit * price;
  }
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function grandTotal() {
    let order_items_amount = 0;
    let optionsPrice = 0;
    for (var i = 0; i < inputArray?.length; i++) {
      order_items_amount +=
        Number(inputArray[i]?.quantity) * Number(inputArray[i].price);

      if (inputArray[i].options) {
        inputArray[i].options.forEach(myFunction);
      }
      // eslint-disable-next-line
      function myFunction(item, index, arr) {
        optionsPrice += Number(item.quantity) * Number(item.price);
      }
    }
    let sum = order_items_amount + optionsPrice;
    for (let i = 0; i < inputArray?.length; i++) {
      inputArray[i]["id"] = Math.random();
    }

    return (
      sum +
      data.order_delivery_fee +
      data.order_insurance_fee +
      data.order_service_fee -
      data.order_discount
    );
  }
  const handleAddFields = () => {
    const values = [...inputArray];
    values.push({ name: "", quantity: "0", price: "0" });
    setInputArray(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputArray];
    values.splice(index, 1);
    setInputArray(values);
  };
  return (
    <div className="row">
      <div className="col-md-12 table-responsive">
        <table className="table table-hover mb-4">
          <colgroup>
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "42%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "18%" }} />
          </colgroup>

          <thead className="bg-gray-300">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Item Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Unit Price(₦)</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {inputArray?.map((inputItem, index) => (
              <Fragment key={`${inputItem}~${index}`}>
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={inputItem.name}
                      onChange={(event) => handleInputChange(index, event)}
                      className="form-control form-control-rounded"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      name="quantity"
                      value={inputItem.quantity}
                      onChange={(event) => handleInputChange(index, event)}
                      className="form-control form-control-rounded"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      name="price"
                      value={inputItem.price}
                      onChange={(event) => handleInputChange(index, event)}
                      className="form-control form-control-rounded"
                    />
                  </td>
                  <td>
                    <span>
                      {" "}
                      ₦
                      {numberWithCommas(
                        orderPrice(inputItem.quantity, inputItem.price)
                      )}{" "}
                    </span>
                    <button
                      className="btn py-0 px-0"
                      type="button"
                      onClick={() => handleRemoveFields(index)}
                    >
                      <i className=" searchIcon i-Close"></i>
                    </button>
                  </td>
                </tr>
                {inputItem.options && inputItem.options.length > 0 && (
                  <tr className="w-100 flex">Options: </tr>
                )}

                {inputItem.options &&
                  inputItem.options.map((option, key) => (
                    <tr key={key}>
                      <th></th>
                      <td>
                        <input
                          key={key}
                          type="text"
                          name="name"
                          defaultValue={option.name}
                          className="form-control form-control-rounded"
                        />
                      </td>
                      <td>
                        <input
                          key={key}
                          type="number"
                          min="0"
                          name="quantity"
                          placeholder="0"
                          defaultValue={option.quantity}
                          className="form-control form-control-rounded"
                        />
                      </td>
                      <td>
                        <input
                          key={key}
                          type="number"
                          min="0"
                          name="price"
                          placeholder="0"
                          defaultValue={option.price}
                          className="form-control form-control-rounded"
                        />
                      </td>
                      <td>
                        <button
                          className="btn mr-3"
                          style={{}}
                          type="button"
                          onClick={() =>
                            handleRemoveOptionsFields(option.id, inputItem.id)
                          }
                        >
                          <i className=" searchIcon i-Close"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </Fragment>
            ))}

            <tr>
              <td colSpan="5">
                <div
                  className="add-field-btn"
                  onClick={() => handleAddFields()}
                >
                  <i className="i-Add mr-2"></i> Add new item
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="float-right">
          <div>
            OrderAmount:₦
            {data.order_amount -
              data.order_delivery_fee -
              data.order_service_fee +
              data.order_discount}
          </div>
          <div>Delivery fee: ₦{numberWithCommas(data.order_delivery_fee)}</div>
          <div>Discount: ₦{data.order_discount}</div>
          <div>Insurance fee: ₦{data.order_insurance_fee}</div>
          <div>Service fee:₦{data.order_service_fee}</div>
          <div>Total Amount: ₦{numberWithCommas(grandTotal())}</div>
        </div>
      </div>
    </div>
  );
}
