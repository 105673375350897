import React, { useEffect } from "react";
import MainApp from "../../layouts/MainApp";
import CreateThirdPartyToken from "./CreateThirdPartyToken";
import AssignRole from "./AssignRole";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import moment from "moment";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";

export default function Settings() {
  // let user = JSON.parse(localStorage.getItem("user"));
  // let teamId = JSON.parse(localStorage.getItem("teamId"));

  // let isCompany = user.data.team_roles[teamId].roles.includes("company");

  const thirdPartyToken = useSelector((state) => state.admin.thirdPartyToken);
  const loading = useSelector((state) => state.admin.getSettingsLoader);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getThirdPartyToken());
  }, [dispatch]);

  function createTableData() {
    let dataToMap = thirdPartyToken;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((token, i) => {
        let singleRowArray = {
          created: moment(token.created).format("lll"),
          label: token.label,
          token: token.token,
          // actions: <span>delete</span>,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Created at",
        field: "created",
        sort: "asc",
        width: 100,
      },
      {
        label: "Label",
        field: "label",
        sort: "asc",
        width: 200,
      },
      {
        label: "Token",
        field: "token",
        sort: "asc",
        width: 500,
      },

      // {
      //     label: 'Actions',
      //     field: 'actions',
      //     sort: 'asc',
      //     width: 150
      // },
    ],
    rows: dataSet,
  };

  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className='main-content-padding'>
          <div className='main-content'>
            <div className='mb-2  d-flex justify-content-between '>
              <h1 className='mr-2'>Settings</h1>
              <CreateThirdPartyToken />
            </div>
            <div className='separator-breadcrumb border-top' />
            <div className='mb-4'>
              <h3 className='mb-3'>Third party token</h3>

              <h5 className='mb-3'>
                {" "}
                This allows a third party to create and edit orders for your
                company using the generated token{" "}
              </h5>
              <br></br>
              <div className='mb-4'>
                  <MDBDataTable
                    responsive
                  searching={false}
                  entries={5}
                  entriesOptions={[5, 8, 15]}
                  striped
                  bordered
                  hover
                  noBottomColumns
                  data={data}
                />
              </div>
              {/* 
              {isCompany && (
                <div>
                  <h3 className='mb-3'>Abilities</h3>
                  <a href='/settings/ability/1' className='btn btn-primary'>
                    Assign abilities
                  </a>
                </div>
              )} */}

              <AssignRole />
            </div>

            {/* end of row*/}
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
