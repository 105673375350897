import React from "react";
import { LOGOWHITE, FOODBG } from '../images/images';

export default function AuthLayout(props) {
  return (
    <div className="">
      <div className="row">
        <div className="col-md-4">
          <div
            className="auth-bg"
            style={{
              background: `url(${FOODBG})`,
              backgroundColor: "#BA2312",
              backgroundSize: "cover",
            }}
          >
            <div className="auth-logo text-center mb-4">
              <img src={LOGOWHITE} alt="Logo" />
            </div>
          </div>
        </div>
        <div className="col-md-7 offset-md-1">{props.children}</div>
      </div>
    </div>
  );
}
