import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RidersMap from "./RidersMap";
import { adminActions } from "../../../redux/actions";

export default function CourierMapWrapper({ rider }) {
  const rider_id = rider?.id;
  const company_id = rider?.company_id;
  const dispatch = useDispatch();
  const [locationList, setLocationList] = useState({});

  useEffect(() => {
    const locationRef = window?.firebase?.database().ref(company_id + "/");
    locationRef.on(`value`, (snapshot) => {
      const location = snapshot.val();
      setLocationList(location);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(adminActions.getLocationList({ location }));
    });
  }, [dispatch, company_id]);

  return (
    <div>
      {" "}
      <RidersMap id={rider_id} locationList={locationList} />
    </div>
  );
}
