import React from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

function ApproveRider({ riderData }) {
  const rider_id = riderData.id;

  const dispatch = useDispatch();

  let dataObject = {
    // rider_email: riderData.rider_email,
    rider_name: riderData.courier_name,
    rider_phone: riderData.courier_phone,
    rider_address: riderData.courier_address,
    rider_city: riderData.courier_city,
    rider_state: riderData.courier_state,
    rider_country: riderData.courier_country,
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const updating = useSelector((state) => state.admin.updating);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(userActions.approveRider({ id: rider_id }, setOpen));
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn onClick={() => setOpen(true)} title='Approve rider'>
          Approve
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name='form' onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>Approve rider</MDBModalHeader>
            <MDBModalBody>
              <div className='text-left col pt-4 px-3'>
                <h5>Would you like to approve the rider named:  { dataObject.rider_name}</h5>
                {/* <p>Email: {dataObject.rider_email}</p><br/> */}
                <h5>Phone: { dataObject.rider_phone}  </h5>
               
                <h5> Address: { dataObject.rider_address}</h5>
               
                <p>
                  Located at: { dataObject.rider_city}, { dataObject.rider_state},{" "}
                  { dataObject.rider_country}?
                </p>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit'>
                {updating === true ? (
                  <Beat loading={updating} />
                ) : (
                  "Approve rider"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default ApproveRider;
