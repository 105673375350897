import React, { useState } from "react";
import MainApp from "../../layouts/MainApp";
import RiderList from "./RiderList";
import RiderMapWrapper from "./RiderMapWrapper";

export default function Riders() {
  const [
    view,
    // eslint-disable-next-line
    SetView,
  ] = useState("allRider");
  return (
    <MainApp>
      <div className="main-content">
        {/* ============ Body content start ============= */}
        <div className="row">
          <div className="col-md-8">
            <RiderMapWrapper view={view} />
          </div>
          <div className="col-md-4">
            <RiderList />
          </div>
        </div>
      </div>
    </MainApp>
  );
}
