import React, { Fragment } from "react";

function PageNumber({ data }) {
  let last_page = data && data.last_page;

  let current_page = data && data.current_page;

  return (
    <Fragment>
      {data && (
        <h5>
          Page {current_page} of {last_page}
        </h5>
      )}
    </Fragment>
  );
}

export default PageNumber;
