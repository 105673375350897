import React, { useEffect } from "react";
// import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

function ViewEmployee({ employeeData }) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(adminActions.getCountries());
  }, [dispatch]);

  const employeeDetails = useSelector((state) => state.admin.singleEmployee);

  let employeeUser = employeeDetails && employeeDetails.user;

  let employeeRoles = employeeUser && employeeUser.roles;

  return (
    <div>
      <MDBContainer>
        <MDBBtn
          onClick={() => {
            setOpen(true);
            dispatch(adminActions.getSingleEmployee(employeeData.id));
          }}
        >
          View Roles
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <MDBModalHeader toggle={handleClose}>
            View employee details
          </MDBModalHeader>
          <MDBModalBody>
            <div className="text-left pt-4 p-2">
              {employeeDetails && (
                <div>
                  <h4 className="mb-2">
                    Employee name: {employeeDetails.employee_name}
                  </h4>
                  <h4 className="mb-2">Employee roles:</h4>
                  <ul>
                    {employeeRoles &&
                      employeeRoles.map((role, i) => {
                        return <li key={i}>{role.name}</li>;
                      })}
                  </ul>
                </div>
              )}

              <p>
                To add more roles eg: supervisor, accountant, to this user, go
                to <a href="/settings">settings</a> and add role to a user.{" "}
              </p>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>

          <div></div>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default ViewEmployee;
