import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MDBBtn } from "mdbreact";
import AssignedOrders from "./AssignedOrders";
import CompletedOrders from "./CompletedOrders";
import CreateOrder from "./CreateOrder.js";
import ClosedOrders from "./ClosedOrders";
import UnpaidUnassignedOrders from "./UnpaidUnassignedOrders";
import { adminActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";

export default function OrdersList() {
  const singleOrder = useSelector((state) => state.admin.singleOrder);
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  const loading = useSelector((state) => state.admin.getUnassignedLoader);

  // const unassignedOrders = useSelector((state) => state.admin.unassigned);
  const assignedOrders = useSelector((state) => state.admin.assigned);
  const completedOrders = useSelector((state) => state.admin.completed);
  const closedOrders = useSelector((state) => state.admin.closedOrders);
  const unassigned_unpaid_Orders = useSelector(
    (state) => state.admin.unassigned_unpaid
  );
  const unassigned_paid_Orders = useSelector(
    (state) => state.admin.unassigned_paid
  );

  const dispatch = useDispatch();
  let params = useParams();
  const [page, setPage] = useState(1);

  useEffect(() => {
    // dispatch(adminActions.getUnassignedOrders(company_id, params.page));
    dispatch(adminActions.getPaidUnassignedOrders(company_id, params.page));
    dispatch(adminActions.getUnpaidUnassignedOrders(company_id, params.page));
    dispatch(adminActions.getAssignedOrders(company_id, params.page));
    dispatch(adminActions.getCompletedOrderList(company_id, params.page));
    dispatch(adminActions.getClosedOrders(company_id, params.page));
  }, [dispatch, params, company_id]);

  const loadMore = () => {
    dispatch(
      adminActions.getPaidUnassignedOrdersLoadMore(
        company_id,
        page + 1,
        setPage
      )
    );
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  //checking if an hour has passed since order was created
  function checkTimePassed(time) {
    const hour = 60 * 60 * 1000;
    const hourLater = new Date() - new Date(time);
    if (hourLater >= hour) {
      return true;
    }
  }

  // function makeApiCall() {
  //   console.log("check times it called");
  //   // Code to make the API call goes here
  //   dispatch(adminActions.getUnassignedOrders(company_id, params.page));
  // }
  const makeApiCall = useCallback(() => {
    // Code to make the API call goes here
    dispatch(adminActions.getPaidUnassignedOrders(company_id, params.page));
  }, [company_id, params.page, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      makeApiCall();
    }, 5 * 60 * 1000); // Execute the fetchData function every 5 minutes (5 * 60 seconds * 1000 milliseconds)

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [makeApiCall]);

  return (
    <div>
      <div className="text-left order-wrapper">
        <div className="d-flex justify-content-between mb-3">
          <h3 className="mt-2">Order List</h3>
          <CreateOrder />
        </div>

        <ul className="nav nav-pills mb-2" id="myPillTab" role="tablist">
          {unassigned_paid_Orders && (
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-icon-pill"
                data-toggle="pill"
                href="#homePIll"
                role="tab"
                aria-controls="homePIll"
                aria-selected="true"
              >
                <span>({unassigned_paid_Orders?.data?.length})</span> Paid
                Unassigned
              </a>
            </li>
          )}

          {unassigned_unpaid_Orders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="unpaid-icon-pill"
                data-toggle="pill"
                href="#unpaidPIll"
                role="tab"
                aria-controls="unpaidPIll"
                aria-selected="false"
              >
                <span>({unassigned_unpaid_Orders?.data?.length})</span> Unpaid
                Unassigned
              </a>
            </li>
          )}
          {assignedOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-icon-pill"
                data-toggle="pill"
                href="#profilePIll"
                role="tab"
                aria-controls="profilePIll"
                aria-selected="false"
              >
                <span>({assignedOrders?.data?.length})</span> Assigned
              </a>
            </li>
          )}
          {completedOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="contact-icon-pill"
                data-toggle="pill"
                href="#contactPIll"
                role="tab"
                aria-controls="contactPIll"
                aria-selected="false"
              >
                <span>({completedOrders?.data?.length})</span> Delivered
              </a>
            </li>
          )}

          {closedOrders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="closed-icon-pill"
                data-toggle="pill"
                href="#closedPIll"
                role="tab"
                aria-controls="closedPIll"
                aria-selected="false"
              >
                <span>({closedOrders?.data?.length})</span> Closed
              </a>
            </li>
          )}
        </ul>

        <div className="" id="myPillTabContent">
          <div
            className="tab-pane fade show active"
            id="homePIll"
            role="tabpanel"
            aria-labelledby="home-icon-pill"
          >
            <div>
              <div
                className="scroll-list"
                data-perfect-scrollbar
                data-suppress-scroll-x="true"
              >
                {unassigned_paid_Orders &&
                unassigned_paid_Orders?.data?.length === 0
                  ? "No content found"
                  : unassigned_paid_Orders?.data?.map((order, i) => {
                      return (
                        <div
                          key={i}
                          className="card text-left mb-2"
                          onClick={() =>
                            dispatch(adminActions.getAnOrder(order.id))
                          }
                        >
                          <div
                            className={
                              order.id === singleOrder.id
                                ? "active-order card-body d-flex justify-content-between "
                                : "card-body d-flex justify-content-between "
                            }
                          >
                            <div className="single-order">
                              <div>
                                {checkTimePassed(
                                  moment(order.created_at).format("lll")
                                ) && (
                                  <i className="clock-icon i-Clock bounce" />
                                )}
                              </div>

                              <h5>
                                Order <span>#{order.id}</span>
                              </h5>
                              <p className="mb-0">
                                <i className="nav-icon i-Clock" />
                                <span>
                                  {moment(order.created_at).format("lll")}
                                </span>
                              </p>
                            </div>
                            <div className="text-right">
                              <h4 className="font-weight-bold">
                                ₦{numberWithCommas(order.order_amount)}
                              </h4>
                              <h5 className="text-capitalize">
                                {order.order_paid === true ? (
                                  <span className="badge badge-pill badge-outline-success p-2">
                                    Paid
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-outline-danger p-2">
                                    Not Paid
                                  </span>
                                )}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                <div>
                  {page === unassigned_paid_Orders?.last_page ? (
                    <p className="text-center my-4">--The End--</p>
                  ) : (
                    <MDBBtn
                      onClick={() => loadMore()}
                      className="pt-2 ml-4 pb-2"
                      color="primary"
                    >
                      {loading === true ? (
                        <Beat loading={loading} />
                      ) : (
                        "Load more..."
                      )}
                    </MDBBtn>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="unpaidPIll"
            role="tabpanel"
            aria-labelledby="unpaid-icon-pill"
          >
            <UnpaidUnassignedOrders />
          </div>

          <div
            className="tab-pane fade"
            id="profilePIll"
            role="tabpanel"
            aria-labelledby="profile-icon-pill"
          >
            <AssignedOrders />
          </div>

          <div
            className="tab-pane fade"
            id="contactPIll"
            role="tabpanel"
            aria-labelledby="contact-icon-pill"
          >
            <CompletedOrders />
          </div>
          <div
            className="tab-pane fade"
            id="closedPIll"
            role="tabpanel"
            aria-labelledby="closed-icon-pill"
          >
            <ClosedOrders />
          </div>
        </div>
      </div>
    </div>
  );
}
