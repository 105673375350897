import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { userActions } from "../../../redux/actions";

export default function AcceptInvite({ modalVisible, modalControl, data }) {
  //MODAL ACTIONS
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    history.push(`/auth/inviteRegisteration?invite_code=${data.invite_code}`);
    modalControl();
  };
  const handleReject = () => {
    dispatch(userActions.rejectInvite(data.id));
    modalControl();
  };
  return (
    <div>
      <MDBContainer>
        <MDBModal isOpen={modalVisible} toggle={modalControl}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={modalControl}>Accept Invite</MDBModalHeader>
            <MDBModalBody>
              <div className="text-left col pt-4 px-3">
                <h5>
                  Are you sure you want to accept this invite from:
                  {data["company"]?.company_name.toUpperCase()}
                  <br></br>
                  to become {data?.type} here:
                </h5>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => handleReject()}>
                Reject
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                Accept
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
