import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cleanInput } from "../../redux/helpers";

export const FilterUserOrders = ({
  date,
  start_date,
  setStartDate = () => {},
  end_date,
  setEndDate = () => {},
}) => {
  let params = useParams();

  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    start_date: start_date,
    end_date: end_date,
    pending: "",
  });
  useEffect(() => {
    setPayload({
      ...payload,
      start_date: start_date,
      end_date: end_date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date]);

  //filtering remittances by date
  const [filterValue, setFilterValue] = useState("");
  const handleClick = (filterValue) => {
    setFilterValue(filterValue);

    switch (filterValue) {
      case "pending":
        setPayload({
          ...payload,
          filterType: "",
        });
        break;

      case "completed":
        setPayload({
          ...payload,
          filterType: "completed",
          pending: "",
        });
        break;

      default:
        setPayload({
          ...payload,
          filterType: "",
        });
        break;
    }
  };

  useEffect(() => {
    cleanInput(payload);
    dispatch(userActions.filterUserOrders(payload, params.page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, params.page, filterValue]);

  return (
    <div>
      <div className="d-flex fil-all">
        <button
          type="Link"
          className="btn btn-link"
          style={{ color: filterValue === "pending" ? "#D14522" : "black" }}
          onClick={() => handleClick("pending")}
        >
          Pending
        </button>
        <button
          type="Link"
          className="btn btn-link"
          style={{ color: filterValue === "completed" ? "#D14522" : "black" }}
          onClick={() => {
            handleClick("completed");
          }}
        >
          Completed
        </button>
      </div>
      <div className="separator-breadcrumb border-top" />
      <div className="date-picker-wrapper">
        <form>
          <div
            className="row d-flex justify-content-between"
            style={{ marginLeft: "10px" }}
          >
            <div className="allign col-md-6">
              <i
                className=" 
            i-Calendar-4"
                style={{ marginRight: "10px", marginTop: "10px" }}
              />
              <div className={"div-box" + (!start_date ? " is-invalid" : "")}>
                <DatePicker
                  className="form-control"
                  selected={start_date}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <span style={{ margin: "2px 2px" }}>-</span>
              <div className={"div-box " + (!end_date ? " is-invalid" : "")}>
                <DatePicker
                  className="form-control"
                  selected={end_date}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
