import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { adminActions } from "../../../redux/actions/admin.actions";
import { fireApp } from "../../firebase/firebaseInit";
import { getDatabase } from "firebase/database";

const db = getDatabase(fireApp);

export const LocationTracker = () => {
  const dispatch = useDispatch();
  const myCourier = useSelector((state) => state.admin.myCourier);

  React.useEffect(() => {
    dispatch(adminActions.getMyCourier());
  }, [dispatch]);

  React.useEffect(() => {
    if (myCourier && myCourier.status === "active") {
      setTimeout(() => {
        console.log("timer");
        navigator.geolocation.getCurrentPosition(function (geoLocation) {
          if (geoLocation && myCourier) {
            db.ref(`${myCourier.company_id}/${myCourier.id}`).set({
              location: geoLocation,
              coords: {
                accuracy: geoLocation.coords.accuracy,
                altitude: geoLocation.coords.altitude,
                altitudeAccuracy: geoLocation.coords.altitudeAccuracy,
                heading: geoLocation.coords.heading,
                latitude: geoLocation.coords.latitude,
                longitude: geoLocation.coords.longitude,
                speed: geoLocation.coords.speed,
              },
              courier_name: myCourier.courier_name,
              status: myCourier.status,
              order_status: {
                order_accepted: myCourier.orders_accepted,
                order_pending: myCourier.orders_pending,
                order_canceled: myCourier.orders_canceled,
                order_completed: myCourier.orders_completed,
              },
            });
          }
        });
      }, 60000);
    }
  }, [myCourier]);

  return null;
};
