import * as xlsx from "xlsx";

/**
 * Read the file and parse it to JSON
 * @param {file} file[0] single file object
 * @param {function} callBack
 */
export const readFileWithXLSX = (file, callBack = () => {}) => {
  const reader = new FileReader();
  // reader.readAsDataURL(file);
  // reader.readAsText(file);
  // reader.readAsArrayBuffer(file);
  reader.readAsBinaryString(file);
  reader.onload = () => {
    // let workbook = xlsx.read(reader.result.split(',').pop(), {type: "base64"});
    // let workbook = xlsx.read(reader.result, {type: "string"});
    // let workbook = xlsx.read(reader.result, {type: "array"});
    let workbook = xlsx.read(reader.result, { type: "binary" });

    // Parse out the first sheet as JSON
    let data = xlsx.utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]],
      { header: 1 }
    );

    // Return results
    callBack(data);
  };
  reader.onerror = (error) => console.log(error);
};

/**
 * Transform the data to xlsx format
 * @param {array:array} data array of array
 * @param {object} options {download:true, filename:"filename.xlsx"}}
 */
export const writeFileWithXLSX = (
  data,
  options = { download: true, filename: "sheet.xlsx" }
) => {
  const wb = xlsx.utils.book_new();
  const ws = xlsx.utils.json_to_sheet(data, { skipHeader: false });
  xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
  xlsx.writeFile(wb, options.filename);
};
