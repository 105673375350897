import React, { useEffect } from "react";
import { AVATAR } from "../../images/images";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";

export default function InactiveRiders() {
  const inactiveRiders = useSelector((state) => state.users.rider_inactive);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getInactiveRider());
  }, [dispatch]);

  return (
    <div>
      {inactiveRiders && inactiveRiders?.length === 0
        ? "No item found"
        : inactiveRiders?.map((rider, key) => {
            return (
              <div key={key} className='d-flex rider-list'>
                <div className='rider-img mr-2'>
                  <img className='rounded-circle' src={AVATAR} alt='' />
                </div>
                <div className='col-xs-6 details' style={{ width: "60%" }}>
                  <span className='name text-muted'>{rider.courier_name}</span>
                  <p className='m-0'>{rider.courier_phone}</p>
                </div>
                <div className='col-xs-3 date'>
                  <a href={`/riderView/${rider.id}`} className='text-muted'>
                    See details <i className='nav-icon i-Arrow-Right' />
                  </a>
                </div>
              </div>
            );
          })}
    </div>
  );
}
