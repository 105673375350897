import React from "react";
import UserMainApp from "../layouts/UserMainApp";
import { useSelector, useDispatch } from "react-redux";
import ChangePassword from "../auth/ChangePassword";
//import { PImage } from "../images/images";
import { userActions } from "../../redux/actions";
import ChangeUserPhone from "./profile/changeUserPhone";
import ImageUploader from "./profile/userImageUpload";

export default function UserProfile() {
  let user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  React.useEffect(() => {
    dispatch(userActions.getMyReferralCode());
  }, [dispatch]);
  const myReferral = useSelector((state) => state.users.myReferral);

  return (
    <UserMainApp>
      <div className="main-content-padding">
        <div className="main-content">
          {/* ============ Body content start ============= */}
          <div className="breadcrumb">
            <h1 className="mr-2 mt-2">My Profile</h1>
          </div>
          <div className="separator-breadcrumb border-top" />

          <div className="row">
            <div className="col-md-6">
              <ImageUploader />
              <div className="mb-2 pb-2 mt-4">
                <h4 className="text-primary mb-2 mr-2 text-capitalize">
                  {" "}
                  {user.data.name}
                </h4>
                {/* <p className='text-primary mb-1'> {user_rank(user)}</p> */}
                <br />
                {/* <h3 className='text-primary'>Description</h3>
                {user.data.description}
                <AddDescription userData={user} /> */}
              </div>

              <div className="row">
                <div className="col-md-6 col-6">
                  <div className="mb-4">
                    <p className="text-primary mb-1 mr-2">
                      <i className="i-Envelope text-16 mr-1" /> Email
                    </p>
                    <span>{user.data.email}</span>
                  </div>
                </div>
                <div className="col-md-6  col-6">
                  <div className="mb-4">
                    <p className="text-primary mb-1">
                      <i className="i-Telephone text-16 mr-1" /> Telephone
                    </p>
                    <span className="mb-1">{user.data.phone}</span>
                    <br />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6  col-6">
                  <div className="mb-4">
                    <p className="text-primary mb-1">
                      <i className="i-Paper-Plane text-16 mr-1" /> Referral Code
                    </p>
                    <span className="mb-1">
                      {myReferral?.data?.referral_code}
                      <i
                        className="i-Files ml-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          copyTextToClipboard(myReferral?.data?.referral_code);
                        }}
                      />
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <ChangePassword />
            <ChangeUserPhone />
          </div>
        </div>
      </div>
    </UserMainApp>
  );
}
