import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function Pagination({ data, route }) {
  let params = useParams();
  let page = params.page;
  let last_page = data && data.last_page;
  let first_page = 1;
  let current_page = data && data.current_page;

  return (
    <Fragment>
      <div className='d-flex justify-content-between mt-4'>
        {data && (
          <h5>
            Showing {data.from} - {data.to} of {data.total} results
          </h5>
        )}

        {data && (
          <ul className='pagination'>
            <li>
              <Link
                to={`/${route}/${page > first_page ? Number(page) - 1 : page}`}
                className='page-link'>
                Previous
              </Link>
            </li>

            <li>
              <Link to={`/${route}/${current_page}`} className='page-link'>
                {current_page}
              </Link>
            </li>

            {/* <li>
              <Link to={`/${route}/${last_page}`} className='page-link'>
                {last_page}
              </Link>
            </li> */}

            <li>
              <Link
                to={`/${route}/${page < last_page ? Number(page) + 1 : page}`}
                className='page-link'>
                Next
              </Link>
            </li>
          </ul>
        )}
      </div>
    </Fragment>
  );
}

export default Pagination;
