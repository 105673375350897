import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions/user.actions";
import { Beat } from "../../../redux/reducers/spinner";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function InviteRider() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user?.data?.groups[teamId]?.company_id;

  // Modal actions
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [input, setInput] = useState({
    invitee_email: "",
    job_title: "",
    job_description: "",
    type: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { invitee_email, job_description, job_title, type } = input;
  const dispatch = useDispatch();

  const sending = useSelector((state) => state.authentication.sending);

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (invitee_email && job_description && job_title && type) {
      // get return url from location state or default to home page
      dispatch(userActions.inviteUser(input, company_id, setOpen));
    }
  }

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => setOpen(true)}>
          Invite Rider
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name='form' onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Invite a new courier to your company
            </MDBModalHeader>
            <MDBModalBody>
              <div className='text-left row pt-4'>
                <div
                  className={
                    "form-group  col-md-12 mb-3" +
                    (submitted && !invitee_email ? " is-invalid" : "")
                  }>
                  <label htmlFor='invitee_email'>Email address</label>
                  <input
                    id='invitee_email'
                    type='invitee_email'
                    name='invitee_email'
                    value={invitee_email}
                    placeholder='Enter email'
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !invitee_email ? " is-invalid" : "")
                    }
                  />
                  {submitted && !invitee_email && (
                    <div className='invalid-feedback'>Email is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12  mb-3" +
                    (submitted && !type ? " is-invalid" : "")
                  }>
                  <label htmlFor='type'>Position</label>
                  <select
                    id='type'
                    type='type'
                    name='type'
                    value={type}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !type ? " is-invalid" : "")
                    }>
                    <option value='none'>Position</option>
                    <option value='employee'>Employee</option>
                    <option value='courier'>Courier</option>
                  </select>

                  {submitted && !type && (
                    <div className='invalid-feedback'>Position is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group  col-md-12 mb-3" +
                    (submitted && !job_title ? " is-invalid" : "")
                  }>
                  <label htmlFor='job_title'>Job Title</label>
                  <input
                    id='job_title'
                    type='job_title'
                    name='job_title'
                    value={job_title}
                    onChange={handleChange}
                    placeholder='Junior Courier'
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !job_title ? " is-invalid" : "")
                    }
                  />
                  {submitted && !job_title && (
                    <div className='invalid-feedback'>
                      Job title is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group  col-md-12 mb-3" +
                    (submitted && !job_description ? " is-invalid" : "")
                  }>
                  <label htmlFor='job_title'>Job_description</label>
                  <input
                    id='job_description'
                    type='job_description'
                    name='job_description'
                    value={job_description}
                    onChange={handleChange}
                    placeholder='Deliveries'
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !job_description ? " is-invalid" : "")
                    }
                  />
                  {submitted && !job_description && (
                    <div className='invalid-feedback'>
                      Job description is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit'>
                {sending === true ? <Beat loading={sending} /> : "Invite Rider"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
