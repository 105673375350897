import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable } from "mdbreact";
import CreateStores from "./CreateStores";
import EditStore from "./EditStore";
import DeleteStore from "./DeleteStore";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import ReuseableSearchBar from "../../search/search";
import TableLoader from "../../includes/Loaders/TableLoader";
import PageNumber from "../../includes/PageNumber";

export default function Stores() {
  const allOutlet = useSelector((state) => state.admin.allOutlet);
  const loading = useSelector((state) => state.admin.getOutletLoader);
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  const searching = useSelector((state) => state.admin.searching);

  const dispatch = useDispatch();

  let params = useParams();

  useEffect(() => {
    dispatch(adminActions.getAllOutlet(company_id, params.page));
  }, [dispatch, company_id, params]);

  let outletData = allOutlet.data;
  function createTableData() {
    let dataToMap = outletData && outletData.data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((outlet, i) => {
        let singleRowArray = {
          name: outlet.outlet_name,
          email: outlet.outlet_email,
          contact: outlet.outlet_phone,
          country: outlet.outlet_country,
          state: outlet.outlet_state,
          city: outlet.outlet_city,
          actions: (
            <div className='d-flex'>
              <EditStore outletData={outlet} />
              <DeleteStore outletData={outlet} />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact no.",
        field: "contact",
        sort: "asc",
        width: 100,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };

  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className='main-content-padding'>
          <div className='main-content'>
            <div className='d-flex justify-content-between mb-3'>
              <h1 className='mr-2'>Outlets</h1>
              <CreateStores />
            </div>

            <div className='separator-breadcrumb border-top' />
            <div className='search-page'>
              <PageNumber data={outletData} route='outlets' />
              <ReuseableSearchBar
                companyId={company_id}
                page={params.page}
                searchAction={adminActions.searchAllOutlet}
                clearSearchResultsAction={
                  adminActions.getAllOutlet
                }></ReuseableSearchBar>
            </div>

            <div>
              {searching ? (
                <TableLoader />
              ) : (
                    <MDBDataTable
                      responsive
                  entries={8}
                  entriesOptions={[8, 15, 20]}
                  // striped
                  bordered
                  theadColor='MDBTableHeader'
                  hover
                  maxHeight='400px'
                 
                  noBottomColumns
                  data={data}
                  paging={false}
                  searching={false}
                />
              )}
            </div>
            <Pagination data={outletData} route='outlets' />
            {/* end of row*/}
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
