// import config from 'config';
import { authHeader, history } from "../helpers";
import base_url from "./base_url";

export const adminService = {
  getAllOrders,
  getAllOrdersByDate,
  getAllOrdersByDateNoPagination,
  searchOrders,
  getClosedOrders,
  getCourierOrders,
  getACourierOrder,
  getUnassignedOrders,
  getPaidUnassignedOrders,
  getUnpaidUnassignedOrders,
  getAssignedOrders,
  getCompletedOrderList,
  getCompletedOrders,
  createOrder,
  editOrder,
  CloseOrder,
  assignOrder,
  unAssignOrder,
  acceptOrder,
  rejectOrder,
  completeOrder,
  cancelOrder,
  getSingleOrder,
  getSingleRider,
  getAnOrder,
  remitOrder,
  commentToRemit,
  getRemittedOrders,
  filterRemittedOrders,
  filterRemittedOrdersNoPage,
  filterDeliveryReport,
  filterCourierRemittedOrders,
  //markAsClosed,
  markAsdelivered,
  verifyRemittance,
  getAllCompanies,
  approveCompany,
  getEmployees,
  createEmployees,
  editEmployee,
  deleteEmployee,
  approveEmployee,
  getSingleEmployee,
  setEmployeeActive,
  setRiderInactive,
  setEmployeeInactive,
  getCustomers,
  createCustomers,
  searchCustomers,
  searchEmployee,
  setRiderActive,
  editCustomer,
  deleteCustomer,
  getRoles,
  assignRole,
  getAllAbility,
  assignAbility,
  getCompanyStats,
  getCourierStats,
  getEmployeeStats,
  getGeneralStats,
  getCountries,
  getAllStates,
  getStates,
  getAllOutlet,
  createOutlet,
  searchOutlet,
  searchCompany,
  editOutlet,
  deleteOutlet,
  getMyCompany,
  getMyCourier,
  createThirdPartyToken,
  getThirdPartyToken,
  getMyPendingCourierOrders,
  getMyAcceptedCourierOrders,
  getMyCompletedCourierOrders,
  getMyCancelledCourierOrders,
  getMyRejectedCourierOrders,
  getMyPendingRemittance,
  getMyCompletedRemittance,
  logout,
};

function getAllOrders() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${base_url}api/order/index?properties=1`, requestOptions).then(
    handleResponse
  );
}

function getAllOrdersByDate(company_id, start_date, end_date, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, start_date, end_date }),
  };

  return fetch(
    `${base_url}api/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getAllOrdersByDateNoPagination(company_id, start_date, end_date) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, start_date, end_date, pagination: 0 }),
  };

  return fetch(`${base_url}api/order/filter/index`, requestOptions).then(
    handleResponse
  );
}

function searchOrders(payload) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...payload }),
  };

  return fetch(`${base_url}api/order/search/index?properties=1`, requestOptions).then(
    handleResponse
  );
}

function getMyPendingRemittance() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "completed", remitted: 0 }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getMyCompletedRemittance() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "completed", remitted: 1 }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getMyPendingCourierOrders() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "pending" }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getMyCancelledCourierOrders() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "canceled" }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getMyAcceptedCourierOrders() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "accepted" }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getMyRejectedCourierOrders() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "rejected" }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getMyCompletedCourierOrders() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "completed" }),
  };

  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}
function getCourierOrders(ordersData, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(ordersData),
  };

  return fetch(
    `${base_url}api/courier/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getUnassignedOrders(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "unassigned" }),
  };

  return fetch(
    `${base_url}api/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getPaidUnassignedOrders(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "unassigned", order_paid: "1" }),
  };

  return fetch(
    `${base_url}api/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getUnpaidUnassignedOrders(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "unassigned", order_paid: "0" }),
  };

  return fetch(
    `${base_url}api/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getAssignedOrders(company_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "assigned" }),
  };

  return fetch(`${base_url}api/order/filter/index`, requestOptions).then(
    handleResponse
  );
}

function getCompletedOrderList(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "delivered" }),
  };

  return fetch(
    `${base_url}api/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getCompletedOrders(company_id, start_date, end_date) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      company_id,
      start_date,
      end_date,
      status: "delivered",
    }),
  };

  return fetch(`${base_url}api/order/filter/index`, requestOptions).then(
    handleResponse
  );
}

function createOrder(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/order/store`, requestOptions).then(
    handleResponse
  );
}

function editOrder(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/order/update`, requestOptions).then(
    handleResponse
  );
}

function CloseOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };

  return fetch(`${base_url}api/order/mark/closed`, requestOptions).then(
    handleResponse
  );
}

function assignOrder(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/courier/order/assign`, requestOptions).then(
    handleResponse
  );
}

function acceptOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...id, status: "accepted" }),
  };

  return fetch(
    `${base_url}api/courier/order/update/status`,
    requestOptions
  ).then(handleResponse);
}
function rejectOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...id, status: "rejected" }),
  };

  return fetch(
    `${base_url}api/courier/order/update/status`,
    requestOptions
  ).then(handleResponse);
}
function completeOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...id, status: "completed" }),
  };

  return fetch(
    `${base_url}api/courier/order/update/status`,
    requestOptions
  ).then(handleResponse);
}
function cancelOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...id, status: "canceled" }),
  };

  return fetch(
    `${base_url}api/courier/order/update/status`,
    requestOptions
  ).then(handleResponse);
}
function unAssignOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };

  return fetch(`${base_url}api/courier/order/unassign`, requestOptions).then(
    handleResponse
  );
}

function getSingleOrder(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id }),
  };

  return fetch(
    `${base_url}api/order/show?id=${id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function getAnOrder(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${base_url}api/order/show?id=${id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function getACourierOrder(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${base_url}api/courier/order/show?courier_order_id=${id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function remitOrder(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/remit`,
    requestOptions
  ).then(handleResponse);
}
function commentToRemit(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/comment`,
    requestOptions
  ).then(handleResponse);
}

function getRemittedOrders(company_id, start_date, end_date, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      company_id,
      start_date,
      end_date,
    }),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function filterRemittedOrders(payload, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function filterRemittedOrdersNoPage(payload) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/filter/index?pagination=0`,
    requestOptions
  ).then(handleResponse);
}

function filterDeliveryReport(payload, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function filterCourierRemittedOrders(payload) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(`${base_url}api/courier/order/filter/me`, requestOptions).then(
    handleResponse
  );
}

function getClosedOrders(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, status: "closed" }),
  };

  return fetch(
    `${base_url}api/order/filter/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}
function markAsdelivered(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: id }),
  };
  return fetch(`${base_url}api/order/mark/delivered`, requestOptions).then(
    handleResponse
  );
}

function verifyRemittance(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };
  return fetch(
    `${base_url}api/courier/order/remittance/remit/verify`,
    requestOptions
  ).then(handleResponse);
}

function getAllCompanies(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${base_url}api/company/index?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function approveCompany(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id }),
  };
  return fetch(
    `${base_url}api/company/approve/management`,
    requestOptions
  ).then(handleResponse);
}

function getEmployees(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id }),
  };
  return fetch(
    `${base_url}api/employee/filter/index/?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function createEmployees(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${base_url}api/onboard/employee`, requestOptions).then(
    handleResponse
  );
}

function editEmployee(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${base_url}api/employee/update`, requestOptions).then(
    handleResponse
  );
}

function deleteEmployee(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };
  return fetch(`${base_url}api/employee/terminate`, requestOptions).then(
    handleResponse
  );
}

function approveEmployee(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };
  return fetch(`${base_url}api/employee/approve/company`, requestOptions).then(
    handleResponse
  );
}

function getSingleEmployee(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${base_url}api/employee/show?id=${id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function getSingleRider(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${base_url}api/courier/show?id=${id}&properties=1`,
    requestOptions
  ).then(handleResponse);
}

function setEmployeeActive() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "active" }),
  };
  return fetch(`${base_url}api/employee/update/status`, requestOptions).then(
    handleResponse
  );
}
function setRiderActive() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "active" }),
  };
  return fetch(`${base_url}api/courier/update/status`, requestOptions).then(
    handleResponse
  );
}

function setEmployeeInactive() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "inactive" }),
  };
  return fetch(`${base_url}api/employee/update/status`, requestOptions).then(
    handleResponse
  );
}
function setRiderInactive() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ status: "inactive" }),
  };
  return fetch(`${base_url}api/courier/update/status`, requestOptions).then(
    handleResponse
  );
}
function getCustomers(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id }),
  };

  return fetch(
    `${base_url}api/customer/filter/index/?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function createCustomers(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/customer/store`, requestOptions).then(
    handleSearchResponse
  );
}

function searchCustomers(search, company_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ search, company_id }),
  };

  return fetch(`${base_url}api/customer/search/index`, requestOptions).then(
    handleSearchResponse
  );
}

function searchEmployee(search) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ search }),
  };

  return fetch(`${base_url}api/employee/search/index`, requestOptions).then(
    handleSearchResponse
  );
}

function editCustomer(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/customer/update`, requestOptions).then(
    handleResponse
  );
}

function deleteCustomer(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };

  return fetch(`${base_url}api/customer/delete`, requestOptions).then(
    handleResponse
  );
}

function getRoles() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(`${base_url}api/role/index`, requestOptions).then(
    handleResponse
  );
}

function assignRole(user_id, team_id, role_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ user_id, team_id, role_id }),
  };

  return fetch(`${base_url}api/role/assign`, requestOptions).then(
    handleResponse
  );
}

function getAllAbility(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${base_url}api/permission/index/?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function assignAbility(input) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${base_url}api/permission/assign`, requestOptions).then(
    handleResponse
  );
}

function getCompanyStats(company_id, start_date, end_date) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, start_date, end_date }),
  };

  return fetch(`${base_url}api/stats/companies`, requestOptions).then(
    handleResponse
  );
}

function getCourierStats(company_id, start_date, end_date) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, start_date, end_date }),
  };

  return fetch(`${base_url}api/stats/couriers`, requestOptions).then(
    handleResponse
  );
}

function getEmployeeStats(company_id, start_date, end_date) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, start_date, end_date }),
  };

  return fetch(`${base_url}api/stats/employees`, requestOptions).then(
    handleResponse
  );
}

function getGeneralStats(company_id, date) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id, date }),
  };

  return fetch(`${base_url}api/stats/general`, requestOptions).then(
    handleResponse
  );
}

function getCountries() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${base_url}api/region/earth/index?category=countries`,
    requestOptions
  ).then(handleResponse);
}

function getAllStates() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${base_url}api/region/earth/index?category=states`,
    requestOptions
  ).then(handleResponse);
}

function getStates(country_id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ country_id }),
  };

  return fetch(`${base_url}api/region/earth/sort/index`, requestOptions).then(
    handleResponse
  );
}

function getAllOutlet(company_id, page) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ company_id }),
  };

  return fetch(
    `${base_url}api/outlet/filter/index/?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function createOutlet(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/outlet/store`, requestOptions).then(
    handleResponse
  );
}

function searchOutlet(search) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(`${base_url}api/outlet/search/index`, requestOptions).then(
    handleSearchResponse
  );
}
function searchCompany(search) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };
  return fetch(`${base_url}api/company/search/index`, requestOptions).then(
    handleSearchResponse
  );
}

function editOutlet(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/outlet/update`, requestOptions).then(
    handleResponse
  );
}

function deleteOutlet(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(id),
  };

  return fetch(`${base_url}api/outlet/delete`, requestOptions).then(
    handleResponse
  );
}

function getMyCompany() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(`${base_url}api/company/me`, requestOptions).then(
    handleResponse
  );
}
function getMyCourier() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(`${base_url}api/courier/me`, requestOptions).then(
    handleResponse
  );
}

function createThirdPartyToken(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${base_url}api/third-party/token/store`, requestOptions).then(
    handleResponse
  );
}

function getThirdPartyToken() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(`${base_url}api/third-party/token/me`, requestOptions).then(
    handleResponse
  );
}

function logout(callBack = () => {}) {
  // remove user from local storage to log user out
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  fetch(`${base_url}api/auth/logout`, requestOptions)
    .then((response) => response.json())
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      history("/auth/login");
      callBack();
      // return user;
    });
}

function handleSearchResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === 204) {
      return (response.data = { data: [] });
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === 204) {
      return (response.data = { data: { data: [] } });
    }
    //  if (response.status === 204) {
    //    return (response.data = { data: [] });
    //  }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
