import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import moment from "moment";

export default function PendingOrders() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  const pendingOrders = useSelector((state) => state.admin.myPending);
  const singleCourierOrder = useSelector((state) => state.admin.Courier);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getMyPendingCourierOrders(company_id));
  }, [dispatch, company_id]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div
      className="scroll-list"
      data-perfect-scrollbar
      data-suppress-scroll-x="true"
    >
      {/* <Rotate loading={true}/> */}
      {pendingOrders && pendingOrders.length === 0
        ? "No content found"
        : pendingOrders?.map((courierOrder, i) => (
            <div
              key={i}
              className="card text-left mb-2"
              onClick={() =>
                dispatch(adminActions.getACourierOrder(courierOrder?.id))
              }
            >
              <div
                className={
                  courierOrder?.id === singleCourierOrder?.id
                    ? "active-order card-body d-flex justify-content-between "
                    : "card-body d-flex justify-content-between "
                }
              >
                <div className="single-order">
                  <h5>
                    Order <span>#{courierOrder?.order?.id}</span>
                  </h5>
                  <p className="mb-0">
                    <i className="nav-icon i-Clock" />{" "}
                    <span>
                      {moment(courierOrder?.order?.created_at).format("lll")}
                    </span>
                  </p>
                </div>
                <div>
                  <h4 className="font-weight-bold">
                    ₦{numberWithCommas(courierOrder?.order?.order_amount)}
                  </h4>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}
