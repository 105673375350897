import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { LOGOWHITE, PImage } from "../images/images";
import { FOODBG } from "../images/images";
import { userActions, adminActions } from "../../redux/actions";

export default function Header() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  let user_details = user && user.data;
  const history = useHistory();

  let id = user_details && user_details.groups[teamId]?.employee_id;
  let riderId = user_details && user_details.groups[teamId]?.courier_id;

  let singleEmployee = useSelector((state) => state.admin.singleEmployee);
  let singleRider = useSelector((state) => state.admin.singleRider);
  const dispatch = useDispatch();

  const [isEmployee, setIsEmployee] = useState(false);
  const [isRider, setIsRider] = useState(false);

  const authority = () => {
    let user_rank = (user) => {
      if (user?.data?.team_roles[teamId]?.roles.includes("company")) {
        return "company_owner";
      }

      if (user?.data?.team_roles[teamId]?.roles.includes("employee")) {
        return "company_employee";
      }

      if (user?.data?.team_roles[teamId]?.roles.includes("courier")) {
        return "company_courier";
      }
    };
    if (
      user_rank(user) === "company_employee" ||
      user_rank(user) === "company_owner"
    ) {
      dispatch(adminActions.getSingleEmployee(id));
      setIsEmployee(true);
    } else {
      dispatch(adminActions.getSingleRider(riderId));
      setIsRider(true);
    }
  };

  useEffect(() => {
    authority();
    // eslint-disable-next-line
  }, []);

  function updateEmployeeActive() {
    dispatch(adminActions.setEmployeeActive(id));
  }
  function updateEmployeeInactive() {
    dispatch(adminActions.setEmployeeInactive(id));
  }
  function updateRiderActive() {
    dispatch(adminActions.setRiderActive(id));
  }
  function updateRiderInactive() {
    dispatch(adminActions.setRiderInactive(id));
  }
  function logout() {
    dispatch(userActions.logout());
  }
  function SwitchAccount() {
    localStorage.removeItem("teamId");
    history.push("/auth/switchAccount");
  }

  return (
    <div className="main-header" style={{ backgroundImage: `url(${FOODBG})` }}>
      <div className="logo">
        {isEmployee ? (
          <a href="/">
            <img src={LOGOWHITE} alt="Logo" />
          </a>
        ) : (
          <a href="/courier/dashboard">
            <img src={LOGOWHITE} alt="Logo" />
          </a>
        )}
      </div>
      <div className="menu-toggle">
        <div />
        <div />
        <div />
      </div>

      <div style={{ margin: "auto" }} />
      <div className="header-part-right">
        {/* User avatar dropdown */}
        {user?.data ? (
          <div className="dropdown">
            <div className="user col align-self-end">
              <div className="d-flex">
                <p className="text-white mb-1 mr-3 text-capitalize">
                  {" "}
                  Hello {user?.data?.name}
                </p>
                <div className="availability-status">
                  <img
                    src={PImage}
                    alt="avatar"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <span
                    className={
                      singleEmployee && singleEmployee.status === "inactive"
                        ? "availability-inactive status status-icon"
                        : "availability-active status-icon"
                    }
                  ></span>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="userDropdown"
                  >
                    <div className="dropdown-header">
                      <i className="i-Lock-User mr-1" /> {user?.data?.name}
                    </div>
                    <a className="dropdown-item" href="/user/profile">
                      My Profile
                    </a>
                    {isEmployee && (
                      <div className="dropdown-item">
                        <a href="#status">
                          Set status
                          <br />
                          <span>
                            {singleEmployee.status === "active" ? (
                              <span className="mode-icon availability-active mr-2"></span>
                            ) : (
                              <span className="mode-icon availability-inactive mr-2"></span>
                            )}
                            {singleEmployee.status}
                          </span>
                        </a>
                        <ul className="dropdown-item-inner">
                          <li onClick={() => updateEmployeeActive()}>
                            <span className="mode-icon availability-active mr-2"></span>
                            Active
                          </li>
                          <li onClick={() => updateEmployeeInactive()}>
                            <span className="mode-icon availability-inactive mr-2"></span>
                            Inactive
                          </li>
                        </ul>
                      </div>
                    )}
                    {isRider && (
                      <div className="dropdown-item">
                        <a href="#status">
                          Set status
                          <br />
                          <span>
                            {singleRider?.status === "active" ? (
                              <span className="mode-icon availability-active mr-2"></span>
                            ) : (
                              <span className="mode-icon availability-inactive mr-2"></span>
                            )}
                            {singleRider?.status}
                          </span>
                        </a>
                        <ul className="dropdown-item-inner">
                          <li onClick={() => updateRiderActive()}>
                            <span className="mode-icon availability-active mr-2"></span>
                            Active
                          </li>
                          <li onClick={() => updateRiderInactive()}>
                            <span className="mode-icon availability-inactive mr-2"></span>
                            Inactive
                          </li>
                        </ul>
                      </div>
                    )}

                    <button
                      className="dropdown-item"
                      onClick={() => SwitchAccount()}
                    >
                      Switch Account
                    </button>
                    <button className="dropdown-item" onClick={() => logout()}>
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <a
            href="/auth/login"
            className="btn btn-rounded btn-secondary btn-block"
          >
            Login
          </a>
        )}
      </div>
    </div>
  );
}
