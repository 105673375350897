import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function AssignToEmployee({ id }) {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  let company_id = user.data.groups[teamId].company_id;

  const allEmployees = useSelector((state) => state.admin.allEmployees);
  let myEmployees = allEmployees.data && allEmployees.data.data;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getEmployees(company_id));
  }, [dispatch, company_id]);

  const [visible, setvisible] = useState(false);
  const assigning = useSelector((state) => state.admin.assigning);

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({ user_id: "" });
  const { user_id } = input;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };

  function assign() {
    let dataToMap = myEmployees;

    if (myEmployees && Array.isArray(myEmployees)) {
      let outputArray = [];
      dataToMap.map((employee, i) => {
        let singleRowArray = [
          <option value={employee.user_id} key={employee.user_id}>
            {employee.employee_name}
          </option>,
        ];
        outputArray.push(singleRowArray);
        return true;
      });

      return outputArray;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (user_id) {
      dispatch(
        adminActions.assignRole(input.user_id, company_id, id, setvisible)
      );
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => setvisible(true)}>
          Assign Role
        </MDBBtn>

        <MDBModal isOpen={visible}>
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>Assign this role to an employee</MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group" + (submitted && !user_id ? " is-invalid" : "")
                }>
                <label htmlFor='user_id'>Select Employee</label>
                <select
                  name='user_id'
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !user_id ? " is-invalid" : "")
                  }>
                  <option>Select employee</option>
                  {assign()}
                </select>
                {submitted && !user_id && (
                  <div className='invalid-feedback'>
                    You must select an employee
                  </div>
                )}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit' disabled={assigning}>
                {assigning === true ? <Beat loading={true} /> : "Assign Role"}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
