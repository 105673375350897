let init = {
    message:{}
}
export function alert(state = init, action) {
    // export function alert(state = {}, action) {
    switch (action.type) {
        case "SUCCESS":
            return {
                type: 'alert-success', 
                message: action.user
            };
        case "ERROR":
            return {
                type: 'alert-danger',
                message: action.user
            };
        case "CLEAR":
            return {};
        default:
            return state
    }
}