import React, {useState} from 'react';
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../../../redux/actions';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

function DeleteStore({ outletData }) {
    const outlet_id = outletData.id;
    const dispatch = useDispatch();

    let dataObject = {
        outlet_email: outletData.outlet_email,
        outlet_name: outletData.outlet_name,
        outlet_phone: outletData.outlet_phone,
        outlet_address: outletData.outlet_address,
        outlet_city: outletData.outlet_city,
        outlet_state: outletData.outlet_state,
        outlet_country: outletData.outlet_country,
    }


    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const deleting = useSelector(state => state.admin.deleting);


    const handleSubmit = (event) => {
        event.preventDefault();

        // dispatch action to delete data
        dispatch(adminActions.deleteOutlet({id:outlet_id }, setOpen) );

    }


    return (
        <div>
            <MDBContainer>
                <MDBBtn onClick={() => setOpen(true)} color="primary" title="Delete Outlet"><i className="nav-icon i-Close-Window" /></MDBBtn>
                <MDBModal isOpen={open} toggle={handleClose}>
                    <form name="form" onSubmit={handleSubmit}>
                        <MDBModalHeader toggle={handleClose}>Delete outlet</MDBModalHeader>
                        <MDBModalBody>
                            <div className="text-left row pt-4 px-3">
                                <h5>Would you like to delete the outlet named: {dataObject.outlet_name} </h5>,
                                <h5>Located at: {dataObject.outlet_address}, {dataObject.outlet_city}, {dataObject.outlet_state}, {dataObject.outlet_country}?</h5>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => setOpen(false)}>Close</MDBBtn>
                            <MDBBtn color="primary" type="submit">
                                {deleting === true ? <Beat loading={deleting} /> : "Delete outlet"}
                            </MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
        </div>


    )
}


export default DeleteStore;