import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function AssignToRole({ id }) {
  const allRoles = useSelector((state) => state.admin.allRoles);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getRoles());
  }, [dispatch]);

  const [visible, setvisible] = useState(false);
  const assigning = useSelector((state) => state.admin.assigning);

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({ permission_id: "", role_id: "" });
  const { role_id } = input;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };
  function assign() {
    let dataToMap = allRoles;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((role, i) => {
        let singleRowArray = [
          <option value={role.id} key={role.id}>
            {role.name}
          </option>,
        ];
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (role_id) {
      dispatch(
        adminActions.assignAbility({ ...input, permission_id: id }, setvisible)
      );
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => setvisible(true)}>
          Assign Permission
        </MDBBtn>

        <MDBModal isOpen={visible}>
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>Assign this permission to a role</MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group" + (submitted && !role_id ? " is-invalid" : "")
                }>
                <label htmlFor='role_id'>Select Role</label>
                <select
                  name='role_id'
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !role_id ? " is-invalid" : "")
                  }>
                  <option>Select role</option>
                  {assign()}
                </select>
                {submitted && !role_id && (
                  <div className='invalid-feedback'>You must select a role</div>
                )}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit' disabled={assigning}>
                {assigning === true ? (
                  <Beat loading={true} />
                ) : (
                  "Assign Permission"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
