import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";
import { Beat } from "../../redux/reducers/spinner";

export default function Login() {
  const [input, setInput] = useState({ email: "", password: "" });
  const [submitted] = useState(false);
  const { email, password } = input;
  const location = useLocation();
  const dispatch = useDispatch();

  const loggingIn = useSelector((state) => state.authentication.loggingIn);

  function toggleFunction(e) {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (email && password) {
      // get return url from location state or default to home page
      const { from } = location.state || { from: { pathname: "/" } };
      dispatch(userActions.login(email, password, from));
    }
  }

  return (
    <AuthLayout>
      <div className="p-4 auth-box">
        <div className="auth-title">
          <h1 className="mb-4 text-primary font-weight-bold">
            Welcome to Ogwugo Delivery
          </h1>
          <h4 className="mb-4">Sign in by entering your information below </h4>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div
            className={
              "form-group mb-3" + (submitted && !email ? " is-invalid" : "")
            }
          >
            <label htmlFor="email">Email address</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !email ? " is-invalid" : "")
              }
            />
            {submitted && !email && (
              <div className="invalid-feedback">Email is required</div>
            )}
          </div>
          <div
            className={
              "form-group" + (submitted && !password ? " is-invalid" : "")
            }
          >
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !password ? " is-invalid" : "")
              }
            />
            {submitted && !password && (
              <div className="invalid-feedback">Password is required</div>
            )}
            <i
              className="i-Eye-Visible toggleIcon"
              onClick={toggleFunction}
            ></i>
          </div>
          <div className="mt-3 text-right">
            <a className="text-muted" href="/auth/password">
              <u>
                <i>Forgot Password?</i>
              </u>
            </a>
          </div>

          <button className="btn btn-rounded btn-primary btn-block mt-4">
            {loggingIn === true ? <Beat loading={loggingIn} /> : "Sign In"}
          </button>
        </form>
        <div className="mt-3 text-center">
          <a className="text-muted" href="/auth/registerUser">
            <u>Register User</u>
          </a>
        </div>
        <div className="mt-4 text-center">
          See our{" "}
          <a className="text-muted" href="/terms-of-service">
            <u>terms of service</u>,
          </a>{" "}
          <a className="text-muted" href="/privacy-policy">
            <u>privacy policy</u>
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}
