import React, { useEffect, useState } from "react";

import {
  Marker,
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  // DirectionsRenderer,
} from "react-google-maps";
import { useSelector } from "react-redux";

function Map() {
  const {
    InfoBox,
  } = require("react-google-maps/lib/components/addons/InfoBox");
  const location = useSelector((state) => state.admin.locationList);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [props, setProps] = useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (location?.location) {
      setProps((p) => ({
        ...p,
        lat: location?.location?.coords.latitude,
        lng: location?.location?.coords.longitude,
      }));
    }
  }, [location]);

  return (
    <>
      <GoogleMap
        zoom={props?.lat ? 17 : 6}
        center={{
          lat: props?.lat ?? 6.465422,
          lng: props?.lng ?? 3.406448,
        }}
      >
        {location.location && (
          <Marker
            position={{
              lat: location?.location?.coords?.latitude,
              lng: location?.location?.coords?.longitude,
            }}
            onChange={() => {}}
            onClick={() => {
              setSelectedLocation(location.location);
            }}
          />
        )}

        {selectedLocation && (
          <InfoBox
            position={{
              lat: selectedLocation.coords.latitude,
              lng: selectedLocation.coords.longitude,
            }}
            onCloseClick={() => {
              setSelectedLocation(null);
            }}
          >
            <div className="info-box">
              <div className="info-text">
                <p>Courier Name: {selectedLocation.courier_name}</p>
                <p>Status: {selectedLocation.status}</p>
                <p>
                  {" "}
                  Pending Orders:
                  {selectedLocation.order_status.order_pending}
                </p>
                <p>
                  {" "}
                  Accepted Orders:
                  {selectedLocation.order_status.order_accepted}
                </p>
              </div>
            </div>
          </InfoBox>
        )}
      </GoogleMap>
    </>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default function RidersMap() {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBYilIq5BSPrwmLLBBp6rnVNsOSmzFcDS0`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "700px" }} />}
        mapElement={<div style={{ height: "100vh" }} />}
      />
    </div>
  );
}
