import React from 'react';
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../../../redux/actions';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

function ApproveEmployee({ employeeData, company }) {
    const employee_id = employeeData.id;

    const dispatch = useDispatch();

    let dataObject = {
        // employee_email: employeeData.employee_email,
        employee_name: employeeData.employee_name,
        employee_phone: employeeData.employee_phone,
        employee_address: employeeData.employee_address,
        employee_city: employeeData.employee_city,
        employee_state: employeeData.employee_state,
        employee_country: employeeData.employee_country,
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const updating = useSelector(state => state.admin.updating);


    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(adminActions.approveEmployee({id:employee_id }, company, setOpen));

    }


    return (
        <div>
            <MDBContainer>
                <MDBBtn onClick={() => setOpen(true)} title="Approve Employee">Approve</MDBBtn>
                <MDBModal isOpen={open} toggle={handleClose}>
                    <form name="form" onSubmit={handleSubmit}>
                        <MDBModalHeader toggle={handleClose}>Approve Employee</MDBModalHeader>
                        <MDBModalBody>
                            <div className="text-left col pt-4 px-3">
                                <h5>Would you like to approve the employee with details: </h5>
                                <p>Name: {dataObject.employee_name}</p>
                                {/* <p>Email: {dataObject.employee_email}</p><br/> */}
                                <p>Phone: {dataObject.employee_phone}</p>
                                <p>Address: {dataObject.employee_address}</p>
                                <p>Located at: {dataObject.employee_city}, {dataObject.employee_state}, {dataObject.employee_country}?</p>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => setOpen(false)}>Close</MDBBtn>
                            <MDBBtn color="primary" type="submit">
                                {updating === true ? <Beat loading={updating} /> : "Approve Employee"}
                            </MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
        </div>


    )
}


export default ApproveEmployee;