import React, { useState } from "react";
import { Beat } from "../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

function ChangePassword({ outletData }) {
  const [input, setInput] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const { old_password, new_password, new_password_confirmation } = input;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.authentication.loading);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [submitted, setSubmitted] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // dispatch action to change password
    dispatch(userActions.changePassword(input));
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color='primary' onClick={() => setOpen(true)}>
          Change Password
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name='form' onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Change your password
            </MDBModalHeader>
            <MDBModalBody>
              <div
                className={
                  "form-group mb-3" +
                  (submitted && !old_password ? " is-invalid" : "")
                }>
                <label htmlFor='old_password'>Old Password</label>
                <input
                  id='old_password'
                  type='password'
                  name='old_password'
                  value={old_password}
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !old_password ? " is-invalid" : "")
                  }
                />
                {submitted && !old_password && (
                  <div className='invalid-feedback'>
                    Old Password is required
                  </div>
                )}
              </div>
              <div
                className={
                  "form-group" +
                  (submitted && !new_password ? " is-invalid" : "")
                }>
                <label htmlFor='new_password'>New password</label>
                <input
                  id='new_password'
                  type='password'
                  name='new_password'
                  value={new_password}
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !new_password ? " is-invalid" : "")
                  }
                />
                {submitted && !new_password && (
                  <div className='invalid-feedback'>
                    New password is required
                  </div>
                )}
              </div>
              <div
                className={
                  "form-group" +
                  (submitted && !new_password_confirmation ? " is-invalid" : "")
                }>
                <label htmlFor='new_password_confirmation'>
                  New password confirmation
                </label>
                <input
                  id='new_password_confirmation'
                  type='password'
                  name='new_password_confirmation'
                  value={new_password_confirmation}
                  onChange={handleChange}
                  className={
                    "form-control form-control-rounded" +
                    (submitted && !new_password_confirmation
                      ? " is-invalid"
                      : "")
                  }
                />
                {submitted && !new_password_confirmation && (
                  <div className='invalid-feedback'>
                    New password confirmation is required
                  </div>
                )}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit'>
                {loading === true ? (
                  <Beat loading={loading} />
                ) : (
                  "Change Password"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default ChangePassword;
