import React from "react";
import { useDispatch } from "react-redux";
// import { fireApp } from "./../firebase/firebaseInit";
//import { useParams } from "react-router-dom";
import { adminActions } from "../../redux/actions";
import { onMessage } from "firebase/messaging";
import { messaging } from "./../firebase/firebaseInit";
import GOOD_CHILLING_VIBE from "../audio/good_chilling_vibe.mp3";

export default function FirebaseNotification() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user?.data?.groups[teamId]?.company_id;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let audio = new Audio(GOOD_CHILLING_VIBE);
    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    if (isSafari) {
      alert(
        "You will not be able to get notifications on orders while using Safari browser"
      );
    }
    if (!isSafari) {
      // eslint-disable-next-line
      const onMessageListener = async () =>
        new Promise((resolve) =>
          (async () => {
            const messagingResolve = await messaging;
            onMessage(messagingResolve, (payload) => {
              console.log("On message: ", messaging, payload);
              resolve(payload);
              audio.play();

              // Get pending orders
              dispatch(adminActions.getUnassignedOrders(company_id, 1));
            });
          })()
        );
      // const message = getMessaging(fireApp);
      // onMessage(message, (payload) => {
      //   // Log the payload
      //   console.log("Message has been received.", payload);

      //   // Play the audio
      //   audio.play();

      //   // Get pending orders
      //   dispatch(adminActions.getUnassignedOrders(company_id, 1));
      // });
    }
  }, [dispatch, company_id]);

  return null;
}
