import React from 'react';
import { useDispatch } from 'react-redux';
import { adminActions } from '../../../redux/actions';

export default function CompanyActionButton({id}) {
    const dispatch = useDispatch();
    function approveCompany(id){
        dispatch(adminActions.approveCompany(id));
    }

    function cloneCompany(id) {
        let user = JSON.parse(localStorage.getItem('user'));

        // Store current user's original company id
        localStorage.setItem('originalUserDetail', JSON.stringify(user));

        // Replace current users company id
        let clonedUser = {...user,data:{...user.data,company_id:id}};
        localStorage.setItem('user', JSON.stringify(clonedUser));

        // refresh page
        window.location.reload();
    }

    return (
        <div>
            <button className="btn bg-white _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="_dot _inline-dot bg-primary" />
                <span className="_dot _inline-dot bg-primary" />
                <span className="_dot _inline-dot bg-primary" />
            </button>
            <div className="dropdown-menu" x-placement="top-start" style={{ position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(678px, 57px, 0px)' }}>
                <button className="dropdown-item ul-widget__link--font"  onClick={() => approveCompany(id)}><i className="i-Bar-Chart-4"></i> Approve</button>
                <button className="dropdown-item ul-widget__link--font" onClick={() => cloneCompany(id)}><i className="i-Duplicate-Layer"></i> Clone</button>
                {/* <div className="dropdown-divider" />
                <a className="dropdown-item ul-widget__link--font" href="##"><i className="i-Data-Save"></i> Save</a>
                <a className="dropdown-item ul-widget__link--font" href="##"><i className="i-Folder-Download" /> Update</a>
                <a className="dropdown-item ul-widget__link--font" href="##"><i className="i-Gears-2" /> Customize</a> */}
            </div>
        </div>
    )
}