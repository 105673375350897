import React, { useState } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

function DeleteCustomer({ customerData }) {
  const customer_id = customerData.id;
  const dispatch = useDispatch();

  let dataObject = {
    customer_email: customerData.customer_email,
    customer_name: customerData.customer_name,
    customer_phone: customerData.customer_phone,
    customer_address: customerData.customer_address,
    customer_city: customerData.customer_city,
    customer_state: customerData.customer_state,
    customer_country: customerData.customer_country,
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const deleting = useSelector((state) => state.admin.deleting);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(adminActions.deleteCustomer({ id: customer_id }, setOpen));
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn
          onClick={() => setOpen(true)}
          title="Delete Customer"
          color="primary"
        >
          <i className="nav-icon i-Close-Window" />
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Delete Customer
            </MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4 px-3">
                <h5>
                  Would you like to delete the customer named:{" "}
                  {dataObject.customer_name},{" "}
                </h5>

                <h5>
                  Located at: {dataObject.customer_address},{" "}
                  {dataObject.customer_city}, {dataObject.customer_state},{" "}
                  {dataObject.customer_country}?
                </h5>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {deleting === true ? (
                  <Beat loading={deleting} />
                ) : (
                  "Delete customer"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default DeleteCustomer;
