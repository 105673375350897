import React, { useState } from "react";
import UserMainApp from "../../layouts/UserMainApp";
import { useDispatch } from "react-redux";
import { userActions } from "../../../redux/actions";
import { useHistory } from "react-router-dom";

function TrackOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [input, setInput] = useState({ order_id: "" });
  const { order_id } = input;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));
  };
  const handleClick = (event) => {
    event.preventDefault();
    if (order_id) {
      dispatch(userActions.getSecondPartyOrder(order_id));
    }
    history.push("/user/trackView");
  };

  return (
    <UserMainApp>
      <div className="main-content-padding">
        <div className="main-content">
          {/* ============ Body content start ============= */}
          <div className=" card track-card">
            <div className="card-body">
              <h5 className="track-title mb-4">Track Orders</h5>
              <div className="separator-breadcrumb border-top" />
              <div className="track-id">
                <p>Tracking ID/Number</p>
                <input
                  type="text"
                  placeholder="Enter tracking ID/number"
                  className="form-control track-col"
                  name="order_id"
                  value={order_id}
                  onChange={handleChange}
                />

                <button
                  className="btn btn-primary track-btn"
                  onClick={handleClick}
                >
                  Save and Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserMainApp>
  );
}

export default TrackOrder;
