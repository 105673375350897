import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function EditEmployee({ employeeData }) {
  const employee_id = employeeData.id;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      employee_name: employeeData.employee_name,
      employee_phone: employeeData.employee_phone,
      employee_address: employeeData.employee_address,
      employee_city: employeeData.employee_city,
      employee_state: employeeData.employee_state,
      employee_country: employeeData.employee_country,
    };

    setData({ ...dataObject });
  }, [employeeData]);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [submitted, setSubmitted] = useState(false);
  let [employee_phone, setEmployeePhone] = useState("");
  const [data, setData] = useState({
    id: "",
    employee_name: "",
    employee_phone: "",
    employee_address: "",
    employee_city: "",
    employee_state: "",
    employee_country: "",
  });
  const {
    employee_name,
    employee_address,
    employee_city,
    employee_state,
    employee_country,
  } = data;
  const updating = useSelector((state) => state.admin.updating);
  employee_phone = employee_phone?.substring(1);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // dispatch action to create data
    dispatch(
      adminActions.editEmployee(
        { ...data, employee_phone, id: employee_id },
        setOpen
      )
    );
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn onClick={() => setOpen(true)} title="Edit Employee">
          <i className="nav-icon i-Pen-2" />
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>Edit Employee</MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && employee_name ? " has-error" : "")
                  }
                >
                  <label htmlFor="employee_name">Employee name*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.employee_name}
                    name="employee_name"
                    onChange={handleChange}
                  />
                  {submitted && employee_name && (
                    <div className="invalid-feedback">
                      Employee name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && employee_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="employee_phone">Employee phone*</label>
                  <PhoneInput
                    defaultCountry="NG"
                    className="form-control"
                    value={data.employee_phone}
                    onChange={setEmployeePhone}
                  />
                  {submitted && employee_phone && (
                    <div className="invalid-feedback">
                      Employee phone is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && employee_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="employee_address">Employee address*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.employee_address}
                    name="employee_address"
                    onChange={handleChange}
                  />
                  {submitted && employee_address && (
                    <div className="invalid-feedback">
                      Employee address is required.
                    </div>
                  )}
                </div>

                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={data.employee_country}
                  state_name={data.employee_state}
                  city_name={data.employee_city}
                />
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && employee_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="employee_country">Employee country*</label>
                  <select
                    name="employee_country"
                    defaultValue={data.employee_country}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.employee_country && (
                      <option value={data.employee_country}>
                        {data.employee_country}
                      </option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && employee_country && (
                    <div className="invalid-feedback">
                      Employee country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && employee_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="employee_state">Employee state</label>
                  <select
                    name="employee_state"
                    selected={data.employee_state}
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.employee_state && (
                      <option value={data.employee_state}>
                        {data.employee_state}
                      </option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && employee_state && (
                    <div className="invalid-feedback">
                      Employee state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && employee_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="employee_city">Employee city*</label>
                  <select
                    className="form-control"
                    selected={data.employee_city}
                    name="employee_city"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {data.employee_city && (
                      <option value={data.employee_city}>
                        {data.employee_city}
                      </option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && employee_city && (
                    <div className="invalid-feedback">
                      Employee city is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {updating === true ? (
                  <Beat loading={updating} />
                ) : (
                  "Edit Customer"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default EditEmployee;
