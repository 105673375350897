// import { userConstants } from '../_constants';

let init = {
  items: [],
  rider_active: [],
  rider_inactive: [],
  singleRider: {},
  singleUser: {},
  my_company: {},
  rejectedInvite: {},
  myInvites: [],
  myReferral: {},
  newReferral: {},
  userOrder: [],
  trackedOrder: {},
  secondPartyOrder: {},
  creating: false,
  updating: false,
  deleting: false,
  inviteLoading: false,
  userOrderLoading: false,
};

export function users(state = init, action) {
  switch (action.type) {
    case "GETRIDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETRIDER_SUCCESS":
      return {
        ...state,
        items: action.users,
      };

    case "GETRIDER_FAILURE":
      return {
        ...state,
        error: action.error,
      };
    case "APPROVERIDER_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "APPROVERIDER_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "APPROVERIDER_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "GETSINGLERIDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETSINGLERIDER_SUCCESS":
      return {
        ...state,
        singleRider: action.rider_data,
      };

    case "GETSINGLERIDER_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETACTIVERIDER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETACTIVERIDER_SUCCESS":
      return {
        ...state,
        rider_active: action.rider_active_data,
      };

    case "GETACTIVERIDER_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETINACTIVERIDER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETINACTIVERIDER_SUCCESS":
      return {
        ...state,
        rider_inactive: action.rider_inactive_data,
      };

    case "GETINACTIVERIDER_FAILURE":
      return {
        error: action.error,
      };

    case "ASSIGNORDER_REQUEST":
      return {
        ...state,
        // singleOrder: action.order
        // loading: true
      };
    case "ASSIGNORDER_SUCCESS":
      return {
        ...state,
        // singleOrder: action.singleOrder
      };

    case "ASSIGNORDER_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "CREATERIDER_REQUEST":
      return {
        ...state,
        creating: true,
      };

    case "CREATERIDER_SUCCESS":
      return {
        ...state,
        creating: false,
      };

    case "CREATERIDER_FAILURE":
      return {
        ...state,
        error: action.error,
        creating: false,
      };

    case "EDITRIDER_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "EDITRIDER_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "EDITRIDER_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "DELETERIDER_REQUEST":
      return {
        ...state,
        deleting: true,
      };

    case "DELETERIDER_SUCCESS":
      return {
        ...state,
        deleting: false,
      };

    case "DELETERIDER_FAILURE":
      return {
        ...state,
        error: action.error,
        deleting: false,
      };

    case "GETSINGLEUSER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETSINGLEUSER_SUCCESS":
      return {
        ...state,
        singleUser: action.user_data,
      };

    case "GETSINGLEUSER_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETMYCOMPANY_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETMYCOMPANY_SUCCESS":
      return {
        ...state,
        my_company: action.my_company,
      };

    case "GETMYCOMPANY_FAILURE":
      return {
        error: action.error,
      };

    case "DELETE_REQUEST":
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, deleting: true } : user
        ),
      };
    case "DELETE_SUCCESS":
      // remove deleted user from state
      return {
        items: state.items.filter((user) => user.id !== action.id),
      };
    case "DELETE_FAILURE":
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        }),
      };
    case "GETMYINVITES_REQUEST":
      return {
        ...state,
        inviteLoading: true,
      };
    case "GETMYINVITES_SUCCESS":
      return {
        ...state,
        myInvites: action.myInvites,
        inviteLoading: false,
      };

    case "GETMYINVITES_FAILURE":
      return {
        ...state,
        error: action.error,
        inviteLoading: false,
      };
    case "REJECTINVITES_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "REJECTINVITES_SUCCESS":
      return {
        ...state,
        rejectedInvite: action.rejectedInvite,
        loading: false,
      };
    case "GETMYREFERRAL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETMYREFERRAL_SUCCESS":
      return {
        ...state,
        myReferral: action.myReferral,
        loading: false,
      };
    case "GETMYREFERRAL_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "CREATEMYREFERRAL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATEMYREFERRAL_SUCCESS":
      return {
        ...state,
        newReferral: action.newReferral,
        loading: false,
      };
    case "CREATEMYREFERRAL_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "GETUSERORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETUSERORDERS_SUCCESS":
      return {
        ...state,
        userOrders: action.userOrders,
        loading: false,
      };
    case "GETUSERORDERS_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "TRACKORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "TRACKORDER_SUCCESS":
      return {
        ...state,
        trackOrder: action.trackOrder,
        loading: false,
      };
    case "TRACKORDER_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "SECONDPARTYORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SECONDPARTYORDER_SUCCESS":
      return {
        ...state,
        secondPartyOrder: action.secondPartyOrder,
        loading: false,
      };
    case "SECONDPARTYORDER_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}
