import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function ReuseableSearchBar({
  searchAction = () => {},
  clearSearchResultsAction = () => {},
  companyId,
  page,
}) {
  const dispatch = useDispatch();
  const [inputState, setInputState] = useState("");

  useEffect(() => {
    /**
     * The purpose of this implementation is to
     * ensure that unnecessary requests are not made
     */
    let timeOut;
    if (inputState.length >= 2) {
      if (timeOut !== undefined) {
        clearTimeout(timeOut);
      }

      timeOut = setTimeout(() => {
        dispatch(searchAction({ search: inputState, company_id: companyId }));
      }, 2000);

      return () => clearTimeout(timeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState]);

  return (
    <div className='input-group mb-2 justify-content-end'>
      <div className='form-outline b-In' style={{ borderRadius: "0" }}>
        <input
          value={inputState}
          placeholder='Search...'
          onChange={(e) => setInputState(e.target.value)}
          type='search'
          className='form-control'
        />
        <button
          type='button'
          className='bttn '
          style={{ borderRadius: "0.2rem", height: "36px" }}
          onClick={() => dispatch(clearSearchResultsAction(companyId, page))}>
          <i className=' searchIcon i-Close'></i>
        </button>
      </div>
    </div>
  );
}
