import React, { useEffect } from "react";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable } from "mdbreact";
import { adminActions } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function MileageReport() {
  const assignedOrders = useSelector((state) => state.admin.assigned);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getAssignedOrders());
  }, [dispatch]);
  function createTableData() {
    let dataToMap = assignedOrders;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, i) => {
        let singleRowArray = {
          employee: order.courier_name,
          customer: order.customer_name,
          invoice: order.id,
          pickupTime:
            order.courier_orders && order.courier_orders.pending_at_date,
          deliveryTime:
            order.courier_orders && order.courier_orders.delivered_at_date,
          deliveryLocation:
            order.courier_orders && order.courier_orders.delivered_at_date,
          // status: order.status == 'pending' ?
          // <span className="badge badge-pill badge-outline-success p-2 m-1">Pending</span>
          // : <span className="badge badge-pill badge-outline-danger p-2 m-1">Delivered</span>,
          status: (
            <span className="badge badge-pill badge-outline-danger p-2 m-1">
              {order.status}
            </span>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Employee",
        field: "employee",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Invoice",
        field: "invoice",
        sort: "asc",
        width: 200,
      },
      {
        label: "Pickup TIme",
        field: "pickupTime",
        sort: "asc",
        width: 100,
      },
      {
        label: "Delivery Time ",
        field: "deliveryTime",
        sort: "asc",
        width: 100,
      },
      {
        label: "Delivery Location",
        field: "deliveryLocation",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };
  return (
    <MainApp>
      <div className="main-content-padding">
        {/* ============ Body content start ============= */}
        <div className="main-content">
          <div className="breadcrumb">
            <h1>Mileage Report</h1>
            {/* <ul>
                            <li><a href="#">Componets</a></li>
                            <li>Table</li>
                        </ul> */}
          </div>
          <div className="separator-breadcrumb border-top" />
          <MDBDataTable striped bordered hover data={data} />
          {/* end of main-content */}
        </div>
      </div>
    </MainApp>
  );
}
