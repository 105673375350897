export function registration(state = {registering: false}, action) {
    switch (action.type) {
        case "REGISTER_REQUEST":
            return {
                ...state, 
                registering: true
             };
        case "REGISTER_SUCCESS":
            return {
                ...state,
                registering: false
            };
        case "REGISTER_FAILURE":
            return {
                ...state,
                registering: false
            };
        default:
            return state
    }
}