import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import { PImage } from "../../images/images";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import UserMainApp from "../../layouts/UserMainApp";
import AcceptInvite from "./AcceptInvite";

export default function MyInvites() {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(userActions.getMyInvites());
  }, [dispatch]);
  const myInvites = useSelector((state) => state.users.myInvites);
  const loading = useSelector((state) => state.users.inviteLoading);
  return (
    <UserMainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div>
          <h2 className=" h-align">Your Invites</h2>

          <div className="card mx-auto " style={{ width: "30rem" }}>
            <ul className="list-group list-group-flush">
              {myInvites?.data?.length > 0 ? (
                myInvites?.data?.map((invite, key) => (
                  <li
                    className="list-group-item cursor-pointer"
                    key={key}
                    onClick={() => {
                      setModalVisible(true);
                      setData(invite);
                    }}
                  >
                    <div className="d-flex flex-row img-txt">
                      <img
                        className="p-img"
                        src={invite["company"]?.company_picture || PImage}
                        alt="avatar"
                      />
                      <div className=" d-flex flex-column ml-2 mt-2">
                        <p className="text-capitalize m-0">
                          {" "}
                          {invite["company"]?.company_name}{" "}
                        </p>
                        <p className="">
                          Just sent you an invite to join their company
                        </p>
                        <p className="text-capitalize ">
                          {" "}
                          Role: {invite?.type}{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <h3 className="pt-3 pb-3 ml-2">No invitations available</h3>
              )}
            </ul>
          </div>
        </div>
      )}
      <AcceptInvite
        data={data}
        modalVisible={modalVisible}
        modalControl={() => setModalVisible(!modalVisible)}
      />
    </UserMainApp>
  );
}
