import React, { useEffect, useCallback } from "react";
import { alertActions } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

export default function Notification() {
  const alert = useSelector((state) => state.alert);

  const dispatch = useDispatch();

  const checkAlert = useCallback(() => {
    let notification = "";
    if (alert && alert.type) {
      let alertMessage = alert.message;
      let message = "";
      if (alertMessage.status_code === 422) {
        let error = alertMessage.errors;

        if (Array.isArray(error)) {
          error.forEach(function (item) {
            message = item;
          });
        }

        if (!Array.isArray(error)) {
          Object.keys(error).forEach(function (key) {
            error[key].forEach(function (item) {
              message = item;
            });
          });
        }
        setTimeout(() => {
          dispatch(alertActions.clear());
        }, 4000);
        return message;
      } else {
        notification = alertMessage.message.toString();
      }

      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 4000);
    }

    return notification;
  }, [alert, dispatch]);

  useEffect(() => {
    checkAlert();
  }, [alert, checkAlert]);

  return (
    <div className='position-relative'>
      {alert && alert.type === "alert-success" && (
        <div className='alert-style-success fade-in position-fixed'>
          <h3>{checkAlert()}</h3>
        </div>
      )}

      {alert && alert.type === "alert-danger" && (
        <div className='alert-style fade-in position-fixed'>
          <h3>{checkAlert()}</h3>
        </div>
      )}
    </div>
  );
}
