import React from "react";
//import { useDispatch } from "react-redux";
//import { userActions } from "../../redux/actions";
import AuthLayout from "../layouts/AuthLayout";
import { useHistory } from "react-router-dom";

export default function CreateAccount() {
  const history = useHistory();

  const SignIn = () => {
    history.push("/auth/login");
  };
  const InviteLink = () => {
    history.push("/auth/inviteLinkReg");
  };
  return (
    <AuthLayout>
      <div className='p-4 auth-box'>
        <div className='auth-title'>
          <h1 className='mb-4 font-weight-bold'>Create Account</h1>
          <h4 className='mb-4'>Sign in/Register Account. </h4>
        </div>
        <div>
          <button
            className='btn btn-rounded  btn-primary btn-block mt-4'
            onClick={() => SignIn()}>
            Sign In
          </button>
          <button
            id='registerBtn'
            className='btn btn-rounded text-primary btn-block mt-4'
            style={{ border: "1px solid #d14522", backgroundColor: "white" }}
            onClick={() => InviteLink()}>
            Register with Invite link
          </button>
        </div>
        <div className='mt-5 mb-5 text-center'>
          <p>
            Already have an account?{" "}
            <span>
              <a className='text-primary' href='/auth/login'>
                Sign In{" "}
              </a>
            </span>
          </p>
        </div>
        <div className='mt-5 pt-5 text-center'>
          By creating an account you accept our <br></br>
          <span>
            <a href='/terms-of-service'>Terms of service </a>
          </span>
          and
          <span>
            <a href='/privacy-policy'> Privacy Policy.</a>
          </span>
        </div>
      </div>
    </AuthLayout>
  );
}
