import React, { useEffect, useState } from "react";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable } from "mdbreact";

import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import TableLoader from "../../includes/Loaders/TableLoader";

import moment from "moment";
import OrdersSummary from "../../includes/OrdersSummary";

import { FilterRidersRemitted } from "../../filter/FilterRiderRemitted";
import RidersRemitAction from "./RiderRemitAction";

export default function RidersRemittance() {
  //Filtering by Date
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  const [remitted_start_date, setStartDate] = useState(lastWeek);
  const [remitted_end_date, setEndDate] = useState(new Date());

  const [dateInput, setDateInput] = useState({});
  const completedOrders = useSelector((state) => state.admin.myCompleted);
  const loading = useSelector((state) => state.admin.getRemittanceLoader);

  useEffect(() => {
    setDateInput({
      remitted_start_date: remitted_start_date,
      remitted_end_date: remitted_end_date,
    });
  }, [remitted_start_date, remitted_end_date]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let completedOrdersList = completedOrders?.data;
  const [dataToMap, setDataToMap] = useState([]);

  useEffect(() => {
    setDataToMap(completedOrdersList && completedOrders.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedOrdersList]);

  const filterRemittedOrders = () => {
    let completed = [];
    dataToMap.length > 0 &&
      dataToMap.forEach((item) => {
        if (item.status === "completed") {
          completed.push(item);
        }
      });
    // return completed;
    return setDataToMap(completed);
  };
  function createTableData() {
    if (Array.isArray(dataToMap && dataToMap.data)) {
      let outputArray = [];
      dataToMap &&
        dataToMap.data.map((courierOrder, i) => {
          let singleRowArray = {
            order_invoice_number: courierOrder?.order?.id,
            created_at: moment(courierOrder?.order?.created_at).format("l"),
            order_amount: (
              <span>
                ₦{numberWithCommas(courierOrder?.order?.order_amount)}
              </span>
            ),
            status:
              courierOrder?.status === "completed" ? (
                <span className="badge badge-pill badge-outline-success p-2 m-1">
                  Completed
                </span>
              ) : (
                <span className="badge badge-pill badge-outline-danger p-2 m-1">
                  Canceled
                </span>
              ),
            remitted:
              courierOrder?.courier_comment !== null ? (
                <span className="badge badge-pill badge-outline-success p-2 m-1">
                  True
                </span>
              ) : (
                <span className="badge badge-pill badge-outline-danger p-2 m-1">
                  False
                </span>
              ),
            amount: (
              <span>
                ₦{numberWithCommas(courierOrder?.order.remitted_amount)}
              </span>
            ),
            actions: (
              <div className="d-flex">
                {courierOrder.courier_comment === null ? (
                  <RidersRemitAction
                    courier_order_id={courierOrder.id}
                    id={courierOrder?.order?.order_id}
                  />
                ) : (
                  <span className="text-success">Remitted</span>
                )}
                <OrdersSummary
                  employee_comment={courierOrder?.order?.employee_comment}
                  courier_comment={courierOrder?.order?.courier_comment}
                  order_data={courierOrder}
                />
              </div>
            ),
          };
          outputArray.push(singleRowArray);
          return true;
        });
      return outputArray;
    }
  }

  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "INVOICE",
        field: "order_invoice_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "CREATED",
        field: "created_at",
        sort: "asc",
        width: 150,
      },
      {
        label: "ORDER AMOUNT",
        field: "order_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "STATUS",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "IS REMITTED",
        field: "remitted",
        sort: "asc",
        width: 100,
      },

      {
        label: "ACTIONS",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: dataSet,
  };
  return (
    <MainApp>
      {loading & dataSet ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          {/* ============ Body content start ============= */}
          <div className="main-content">
            <div className="breadcrumb">
              <h1>Order Remittance</h1>
            </div>
            <FilterRidersRemitted
              date={dateInput}
              filter={filterRemittedOrders}
              remitted_start_date={remitted_start_date}
              setStartDate={setStartDate}
              remitted_end_date={remitted_end_date}
              setEndDate={setEndDate}
            />
            <h6>*Displays from the Most recent to the oldest</h6>

            {loading ? (
              <TableLoader />
            ) : (
              <MDBDataTable
                responsive
                bordered
                theadColor="MDBTableHeader"
                hover
                maxHeight="400px"
                noBottomColumns
                data={data}
                paging={false}
                sortable={false}
                searching={false}
              />
            )}

            <Pagination
              data={completedOrdersList}
              route="/courier/Remittance"
            />
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
