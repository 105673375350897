import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBCpD9GbnuH3YAleZCoyW-GmXRKgJI0caE",
  authDomain: "ogwugo-courier-319221.firebaseapp.com",
  databaseURL: "https://ogwugo-courier-319221-default-rtdb.firebaseio.com",
  projectId: "ogwugo-courier-319221",
  storageBucket: "ogwugo-courier-319221.appspot.com",
  messagingSenderId: "611284402952",
  appId: "1:611284402952:web:95105d43fcbac8ab4322bd",
  measurementId: "G-Z36Y95YES7",
};

//Initialize Firebase
export const fireApp = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(fireApp);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

getToken(messaging, {
  vapidKey:
    "BHPfN98iemvivJclcmDGnuN6PgZ_8QyJPtLixcsPcjFi0LX-jxzJNRBwTuslbX29FMM-C4qx9lut-U9ILPtd2XA",
})
  .then((deviceToken) => {
    console.log("deviceToken", deviceToken);
    const uuid = async () => {
      // generate a unique id for the browser
      let uuid = await localStorage.getItem("UUID");
      if (!uuid) {
        uuid = Math.random().toString(36).slice(2) + Date.now();
        localStorage.setItem("UUID", uuid);
      }
      return uuid;
    };

    if (deviceToken) {
      // Send the token to your server and update the UI if necessary
      localStorage.setItem(
        "firebaseDetails",
        JSON.stringify({
          // "user_id": 'this-would-be-the-user-id',
          // "url":"%REACT_APP_FIREBASE_URL%",
          // "server_key":"%REACT_APP_FIREBASE_SERVER_KEY%",
          device_token: deviceToken,
          device_id: uuid(),
          device_type: "web",
          notification_type: "direct",
          notification_agent: "firebase",
        })
      );
    } else {
      // Show permission request UI
      alert("Enable notifications for this application.");

      // log status
      console.log(
        "No firebase token available. Request permission to generate one."
      );
    }
  })
  .catch((err) => {
    // log status
    console.log("An error occurred while retrieving firebase token. ", err);
  });

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.
let toggleProcessingMusic;
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  toggleProcessingMusic("play");
});

// Toggle music
toggleProcessingMusic = (control) => {
  if (control === "play") {
    document.getElementById("notification-music").volume = 0.6;
    document.getElementById("notification-music").play();
  } else {
    document.getElementById("notification-music").pause();
  }
};
