export const alertActions = {
  success,
  error,
  clear,
};

function success(user) {
  return { type: "SUCCESS", user };
}

function error(user) {
  return { type: "ERROR", user };
}

function clear() {
  return { type: "CLEAR" };
}
