import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import AssignToEmployee from "./AssignToEmployee";

export default function AssignRole() {
  const allRoles = useSelector((state) => state.admin.allRoles);

  const dispatch = useDispatch();

  let params = useParams();

  useEffect(() => {
    dispatch(adminActions.getRoles());
  }, [dispatch, params]);

  function createTableData() {
    let dataToMap = allRoles;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((role, i) => {
        let singleRowArray = {
          role: role.name,
          actions: <AssignToEmployee id={role.id} />,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Roles",
        field: "role",
        sort: "asc",
        width: 200,
      },

      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };

  return (
    <div className='mt-4'>
      <div className='separator-breadcrumb border-top' />

      <div className='breadcrumb'>
        <h1 className='mr-2'>Assign roles to employees</h1>
      </div>

      <MDBDataTable
        responsive
        searching={false}
        striped
        bordered
        hover
        noBottomColumns
        data={data}
        paging={false}
      />
      {/* <Pagination data={abilityData}  route="settings/ability"/> */}
    </div>
  );
}
