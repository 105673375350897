import React, { useState, Fragment, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { cleanInput } from "../../../redux/helpers";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function CreateOrder() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const dispatch = useDispatch();

  const [myCompany, setMyCompany] = useState({});
  useEffect(() => {
    const getMyCompany = async () => {
      let companyData = JSON.parse(await localStorage.getItem("myCompany"));
      companyData && setMyCompany(companyData);
      companyData &&
        setInput((input) => ({
          ...input,
          outlet_city: companyData.company_city,
          outlet_state: companyData.company_state,
          outlet_country: companyData.company_country,
          customer_city: companyData.company_city,
          customer_state: companyData.company_state,
          customer_country: companyData.company_country,
        }));
    };
    getMyCompany();
  }, []);

  // Modal actions
  const [open, setOpen] = React.useState(false);

  const handleClosed = () => {
    setOpen(false);
  };
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [visible, setvisible] = useState(false);
  const handleClose = () => {
    setvisible(false);
  };

  const creating = useSelector((state) => state.admin.creating);
  const recording = useSelector((state) => state.admin.recording);
  const adding = useSelector((state) => state.admin.adding);
  let searched = useSelector((state) => state.admin.allCustomers);
  let searchedOutlet = useSelector((state) => state.admin.allOutlet);

  const [submitted, setSubmitted] = useState(false);
  let [customer_phone, setCustomerPhone] = useState("");
  let [outlet_phone, setOutletPhone] = useState("");
  const [input, setInput] = useState({
    company_id: "",
    customer_name: "",
    customer_phone: "",
    customer_email: "",
    customer_gender: "",
    customer_address: "",
    customer_city: "",
    customer_state: "",
    customer_country: "",
    order_amount: "",
    order_discount: "0",
    order_items: [],
    order_payment_method: [],
    order_description: "",
    order_paid: "0",
    order_delivery_fee: "",
    outlet_name: "",
    outlet_phone: "",
    outlet_address: "",
    outlet_city: "",
    outlet_state: "",
    outlet_country: "",
  });

  const {
    customer_name,
    customer_email,
    customer_gender,
    customer_address,
    customer_city,
    customer_state,
    customer_country,
    order_amount,
    order_discount,
    order_payment_method,
    order_description,
    order_paid,
    order_delivery_fee,
    outlet_name,
    outlet_address,
    outlet_city,
    outlet_state,
    outlet_country,
  } = input;
  customer_phone = customer_phone?.substring(1);
  outlet_phone = outlet_phone?.substring(1);
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setInput((input) => ({ ...input, [name]: value }));

    // if (event.target.name === "outlet_name") {
    if (value.length >= 3) {
      dispatch(adminActions.searchAllOutlet({ search: value }));
    } else if (value.length <= 3) {
      searchedOutlet = { data: [] };
      dispatch({ type: "SEARCH_ALL_OUTLET_SUCCESS", searchedOutlet });
    }
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  }
  //this is for the options array
  const [optionsArray, setOptionsArray] = useState({
    name: "",
    quantity: "",
    price: "",
  });
  const [remove, setRemove] = useState(false);

  const [error, setError] = useState({ customerError: "", optionsError: "" });
  const [selectedOrder, setSelectedOrder] = useState("");
  // This is for the order items array
  const [inputArray, setInputArray] = useState([
    {
      name: "",
      quantity: "",
      price: "",
    },
  ]);
  //This adds options to the input array
  const addOption = () => {
    if (
      optionsArray["name"] === "" ||
      optionsArray["price"] === "" ||
      optionsArray["quantity"] === ""
    ) {
      setError({
        ...error,
        optionsError: "Option details are required.",
      });
      return;
    }
    const index = inputArray.findIndex((object) => {
      return object.id === selectedOrder;
    });

    optionsArray["id"] = Math.random();
    if (inputArray[index].options) {
      inputArray[index] = {
        ...inputArray[index],
        options: [...inputArray[index].options, optionsArray],
      };
    } else {
      inputArray[index] = {
        ...inputArray[index],
        options: [],
      };
      inputArray[index].options.push({
        ...optionsArray,
      });
    }

    setOpen(false);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputArray];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
    }
    setInputArray(values);
  };

  const handleAddFields = () => {
    const values = [...inputArray];
    values.push({
      name: "",
      quantity: "",
      price: "",
    });
    setInputArray(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputArray];
    values.splice(index, 1);
    setInputArray(values);
  };
  //this removes the option that was added
  const handleRemoveOptionsFields = (id, itemId) => {
    const index = inputArray.findIndex((object) => {
      return object.id === itemId;
    });

    let newArray = inputArray[index].options.filter((item) => {
      return item.id !== id;
    });
    inputArray[index] = {
      ...inputArray[index],
      options: newArray,
    };
    setRemove(!remove);
  };

  function getPrice() {
    let order_items_amount = 0;
    let optionsPrice = 0;
    for (var i = 0; i < inputArray.length; i++) {
      order_items_amount +=
        Number(inputArray[i].quantity) * Number(inputArray[i].price);

      if (inputArray[i].options) {
        inputArray[i].options.forEach(myFunction);
      }
      // eslint-disable-next-line
      function myFunction(item, index, arr) {
        optionsPrice += Number(item.quantity) * Number(item.price);
      }
    }
    let sum = order_items_amount + optionsPrice;
    for (let i = 0; i < inputArray.length; i++) {
      inputArray[i]["id"] = Math.random();
    }
    return sum;
  }

  useEffect(() => {
    setInput((input) => ({
      ...input,
      order_amount:
        Number(input.order_delivery_fee) +
        getPrice() -
        Number(input.order_discount),
    }));

    // eslint-disable-next-line
  }, [inputArray, order_delivery_fee, order_discount]);
  const recheckPrice = () => {
    setInput((input) => ({
      ...input,
      order_amount:
        Number(input.order_delivery_fee) +
        getPrice() -
        Number(input.order_discount),
    }));
  };

  const [orderPayment, setorderPayment] = useState([
    { id: "", name: "", icon: "", details: "" },
  ]);
  const handleOrderInputChange = (event) => {
    let values = orderPayment;
    if (event.target.value === "2") {
      values = {
        id: "2",
        name: "cash",
        icon: "https://xend.africa/assets/img/xend-logo.svg",
        details: "",
      };
    } else if (event.target.value === "3") {
      values = {
        id: "3",
        name: "paystack",
        icon: "https://paystack.com/blog/public/assets/images/logo/paystack-logo.svg",
        details: "",
      };
    } else if (event.target.value === "5") {
      values = {
        id: "5",
        name: "transfer",
        icon: "",
        details: "",
      };
    }

    setorderPayment(values);
  };

  const clearInput = () => {
    setInput({
      company_id: "",
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      customer_gender: "",
      customer_address: "",
      customer_city: myCompany?.company_city,
      customer_state: myCompany?.company_state,
      customer_country: myCompany?.company_country,
      order_amount: "",
      order_discount: "0",
      order_items: [],
      order_payment_method: [],
      order_description: "",
      order_paid: "0",
      order_delivery_fee: "",
      outlet_name: "",
      outlet_phone: "",
      outlet_address: "",
      outlet_city: myCompany?.company_city,
      outlet_state: myCompany?.company_state,
      outlet_country: myCompany?.company_country,
    });

    setSubmitted(false);
    setSearchValue("");
    setInputArray([
      {
        name: "",
        quantity: "",
        price: "",
      },
    ]);
  };

  const handleSubmit = (event, type = "create") => {
    event.preventDefault();
    let my_company_id = user.data.groups[teamId].company_id;
    setSubmitted(true);
    // Check if customer details were filled, else, display error message
    if (!customer_name && !customer_phone && !customer_address) {
      setError((error) => ({
        ...error,
        customerError:
          "Customer details are required. Search for existing customers or add a new customer.",
      }));
    }
    cleanInput(input);
    if (
      customer_name &&
      customer_phone &&
      customer_address &&
      order_amount &&
      order_delivery_fee &&
      outlet_address &&
      order_payment_method &&
      order_paid
    ) {
      if (type !== "record") {
        dispatch(
          adminActions.createOrder(
            {
              ...input,
              customer_phone,
              outlet_phone,
              company_id: my_company_id,
              order_items: inputArray,
              order_payment_method: orderPayment,
            },
            setvisible
          )
        );
      } else {
        dispatch(
          adminActions.createRecordOrder(
            {
              ...input,
              customer_phone,
              outlet_phone,
              company_id: my_company_id,
              order_items: inputArray,
              order_payment_method: orderPayment,
            },
            setvisible
          )
        );
      }
      clearInput();
    }
  };

  // search customer and fill customer details with results
  const [searchValue, setSearchValue] = useState({ search: "" });
  const { search } = searchValue;

  const handleSearch = (event) => {
    event.preventDefault();
    let company_id = user.data.groups[teamId].company_id;
    const { name, value } = event.target;
    setSearchValue((searchValue) => ({ ...searchValue, [name]: value }));
    if (value.length >= 3) {
      dispatch(
        adminActions.searchAllCustomers({
          search: value,
          company_id,
        })
      );
    } else if (value.length <= 3) {
      searched = { data: [] };
      dispatch({ type: "SEARCH_ALL_CUSTOMERS_SUCCESS", searched });
    }
  };

  // Toggle button to show form to fill customers manually if they don't exist
  function showCustomer() {
    // Firstly, clear the details of the search and remove search results
    setSearchValue({ search: "" });
    searched = { data: [] };

    dispatch({ type: "SEARCH_ALL_CUSTOMERS_SUCCESS", searched });

    // Then bring out form for customer details
    var x = document.getElementById("customerDetails");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  function fillCustomer(result) {
    setCustomerPhone(result.customer_phone);
    setInput((input) => ({
      ...input,
      customer_name: result.customer_name,
      customer_phone: customer_phone,
      customer_email: result.customer_email,
      customer_gender: result.customer_gender,
      customer_address: result.customer_address,
      customer_city: result.customer_city,
      customer_state: result.customer_state,
      customer_country: result.customer_country,
    }));
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "#D14522";
    }

    setSearchValue((searchValue) => ({
      search: result.customer_name,
    }));

    searched = { data: [] };
    dispatch({ type: "SEARCH_ALL_CUSTOMERS_SUCCESS", searched });
  }

  // Search for stores and fill their details automatically if they exist
  function fillOutlet(result) {
    setOutletPhone(result.outlet_phone);
    setInput((input) => ({
      ...input,
      outlet_name: result.outlet_name,
      outlet_phone: result.outlet_phone,
      outlet_address: result.outlet_address,
      outlet_city: result.outlet_city,
      outlet_state: result.outlet_state,
      outlet_country: result.outlet_country,
    }));
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "#D14522";
    }

    searchedOutlet = { data: [] };
    dispatch({ type: "SEARCH_ALL_OUTLET_SUCCESS", searchedOutlet });
  }

  return (
    <div data-test="createOrder">
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setvisible(true)}>
          Create Order
        </MDBBtn>
        <MDBModal
          isOpen={visible}
          toggle={handleClose}
          size="lg"
          scrollable={"true"}
        >
          <form onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              <span className={"h5"}>Create a new order</span>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row mb-1">
                <div
                  className={
                    "form-group col-md-9" +
                    (submitted && !search ? " is-invalid" : "")
                  }
                >
                  <input
                    type="search"
                    name="search"
                    autoComplete="off"
                    placeholder="Search customers by name, phone number etc. Minimum of 3 characters"
                    value={search}
                    onChange={handleSearch}
                    className="form-control form-control-rounded"
                  />
                </div>
                <div className="form-group col-md-3">
                  <div
                    className="text-center customer-btn"
                    id="customer-btn"
                    onClick={() => showCustomer()}
                  >
                    <i className="i-Add mr-2"></i>New customer
                  </div>
                </div>
              </div>
              {error && error.customerError && (
                <div className="error-notification">
                  {error && error.customerError}
                </div>
              )}
              <br />

              {searched.data &&
                Array.isArray(searched.data.data) &&
                searched.data.data.map((result, index) => (
                  <div
                    className="p-3 border mb-2"
                    id={result.id}
                    key={index}
                    onClick={() => fillCustomer(result)}
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img
                          className="customer-search-img"
                          src={result.customer_picture}
                          alt="customer"
                        />
                      </div>
                      <div className="col-md-10">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="customer-details">
                              <h6>Name:</h6>
                              <h5>{result.customer_name}</h5>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <h6>Phone:</h6>
                              <h5>{result.customer_phone}</h5>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <h6>Address:</h6>
                              <h5>{result.customer_address}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div id="customerDetails">
                <h4>Enter new customer details</h4>
                <div className="separator-breadcrumb border-top" />
                <div className="row">
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_name ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_name">Customer name*</label>
                    <input
                      type="text"
                      name="customer_name"
                      value={customer_name}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_name ? " is-invalid" : "")
                      }
                    />
                    {submitted && !customer_name && (
                      <div className="invalid-feedback">
                        Customer name is required
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_phone ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_phone">Customer phone*</label>
                    <PhoneInput
                      defaultCountry="NG"
                      value={customer_phone}
                      onChange={setCustomerPhone}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_phone ? " is-invalid" : "")
                      }
                    />
                    {submitted && !customer_phone && (
                      <div className="invalid-feedback">
                        Customer phone is required
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_email ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_email">Customer email</label>
                    <input
                      type="email"
                      name="customer_email"
                      value={customer_email}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_email ? " is-invalid" : "")
                      }
                    />
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_gender ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_gender">Customer gender</label>
                    <select
                      name="customer_gender"
                      value={customer_gender}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_gender ? " is-invalid" : "")
                      }
                    >
                      <option>Select option</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {submitted && !customer_gender && (
                      <div className="invalid-feedback">
                        Customer gender is required
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_address ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_address">Customer address</label>
                    <input
                      type="text"
                      name="customer_address"
                      value={customer_address}
                      onChange={handleChange}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_address ? " is-invalid" : "")
                      }
                    />
                    {submitted && !customer_address && (
                      <div className="invalid-feedback">
                        Customer address is required
                      </div>
                    )}
                  </div>
                  <Location
                    country_id={locationId.country_id}
                    state_id={locationId.state_id}
                    city_id={locationId.city_id}
                    countryList={(value) => {
                      setLocationList({
                        ...locationList,
                        countryList: value,
                      });
                    }}
                    stateList={(value) => {
                      setLocationList({ ...locationList, stateList: value });
                    }}
                    cityList={(value) => {
                      setLocationList({ ...locationList, cityList: value });
                    }}
                    country_name={myCompany.company_country}
                    state_name={myCompany.company_state}
                    city_name={myCompany.company_city}
                  />
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_country ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_country">Customer country</label>
                    <select
                      name="customer_country"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          country_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_country ? " is-invalid" : "")
                      }
                    >
                      {myCompany.company_country && (
                        <option value={myCompany.company_country}>
                          {myCompany.company_country}
                        </option>
                      )}
                      {locationList.countryList &&
                        locationList.countryList.length > 0 &&
                        locationList.countryList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({
                                name: item.name,
                                id: item.id,
                              })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    {submitted && !customer_country && (
                      <div className="invalid-feedback">
                        Customer country is required
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_state ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_state">Customer state</label>
                    <select
                      name="customer_state"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          state_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_state ? " is-invalid" : "")
                      }
                    >
                      {myCompany.company_state && (
                        <option>{myCompany.company_state}</option>
                      )}
                      {locationList.stateList &&
                        locationList.stateList.length > 0 &&
                        locationList.stateList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({
                                name: item.name,
                                id: item.id,
                              })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    {submitted && !customer_state && (
                      <div className="invalid-feedback">
                        Customer state is required
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group col-md-6 mb-4" +
                      (submitted && !customer_city ? " is-invalid" : "")
                    }
                  >
                    <label htmlFor="customer_city">Customer city</label>
                    <select
                      name="customer_city"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          city_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                      className={
                        "form-control form-control-rounded" +
                        (submitted && !customer_city ? " is-invalid" : "")
                      }
                    >
                      {myCompany.company_city && (
                        <option>{myCompany.company_city}</option>
                      )}
                      {locationList.cityList &&
                        locationList.cityList.length > 0 &&
                        locationList.cityList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({
                                name: item.name,
                                id: item.id,
                              })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    {submitted && !customer_city && (
                      <div className="invalid-feedback">
                        Customer city is required
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-100 px-2">
                <h4 className="mt-4">Order details</h4>
                <div className="separator-breadcrumb border-top" />
              </div>

              <div className="row">
                <div className="col-md-12 table-responsive">
                  <table className="table table-hover mb-4">
                    <colgroup>
                      <col span="1" style={{ width: "10%" }} />
                      <col span="1" style={{ width: "42%" }} />
                      <col span="1" style={{ width: "15%" }} />
                      <col span="1" style={{ width: "15%" }} />
                      <col span="1" style={{ width: "15%" }} />
                      <col span="1" style={{ width: "15%" }} />
                    </colgroup>

                    <thead className="bg-gray-300">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Price(₦)</th>
                        <th scope="col">Action</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputArray.map((inputItem, index) => (
                        <Fragment key={`${inputItem}~${index}`}>
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <input
                                type="text"
                                name="name"
                                value={inputItem.name}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                className="form-control form-control-rounded"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                name="quantity"
                                placeholder="0"
                                value={inputItem.quantity}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                className="form-control form-control-rounded"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                name="price"
                                placeholder="0"
                                value={inputItem.price}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                className="form-control form-control-rounded"
                              />
                            </td>
                            <td>
                              <MDBBtn
                                color="primary"
                                disabled={!inputItem.name}
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedOrder(inputItem.id);
                                }}
                              >
                                Options
                              </MDBBtn>

                              <MDBModal isOpen={open} toggle={handleClosed}>
                                {/* <form name="form" onSubmit={addOption}> */}
                                <MDBModalHeader toggle={handleClosed}>
                                  Add an Option
                                </MDBModalHeader>
                                <MDBModalBody>
                                  <div className="text-left row pt-4">
                                    <div
                                      className={"form-group  col-md-12 mb-3"}
                                    >
                                      <label htmlFor="name">Name</label>
                                      <input
                                        id="name"
                                        type="name"
                                        name="name"
                                        placeholder="Enter name"
                                        onChange={(event) =>
                                          setOptionsArray({
                                            ...optionsArray,
                                            name: event.target.value,
                                          })
                                        }
                                        className={
                                          "form-control form-control-rounded"
                                        }
                                      />
                                    </div>
                                    <div
                                      className={"form-group col-md-12  mb-3"}
                                    >
                                      <label htmlFor="price">Unit Price</label>
                                      <input
                                        id="price"
                                        type="price"
                                        name="price"
                                        onChange={(event) =>
                                          setOptionsArray({
                                            ...optionsArray,
                                            price: event.target.value,
                                          })
                                        }
                                        className={
                                          "form-control form-control-rounded"
                                        }
                                      />
                                    </div>
                                    <div
                                      className={"form-group  col-md-12 mb-3"}
                                    >
                                      <label htmlFor="quantity">Quantity</label>
                                      <input
                                        id="quantity"
                                        type="quantity"
                                        name="quantity"
                                        onChange={(event) =>
                                          setOptionsArray({
                                            ...optionsArray,
                                            quantity: event.target.value,
                                          })
                                        }
                                        placeholder="quantity"
                                        className={
                                          "form-control form-control-rounded"
                                        }
                                      />
                                      {error && error.optionsError && (
                                        <div className="error-notification">
                                          {error && error.optionsError}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </MDBModalBody>
                                <MDBModalFooter>
                                  <MDBBtn
                                    color="secondary"
                                    onClick={() => setOpen(false)}
                                  >
                                    Close
                                  </MDBBtn>
                                  <MDBBtn
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                      addOption();
                                      recheckPrice();
                                    }}
                                  >
                                    {adding === true ? (
                                      <Beat loading={adding} />
                                    ) : (
                                      "Save"
                                    )}
                                  </MDBBtn>
                                </MDBModalFooter>
                                {/* </form> */}
                              </MDBModal>
                            </td>
                            <td>
                              {
                                <button
                                  className="btn "
                                  type="button"
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  <i className=" searchIcon i-Close"></i>
                                </button>
                              }
                            </td>
                          </tr>
                          {submitted && !inputItem.name && (
                            <div className="invalid-feedback">
                              Order items are required
                            </div>
                          )}
                          {inputItem.options &&
                            inputItem.options.length > 0 && (
                              <tr className="w-100 flex">Options: </tr>
                            )}

                          {inputItem.options &&
                            inputItem.options.map((option, key) => (
                              <tr key={key}>
                                <th></th>
                                <td>
                                  <input
                                    key={key}
                                    type="text"
                                    name="name"
                                    defaultValue={option.name}
                                    className="form-control form-control-rounded"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={key}
                                    type="number"
                                    min="0"
                                    name="quantity"
                                    placeholder="0"
                                    defaultValue={option.quantity}
                                    className="form-control form-control-rounded"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={key}
                                    type="number"
                                    min="0"
                                    name="price"
                                    placeholder="0"
                                    defaultValue={option.price}
                                    className="form-control form-control-rounded"
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn mr-3"
                                    style={{}}
                                    type="button"
                                    onClick={() =>
                                      handleRemoveOptionsFields(
                                        option.id,
                                        inputItem.id
                                      )
                                    }
                                  >
                                    <i className=" searchIcon i-Close"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </Fragment>
                      ))}

                      <tr>
                        <td colSpan="6">
                          <div
                            className="add-field-btn"
                            onClick={() => handleAddFields()}
                          >
                            <i className="i-Add mr-2"></i> Add new item
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && !order_description ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="order_description">
                    Order description (optional)
                  </label>
                  <textarea
                    type="text"
                    name="order_description"
                    value={order_description}
                    onChange={handleChange}
                    className="form-control form-control-rounded"
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className={
                    "form-group col-md-4 mb-4" +
                    (submitted && !order_delivery_fee ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="order_delivery_fee">
                    Order delivery fee(₦)
                  </label>
                  <input
                    type="number"
                    min="0"
                    name="order_delivery_fee"
                    value={order_delivery_fee}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !order_delivery_fee ? " is-invalid" : "")
                    }
                  />
                  {submitted && !order_delivery_fee && (
                    <div className="invalid-feedback">
                      Order delivery fee is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-4 mb-4" +
                    (submitted && !order_discount ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="order_discount">Order discount(₦)</label>
                  <input
                    type="number"
                    min="0"
                    name="order_discount"
                    value={input.order_discount}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !order_discount ? " is-invalid" : "")
                    }
                  />
                </div>

                <div
                  className={
                    "form-group col-md-4 mb-4" +
                    (submitted && !order_amount ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="order_amount">Order amount(₦)</label>
                  <input
                    type="number"
                    min="0"
                    name="order_amount"
                    value={input.order_amount}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !order_amount ? " is-invalid" : "")
                    }
                    readOnly
                  />
                  {submitted && !order_amount && (
                    <div className="invalid-feedback">
                      Order_amount is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !order_paid ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="order_paid">Order paid</label>
                  <select
                    name="order_paid"
                    defaultValue={input.order_paid}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !order_paid ? " is-invalid" : "")
                    }
                  >
                    <option>Select option</option>
                    <option value="0">Not Paid</option>
                    <option value="1">Paid</option>
                  </select>
                  {submitted && !order_paid && (
                    <div className="invalid-feedback">
                      Order paid is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !order_payment_method ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="order_payment_method">Payment method</label>
                  <select
                    name="order_payment_method"
                    onChange={(event) => handleOrderInputChange(event)}
                    className="form-control form-control-rounded"
                  >
                    <option>Select option</option>

                    <option value="2">Cash</option>
                    <option value="3">Paystack</option>

                    <option value="5">Transfer</option>
                  </select>
                  {submitted && !order_payment_method && (
                    <div className="invalid-feedback">
                      order payment method is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !outlet_name ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="outlet_name">Order outlet name</label>
                  <input
                    type="search"
                    name="outlet_name"
                    autoComplete="off"
                    placeholder="Search Outlets by name, phone number etc."
                    value={outlet_name}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !outlet_name ? " is-invalid" : "")
                    }
                  />
                  {searchedOutlet.data &&
                    Array.isArray(searchedOutlet.data.data) &&
                    searchedOutlet.data.data.map((result, index) => (
                      <div
                        key={result}
                        className="p-3 border mb-2"
                        id={result.id}
                        onClick={() => fillOutlet(result)}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="customer-details">
                              <h5>{result.outlet_name}</h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <h5>{result.outlet_phone}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {submitted && !outlet_name && (
                    <div className="invalid-feedback">
                      Order outlet name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !outlet_phone ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="outlet_phone">Order outlet phone</label>
                  <PhoneInput
                    defaultCountry="NG"
                    value={outlet_phone}
                    name="outlet_phone"
                    onChange={setOutletPhone}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !outlet_phone ? " is-invalid" : "")
                    }
                  />
                  {submitted && !outlet_address && (
                    <div className="invalid-feedback">
                      Order outlet phone is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !outlet_address ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="outlet_address">Order outlet address</label>
                  <input
                    type="text"
                    name="outlet_address"
                    value={outlet_address}
                    onChange={handleChange}
                    className={
                      "form-control form-control-rounded" +
                      (submitted && !outlet_address ? " is-invalid" : "")
                    }
                  />
                  {submitted && !outlet_address && (
                    <div className="invalid-feedback">
                      Order outlet address is required
                    </div>
                  )}
                </div>

                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={myCompany.company_country}
                  state_name={myCompany.company_state}
                  city_name={myCompany.company_city}
                />

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !outlet_country ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="outlet_country">Order outlet country</label>
                  <select
                    name="outlet_country"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                    className="form-control form-control-rounded"
                  >
                    {myCompany.company_country && (
                      <option value={myCompany.company_country}>
                        {myCompany.company_country}
                      </option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_country && (
                    <div className="invalid-feedback">
                      Order outlet country is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !outlet_state ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="outlet_state">Order outlet state</label>
                  <select
                    name="outlet_state"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                    className="form-control form-control-rounded"
                  >
                    {myCompany.company_state && (
                      <option value={myCompany.company_state}>
                        {myCompany.company_state}
                      </option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_state && (
                    <div className="invalid-feedback">
                      Order outlet state is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !outlet_city ? " is-invalid" : "")
                  }
                >
                  <label htmlFor="outlet_city">Order outlet city</label>
                  <select
                    name="outlet_city"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                    className="form-control form-control-rounded"
                  >
                    {myCompany.company_city && (
                      <option value={myCompany.company_city}>
                        {myCompany.company_city}
                      </option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_city && (
                    <div className="invalid-feedback">
                      Order outlet city is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn
                color="primary"
                type="submit"
                onClick={(e) => handleSubmit(e, "create")}
              >
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Create Order"
                )}
              </MDBBtn>
              <MDBBtn
                color="primary"
                type="submit"
                onClick={(e) => handleSubmit(e, "record")}
              >
                {recording === true ? (
                  <Beat loading={recording} />
                ) : (
                  "Record Order"
                )}
              </MDBBtn>
            </MDBModalFooter>

            {/* <br/>
                        <pre>
                        {JSON.stringify(orderPayment, null, 4)}
                        </pre> */}
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
