import React from 'react';
import { MDBContainer, MDBBtn } from 'mdbreact';

function UndoCompanyClone() {

    function unCloneCompany() {
        // Retrieve original user details and replace cloned detail
        let originalUserDetail = JSON.parse(localStorage.getItem('originalUserDetail'));
        localStorage.setItem('user', JSON.stringify(originalUserDetail));

        // Reset original user detail holder
        localStorage.removeItem('originalUserDetail');

        // refresh page
        window.location.reload();
    }

    function isCloned() {
        let isCloned = localStorage.getItem('originalUserDetail');
        if (isCloned) {
            return true;
        }

        return false;
    }

    return (
        <div>
            <MDBContainer>
                {isCloned()?<MDBBtn onClick={()=>unCloneCompany()}>Reset Clone</MDBBtn>:null}
            </MDBContainer>
        </div>
    )
}


export default UndoCompanyClone;