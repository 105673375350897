import React from "react";
import UserMainApp from "../layouts/UserMainApp";
export default function UserDashboard() {
  let user = JSON.parse(localStorage.getItem("user"));
  let last4months = new Date();
  last4months.setDate(last4months.getDate() - 100);

  return (
    <UserMainApp>
      <div className="main-content-padding">
        <div className="main-content">
          <div className="breadcrumb">
            <h1 className="mr-2 text-capitalize">Welcome {user.data.name},</h1>
          </div>
          <div className="separator-breadcrumb border-top" />
          <div className="row">
            <div
              className="card mt-5 ml-2"
              style={{ width: "343px", height: "180px" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-3">Book Order</h2>

                <p className="card-text">
                  Order or Deliver time-sensitive packages and documents to
                  domestic locations usingOgwugo.
                </p>
              </div>
            </div>
            <div
              className="card mt-5 ml-4"
              style={{ width: "343px", height: "180px" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-3">Track Order</h2>

                <p className="card-text">Keep track of all your orders.</p>
              </div>
            </div>
            <div
              className="card mt-5 ml-4"
              style={{ width: "343px", height: "180px" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-3">Delivery Rates</h2>

                <p className="card-text">
                  Check prices for your orders and delivery.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="card mt-5 ml-2"
              style={{ width: "343px", height: "180px" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-3">Orders</h2>

                <p className="card-text">
                  Check pending or completed orders here.
                </p>
              </div>
            </div>
            <div
              className="card mt-5 ml-4"
              style={{ width: "343px", height: "180px" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-3">Invites</h2>

                <p className="card-text">
                  Accept Or Decline invites from companies registered under
                  Ogwugo?
                </p>
              </div>
            </div>
            <div
              className="card mt-5 ml-4"
              style={{ width: "343px", height: "180px" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-3">Referrals</h2>

                <p className="card-text">
                  Tell a friend, customer or associate about Ogwugo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserMainApp>
  );
}
