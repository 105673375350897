import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MainApp from "../../layouts/MainApp";
import OrdersSummary from "../../includes/OrdersSummary";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import TableLoader from "../../includes/Loaders/TableLoader";
import { adminActions, userActions } from "../../../redux/actions";

export default function OrdersTable() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;

  let lastmonths = new Date();
  lastmonths.setDate(lastmonths.getDate() - 20);
  const [start_date, setStartDate] = useState(lastmonths);
  const [end_date, setEndDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState("");
  const [dataSet, setDataSet] = useState([]);
  let params = useParams();
  const dispatch = useDispatch();

  //fetch the company data, retrieve it from the reducer and store in local storage
  useEffect(() => {
    dispatch(adminActions.getMyCompany());
  }, [dispatch]);

  const myCompany = useSelector((state) => state.admin.myCompany);
  const loading = useSelector((state) => state.admin.getCompanyLoader);
  const searchOrders = useSelector((state) => state.admin.searchOrders);
  const loadingOrder = useSelector((state) => state.admin.getOrderByDateLoader);
  const searching = useSelector((state) => state.admin.searching);
  localStorage.setItem("myCompany", JSON.stringify(myCompany));

  useEffect(() => {
    dispatch(userActions.getRider(company_id));
    dispatch(adminActions.getEmployees(company_id));
  }, [dispatch, company_id]);

  const allOrdersByDate = useSelector((state) => state.admin.allOrdersByDate);
  let allOrdersData = allOrdersByDate.data;

  useEffect(() => {
    dispatch(
      adminActions.getAllOrdersByDate(
        company_id,
        start_date,
        end_date,
        params.page
      )
    );
    // eslint-disable-next-line
  }, [dispatch, company_id, params]);

  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    dispatch(
      adminActions.getAllOrdersByDate(
        company_id,
        start_date,
        end_date,
        params.page
      )
    );
  };
  const handleClick = () => {
    dispatch(
      adminActions.getAllOrdersByDate(
        company_id,
        start_date,
        end_date,
        params.page
      )
    );
    dispatch(adminActions.getCompanyStats(company_id, start_date, end_date));
  };

  useEffect(() => {
    const new_dataSet = createTableData(allOrdersData && allOrdersData?.data);
    setDataSet(new_dataSet);
    // eslint-disable-next-line
  }, [allOrdersData?.data]);

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (value?.length >= 3) {
      dispatch(adminActions.searchOrders({ search: value }));
    }
    if (value?.length < 1) {
      const new_dataSet = createTableData(allOrdersData && allOrdersData?.data);
      setDataSet(new_dataSet);
    }
  };

  useEffect(() => {
    if (searchOrders?.data?.length > 0) {
      const new_dataSet = createTableData(searchOrders?.data);
      setDataSet(new_dataSet);
    }
    // eslint-disable-next-line
  }, [searchOrders]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function createTableData(props) {
    let dataToMap = props;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((orders, i) => {
        let singleRowArray = {
          created_at: moment(orders.created_at).format("l"),
          invoice: orders.id,
          customer_name: (
            <span className="text-capitalize">{orders.customer_name}</span>
          ),
          outlet_name: (
            <span className="text-capitalize">{orders.outlet_name}</span>
          ),
          price: <span>₦{numberWithCommas(orders.order_amount)}</span>,

          payment:
            orders.order_paid === true ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                True
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                False
              </span>
            ),

          status:
            orders.status === "delivered" ? (
              <span className="badge badge-pill badge-outline-success p-2 m-1">
                Delivered
              </span>
            ) : orders.status === "assigned" ? (
              <span className="badge badge-pill badge-outline-warning p-2 m-1">
                Assigned
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-danger p-2 m-1">
                Unassigned
              </span>
            ),
          source:
            orders.third_party_order === false &&
            orders.second_party_order === false ? (
              <span className="badge badge-pill badge-outline-success p-2 mb-2">
                DashBoard Side Order
              </span>
            ) : orders.third_party_order === true ? (
              <span className="badge badge-pill badge-outline-success p-2 mb-2">
                Food App Order
              </span>
            ) : (
              <span className="badge badge-pill badge-outline-success p-2 mb-2">
                User Side Order
              </span>
            ),

          actions: <OrdersSummary order_data={orders} fromOrders />,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  const data = {
    columns: [
      {
        label: "Created At",
        field: "created_at",
        sort: "asc",
        width: 150,
      },
      {
        label: "Invoice",
        field: "invoice",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Outlet Name",
        field: "outlet_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 100,
      },
      {
        label: "Paid",
        field: "payment",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: dataSet,
  };

  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className="main-content-padding">
          {/* ============ Body content start ============= */}
          <div className="main-content">
            <div
              className="d-flex flex-wrap justify-content-between 
               align-items-center mb-4"
            >
              <div className="">
                <div className="breadcrumb">
                  <h1>Orders Review</h1>
                </div>
              </div>
            </div>

            <div className="date-picker-wrapper" style={{ height: "100px" }}>
              <form onSubmit={handleSubmit}>
                <div className="d-flex">
                  <div
                    className={
                      "form-group col-md-4" +
                      (submitted && !start_date ? " is-invalid" : "")
                    }
                  >
                    <label className="text-truncate" htmlFor="start_date">
                      Start date
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={start_date}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div
                    className={
                      "form-group col-md-4" +
                      (submitted && !end_date ? " is-invalid" : "")
                    }
                  >
                    <label className="text-truncate" htmlFor="date">
                      End date
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={end_date}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>

                  <div className=" col-md-4 pt-2">
                    <button
                      className="btn btn-rounded btn-primary btn-block mt-3"
                      onClick={() => handleClick()}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="input-group mb-4 justify-content-end">
              <div className="form-outline b-In" style={{ borderRadius: "0" }}>
                <input
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => handleSearch(e.target.value)}
                  type="search"
                  className="form-control"
                />
                <button
                  type="button"
                  className="bttn "
                  style={{ borderRadius: "0.2rem", height: "36px" }}
                  onClick={() => {
                    setSearchValue("");
                    const new_dataSet = createTableData(
                      allOrdersData && allOrdersData?.data
                    );
                    setDataSet(new_dataSet);
                  }}
                >
                  <i className=" searchIcon i-Close"></i>
                </button>
              </div>
            </div>
            <div className="separator-breadcrumb border-top" />
            <h6>*Displays from the Most recent to the oldest</h6>
            <div style={{ overflowX: "auto" }}>
              {loadingOrder || searching ? (
                <TableLoader />
              ) : (
                <MDBDataTable
                  id="page"
                  responsive
                  // scrollY
                  theadColor="MDBTableHeader"
                  hover
                  maxHeight="400px"
                  noBottomColumns
                  bordered
                  paging={false}
                  data={data}
                  sortable={false}
                  searching={false}
                />
              )}
            </div>

            <Pagination data={allOrdersData} route="ordersReport" />
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
