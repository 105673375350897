import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainApp from "../../layouts/MainApp";
import { MDBDataTable } from "mdbreact";
import CreateRider from "./CreateRider";
import DeleteRider from "./DeleteRider";
import EditRider from "./EditRider";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import Pagination from "../../includes/Pagination";
import PrimaryLoader from "../../includes/Loaders/PrimaryLoader";
import InviteRider from "./InviteRider";
import ApproveRider from "./ApproveRider";

export default function AllRiders() {
  const allRiders = useSelector((state) => state.users.items);
  const loading = useSelector((state) => state.admin.getRidersLoader);
  const dispatch = useDispatch();

  let params = useParams();

  useEffect(() => {
    dispatch(userActions.getRider(params.page));
  }, [dispatch, params]);

  let riderData = allRiders.data;
  function createTableData() {
    let dataToMap = riderData && riderData.data;

    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((riders, i) => {
        let singleRowArray = {
          name: riders.courier_name,
          contact: riders.courier_phone,
          company_status:
            riders.company_approved === true ? (
              <span className='badge badge-pill badge-outline-success p-2 m-1'>
                Approved
              </span>
            ) : (
              <span className='badge badge-pill badge-outline-danger p-2 m-1'>
                Unapproved
              </span>
            ),
          status:
            riders.status === "active" ? (
              <span className='badge badge-pill badge-outline-success p-2 m-1'>
                Active
              </span>
            ) : (
              <span className='badge badge-pill badge-outline-danger p-2 m-1'>
                Inactive
              </span>
            ),
          actions: (
            <div className='d-flex'>
              <a
                href={`/riderView/${riders.id}`}
                id={riders.user_id}
                key={riders.id}
                className='btn-small btn indigo text-white waves-effect waves-light btn mb-2 mr-1'>
                View
              </a>

              <EditRider riderData={riders} />
              <DeleteRider riderData={riders} />
              {riders.company_approved === false &&
                riders.termination_date === null && (
                  <ApproveRider riderData={riders} />
                )}
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact no.",
        field: "contact",
        sort: "asc",
        width: 100,
      },
      {
        label: "Company Approved",
        field: "company_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };
  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className='main-content-padding'>
          <div className='main-content'>
            <div className='d-flex justify-content-between'>
              <h1>All Riders</h1>
              <div className='d-flex justify-content-end align-items-center mb-3'>
                <InviteRider />
                <CreateRider />
              </div>
            </div>
            <div className='separator-breadcrumb border-top' />
              <MDBDataTable
             responsive
              theadColor='MDBTableHeader'
              entries={8}
              entriesOptions={[8, 10, 15]}
                hover
                bordered
              noBottomColumns
              data={data}
              paging={false}
              searching={false}
            
            />

            <Pagination data={riderData} route='allriders' />
          </div>
        </div>
      )}
    </MainApp>
  );
}
