import { userService } from "../services";
import { alertActions } from "../actions";
import { history } from "../helpers";

export const userActions = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  approveRider,
  ImageUpload,
  createPushNotification,
  registerCompany,
  getRider,
  getSingleRider,
  getActiveRider,
  getInactiveRider,
  createRider,
  editRider,
  deleteRider,
  getSingleUser,
  getMyCompanies,
  delete: _delete,
  changePhone,
  addDescription,
  inviteUser,
  registerInvite,
  registerUser,
  getMyInvites,
  rejectInvite,
  getMyReferralCode,
  CreateMyReferral,
  filterUserOrders,
  getUserOrders,
  trackOrder,
  getSecondPartyOrder,
  changeUserPhone,
  userImageUpload,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user));
        dispatch(createPushNotification());
        history("/auth/switchAccount");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "LOGIN_REQUEST", user };
  }
  function success(user) {
    return { type: "LOGIN_SUCCESS", user };
  }
  function failure(error) {
    return { type: "LOGIN_FAILURE", error };
  }
}

function logout() {
  userService.logout();
  return { type: "LOGOUT" };
}

function forgotPassword(input) {
  return (dispatch) => {
    dispatch(request(input));

    userService.forgotPassword(input).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "PASSWORD_CODE_REQUEST", user };
  }
  function success(user) {
    return { type: "PASSWORD_CODE_SUCCESS", user };
  }
  function failure(error) {
    return { type: "PASSWORD_CODE_FAILURE", error };
  }
}

function resetPassword(input) {
  return (dispatch) => {
    dispatch(request(input));

    userService.resetPassword(input).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
        window.location.assign("/auth/login");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "RESET_PASSWORD_REQUEST", user };
  }
  function success(user) {
    return { type: "RESET_PASSWORD_SUCCESS", user };
  }
  function failure(error) {
    return { type: "RESET_PASSWORD_FAILURE", error };
  }
}

function changePassword(input) {
  return (dispatch) => {
    dispatch(request(input));

    userService.changePassword(input).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "CHANGE_PASSWORD_REQUEST", user };
  }
  function success(user) {
    return { type: "CHANGE_PASSWORD_SUCCESS", user };
  }
  function failure(error) {
    return { type: "CHANGE_PASSWORD_FAILURE", error };
  }
}

function changePhone(data) {
  return (dispatch) => {
    dispatch(request());
    userService.changePhone(data).then(
      (editedEmployee) => {
        dispatch(success(editedEmployee.data));
        dispatch(success(editedEmployee));
        dispatch(alertActions.success(editedEmployee));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CHANGE_PHONE_REQUEST" };
  }
  function success(editedEmployee) {
    return { type: "CHANGE_PHONE_SUCCESS", editedEmployee };
  }
  function failure(error) {
    return { type: "CHANGE_PHONE_FAILURE", error };
  }
}
function changeUserPhone(data) {
  return (dispatch) => {
    dispatch(request());
    userService.changeUserPhone(data).then(
      (user) => {
        dispatch(success(user.data));
        dispatch(success(user));
        dispatch(alertActions.success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CHANGE_PHONE_REQUEST" };
  }
  function success(user) {
    return { type: "CHANGE_PHONE_SUCCESS", user };
  }
  function failure(error) {
    return { type: "CHANGE_PHONE_FAILURE", error };
  }
}
function ImageUpload(data) {
  return (dispatch) => {
    dispatch(request());
    userService.ImageUpload(data).then(
      (editedEmployee) => {
        dispatch(success(editedEmployee.data));
        dispatch(success(editedEmployee));
        dispatch(alertActions.success(editedEmployee));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "IMAGEUPLOAD_REQUEST" };
  }
  function success(editedEmployee) {
    return { type: "IMAGEUPLOAD_SUCCESS", editedEmployee };
  }
  function failure(error) {
    return { type: "IMAGEUPLOAD_FAILURE", error };
  }
}

function userImageUpload(data) {
  return (dispatch) => {
    dispatch(request());
    userService.userImageUpload(data).then(
      (user) => {
        dispatch(success(user.data));
        dispatch(success(user));
        dispatch(alertActions.success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "IMAGEUPLOAD_REQUEST" };
  }
  function success(user) {
    return { type: "IMAGEUPLOAD_SUCCESS", user };
  }
  function failure(error) {
    return { type: "IMAGEUPLOAD_FAILURE", error };
  }
}

function addDescription(data) {
  return (dispatch) => {
    dispatch(request());

    userService.addDescription(data).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "ADD_DESCRIPTION_REQUEST" };
  }
  function success(user) {
    return { type: "ADD_DESCRIPTION_SUCCESS", user };
  }
  function failure(error) {
    return { type: "ADD_DESCRIPTION_FAILURE", error };
  }
}

function inviteUser(input, company_id, setOpen) {
  return (dispatch) => {
    dispatch(request(input));

    userService.inviteUser(input, company_id).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "INVITE_USER_REQUEST", user };
  }
  function success(user) {
    return { type: "INVITE_USER_SUCCESS", user };
  }
  function failure(error) {
    return { type: "INVITE_USER_FAILURE", error };
  }
}

function createPushNotification() {
  let user = JSON.parse(localStorage.getItem("user"));
  let id = user.data.id;
  let firebaseDetails = JSON.parse(localStorage.getItem("firebaseDetails"));

  return (dispatch) => {
    if (!user || !user.data || !user.data.id) {
      return;
    }

    if (
      firebaseDetails &&
      firebaseDetails.device_token &&
      firebaseDetails.device_type &&
      firebaseDetails.notification_type
    ) {
      userService.createPushNotification(id, firebaseDetails);
    }
  };
}

function registerCompany(data) {
  return (dispatch) => {
    dispatch(request(data));
    userService.registerCompany(data).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
        window.location.assign("/auth/login");
        return user;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "REGISTER_REQUEST", user };
  }
  function success(user) {
    return { type: "REGISTER_SUCCESS", user };
  }
  function failure(error) {
    return { type: "REGISTER_FAILURE", error };
  }
}
function registerUser(data) {
  return (dispatch) => {
    dispatch(request(data));
    userService.registerUser(data).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
        window.location.assign("/auth/login");
        return user;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "REGISTER_REQUEST", user };
  }
  function success(user) {
    return { type: "REGISTER_SUCCESS", user };
  }
  function failure(error) {
    return { type: "REGISTER_FAILURE", error };
  }
}
function registerInvite(data) {
  return (dispatch) => {
    dispatch(request(data));
    userService.registerInvite(data).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success(user));
        history("/auth/login");
        return user;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: "REGISTER_REQUEST", user };
  }
  function success(user) {
    return { type: "REGISTER_SUCCESS", user };
  }
  function failure(error) {
    return { type: "REGISTER_FAILURE", error };
  }
}
function getMyInvites() {
  return (dispatch) => {
    dispatch(request(true));
    userService.getMyInvites().then(
      (myInvites) => {
        dispatch(request(false));
        dispatch(success(myInvites));

        dispatch(alertActions.success(myInvites));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "GETMYINVITES_REQUEST" };
  }

  function success(myInvites) {
    return { type: "GETMYINVITES_SUCCESS", myInvites };
  }
  function failure(error) {
    return { type: "GETMYINVITES_FAILURE", error };
  }
}
function rejectInvite(id) {
  return (dispatch) => {
    dispatch(request());
    userService.rejectInvite(id).then(
      (rejectedInvite) => {
        dispatch(success(rejectedInvite));

        dispatch(alertActions.success(rejectedInvite));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "REJECTINVITES_REQUEST" };
  }

  function success(rejectedInvite) {
    return { type: "REJECTINVITES_SUCCESS", rejectedInvite };
  }
  function failure(error) {
    return { type: "REJECTINVITES_FAILURE", error };
  }
}

function getMyReferralCode() {
  let user = JSON.parse(localStorage.getItem("user"));
  return (dispatch) => {
    dispatch(request(true));
    userService.getMyReferral().then(
      (myReferral) => {
        dispatch(request(false));
        dispatch(success(myReferral));

        dispatch(alertActions.success(myReferral));
      },
      (error) => {
        if (error.status_code === 404) {
          dispatch(userActions.CreateMyReferral(user.data));
        } else {
          dispatch(request(false));
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error));
        }
      }
    );
  };

  function request() {
    return { type: "GETMYREFERRAL_REQUEST" };
  }

  function success(myReferral) {
    return { type: "GETMYREFERRAL_SUCCESS", myReferral };
  }
  function failure(error) {
    return { type: "GETMYREFERRAL_FAILURE", error };
  }
}
function CreateMyReferral(data) {
  return (dispatch) => {
    dispatch(request(data));
    userService.createMyReferral(data).then(
      (newReferral) => {
        dispatch(success(newReferral));
        dispatch(getMyReferralCode());
        dispatch(alertActions.success(newReferral));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATEMYREFERRAL_REQUEST" };
  }

  function success(newReferral) {
    return { type: "CREATEMYREFERRAL_SUCCESS", newReferral };
  }
  function failure(error) {
    return { type: "CREATEMYREFERRAL_FAILURE", error };
  }
}
function getRider() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  let company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request());
    userService.getRider(company_id).then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETRIDER_REQUEST" };
  }
  function success(users) {
    return { type: "GETRIDER_SUCCESS", users };
  }
  function failure(error) {
    return { type: "GETRIDER_FAILURE", error };
  }
}
function approveRider(id, setOpen) {
  return (dispatch) => {
    dispatch(request());
    userService.approveRider(id).then(
      (approvedRider) => {
        dispatch(success(approvedRider.data));
        dispatch(alertActions.success(approvedRider));
        dispatch(getRider());
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "APPROVERIDER_REQUEST" };
  }
  function success(approvedRider) {
    return { type: "APPROVERIDER_SUCCESS", approvedRider };
  }
  function failure(error) {
    return { type: "APPROVERIDER_FAILURE", error };
  }
}

function getSingleRider(rider_id) {
  return (dispatch) => {
    dispatch(request());
    userService.getSingleRider(rider_id).then(
      (rider_data) => dispatch(success(rider_data.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETSINGLERIDER_REQUEST" };
  }
  function success(rider_data) {
    return { type: "GETSINGLERIDER_SUCCESS", rider_data };
  }
  function failure(error) {
    return { type: "GETSINGLERIDER_FAILURE", error };
  }
}

function getActiveRider() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user?.data?.groups[teamId]?.company_id;
  return (dispatch) => {
    dispatch(request());
    userService.getActiveRider(company_id).then(
      (rider_active_data) => dispatch(success(rider_active_data.data.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETACTIVERIDER_REQUEST" };
  }
  function success(rider_active_data) {
    return { type: "GETACTIVERIDER_SUCCESS", rider_active_data };
  }
  function failure(error) {
    return { type: "GETACTIVERIDER_FAILURE", error };
  }
}

function getInactiveRider() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user.data.groups[teamId].company_id;
  return (dispatch) => {
    dispatch(request());
    userService.getInactiveRider(company_id).then(
      (rider_inactive_data) => dispatch(success(rider_inactive_data.data.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETINACTIVERIDER_REQUEST" };
  }
  function success(rider_inactive_data) {
    return { type: "GETINACTIVERIDER_SUCCESS", rider_inactive_data };
  }
  function failure(error) {
    return { type: "GETINACTIVERIDER_FAILURE", error };
  }
}

function createRider(data, id) {
  return (dispatch) => {
    dispatch(request(data));
    userService.createRider(data).then(
      (unassigned) => {
        dispatch(success(unassigned.data.data));
        dispatch(getRider());
        dispatch(alertActions.success(unassigned));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "CREATERIDER_REQUEST" };
  }
  function success(unassigned) {
    return { type: "CREATERIDER_SUCCESS", unassigned };
  }
  function failure(error) {
    return { type: "CREATERIDER_FAILURE", error };
  }
}

function editRider(data, setOpen) {
  return (dispatch) => {
    dispatch(request());
    userService.editRider(data).then(
      (editedRider) => {
        dispatch(success(editedRider.data));
        dispatch(alertActions.success(editedRider));
        dispatch(getRider());
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "EDITRIDER_REQUEST" };
  }
  function success(editedRider) {
    return { type: "EDITRIDER_SUCCESS", editedRider };
  }
  function failure(error) {
    return { type: "EDITRIDER_FAILURE", error };
  }
}

function deleteRider(id, setOpen) {
  return (dispatch) => {
    dispatch(request());
    userService.deleteRider(id).then(
      (deletedRider) => {
        dispatch(success(deletedRider.data));
        dispatch(alertActions.success(deletedRider));
        dispatch(getRider());
        setOpen(false);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: "DELETERIDER_REQUEST" };
  }
  function success(deletedRider) {
    return { type: "DELETERIDER_SUCCESS", deletedRider };
  }
  function failure(error) {
    return { type: "DELETERIDER_FAILURE", error };
  }
}

function getSingleUser(user_id) {
  return (dispatch) => {
    dispatch(request());
    userService.getSingleUser(user_id).then(
      (user_data) => dispatch(success(user_data.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETSINGLEUSER_REQUEST" };
  }
  function success(user_data) {
    return { type: "GETSINGLEUSER_SUCCESS", user_data };
  }
  function failure(error) {
    return { type: "GETSINGLEUSER_FAILURE", error };
  }
}

function getMyCompanies() {
  return (dispatch) => {
    dispatch(request());
    userService.getMyCompanies().then(
      (my_company) => dispatch(success(my_company.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: "GETMYCOMPANY_REQUEST" };
  }
  function success(my_company) {
    return { type: "GETMYCOMPANY_SUCCESS", my_company };
  }
  function failure(error) {
    return { type: "GETMYCOMPANY_FAILURE", error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: "DELETE_REQUEST", id };
  }
  function success(id) {
    return { type: "DELETE_SUCCESS", id };
  }
  function failure(id, error) {
    return { type: "DELETE_FAILURE", id, error };
  }
}
function getUserOrders() {
  return (dispatch) => {
    dispatch(request(true));

    userService.getUserOrders().then(
      (userOrders) => {
        dispatch(success(userOrders));
        dispatch(request(false));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "GETUSERORDERS_REQUEST", payload };
  }
  function success(userOrders) {
    return { type: "GETUSERORDERS_SUCCESS", userOrders };
  }
  function failure(error) {
    return { type: "GETUSERORDERS_FAILURE", error };
  }
}
function filterUserOrders(payload, page) {
  return (dispatch) => {
    dispatch(request(true));

    userService.filterUserOrders(payload, page).then(
      (userOrders) => {
        if (payload.filterType === "completed") {
          let results = userOrders.data.data.filter((item) => {
            return item.status === "completed";
          });
          userOrders = {
            ...userOrders,
            data: { ...userOrders.data, data: results },
          };
        }
        dispatch(request(false));

        dispatch(success(userOrders));
      },
      (error) => {
        dispatch(request(false));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(payload) {
    return { type: "GETUSERORDERS_REQUEST", payload };
  }
  function success(userOrders) {
    return { type: "GETUSERORDERS_SUCCESS", userOrders };
  }
  function failure(error) {
    return { type: "GETUSERORDERS_FAILURE", error };
  }
}
function trackOrder() {
  return (dispatch) => {
    dispatch(request(true));

    userService.getUserOrders().then(
      (trackedOrder) => {
        dispatch(success(trackedOrder));
        dispatch(request(false));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "TRACKORDER_REQUEST", payload };
  }
  function success(trackedOrder) {
    return { type: "TRACKORDER_SUCCESS", trackedOrder };
  }
  function failure(error) {
    return { type: "TRACKORDER_FAILURE", error };
  }
}

function getSecondPartyOrder(id) {
  return (dispatch) => {
    dispatch(request(true));

    userService.getSecondPartyOrder(id).then(
      (secondPartyOrder) => {
        dispatch(success(secondPartyOrder));
        dispatch(request(false));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(request(false));
      }
    );
  };

  function request(payload) {
    return { type: "SECONDPARTYORDER_REQUEST", payload };
  }
  function success(secondPartyOrder) {
    return { type: "SECONDPARTYORDER_SUCCESS", secondPartyOrder };
  }
  function failure(error) {
    return { type: "SECONDPARTYORDER_FAILURE", error };
  }
}
