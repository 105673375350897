import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { adminActions } from "../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { DEFAULTORDER } from "../images/images";
import moment from "moment";

//to detect Url
function detectURLs(message) {
  var urlRegex = /(((https?:\/\/)|(support\.)| (www\.))[^\s]+)/g;
  return message.match(urlRegex);
}
//Format a json string to defined sentence
const formatJsonToString = (json) => {
  try {
    let parsedJson = JSON.parse(json);
    let string = "";

    parsedJson &&
      Object.keys(parsedJson).map((value) => {
        return (string = string + value + ": " + parsedJson[value] + "\n");
      });

    return string;
  } catch (error) {
    return json;
  }
};

export default function OrderSummary({
  order_data,
  employee_comment,
  courier_comment,
  fromOrders,
}) {
  const data = useSelector((state) => state.admin.singleOrder);
  const singleDelieveredItem = data?.courier_orders?.[0]?.delivered_items;
  const dispatch = useDispatch();

  const [visible, setvisible] = useState(false);
  const [currentOrderData, setCurrentOrderData] = useState({});
  useEffect(() => {
    setCurrentOrderData(order_data);
  }, [order_data]);

  function orderPrice(unit, price) {
    return unit * price;
  }

  const singleOrderItem = data.order_items;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function createTableData() {
    let dataToMap = singleOrderItem;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((item, i) => {
        let singleRowArray = {
          photo: (
            <img className="order_img" src={DEFAULTORDER} alt="order_image" />
          ),
          name: (
            <span>
              <span> {item.name} </span>
              <br></br>
              <span>
                {" "}
                {item.options &&
                  item.options.map((option, key) => {
                    return option.name ? (
                      <span key={key}>
                        {option.name} <br></br>{" "}
                      </span>
                    ) : null;
                  })}{" "}
              </span>
            </span>
          ),
          quantity: (
            <span>
              <span> {item.quantity} </span>
              <br></br>
              <span>
                {" "}
                {item.options &&
                  item.options.map((option, key) => {
                    return option.quantity ? (
                      <span key={key}>
                        {option.quantity} <br></br>{" "}
                      </span>
                    ) : null;
                  })}{" "}
              </span>
            </span>
          ),
          unit_price: (
            <span>
              <span>
                <span> ₦{numberWithCommas(item.price)} </span>
                <br></br>
                <span>
                  {" "}
                  {item.options &&
                    item.options.map((option, key) => {
                      return option.price ? (
                        <span key={key}>
                          ₦{numberWithCommas(option.price)} <br></br>{" "}
                        </span>
                      ) : null;
                    })}{" "}
                </span>
              </span>
            </span>
          ),
          order_price: (
            <span>
              <span>
                <span>
                  {" "}
                  ₦{numberWithCommas(
                    orderPrice(item.quantity, item.price)
                  )}{" "}
                </span>
                <br></br>
                <span>
                  {" "}
                  {item.options &&
                    item.options.map((option, key) => {
                      return option.price ? (
                        <span key={key}>
                          ₦
                          {numberWithCommas(
                            orderPrice(option.price, option.quantity)
                          )}{" "}
                          <br></br>{" "}
                        </span>
                      ) : null;
                    })}{" "}
                </span>
              </span>
            </span>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const dataSet = createTableData();

  const tableData = {
    columns: [
      {
        label: "Image",
        field: "photo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Quantity",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Unit Price",
        field: "unit_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Price",
        field: "order_price",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataSet,
  };
  function createRemittanceTableData() {
    let dataToMap = singleDelieveredItem;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((item, i) => {
        let singleRowArray = {
          photo: (
            <img className="order_img" src={DEFAULTORDER} alt="order_image" />
          ),
          name: (
            <span>
              <span> {item.name} </span>
              <br></br>
              <span>
                {" "}
                {item.options &&
                  item.options.map((option, key) => {
                    return option.name ? (
                      <span key={key}>
                        {option.name} <br></br>{" "}
                      </span>
                    ) : null;
                  })}{" "}
              </span>
            </span>
          ),
          quantity: (
            <span>
              <span> {item.quantity} </span>
              <br></br>
              <span>
                {" "}
                {item.options &&
                  item.options.map((option, key) => {
                    return option.quantity ? (
                      <span key={key}>
                        {option.quantity} <br></br>{" "}
                      </span>
                    ) : null;
                  })}{" "}
              </span>
            </span>
          ),
          unit_price: (
            <span>
              <span>
                <span> ₦{numberWithCommas(item.price)} </span>
                <br></br>
                <span>
                  {" "}
                  {item.options &&
                    item.options.map((option, key) => {
                      return option.price ? (
                        <span key={key}>
                          ₦{numberWithCommas(option.price)} <br></br>{" "}
                        </span>
                      ) : null;
                    })}{" "}
                </span>
              </span>
            </span>
          ),
          order_price: (
            <span>
              <span>
                <span>
                  {" "}
                  ₦{numberWithCommas(
                    orderPrice(item.quantity, item.price)
                  )}{" "}
                </span>
                <br></br>
                <span>
                  {" "}
                  {item.options &&
                    item.options.map((option, key) => {
                      return option.price ? (
                        <span key={key}>
                          ₦
                          {numberWithCommas(
                            orderPrice(option.price, option.quantity)
                          )}{" "}
                          <br></br>{" "}
                        </span>
                      ) : null;
                    })}{" "}
                </span>
              </span>
            </span>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }
  const deliveredDataSet = createRemittanceTableData();

  const deliveredTableData = {
    columns: [
      {
        label: "Image",
        field: "photo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Quantity",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Unit Price",
        field: "unit_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Price",
        field: "order_price",
        sort: "asc",
        width: 150,
      },
    ],
    rows: deliveredDataSet,
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn
          title="Order details"
          onClick={() => {
            setvisible(true);
            !fromOrders &&
              dispatch(adminActions.getAnOrder(order_data.order_id));
            fromOrders && dispatch(adminActions.getAnOrder(order_data.id));
          }}
        >
          <i className="nav-icon i-Eye-Visible" />
        </MDBBtn>

        <MDBModal
          isOpen={visible}
          size="lg"
          toggle={() => setvisible(false)}
          scrollable="true"
        >
          <MDBModalHeader toggle={() => setvisible(false)}>
            View Order Summary
          </MDBModalHeader>
          <MDBModalBody>
            {currentOrderData !== {} && (
              <>
                {data.status === "assigned" && (
                  <div className="mb-4">
                    {" "}
                    <span className="badge badge-pill badge-outline-warning p-2 m-1">
                      Order Assigned
                    </span>
                  </div>
                )}
                {data.status === "unassigned" && (
                  <div className="mb-4">
                    {" "}
                    <span className="badge badge-pill badge-outline-danger p-2 m-1">
                      Order Unassigned
                    </span>{" "}
                  </div>
                )}
                {data.status === "delivered" && (
                  <div className="mb-4">
                    {" "}
                    <span className="badge badge-pill badge-outline-success p-2 m-1">
                      Order Delivered
                    </span>{" "}
                  </div>
                )}

                <div className="accordion mb-3" id="accordionContact">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                        <a
                          className="text-default collapsed"
                          data-toggle="collapse"
                          href="#accordion-item-icon-right-1"
                          aria-expanded="false"
                        >
                          Customer Information
                        </a>
                      </h3>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-item-icon-right-1"
                      data-parent="#accordionContact"
                      style={{}}
                    >
                      <div className="order_contact">
                        <div className="d-flex">
                          <div className="mr-4">
                            <div className="alternative-image">
                              {data &&
                                data.customer_name &&
                                data.customer_name.charAt(0)}
                            </div>
                          </div>
                          <div className="px-2 w-100">
                            <div className="row">
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Name:</h6>
                                  <h5 className="text-capitalize">
                                    {data.customer_name}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Phone:</h6>
                                  <h5>{data.customer_phone}</h5>
                                </div>
                              </div>
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Email:</h6>
                                  <h5>{data.customer_email}</h5>
                                </div>
                              </div>
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Address:</h6>
                                  <h5>{data.customer_address}</h5>
                                </div>
                              </div>
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>City:</h6>
                                  <h5>{data.customer_city}</h5>
                                </div>
                              </div>
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Gender:</h6>
                                  <h5>{data.customer_gender}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion mb-3" id="accordionStore">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                        <a
                          className="text-default collapsed"
                          data-toggle="collapse"
                          href="#accordion-item-icon-right-2"
                        >
                          Store Information
                        </a>
                      </h3>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-item-icon-right-2"
                      data-parent="#accordionStore"
                    >
                      <div className="order_contact">
                        <div className="d-flex">
                          <div className="mr-4">
                            <div className="alternative-image">
                              {data &&
                                data.outlet_name &&
                                data.outlet_name.charAt(0)}
                            </div>
                          </div>
                          <div className="px-2 w-100">
                            <div className="row">
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Store name:</h6>
                                  <h5 className="text-capitalize">
                                    {data.outlet_name}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-md-4 mb-2">
                                <div className="customer-details">
                                  <h6>Store phone:</h6>
                                  <h5>{data.outlet_phone}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="order_contact mb-4">
                  <div className="pt-4 mb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="mb-2">Order Details</h3>
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div className="mr-4">
                      <div className="customer-details">
                        <h6>Payment Status:</h6>
                        <h5 className="text-capitalize">
                          {data.order_paid === true ? (
                            <span className="badge badge-pill badge-outline-success p-2">
                              Paid
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-outline-danger p-2">
                              Not Paid
                            </span>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="mr-4">
                      <div className="customer-details">
                        <h6>Payment Method:</h6>
                        <h5 className="text-capitalize">
                          {data.order_payment_method &&
                            data.order_payment_method.name}
                        </h5>
                      </div>
                    </div>
                    <div className="customer-details">
                      <h6>Payment Details:</h6>
                      {data.order_payment_method &&
                      data.order_payment_method.details ? (
                        <h5>
                          {data.order_payment_method &&
                            formatJsonToString(
                              data.order_payment_method.details
                                .split(",")
                                .map(function (item, idx) {
                                  return detectURLs(item) ? (
                                    <a
                                      href={item.trim()}
                                      key={idx}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item}
                                      <br />
                                    </a>
                                  ) : (
                                    <span key={idx}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })
                            )}
                        </h5>
                      ) : (
                        <h5>No Details</h5>
                      )}
                    </div>
                  </div>
                  {data && (
                    <MDBDataTable
                      responsive
                      entriesOptions={[5, 10, 15]}
                      bordered
                      hover
                      noBottomColumns
                      displayEntries={false}
                      searching={false}
                      paging={false}
                      data={tableData}
                    />
                  )}
                  <div className="float-right text-right mt-3">
                    <div className="d-flex">
                      <h5 className="mr-2">Delivery fee:</h5>
                      <h5 className="font-weight-bold">
                        ₦
                        {data?.order_delivery_fee &&
                          numberWithCommas(data.order_delivery_fee)}
                      </h5>
                    </div>
                    <div className="d-flex">
                      <h5 className="mr-2">Discount:</h5>
                      <h5 className="font-weight-bold">
                        ₦
                        {data?.order_discount &&
                          numberWithCommas(data.order_discount)}
                      </h5>
                    </div>

                    <div className="d-flex">
                      <h5 className="mr-2">Service fee:</h5>
                      <h5 className="font-weight-bold">
                        ₦
                        {data?.order_service_fee &&
                          numberWithCommas(data.order_service_fee)}
                      </h5>
                    </div>
                    <div className="d-flex">
                      <h5 className="mr-2">Insurance fee:</h5>
                      <h5 className="font-weight-bold">
                        ₦
                        {data?.order_insurance_fee &&
                          numberWithCommas(data.order_insurance_fee)}
                      </h5>
                    </div>
                    <hr className="my-2" />
                    <h5>Total amount:</h5>
                    <h3>
                      ₦
                      {data &&
                        data.order_amount &&
                        numberWithCommas(
                          data.order_amount +
                            data.order_insurance_fee +
                            data.order_service_fee
                        )}
                    </h3>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-7">
                      <div className="mb-3">
                        <label className="mb-0">Customer Comment:</label>
                        {data.customer_comment ? (
                          <h4 className="mb-4">{data.customer_comment}</h4>
                        ) : (
                          <h4 className="mb-4">No comments</h4>
                        )}
                      </div>
                      <div>
                        <label className="mb-0">Order Description:</label>
                        {data.order_description ? (
                          <h4 className="mb-4">{data.order_description}</h4>
                        ) : (
                          <h4 className="mb-4">No description</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="order_contact mb-4">
                  <div className="pt-4 mb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="mb-2">Remittance Details</h3>
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div className="mr-4">
                      <div className="customer-details">
                        <h6>Remittance Status:</h6>
                        <h5 className="text-capitalize">
                          {data.courier_orders?.[0]?.remitted === true ? (
                            <span className="badge badge-pill badge-outline-success p-2">
                              Remitted
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-outline-danger p-2">
                              Not Remitted
                            </span>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="mr-4">
                      <div className="customer-details">
                        <h6>Remittance Verified:</h6>
                        <h5 className="text-capitalize">
                          {data.courier_orders?.[0]?.remittance_verified ===
                          true ? (
                            <span className="badge badge-pill badge-outline-success p-2">
                              Verified
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-outline-danger p-2">
                              Not Verified
                            </span>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="mr-4">
                      <div className="customer-details">
                        <h6>Accepted At:</h6>
                        <h5 className="text-capitalize">
                          {data.courier_orders?.[0]?.accepted_at_date ===
                          null ? (
                            <span>Not available</span>
                          ) : (
                            moment(
                              data.courier_orders?.[0]?.accepted_at_date
                            ).format("LT")
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="">
                      <div className="customer-details">
                        <h6>Delivered At:</h6>
                        <h5 className="text-capitalize">
                          {data.courier_orders?.[0]?.delivered_at_date ===
                          null ? (
                            <span>Not available</span>
                          ) : (
                            moment(
                              data.courier_orders?.[0]?.delivered_at_date
                            ).format("LT")
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {data && (
                    <MDBDataTable
                      responsive
                      entriesOptions={[5, 10, 15]}
                      bordered
                      hover
                      noBottomColumns
                      displayEntries={false}
                      searching={false}
                      paging={false}
                      data={deliveredTableData}
                    />
                  )}
                  <div className="float-right text-right mt-3">
                    <h5>Remitted amount:</h5>
                    <h3>
                      ₦
                      {data &&
                        data.courier_orders?.[0]?.remitted_amount &&
                        numberWithCommas(
                          data.courier_orders?.[0]?.remitted_amount
                        )}
                    </h3>
                  </div>
                  <div className="row mt-4">
                    <div className="">
                      <p className="mb-0">Verifier Comment:</p>
                      {data.courier_orders?.[0]?.verifier_comment ? (
                        <h4 className="mb-4">
                          {data.courier_orders?.[0]?.verifier_comment}
                        </h4>
                      ) : (
                        <p className="">No comments</p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    {employee_comment ? (
                      <p>{`Employee Comment:  ${employee_comment}`}</p>
                    ) : (
                      <p>{`Employee Comment: No Comment`}</p>
                    )}
                    {courier_comment ? (
                      <p>{`Riders Comment:  ${courier_comment}`}</p>
                    ) : (
                      <p>{`Riders Comment: No Comment`}</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setvisible(false)}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
