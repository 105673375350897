import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function CreateStores() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const id = user.data.groups[teamId].company_id;

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  //This is to get company details
  const [myCompany, setMyCompany] = React.useState({});
  useEffect(() => {
    let isUnmount = false;
    const getMyCompany = async () => {
      let companyData = JSON.parse(await localStorage.getItem("myCompany"));
      if (!isUnmount) {
        companyData && setMyCompany(companyData);
        companyData &&
          setData((data) => ({
            ...data,
            outlet_city: companyData.company_city,
            outlet_state: companyData.company_state,
            outlet_country: companyData.company_country,
          }));
      }
    };
    getMyCompany();
    return () => {
      isUnmount = true;
    };
  }, []);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [submitted, setSubmitted] = useState(false);
  let [outlet_phone, setOutletPhone] = useState("");
  const [data, setData] = useState([
    {
      company_id: "",
      outlet_email: "",
      outlet_name: "",
      outlet_phone: "",
      outlet_address: "",
      outlet_city: "",
      outlet_state: "",
      outlet_country: "",
    },
  ]);
  const {
    outlet_email,
    outlet_name,
    outlet_address,
    outlet_city,
    outlet_state,
    outlet_country,
  } = data;
  const creating = useSelector((state) => state.admin.creating);
  outlet_phone = outlet_phone?.substring(1);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      outlet_name &&
      outlet_phone &&
      outlet_address &&
      outlet_city &&
      outlet_state &&
      outlet_country
    ) {
      // dispatch action to create data
      dispatch(
        adminActions.createOutlet(
          { ...data, outlet_phone, company_id: id },
          setOpen
        )
      );
      handleClose();
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setOpen(true)}>
          Add an outlet
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Add a new outlet
            </MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_name ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_name">Outlet name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outlet_name"
                    onChange={handleChange}
                  />
                  {submitted && !outlet_name && (
                    <div className="invalid-feedback">
                      Outlet name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_email ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_email">Outlet email*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="outlet_email"
                    onChange={handleChange}
                  />
                  {submitted && !outlet_email && (
                    <div className="invalid-feedback">
                      outlet_email is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_phone">Outlet phone*</label>
                  <PhoneInput
                    defaultCountry="NG"
                    className="form-control"
                    value={outlet_phone}
                    onChange={setOutletPhone}
                  />
                  {submitted && !outlet_phone && (
                    <div className="invalid-feedback">
                      Outlet phone is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_address">Outlet address*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outlet_address"
                    onChange={handleChange}
                  />
                  {submitted && !outlet_address && (
                    <div className="invalid-feedback">
                      Outlet address is required.
                    </div>
                  )}
                </div>
                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={myCompany.company_country}
                  state_name={myCompany.company_state}
                  city_name={myCompany.company_city}
                />
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_country">Outlet country*</label>
                  <select
                    name="outlet_country"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_country && (
                      <option>{myCompany.company_country}</option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_country && (
                    <div className="invalid-feedback">
                      Outlet country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_state">Outlet state</label>
                  <select
                    name="outlet_state"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_state && (
                      <option>{myCompany.company_state}</option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_state && (
                    <div className="invalid-feedback">
                      Outlet state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-12 mb-4" +
                    (submitted && outlet_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="outlet_city">Outlet city*</label>
                  <select
                    className="form-control"
                    name="outlet_city"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_city && (
                      <option>{myCompany.company_city}</option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !outlet_city && (
                    <div className="invalid-feedback">
                      Outlet city is required
                    </div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Create outlet"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default CreateStores;
