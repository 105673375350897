import React from "react";
import ActiveRiders from "./ActiveRiders";
import InactiveRiders from "./InactiveRiders";
import { useSelector } from "react-redux";
import { REDPIN, GRNPIN } from "../../images/images";

export default function RiderList() {
  const activeRiders = useSelector((state) => state.users.rider_active);
  const inactiveRiders = useSelector((state) => state.users.rider_inactive);
  return (
    <div>
      <div className="text-left">
        <div className="order-title">
          <h4>Riders List</h4>
          <a
            href="/allriders/1"
            className="btn btn-raised btn-raised-secondary m-1"
          >
            See all riders
          </a>
        </div>
        <ul className="nav nav-pills mb-2" id="myPillTab" role="tablist">
          {activeRiders && (
            <li className="nav-item">
              <a
                className="nav-link active"
                id="activeRider-pill"
                data-toggle="pill"
                href="#activeRider"
                role="tab"
                aria-controls="activeRider"
                aria-selected="true"
              >
                <span>{activeRiders.length}</span> Active
              </a>
            </li>
          )}
          {inactiveRiders && (
            <li className="nav-item">
              <a
                className="nav-link"
                id="inactiveRider-pill"
                data-toggle="pill"
                href="#inactiveRider"
                role="tab"
                aria-controls="inactiveRider"
                aria-selected="false"
              >
                <span>{inactiveRiders.length}</span> Inactive
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content" id="myPillTabContent">
          <div
            className=" tab-pane fade show active"
            id="activeRider"
            role="tabpanel"
            aria-labelledby="activeRider-pill"
          >
            <ActiveRiders />
          </div>
          <div
            className="tab-pane fade"
            id="inactiveRider"
            role="tabpanel"
            aria-labelledby="inactiveRider-pill"
          >
            <InactiveRiders />
          </div>
        </div>
        <div className="ml-3">
          <p>
            <img className="pins" src={GRNPIN} alt="Green Pin" />
            No Orders Assigned
          </p>
          <p>
            <img className="pins" src={REDPIN} alt="Red Pin" />
            Orders Assigned{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
