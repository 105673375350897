import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";
import { Beat } from "../../redux/reducers/spinner";

export default function ResetPassword() {
  const urlParams = new URLSearchParams(window.location.search);
  const reset_token = urlParams.get("token");

  const [input, setInput] = useState({
    email: "",
    token: reset_token,
    new_password: "",
    new_password_confirmation: "",
  });
  const [submitted] = useState(false);
  const { email, token, new_password, new_password_confirmation } = input;
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.authentication.loading);

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (email && token && new_password && new_password_confirmation) {
      // get return url from location state or default to home page
      dispatch(userActions.resetPassword(input));
    }
  }

  return (
    <AuthLayout>
      <div className='p-4 auth-box'>
        <div className='auth-title'>
          <h1 className='mb-4 text-primary font-weight-bold'>
            Reset your password
          </h1>
          <h4 className='mb-4'>Sign in by entering the information below </h4>
        </div>
        <form onSubmit={handleSubmit}>
          <div
            className={
              "form-group mb-3" + (submitted && !email ? " is-invalid" : "")
            }>
            <label htmlFor='email'>Email address</label>
            <input
              id='email'
              type='email'
              name='email'
              v
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !email ? " is-invalid" : "")
              }
            />
            {submitted && !email && (
              <div className='invalid-feedback'>Email is required</div>
            )}
          </div>
          <div
            className={
              "form-group mb-3" + (submitted && !token ? " is-invalid" : "")
            }>
            <label htmlFor='token'>Token</label>
            <input
              id='token'
              type='text'
              name='token'
              defaultValue={token}
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !token ? " is-invalid" : "")
              }
            />
            {submitted && !token && (
              <div className='invalid-feedback'>Token is required</div>
            )}
          </div>
          <div
            className={
              "form-group" + (submitted && !new_password ? " is-invalid" : "")
            }>
            <label htmlFor='new_password'>New password</label>
            <input
              id='new_password'
              type='password'
              name='new_password'
              autoComplete='off'
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !new_password ? " is-invalid" : "")
              }
            />
            {submitted && !new_password && (
              <div className='invalid-feedback'>New password is required</div>
            )}
          </div>
          <div
            className={
              "form-group" +
              (submitted && !new_password_confirmation ? " is-invalid" : "")
            }>
            <label htmlFor='new_password_confirmation'>
              New password confirmation
            </label>
            <input
              id='new_password_confirmation'
              type='password'
              name='new_password_confirmation'
              autoComplete='off'
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !new_password_confirmation ? " is-invalid" : "")
              }
            />
            {submitted && !new_password_confirmation && (
              <div className='invalid-feedback'>
                New password confirmation is required
              </div>
            )}
          </div>
          <button className='btn btn-rounded btn-primary btn-block mt-4'>
            {loading === true ? <Beat loading={loading} /> : "Reset Password"}
          </button>
        </form>
        <div className='mt-3 mb-4 text-center'>
          <a className='text-muted' href='/auth/login'>
            {" "}
            <u>Back to Login</u>
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}
