import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../redux/actions";
import { Beat } from "../../../redux/reducers/spinner";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

export default function UnassignAnOrder({
  actionType,
  id_holder,
  visible,
  setvisible = () => {},
  openModal = () => {},
}) {
  const dispatch = useDispatch();

  const assigning = useSelector((state) => state.admin.assigning);

  const handleSubmit = (event) => {
    event.preventDefault();
    let id = id_holder?.pop().id;

    dispatch(
      adminActions.unAssignOrder(
        { courier_order_id: id },
        setvisible,
        openModal
      )
    );
  };

  return (
    <div>
      <MDBContainer>
        <MDBModal isOpen={visible}>
          <form onSubmit={handleSubmit}>
            <MDBModalHeader>{actionType} action notice!</MDBModalHeader>
            <MDBModalBody>
              <h5>You are about to {actionType} this order.</h5>
              <h5>
                Continuing to {actionType} will unassign the order. Do you wish
                to unassign and continue?
              </h5>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setvisible(false)}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' type='submit' disabled={assigning}>
                {assigning === true ? (
                  <Beat loading={true} />
                ) : (
                  "Unassign order and continue"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}
