import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";
import { Beat } from "../../redux/reducers/spinner";

export default function ForgotPassword() {
  const [input, setInput] = useState({ email: "" });
  const [submitted] = useState(false);
  const { email } = input;
  const dispatch = useDispatch();

  const sending = useSelector((state) => state.authentication.sending);

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (email) {
      // get return url from location state or default to home page
      dispatch(userActions.forgotPassword(input));
    }
  }

  return (
    <AuthLayout>
      <div className='p-4 auth-box'>
        <div className='auth-title'>
          <h1 className='mb-4 text-primary font-weight-bold'>
            Forgot your password?
          </h1>
          <h4 className='mb-4'>Sign in by entering the information below </h4>
        </div>
        <form onSubmit={handleSubmit}>
          <div
            className={
              "form-group mb-3" + (submitted && !email ? " is-invalid" : "")
            }>
            <label htmlFor='email'>Email address</label>
            <input
              id='email'
              type='email'
              name='email'
              value={email}
              onChange={handleChange}
              className={
                "form-control form-control-rounded" +
                (submitted && !email ? " is-invalid" : "")
              }
            />
            {submitted && !email && (
              <div className='invalid-feedback'>Email is required</div>
            )}
          </div>

          <button className='btn btn-rounded btn-primary btn-block mt-4'>
            {sending === true ? (
              <Beat loading={sending} />
            ) : (
              "Confirm your email"
            )}
          </button>
        </form>
        <div className='mt-3 text-center'>
          <a className='text-muted' href='/auth/login'>
            <u>Back to Login</u>
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}
