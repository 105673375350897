import React, { useState, useEffect } from "react";
import AuthLayout from "../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { userActions, adminActions } from "../../redux/actions";
import { Beat } from "../../redux/reducers/spinner";
import Location from "../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";

export default function InviteLinkReg() {
  const urlParams = new URLSearchParams(window.location.search);
  const invite = urlParams.get("invite_code");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.getCountries());
  }, [dispatch]);

  const registering = useSelector((state) => state.registration.registering);

  // Used to toggle the password visibility
  function toggleFunction(e) {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }
  function toggleFunction2(e) {
    var x = document.getElementById("password2");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  // Form actions
  const [submitted] = useState(false);
  let [user_phone, setUserPhone] = useState("");
  const [data, setData] = useState({
    user_name: "",
    user_email: "",
    user_password: "",
    user_password_confirmation: "",
    user_address: "",
    user_city: "",
    user_state: "",
    user_country: "",
    invite_code: invite,
    user_birth_date: "",
    user_birth_year: "",
  });
  const {
    user_name,
    user_email,
    user_password,
    user_password_confirmation,
    user_address,
    user_city,
    user_state,
    user_country,
    invite_code,
    user_birth_year,
    user_birth_date,
  } = data;

  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  user_phone = user_phone?.substring(1);
  function handleSubmit(e) {
    e.preventDefault();

    if (
      user_name &&
      user_email &&
      user_password &&
      user_password_confirmation &&
      user_phone &&
      user_address &&
      user_city &&
      user_state &&
      user_country &&
      invite_code &&
      user_birth_year &&
      user_birth_date
    ) {
      // get return url from location state or default to home page
      dispatch(userActions.registerInvite(data, user_phone));
    }
  }

  return (
    <AuthLayout>
      <div className="auth-box">
        <div className="auth-title">
          <h1 className="mb-4 text-primary font-weight-bold">
            Get started with Ogwugo Delivery
          </h1>
          <h4 className="mb-4">Lets connect your business to the world </h4>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h3>Enter Personal details</h3>
          <div className="text-left row pt-4">
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_name ? " has-error" : "")
              }
            >
              <label htmlFor="user_name">Full name*</label>
              <input
                placeholder="Enter name"
                type="text"
                className="form-control"
                name="user_name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !user_name && (
                <div className="help-block">Name is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_phone ? " has-error" : "")
              }
            >
              <label htmlFor="user_phone">Phone*</label>
              <PhoneInput
                defaultCountry="NG"
                placeholder="Enter Phone number"
                className="form-control"
                value={user_phone}
                onChange={setUserPhone}
              />
              {submitted && !user_phone && (
                <div className="help-block">Phone is required</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_email ? " has-error" : "")
              }
            >
              <label htmlFor="user_email">Email*</label>
              <input
                placeholder="Enter Email"
                type="email"
                className="form-control"
                name="user_email"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !user_email && (
                <div className="help-block">Email is required</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_password ? " has-error" : "")
              }
            >
              <label htmlFor="user_password">Password*</label>
              <input
                placeholder="Minimum of six(6) characters"
                type="password"
                id="password"
                className="form-control"
                name="user_password"
                onChange={handleChange}
              />
              {submitted && !user_password && (
                <div className="help-block"> Password is required</div>
              )}
              <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction}
              ></i>
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_password_confirmation ? " has-error" : "")
              }
            >
              <label htmlFor="user_password_confirmation">
                Confirm Password*
              </label>
              <input
                placeholder="Minimum of six(6) characters"
                type="password"
                id="password2"
                className="form-control"
                name="user_password_confirmation"
                onChange={handleChange}
              />
              {submitted && !user_password_confirmation && (
                <div className="help-block">
                  Password confirmation is required
                </div>
              )}
              <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction2}
              ></i>
            </div>
          </div>

          <div className="text-left row ">
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_address ? " has-error" : "")
              }
            >
              <label htmlFor="user_address">Residential Address*</label>
              <input
                placeholder="Enter address"
                type="text"
                className="form-control"
                name="user_address"
                onChange={handleChange}
              />
              {submitted && !user_address && (
                <div className="help-block"> Address is required.</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-12 mb-4" +
                ((submitted && user_birth_date) ||
                (submitted && user_birth_year)
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor="user_birth_date ">Date of birth:</label>

              <input
                type="date"
                placeholder="yyyy-mm-dd"
                className="form-control"
                selected={user_birth_date}
                onChange={(event) => {
                  setData({
                    ...data,
                    user_birth_date: moment(event.target.value).format(
                      "YYYY-MM-DD"
                    ),
                    user_birth_year: new Date(user_birth_date)
                      .getFullYear()
                      .toString(),
                  });
                }}
              />

              {(submitted && !user_birth_date) ||
                (submitted && !user_birth_year && (
                  <div className="help-block">Date of birth is required</div>
                ))}
            </div>

            {/* Here, we utilize the location component to get the location data */}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({ ...locationList, countryList: value });
              }}
              stateList={(value) => {
                setLocationList({ ...locationList, stateList: value });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_country ? " has-error" : "")
              }
            >
              <label htmlFor="user_country"> Country*</label>
              <select
                name="user_country"
                placeholder="Select Country"
                className="form-control"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select option</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !user_country && (
                <div className="help-block"> country is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_state ? " has-error" : "")
              }
            >
              <label htmlFor="user_state"> State*</label>

              <select
                name="user_state"
                placeholder="select State"
                className="form-control"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select option</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !user_state && (
                <div className="help-block">State is required.</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_city ? " has-error" : "")
              }
            >
              <label htmlFor="user_city">City*</label>

              <select
                name="user_city"
                placeholder="Select City"
                className="form-control"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select option</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !user_city && (
                <div className="help-block">City is required</div>
              )}
            </div>
          </div>

          <button className="btn btn-rounded btn-primary btn-block mt-4">
            {registering === true ? (
              <Beat loading={registering} />
            ) : (
              "Register "
            )}
          </button>
        </form>
        <div className="mt-3 mb-4 text-center">
          <a className="text-muted" href="/auth/login">
            {" "}
            <u>Sign In</u>
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}
