import React from "react";

export default function SideNavUser() {
  const currentPage = window.location.href.split("/")[3];

  return (
    <div className="side-content-wrap">
      <div
        className="sidebar-left open rtl-ps-none"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul className="navigation-left">
          <li
            className={
              currentPage === "userDashboard" ? "nav-item active" : "nav-item"
            }
          >
            <a className="nav-item-hold" href="/userDashboard">
              <i className="nav-icon i-Dashboard" />
              <span className="nav-text">Dashboard</span>
            </a>
          </li>
          <li
            className={
              currentPage === "trackOrder" ? "nav-item active " : "nav-item "
            }
          >
            <a className="nav-item-hold" href="/user/trackOrder">
              <i className="nav-icon  i-Map-Marker" />
              <span className="nav-text">Track Order</span>
            </a>
          </li>
          <li
            className={
              currentPage === "userOrders" ? "nav-item active " : "nav-item "
            }
          >
            <a className="nav-item-hold" href="/user/userOrders">
              <i className="nav-icon  i-Shopping-Cart" />
              <span className="nav-text">Orders</span>
            </a>
          </li>
          <li
            className={
              currentPage === "invite" ? "nav-item active " : "nav-item "
            }
          >
            <a className="nav-item-hold" href="/invite/me">
              <i className="nav-icon i-Library" />
              <span className="nav-text">Accept Invite</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-item-hold" href="/auth/registerCompany">
              <i className="nav-icon  i-Management" />
              <span className="nav-text">Register Company</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="sidebar-overlay" />
    </div>
  );
}
