import axios from 'axios';
import * as React from 'react';
import PropTypes from 'prop-types';

function Location({country_id='', state_id='', city_id='', countryList, stateList, cityList, country_name='', state_name='', city_name=''}) {
    const [region, setRegion] = React.useState([]);
    const [country, setCountry] = React.useState({});
    const [state, setState] = React.useState({});
    const [city, setCity] = React.useState({});

    React.useEffect(() => {

        const getAvailableRegions = async () => {
            let earthRegions = await JSON.parse(localStorage.getItem('EARTH_REGIONS'));
            if (earthRegions && earthRegions.data && Array.isArray(earthRegions.data.data)) {
                setRegion(earthRegions.data.data);
                countryList(earthRegions.data.data);
                return;
            }

            axios.post('https://server.courier.test.ogwugo.xyz/api/region/earth/sort/index', {})
            .then((response) => {
                localStorage.setItem('EARTH_REGIONS', JSON.stringify(response));
                setRegion(response.data.data);
                countryList(response.data.data);
            })
            .catch((error) => {
                // console.log(error);
            });
        }

        getAvailableRegions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This method takes a country id and searches for it. if found, it returns a list of states
    React.useEffect(() => {
        if (region.length < 1 || country_id==='') {return;}
        let country = region.find(element => element.id === country_id);

        if (country) {
            setCountry(country);
            stateList(country.states);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country_id, region]);

    // This method takes a state id and searches for it. if found, it returns a list of cites
    React.useEffect(() => {
        if (!country.states || country.states.length < 1 || state_id==='') {return;}
        let state = country.states.find(element => element.id === state_id);

        if (state) {
            setState(state);
            cityList(state.cities);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id, region]);

    // This method takes a city id and searches for it. if found, does nothing much
    React.useEffect(() => {
        if (!state.cities || state.cities.length < 1 || city_id==='') {return;}
        let city = state.cities.find(element => element.id === city_id);

        if (city) {
            setCity(city);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city_id, region]);

    // This is for future expansion in case city streets are added
    React.useEffect(() => {
        //
    }, [city]);

    // This method takes a country name and searches for it. if found, it returns a list of states
    React.useEffect(() => {
        if (region.length < 1 || country_name==='') {return;}
        // let country = region.find(element => (new RegExp(element.name, 'g')).test(country_name.trim()) );
        let country = region.find(element =>  element.name === country_name);

        if (country) {
            setCountry(country);
            stateList(country.states);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country_name,region]);

    // This method takes a state name and searches for it. if found, it returns a list of cities
    React.useEffect(() => {
        if (!country.states || country.states.length < 1 || state_name==='') {return;}
        // let state = country.states.find(element => (new RegExp(element.name, 'g')).test(state_name.trim()) );
        let state = country.states.find(element => element.name === state_name);

        if (state) {
            setState(state);
            cityList(state.cities);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_name,country]);

    // This method takes a city name and searches for it. if found, does nothing much
    React.useEffect(() => {
        if (!state.cities || state.cities.length < 1 || city_name==='') {return;}
        // let city = state.cities.find(element => (new RegExp(element.name, 'g')).test(city_name.trim()) );
        let city = state.cities.find(element => element.name === city_name );

        if (city) {
            setCity(city);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city_name,state]);

    return (null);
}

// PropTypes
Location.propTypes = {
    country_id: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
    state_id: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
    city_id: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
    countryList: PropTypes.func.isRequired,
    stateList: PropTypes.func.isRequired,
    cityList: PropTypes.func.isRequired,
}

export default Location
